import React, { Fragment, useEffect, useState } from "react";
import { Card, Row, CardHeader } from "reactstrap";
import { useHistory } from "react-router-dom";
import firebase from "../../data/firebase";
import MaterialUiTable from "./MaterialUiTable";

const TravellersTable = (props) => {
  let { travellersUid } = props;
  const history = useHistory();
  const [travellerList, setTravellerList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var allUserData = {};
    var listData = [];
    const fetchAllUserData = async () => {
      const responseUser = await firebase
        .firestore()
        .collection("aggregateList")
        .doc("userList")
        .get();

      var data = responseUser.data();
      allUserData = data;

      if (travellersUid && travellersUid.length > 0) {
        travellersUid.forEach((uid) => {
          const userData = allUserData[uid];
          listData.push({
            id: uid,
            avatar: userData.avatar,
            name: userData.fullName,
            email: userData.email,
          });
        });
      }

      setTravellerList(listData);
    };

    fetchAllUserData().then(() => {
      setLoading(false);
    });
  }, []);

  const columnsTraveller = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "avatar",
      label: " ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img className="avatar-sm" src={value} alt={value} />;
        },
        searchable: false,
      },
    },
    {
      name: "name",
      label: "NAMA",
    },
    {
      name: "email",
      label: "EMAIL",
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const toUserDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/users/user-detail/${id}`);
  };

  return (
    <Fragment>
      <Card className="mx-4">
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Traveller List</h5>
            <p className="text-muted">(total {travellerList.length})</p>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columnsTraveller}
            data={travellerList}
            toItemDetail={toUserDetail}
            sortInitialColumn="name"
            searchableCols="Nama, Email"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default TravellersTable;
