import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { Plus } from "react-feather";
import PriceTypeInput from "./PriceTypeInput";

const PriceTypeComponent = (props) => {
  const { value, setValue, priceRangeId, setDefaultPrice } = props;
  const firstInput = {};
  const [state, setState] = useState(value || []);

  const addInput = () => {
    setState([...state, { ...firstInput }]);
  };

  const handleChange = (e, idx) => {
    const updatedState = [...state];
    var fieldName = "";
    var value = "";
    var id = 0;

    const className = e.target.className;

    if (className.includes("form-check-input")) {
      fieldName = className.split(" form-check-input")[0];
      id = e.target.dataset.id;
      updatedState[id][fieldName] = e.target.checked;

      // if default price set => set other checkboxes to false
      if (fieldName === "isDefaultPrice" && updatedState[id][fieldName]) {
        setDefaultPrice(priceRangeId, id);
      }
    } else {
      fieldName = className.split(" form-control")[0];
      value = e.target.value;
      id = e.target.dataset.id;
      updatedState[id][fieldName] = value;
    }

    setState(updatedState);
    setValue(updatedState);
  };

  const deleteInput = (id) => {
    const updatedState = [...state];
    updatedState.splice(id, 1);
    setState(updatedState);
    setValue(updatedState);
  };

  return (
    <Col className="ml-auto">
      {state &&
        state.map((val, idx) => (
          <PriceTypeInput
            key={`priceRange-${idx}`}
            idx={idx}
            state={state}
            handleChange={handleChange}
            deleteInput={deleteInput}
          />
        ))}

      <Button
        type="button"
        color="primary"
        className="btn-info-gradien mr-2 px-3 button-vertical-center"
        onClick={addInput}
      >
        <Plus /> Tambah Tipe
      </Button>
    </Col>
  );
};

export default PriceTypeComponent;
