import React from "react";
import Select from "react-select";
import {
  Container,
  Col,
  Card,
  FormGroup,
  Row,
  Input,
  Label,
  Form,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import Dropzone from "../../common/Dropzone";
import FileDownload from "../../common/FileDownload";
import sprite from "../../../assets/images/orion-svg-sprite.svg";
import OrionIcon from "../../../assets/images/orion-icon.pdf";

const BannerComponent = (props) => {
  const { idx, bannerState, handleBannerChange, handleBannerLinksChange } =
    props;

  const imageId = `image-${idx}`;
  const titleId = `title-${idx}`;
  const subtitleId = `subtitle-${idx}`;
  const contentId = `content-${idx}`;
  const buttonTextId = `buttonText-${idx}`;
  const buttonLinkId = `buttonLink-${idx}`;

  const linkOptions = [
    { value: "none", label: "None" },
    { value: "iconsRight", label: "Icons Right" },
    { value: "iconsBottom", label: "Icons Bottom" },
    { value: "blocks", label: "Blocks" },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col md="1" className="px-0">
          <Button
            type="button"
            color="danger"
            data-id={idx}
            onClick={() => props.deleteInput(idx)}
          >
            x
          </Button>
        </Col>
        <Col md="2" className="px-0">
          <h4>Slide {idx + 1}</h4>
        </Col>
      </Row>
      <Dropzone
        path="webImages"
        name="bannerBeranda"
        handlePhotoUploaded={(photo) => handleBannerChange(photo, "photo", idx)}
      />
      <div
        style={{
          backgroundImage: `url(${bannerState[idx].photo})`,
          fontFamily: "Poppins,sans-serif",
        }}
        className="vh-100 bg-cover dark-overlay"
      >
        <Container className="h-100">
          <div
            data-swiper-parallax="true"
            className="d-flex h-100 w-100 text-white overlay-content align-items-center"
          >
            <div className="w-100">
              <Row>
                <Col lg={bannerState[idx].linksType === "blocks" ? "12" : "6"}>
                  <p className="subtitle text-white letter-spacing-3 mb-3 font-weight-light text-uppercase">
                    {bannerState[idx].subtitle}
                  </p>
                  <h2
                    style={{ lineHeight: "1" }}
                    className="display-3 font-weight-bold mb-3"
                  >
                    {bannerState[idx].title}
                  </h2>
                  <p className="mb-5">{bannerState[idx].content}</p>
                  {bannerState[idx].buttonText && bannerState[idx].buttonLink && (
                    <Button color="outline-light">
                      <span style={{ color: "white" }}>
                        {bannerState[idx].buttonText}
                      </span>
                      <i className="fa fa-angle-right ml-2" />
                    </Button>
                  )}
                </Col>
                <Col
                  lg={bannerState[idx].linksType === "iconsRight" ? "6" : "12"}
                  className="pl-lg-5 my-3 my-md-5 my-lg-0"
                >
                  {bannerState[idx].linksType === "iconsRight" &&
                    bannerState[idx].icons &&
                    bannerState[idx].icons.map((icon, id) => (
                      <div
                        key={id}
                        className="media text-reset text-decoration-none hover-animate mb-2 mb-md-5 cursor-pointer"
                      >
                        <div className="icon-rounded bg-white opacity-7 mr-4">
                          <svg
                            className="svg-icon text-dark"
                            style={{ width: "2rem", height: "2rem" }}
                          >
                            <use xlinkHref={sprite + "#" + icon.icon} />
                          </svg>
                        </div>
                        <div className="media-body">
                          {icon.title && <h5>{icon.title}</h5>}
                          {icon.badge && (
                            <Badge color="light">{icon.badge}</Badge>
                          )}
                          {icon.content && <p>{icon.content}</p>}
                        </div>
                      </div>
                    ))}
                </Col>
              </Row>
              {bannerState[idx].linksType === "iconsBottom" && (
                <Row className="mt-3 mt-md-5">
                  {bannerState[idx].icons &&
                    bannerState[idx].icons.map((icon) => (
                      <Col key={icon.title} md="4" className="mb-2 mb-md-0">
                        <a
                          href="/"
                          className="media text-reset text-decoration-none hover-animate mb-2 mb-md-5"
                        >
                          <div className="icon-rounded bg-white opacity-7 mr-4">
                            <svg
                              className="svg-icon text-dark"
                              style={{ width: "2rem", height: "2rem" }}
                            >
                              <use xlinkHref={sprite + "#" + icon.icon} />
                            </svg>
                          </div>
                          <div className="media-body">
                            <h5>{icon.title}</h5>
                            {icon.badge && (
                              <Badge color="light">{icon.badge}</Badge>
                            )}
                            {icon.content && <p>{icon.content}</p>}
                          </div>
                        </a>
                      </Col>
                    ))}
                </Row>
              )}

              {bannerState[idx].linksType === "blocks" && (
                <Row>
                  {bannerState[idx].icons &&
                    bannerState[idx].icons.map((block) => (
                      <Col key={block.title} md="4" className="d-md-block mb-5">
                        <Card className="h-100 border-0 shadow-lg bg-dark hover-animate">
                          <div className="card-img-top overflow-hidden gradient-overlay">
                            <img
                              src={block.image}
                              alt={block.title}
                              className="img-fluid"
                              style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <CardBody className=" d-flex align-items-center">
                            <div className="w-100">
                              <CardTitle tag="h6">
                                <p className="text-decoration-none text-white">
                                  {block.title}
                                </p>
                              </CardTitle>
                              <CardSubtitle className="d-flex">
                                <p className="flex-grow-1 mb-0 text-muted text-sm">
                                  {block.subtitle}
                                </p>
                              </CardSubtitle>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                </Row>
              )}
            </div>
          </div>
        </Container>
      </div>

      <FileDownload url={bannerState[idx].photo} />
      <Label className="col-form-label">
        Title <span className="text-danger">*</span>
      </Label>
      <Input
        type="text"
        input={titleId}
        name={titleId}
        id={titleId}
        data-id={idx}
        className="title"
        value={bannerState[idx].title || ""}
        onChange={(e) => handleBannerChange(e, "title", idx)}
      />
      <Label className="col-form-label">Subtitle</Label>
      <Input
        type="text"
        input={subtitleId}
        name={subtitleId}
        id={subtitleId}
        data-id={idx}
        className="subtitle"
        value={bannerState[idx].subtitle || ""}
        onChange={(e) => handleBannerChange(e, "subtitle", idx)}
      />
      <Label className="col-form-label">Content</Label>
      <Input
        type="textarea"
        input={contentId}
        name={contentId}
        id={contentId}
        data-id={idx}
        className="content"
        value={bannerState[idx].content || ""}
        onChange={(e) => handleBannerChange(e, "content", idx)}
      />
      <Row>
        <Col lg="1">
          <Label className="col-form-label">Button</Label>
        </Col>
        <Col lg="11">
          <Label className="col-form-label">Text</Label>
          <Input
            type="text"
            input={buttonTextId}
            name={buttonTextId}
            id={buttonTextId}
            data-id={idx}
            className="buttonText"
            value={bannerState[idx].buttonText || ""}
            onChange={(e) => handleBannerChange(e, "buttonText", idx)}
          />

          <Label className="col-form-label">Link</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>https://getgoing.co.id/</InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              input={buttonLinkId}
              name={buttonLinkId}
              id={buttonLinkId}
              data-id={idx}
              className="buttonLink"
              value={bannerState[idx].buttonLink || ""}
              onChange={(e) => handleBannerChange(e, "buttonLink", idx)}
            />
          </InputGroup>
        </Col>
      </Row>

      <Label className="col-form-label">Links</Label>
      <Select
        id="links"
        instanceId="links"
        name="links"
        options={linkOptions}
        onChange={(e) => handleBannerChange(e, "linksType", idx)}
        className="selectpicker mb-3"
        classNamePrefix="selectpicker"
        defaultValue="none"
      />
      {(bannerState[idx].linksType === "iconsRight" ||
        bannerState[idx].linksType === "iconsBottom") && (
        <a href={OrionIcon} target="_blank" className="anchor-button w-20">
          Lihat semua icon
        </a>
      )}

      {(bannerState[idx].linksType === "iconsRight" ||
        bannerState[idx].linksType === "iconsBottom") &&
        [0, 1, 2].map((id) => (
          <Row className="mt-3 mb-5" key={id}>
            <Col lg="1">
              <span>{id + 1}</span>
            </Col>
            <Col>
              <Label className="col-form-label">Icon</Label>
              <Input
                className="form-control"
                type="text"
                name="bannerIcon"
                value={
                  bannerState[idx].icons &&
                  bannerState[idx].icons[id] &&
                  bannerState[idx].icons[id].icon
                }
                onChange={(icon) =>
                  handleBannerLinksChange(idx, id, "icon", icon)
                }
              />
              <Label className="col-form-label">Link</Label>

              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>https://getgoing.co.id/</InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-control"
                  type="text"
                  name="bannerLink"
                  defaultValue={
                    bannerState[idx].icons &&
                    bannerState[idx].icons[id] &&
                    bannerState[idx].icons[id].link
                  }
                  onChange={(link) =>
                    handleBannerLinksChange(idx, id, "link", link)
                  }
                />
              </InputGroup>
            </Col>
            <Col>
              <Label className="col-form-label">Title</Label>
              <Input
                className="form-control"
                type="text"
                name="bannerSubtitle"
                value={
                  bannerState[idx].icons &&
                  bannerState[idx].icons[id] &&
                  bannerState[idx].icons[id].title
                }
                onChange={(title) =>
                  handleBannerLinksChange(idx, id, "title", title)
                }
              />
              <Label className="col-form-label">Badge Text</Label>
              <Input
                className="form-control"
                type="text"
                name="bannerSubtitle"
                value={
                  bannerState[idx].icons &&
                  bannerState[idx].icons[id] &&
                  bannerState[idx].icons[id].badge
                }
                onChange={(badge) =>
                  handleBannerLinksChange(idx, id, "badge", badge)
                }
              />
              <Label className="col-form-label">Content</Label>
              <Input
                className="form-control"
                type="text"
                name="bannerSubtitle"
                value={
                  bannerState[idx].icons &&
                  bannerState[idx].icons[id] &&
                  bannerState[idx].icons[id].content
                }
                onChange={(content) =>
                  handleBannerLinksChange(idx, id, "content", content)
                }
              />
            </Col>
          </Row>
        ))}

      {bannerState[idx].linksType === "blocks" &&
        [0, 1, 2].map((id) => (
          <Row className="mt-3 mb-5" key={id}>
            <Col lg="1">
              <span>{id + 1}</span>
            </Col>
            <Col>
              <Label className="col-form-label">Image</Label>
              <Row>
                <Col xl="6" lg="6">
                  <span className="media user-header">
                    <img
                      className="mr-2 img-35 w-100"
                      src={
                        (bannerState[idx].icons &&
                          bannerState[idx].icons[id] &&
                          bannerState[idx].icons[id].image) ||
                        ""
                      }
                      alt={
                        bannerState[idx].icons &&
                        bannerState[idx].icons[id] &&
                        bannerState[idx].icons[id].title
                      }
                    />
                  </span>
                  {bannerState[idx].icons &&
                    bannerState[idx].icons[id] &&
                    bannerState[idx].icons[id].image && (
                      <FileDownload url={bannerState[idx].icons[id].image} />
                    )}
                </Col>
                <Col xl="6" lg="6">
                  <Dropzone
                    path="webImages"
                    name={
                      bannerState[idx].icons &&
                      bannerState[idx].icons[id] &&
                      bannerState[idx].icons[id].title
                    }
                    handlePhotoUploaded={(e) =>
                      handleBannerLinksChange(idx, id, "image", e)
                    }
                  />
                </Col>
              </Row>
              <Label className="col-form-label">Link</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>https://getgoing.co.id/</InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-control"
                  type="text"
                  name="bannerLink"
                  defaultValue={
                    bannerState[idx].icons &&
                    bannerState[idx].icons[id] &&
                    bannerState[idx].icons[id].link
                  }
                  onChange={(link) =>
                    handleBannerLinksChange(idx, id, "link", link)
                  }
                />
              </InputGroup>
            </Col>
            <Col>
              <Label className="col-form-label">Title</Label>
              <Input
                className="form-control"
                type="text"
                name="bannerSubtitle"
                value={
                  bannerState[idx].icons &&
                  bannerState[idx].icons[id] &&
                  bannerState[idx].icons[id].title
                }
                onChange={(title) =>
                  handleBannerLinksChange(idx, id, "title", title)
                }
              />
              <Label className="col-form-label">Subtitle</Label>
              <Input
                className="form-control"
                type="text"
                name="bannerSubtitle"
                value={
                  bannerState[idx].icons &&
                  bannerState[idx].icons[id] &&
                  bannerState[idx].icons[id].subtitle
                }
                onChange={(title) =>
                  handleBannerLinksChange(idx, id, "subtitle", title)
                }
              />
            </Col>
          </Row>
        ))}
    </React.Fragment>
  );
};

export default BannerComponent;
