import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../data/firebase";
import FileDownload from "../common/FileDownload";

const CountryDetail = () => {
  let { country } = useParams();

  const [loading, setLoading] = useState(true);
  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [newCountry, setNewCountry] = useState(true);
  const [countryData, setCountryData] = useState({});

  const [iconPhoto, setIconPhoto] = useState({});

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: false,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotoUploadFinished(false);

      const file = acceptedFiles[0];
      uploadHeroPhoto(file).then((url) => {
        file["preview"] = url;
        setPhotoUploadFinished(true);
        setHeroPhoto(file);
      });
    },
  });

  const uploadHeroPhoto = (file) => {
    return new Promise((resolve, reject) => {
      const fileName = uuidv4();
      const ref = firebase.storage().ref(`cityImages/${fileName}`);
      ref.put(file).then(() => {
        ref
          .getDownloadURL()
          .then((url) => {
            resolve(url);
          })
          .catch((e) => reject(e));
      });
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("countries")
          .doc(country)
          .get();
        if (response.exists) {
          data = response.data();
        }
        setCountryData(data);
        setIconPhoto({ preview: data.iconUrl });
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    if (country !== "new") {
      fetchData();
      setNewCountry(false);
    } else {
      setLoading(false);
    }
  }, []);

  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const updateCityData = (countryDocName, countryData) => {
    firebase
      .firestore()
      .collection("countries")
      .doc(countryDocName)
      .collection("cities")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.update({
            getGuidePrice: countryData.getGuidePrice,
            getGuidePriceExtra: countryData.getGuidePriceExtra,
          });
        });
      });
  };

  const onSubmit = (data) => {
    const countryDocName = newCountry ? data.docName : country;
    const countryData = {
      id: data.id,
      displayName: data.displayName || "",
      iconUrl: data.iconUrl || "",
      // getGuidePrice: data.getGuidePrice || "",
      // getGuidePriceExtra: data.getGuidePriceExtra || "",
    };
    firebase
      .firestore()
      .collection("countries")
      .doc(countryDocName)
      .set(countryData, { merge: true })
      .then(() => {
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("countryList")
          .set(
            {
              [countryData.id]: {
                displayName: data.displayName,
                iconUrl: data.iconUrl,
                docName: countryDocName,
              },
            },
            { merge: true }
          )
          .then(() => {
            if (newCountry) {
              firebase
                .firestore()
                .collection("aggregateList")
                .doc("countryCityList")
                .set(
                  {
                    [countryDocName]: [],
                  },
                  { merge: true }
                );
            }
            // updateCityData(countryDocName, countryData);
            toast.success("Berhasil disimpan", {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/country/country-list`);
          });
      });
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      {/* <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>
        <ModalFooter>
          <ModalBody>Hapus Kota?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteCity}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal> */}
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2>Detail Negara</h2>

                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <Label className="col-form-label my-3">
                      Icon Bendera (imagekit){" "}
                      <span className="text-danger">*</span>
                    </Label>
                    {!newCountry && countryData.iconUrl && (
                      <FileDownload url={countryData.iconUrl} />
                    )}
                    <Input
                      className="form-control"
                      type="text"
                      name="iconUrl"
                      innerRef={register({ required: false })}
                      defaultValue={countryData.iconUrl}
                      required
                    />
                    {/* <div
                      {...getRootProps({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps()} />
                      <div>
                        <div className="dz-message text-muted">
                          <p>Klik untuk upload foto baru (max 1)</p>
                          <img
                            src={iconPhoto.preview}
                            alt={"icon"}
                            className="avatar avatar-large mb-2"
                          />
                          {!photoUploadFinished && (
                            <div className="loader-box">
                              <div className="loader-3"></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div> */}
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Kode Negara (ISO Code){" "}
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="id"
                      innerRef={register({ required: false })}
                      defaultValue={countryData.id}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Nama Dokumen di Firebase{" "}
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="docName"
                      innerRef={register({ required: false })}
                      defaultValue={newCountry ? "" : country}
                      disabled={!newCountry}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Nama Tampilan</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="displayName"
                      innerRef={register({ required: false })}
                      defaultValue={countryData.displayName}
                      required
                    />
                  </FormGroup>

                  {/* <FormGroup>
                    <Label className="col-form-label">
                      Harga GetGuide per Grup per 5 Jam (dalam RUPIAH)
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="getGuidePrice"
                      innerRef={register({ required: false })}
                      defaultValue={countryData.getGuidePrice}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Harga GetGuide per Ekstra Jam (dalam RUPIAH)
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="getGuidePriceExtra"
                      innerRef={register({ required: false })}
                      defaultValue={countryData.getGuidePriceExtra}
                    />
                  </FormGroup> */}

                  <FormGroup className="form-row mt-5 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      disabled={!photoUploadFinished}
                    >
                      Simpan Negara
                    </Button>
                    {/* {!newCity && (
                      <Button
                        className="btn-secondary-gradien mr-2"
                        type="button"
                        onClick={toggle}
                      >
                        Hapus Kota
                      </Button>
                    )} */}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default CountryDetail;
