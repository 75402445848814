import React, { useContext } from "react";
import PropTypes, { instanceOf } from "prop-types";
import { Media } from "reactstrap";
import moment from "moment";
import LastMessage from "./LastMessage";
import Avatar from "../helper/Avatar";
import Flex from "../helper/Flex";
import ChatSidebarDropdownAction from "./ChatSidebarDropdownAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChatContext } from "../helper/Context";
import classNames from "classnames";
import { useAuth } from "../../../services/auth-context";
import { formatDate } from "../helper/helperFuncs";

const ChatThread = ({ thread }) => {
  const {
    threadsDispatch,
    messages,
    activeThreadId,
    setActiveThreadId,
    getUser,
  } = useContext(ChatContext);

  const lastMessage = thread?.lastMessage ? thread?.lastMessage : {};

  const auth = useAuth();
  const currentUserId = auth.user.uid;

  const user = getUser(thread);

  const isToday = (date) => {
    if (!(date instanceof Date)) {
      date = date.toDate();
    }

    return moment().isSame(moment(date), "day");
  };

  return (
    <Media
      className={classNames(`chat-contact hover-actions-trigger w-100 `, {
        "unread-message": !thread.read,
        "read-message": thread.read,
        active: thread.messagesId === activeThreadId,
      })}
      onClick={() => {
        setActiveThreadId(thread.messagesId);
        threadsDispatch({
          type: "EDIT",
          id: thread.messagesId,
          payload: { ...thread, read: true },
          contentType: "thread",
        });

        document.getElementsByClassName("chat-sidebar")[0].style.left = "-100%";
      }}
    >
      {user ? (
        <>
          <Avatar className={user.status} src={user.avatar} size="xl" />
          <Media body className="chat-contact-body ml-2 d-md-none d-lg-block">
            <Flex justify="between">
              <h6 className="mb-0 chat-contact-title">{user.fullName}</h6>
              <span className="message-time fs--2">
                {" "}
                {!!lastMessage && lastMessage.time
                  ? isToday(lastMessage.time)
                    ? formatDate(lastMessage.time).hour
                    : formatDate(lastMessage.time).datenumber
                  : null}{" "}
              </span>
            </Flex>
            <div className="min-w-0">
              {!!lastMessage && Object.keys(lastMessage).length > 0 ? (
                <div
                  className="chat-contact-content pr-3"
                  dangerouslySetInnerHTML={{
                    __html: LastMessage(lastMessage, thread),
                  }}
                />
              ) : (
                <p>Say hi</p>
              )}

              {/* <ChatSidebarDropdownAction /> */}
              {/* <div className="position-absolute b-0 r-0 hover-hide"> */}
              <div
                className="position-absolute"
                style={{ right: 0, bottom: 0 }}
              >
                {!!lastMessage?.status && (
                  <FontAwesomeIcon
                    icon={classNames({
                      check:
                        lastMessage.senderUserId === currentUserId &&
                        lastMessage.status === "delivered",
                      "check-double":
                        lastMessage.senderUserId === currentUserId &&
                        lastMessage.status === "read",
                    })}
                    transform="shrink-5 down-4"
                    className={classNames({
                      "text-success": lastMessage.status === "read",
                      "text-400":
                        lastMessage.status === "delivered" ||
                        lastMessage.status === "sent",
                    })}
                  />
                )}
              </div>
            </div>
          </Media>
        </>
      ) : null}
    </Media>
  );
};

ChatThread.propTypes = {
  thread: PropTypes.object.isRequired,
};

export default ChatThread;
