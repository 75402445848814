import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import store from "./store/index";
import { routes } from "./route/ContentRoutes";
import ConfigDB from "./data/customizer/config";
import Signin from "./auth/signin";
import App from "./components/app";
import ProtectedRoute from "./components/common/ProtectedRoute";
import { ProvideAuth } from "./services/auth-context";
import Error403 from "./pages/Error403";

import "./components/chat/helper/initFA";

const Root = (props) => {
  const [anim, setAnim] = useState("");
  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const abortController = new AbortController();

  useEffect(() => {
    setAnim(animation);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <ProvideAuth>
      <Fragment>
        <Provider store={store}>
          <BrowserRouter basename={`/`}>
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                component={Signin}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error403`}
                component={Error403}
              ></Route>
              <Fragment>
                <App>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return (
                        <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
                      );
                    }}
                  />
                  <TransitionGroup>
                    {routes.map(({ path, Component }) => (
                      <ProtectedRoute
                        key={path}
                        redirectTo="/login"
                        notAuthorized="/pages/errors/error403"
                        path={path}
                      >
                        <Route key={path} exact path={path}>
                          {({ match }) => (
                            <CSSTransition
                              in={match != null}
                              timeout={100}
                              classNames={anim}
                              unmountOnExit
                            >
                              <div>
                                <Component />
                              </div>
                            </CSSTransition>
                          )}
                        </Route>
                      </ProtectedRoute>
                    ))}
                  </TransitionGroup>
                </App>
              </Fragment>
            </Switch>
          </BrowserRouter>
        </Provider>
      </Fragment>
    </ProvideAuth>
  );
};
ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
