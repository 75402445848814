import React, { Fragment, useState, useEffect } from "react";
import { Container, Col, Card, FormGroup, Form, Button } from "reactstrap";
import { toast } from "react-toastify";
import firebase from "../../data/firebase";
import EntryComponent from "./tnc-faq-components/EntryComponent";

const TNCFAQGeneral = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("content")
          .doc("FAQ-TNC-General")
          .get();
        var responseData = response.data();
        setData(responseData);

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const setValue = (type, product, value) => {
    const prevData = data;
    prevData[product][type] = value;
    setData(prevData);
  };

  const setToProduct = (data) => {
    if (data.getGuide) {
      firebase
        .firestore()
        .collection("getGuide")
        .get()
        .then((snapshot) => {
          snapshot.forEach(function (doc) {
            doc.ref.update({
              tncGeneral: data.getGuide.tnc,
              faqGeneral: data.getGuide.faq,
              cancellationPolicyGeneral: data.getGuide.cancellationPolicy,
            });
          });
        });
    }
    if (data.getInfo) {
      firebase
        .firestore()
        .collection("getInfo")
        .get()
        .then((snapshot) => {
          snapshot.forEach(function (doc) {
            doc.ref.update({
              tncGeneral: data.getInfo.tnc,
              faqGeneral: data.getInfo.faq,
            });
          });
        });
    }
    if (data.getFlexBasic) {
      firebase
        .firestore()
        .collection("getFlex")
        .where("type", "==", "basic")
        .get()
        .then((snapshot) => {
          snapshot.forEach(function (doc) {
            doc.ref.update({
              tncGeneral: data.getFlexBasic.tnc,
              faqGeneral: data.getFlexBasic.faq,
              cancellationPolicyGeneral: data.getFlexBasic.cancellationPolicy,
            });
          });
        });
    }
  };

  const onSubmit = () => {
    firebase
      .firestore()
      .collection("content")
      .doc("FAQ-TNC-General")
      .set(data, { merge: true })
      .then(() => {
        setToProduct(data);
        toast.success("Berhasil disimpan", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch(function (error) {
        console.log("error " + error);
      });
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Col className="p-3">
          <Card className="mt-4 p-3">
            <div className="mt-3 mb-5">
              <h2>Syarat &amp; Ketentuan General</h2>

              <h4>TNC GetGuide</h4>
              <EntryComponent
                value={data.getGuide.tnc}
                setValue={(e) => setValue("tnc", "getGuide", e)}
              />
              <h4 className="mt-5">TNC GetInfo</h4>
              <EntryComponent
                value={data.getInfo.tnc}
                setValue={(e) => setValue("tnc", "getInfo", e)}
              />
              <h4 className="mt-5">TNC GetFlex Basic</h4>
              <EntryComponent
                value={data.getFlexBasic.tnc}
                setValue={(e) => setValue("tnc", "getFlexBasic", e)}
              />
            </div>
            <hr />
            <div className="mt-3 mb-5">
              <h2>FAQ General</h2>

              <h4>FAQ GetGuide</h4>
              <EntryComponent
                value={data.getGuide.faq}
                setValue={(e) => setValue("faq", "getGuide", e)}
              />
              <h4 className="mt-5">FAQ GetInfo</h4>
              <EntryComponent
                value={data.getInfo.faq}
                setValue={(e) => setValue("faq", "getInfo", e)}
              />
              <h4 className="mt-5">FAQ GetFlex Basic</h4>
              <EntryComponent
                value={data.getFlexBasic.faq}
                setValue={(e) => setValue("faq", "getFlexBasic", e)}
              />
            </div>
            <hr />

            <div className="mt-3 mb-5">
              <h2>Kebjiakan Pembatalan General</h2>

              <h4>Kebjiakan Pembatalan GetGuide</h4>
              <EntryComponent
                value={data.getGuide.cancellationPolicy}
                setValue={(e) => setValue("cancellationPolicy", "getGuide", e)}
              />
              <h4 className="mt-5">Kebjiakan Pembatalan GetFlex Basic</h4>
              <EntryComponent
                value={data.getFlexBasic.cancellationPolicy}
                setValue={(e) =>
                  setValue("cancellationPolicy", "getFlexBasic", e)
                }
              />
            </div>

            <FormGroup className="form-row my-4">
              <Button
                className="btn-success-gradien mr-2"
                type="submit"
                onClick={onSubmit}
              >
                Simpan
              </Button>
            </FormGroup>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default TNCFAQGeneral;
