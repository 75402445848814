import LanguageMapData from "../data/languageMapData.json";
import firebase from "../data/firebase";
import { countryCodeList } from "../util/CountryCodes";

const isoLang = LanguageMapData.isoLangs;

export function upperCaseFirst(string) {
  return string !== "" && string !== undefined
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : "";
}

export function getLanguageOptions() {
  var langOptionArray = [];
  for (const [key, value] of Object.entries(isoLang)) {
    if (isoLang[key]["icon"]) {
      langOptionArray.push({
        value:
          key +
          "," +
          isoLang[key]["name"] +
          isoLang[key]["nativeName"] +
          isoLang[key]["indonesianName"],
        label:
          isoLang[key]["nativeName"] +
          " (" +
          isoLang[key]["indonesianName"] +
          ")",
        nativeName: isoLang[key]["nativeName"],
      });
    }
  }
  return langOptionArray;
}

export function getUserLanguages(languages) {
  var langArray = [];
  languages?.forEach((lang) => {
    if (!lang.includes("Bahasa Lain")) {
      var langObj = {
        value:
          lang +
          "," +
          isoLang[lang]["name"] +
          isoLang[lang]["nativeName"] +
          isoLang[lang]["indonesianName"],
        label: isoLang[lang]["nativeName"],
        nativeName: isoLang[lang]["nativeName"],
      };
      langArray.push(langObj);
    }
  });
  return langArray;
}

export const fetchCityData = async () => {
  try {
    const response = await firebase
      .firestore()
      .collection("aggregateList")
      .doc("countryCityList")
      .get();
    var responseData = response.data();
    var data = [];
    for (const key in responseData) {
      const value = responseData[key];
      value.forEach((city) => {
        data.push({
          country: key,
          city: city.split(",")[0],
          img: city.split(",")[1],
        });
      });
    }
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getCityImage = (cityName, cityData) => {
  var img = "";
  cityData.forEach((c) => {
    if (c.city === cityName) {
      img = c.img;
    }
  });
  return img;
};

export const replaceImageUrl = (url, width, height) => {
  if (typeof url === "undefined") return undefined;
  if (typeof width === "undefined" || typeof height === "undefined") {
    return url.replace(
      "https://firebasestorage.googleapis.com",
      "https://ik.imagekit.io/getgoing"
    );
  }
  return url.replace(
    "https://firebasestorage.googleapis.com",
    `https://ik.imagekit.io/getgoing/tr:w-${width},h-${height}`
  );
};

const getDialCode = (countryCode) => {
  if (!countryCode) return "";
  const filteredItem = countryCodeList.filter(
    (item) => item.code === countryCode
  );
  if (filteredItem && filteredItem.length > 0) {
    return filteredItem[0].dial_code;
  }
  return "";
};

export const getPhoneNumber = (phoneString) => {
  if (phoneString) {
    const arr = phoneString.split("_");
    const dialCode = getDialCode(arr[0]);
    const number = arr[1] ? arr[1] : phoneString;
    return dialCode + number;
  }
  return "";
};

export const formatSlug = (title) => {
  return title.toLowerCase().replace(/ /g, "-");
};

export const sendPushNotification = (pushToken, title, body, data) => {
  const message = {
    to: pushToken,
    title: title,
    body: body,
    data: data,
    sound: "default",
    _displayInForeground: "true",
  };

  fetch("https://exp.host/--/api/v2/push/send", {
    mode: "no-cors",
    method: "POST",
    body: JSON.stringify(message),
  })
    .then(() => console.log("notification sent"))
    .catch((err) => console.log(err));
};

export const createDynamicLink = async (path, params, metaTag) => {
  const webApi = "AIzaSyBbLpn6gmwGCBdCKDJstOT8ol7BiyIwGPc";

  let paramsString = "";
  var paramsArr = [];
  if (params && Object.keys(params).length > 0) {
    paramsString = "/";
    for (var key in params) {
      var encodedValue = encodeURIComponent(params[key]);
      paramsArr.push(encodedValue);
    }
    paramsString += paramsArr.join("/");
  }

  const data = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://getgoing.co.id/app",
      link: "https://getgoing.co.id/" + path + paramsString,
      androidInfo: {
        androidPackageName: "com.getgoing.app",
      },
      iosInfo: {
        iosBundleId: "com.getgoing.app",
      },
      socialMetaTagInfo: {
        socialTitle: metaTag.title,
        socialDescription: metaTag.description,
        socialImageLink: metaTag.imageLink,
      },
    },
    suffix: {
      option: "UNGUESSABLE",
    },
  };

  const formString = JSON.stringify(data);

  return fetch(
    "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" + webApi,
    {
      method: "POST",
      body: formString,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
