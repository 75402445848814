import React from "react";
import PropTypes from "prop-types";
import { Media } from "reactstrap";
import { Link } from "react-router-dom";
import Avatar from "../helper/Avatar";

const ChatContentBodyIntro = ({ user, isGroup }) => {
  return (
    <Media className="position-relative p-3 border-bottom mb-3 align-items-center">
      <Avatar className={`${user.status} mr-3`} size="2xl" src={user.avatar} />
      <Media body className="ml-3">
        <h6 className="mb-0">
          <Link
            to="/pages/profile"
            className=" text-decoration-none stretched-link text-700"
          >
            {user.fullName}
          </Link>
        </h6>
        {/* <p className="mb-0">
        {isGroup
          ? `You are a member of ${user.name}. Say hi to start conversation to the group.`
          : `You friends with ${user.name}. Say hi to start the conversation`}
      </p> */}
        <p className="mb-0">User</p>
      </Media>
    </Media>
  );
};

ChatContentBodyIntro.propTypes = {
  isGroup: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default ChatContentBodyIntro;
