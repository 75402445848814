import React, { useState, useContext } from "react";
import { TabContent, TabPane } from "reactstrap";
import ChatContentHeader from "./ChatContentHeader";
import MessageTextArea from "./MessageTextArea";
import ChatContentBody from "./ChatContentBody";
import { ChatContext } from "../helper/Context";

const ChatContent = () => {
  const { threads, activeThreadId, loading } = useContext(ChatContext);
  const [isOpenThreadInfo, setIsOpenThreadInfo] = useState(false);

  if (loading) {
    return <p>loading...</p>;
  }

  const thread =
    threads && threads.find(({ messagesId }) => messagesId === activeThreadId);

  return (
    <TabContent className="card-chat-content fs--1 position-relative">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <TabPane className="card-chat-pane active">
            <ChatContentHeader
              thread={thread}
              setIsOpenThreadInfo={setIsOpenThreadInfo}
            />
            <ChatContentBody
              thread={thread}
              isOpenThreadInfo={isOpenThreadInfo}
            />
          </TabPane>

          <MessageTextArea thread={thread} />
        </div>
      )}
    </TabContent>
  );
};

export default ChatContent;
