import React, { Fragment, useEffect, useState } from "react";
import { Card, Row, CardHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Plus } from "react-feather";
import MUIDataTable from "mui-datatables";
import CustomFooter from "../../common/CustomFooter";

const AddItinerary = (props) => {
  const { allItineraryList, preselectedList, handleSelectedRowChange } = props;
  const [preselectedRows, setPreselectedRows] = useState([]);

  useEffect(() => {
    var rows = [];
    for (let i = 0; i < allItineraryList.length; i++) {
      preselectedList.forEach((preselected) => {
        if (preselected.id === allItineraryList[i].id) {
          rows.push(i);
        }
      });
    }
    setPreselectedRows(rows);
  }, [preselectedList, allItineraryList]);

  const history = useHistory();

  const toItineraryDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/itinerary/itinerary-detail/${id}`);
  };

  const columnsItinerary = [
    {
      name: "id",
      options: {
        // display: "excluded",
        filter: false,
      },
    },
    {
      name: "type",
      label: "TIPE",
    },
    {
      name: "dateArr",
      label: "TANGGAL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <p>{value[0]}</p>
              {value.length > 0 ? <p>{value[1]}</p> : null}
            </div>
          );
        },
      },
    },
    {
      name: "timeArr",
      label: "WAKTU",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <p>{value[0]}</p>
              {value.length > 0 ? <p>{value[1]}</p> : null}
            </div>
          );
        },
        filter: false,
      },
    },
    {
      name: "info",
      label: "INFO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ whiteSpace: "pre-line" }}>{value}</span>;
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const tableOptions = {
    print: false,
    viewColumns: false,
    download: false,
    filter: true,
    search: true,
    fixedHeader: false,
    searchOpen: true,
    searchPlaceholder: `Cari...`,

    filterType: "checkbox",
    rowsSelected: preselectedRows,
    onRowClick(rowNode) {
      var id = rowNode[0];
      toItineraryDetail(id);
    },

    onRowSelectionChange(currentRowsSelected, allRowsSelected, rowsSelected) {
      // handleSelectedRowChange(allRowsSelected.map((row) => row.dataIndex));
    },

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      );
    },

    textLabels: {
      body: {
        noMatch: "Tidak ada data",
        toolTip: "Urutkan",
        columnHeaderTooltip: (column) => `Urutkan ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Baris per halaman:",
        displayRows: "dari",
      },
    },

    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => null,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      if (action === "rowSelectionChange") {
        const allRowsSelected = tableState.selectedRows.data;
        handleSelectedRowChange(allRowsSelected.map((row) => row.dataIndex));
      }
    },
  };

  return (
    <Fragment>
      <Card className="mx-4">
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Pilih Itinerary</h5>
            {/* <Button
              type="button"
              className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
              onClick={() => toItineraryDetail("new")}
            >
              <Plus /> Tambah Itinerary Baru
            </Button> */}
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MUIDataTable
            data={allItineraryList}
            columns={columnsItinerary}
            options={tableOptions}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default AddItinerary;
