import React, { Fragment, useEffect } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Plus } from "react-feather";
import MaterialUiTable from "../../common/MaterialUiTable";

const FreeActivitiesList = (props) => {
  const { country, city, freeActivityList } = props;
  var freeActivities = [];
  if (freeActivityList) {
    Object.keys(freeActivityList).forEach((id) => {
      freeActivities.push({ id, ...freeActivityList[id] });
    });
  }

  const history = useHistory();

  const toFreeActivityDetail = (id) => {
    history.push(
      `${process.env.PUBLIC_URL}/free-activity/${country}/${city}/${id}`
    );
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "img",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img className="avatar-rect" src={value} alt={value} />;
        },
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "NAMA",
      options: {
        filter: false,
      },
    },
    {
      name: "category",
      label: "KATEGORI",
    },
  ];

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row className="ml-1">
                  <h5 className="line-height-inherit mr-3">
                    List Kegiatan Gratis
                  </h5>
                  <p className="text-muted">(total {freeActivities.length})</p>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
                    onClick={() => toFreeActivityDetail("new")}
                  >
                    <Plus /> Tambah Kegiatan Gratis
                  </Button>
                </Row>
              </CardHeader>

              <div className="table-responsive">
                <MaterialUiTable
                  columns={columns}
                  data={freeActivities}
                  toItemDetail={toFreeActivityDetail}
                  sortInitialColumn="title"
                  searchableCols="Judul, Kategori"
                  defaultSearchInput={true}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default FreeActivitiesList;
