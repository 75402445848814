import React, { useState, useEffect } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { SingleDatePicker } from "react-dates";
import Select from "react-select";
import "react-dates/initialize";
import firebase from "../../../../data/firebase";
import LinkButton from "../../../common/LinkButton";

const GetGuide = (props) => {
  const {
    itineraryData,
    register,
    setDate,
    setTime,
    handleGetGuideOptionChange,
  } = props;

  const [focused, setFocused] = useState({ focused: false });
  const [loading, setLoading] = useState(true);
  const [getGuideList, setGetGuideList] = useState([]);
  const [selectedGetGuide, setSelectedGetGuide] = useState(
    itineraryData.getGuide
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("getGuideList")
          .get();
        const data = response.data();
        const list = [];
        Object.keys(data).forEach((id) => {
          const getGuideData = data[id];
          list.push({ id: id, ...getGuideData });
        });
        setGetGuideList(list);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData().then(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const getCurrentGetGuideOption = (getGuide) => {
    if (!getGuide) {
      return {
        value: "",
        label: "",
      };
    }
    const option = {
      value: getGuide.id,
      label: getGuide?.author?.fullName + ", " + getGuide?.city,
    };
    return option;
  };

  const getGetGuideOptions = () => {
    const options = [];
    getGuideList.forEach((getGuide) => {
      options.push({
        value: getGuide?.id,
        label: getGuide?.author?.fullName + ", " + getGuide?.city,
      });
    });
    return options;
  };

  const onOptionChange = (e) => {
    const getGuideId = e.value;

    const getGuideData = getGuideList.filter(
      (getGuide) => getGuide.id === getGuideId
    )[0];
    setSelectedGetGuide(getGuideData);
    handleGetGuideOptionChange(getGuideData);
  };

  return (
    <Col>
      <Label className="col-form-label pt-3">
        Tipe: <span>{itineraryData.type}</span>
      </Label>

      <FormGroup>
        <Label className="col-form-label">GetGuide</Label>
        <Select
          defaultValue={getCurrentGetGuideOption(selectedGetGuide)}
          name="getGuide"
          options={getGetGuideOptions()}
          onChange={onOptionChange}
        />
      </FormGroup>
      {selectedGetGuide ? (
        <LinkButton
          title="Lihat GetGuide"
          url={`${process.env.PUBLIC_URL}/services/getguide-detail/${selectedGetGuide.id}`}
        />
      ) : null}

      <FormGroup>
        <Label className="col-form-label">
          Tanggal, Waktu
          <span className="text-danger">*</span>
        </Label>
        <Row>
          <Col lg="4">
            <SingleDatePicker
              date={itineraryData.date}
              onDateChange={(date) => setDate(date)}
              focused={focused.focused}
              onFocusChange={(focused) => setFocused(focused)}
              id="date"
              placeholder="Tanggal"
              numberOfMonths={1}
              orientation="horizontal"
              isOutsideRange={() => false}
              required
            />
          </Col>
          <Col lg="4">
            <Input
              type="time"
              name="time"
              id="time"
              className="startTime"
              defaultValue={itineraryData.time}
              onChange={(value) => setTime(value.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label">Durasi (jam)</Label>
        <Input
          className="form-control"
          type="number"
          name="duration"
          step="1"
          innerRef={register({ required: false })}
          defaultValue={itineraryData.duration}
        />
      </FormGroup>
    </Col>
  );
};

export default GetGuide;
