import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import Dropzone from "../../common/Dropzone";
import FileDownload from "../../common/FileDownload";

const USPInput = (props) => {
  const { idx, state, handleChange, disableDelete, deleteInput } = props;

  const descriptionId = `descriptionId-${idx}`;
  const imageId = `image-${idx}`;
  return (
    <div key={`activity-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <Col className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <h5>{idx + 1}</h5>
            <Col md="11">
              <FormGroup className="mb-3">
                <Label className="form-label" for={descriptionId}>
                  Deskripsi (max 120 karakter)
                </Label>
                <Input
                  type="textarea"
                  input={descriptionId}
                  name={descriptionId}
                  id={descriptionId}
                  data-id={idx}
                  className="name"
                  value={state[idx].name || ""}
                  onChange={handleChange}
                  maxLength={120}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label" for={imageId}>
                  Foto
                </Label>
                <Row>
                  <Col>
                    <Dropzone
                      path="webImages"
                      name={state[idx].title}
                      handlePhotoUploaded={(e) => handleChange(e, idx)}
                    />
                  </Col>
                  <Col>
                    <span className="media user-header">
                      <img
                        className="mr-2 img-35"
                        src={state[idx].image || ""}
                        alt={state[idx].title}
                        style={{ maxHeight: 100, maxWidth: 100 }}
                      />
                    </span>
                    {state[idx].image && (
                      <FileDownload url={state[idx].image} />
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default USPInput;
