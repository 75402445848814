import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

const app = firebase.initializeApp({
  // apiKey: "AIzaSyCGuAXZYTd9th8HsYwEqkysr4imbcwL9Do",
  // authDomain: "getgoing-website.firebaseapp.com",
  // databaseURL: "https://getgoing-website.firebaseio.com",
  // projectId: "getgoing-website",
  // storageBucket: "getgoing-website.appspot.com",
  // messagingSenderId: "562583148331",
  // appId: "1:562583148331:web:64fdec0f31dc00438f576e",
  // measurementId: "G-EMW9V7SEMP"

  apiKey: "AIzaSyBbLpn6gmwGCBdCKDJstOT8ol7BiyIwGPc",
  authDomain: "getgoing-prod.firebaseapp.com",
  databaseURL: "https://getgoing-prod.firebaseio.com",
  projectId: "getgoing-prod",
  storageBucket: "getgoing-prod.appspot.com",
  messagingSenderId: "679166256",
  appId: "1:679166256:web:b9908dc848ecf4056988f5",
  measurementId: "G-W0SGH4SFPZ",
});
export const db = firebase.firestore();

export default app;
