import Dashboard from "../components/dashboard";
import GetGuideList from "../components/services/getguide-list";
import GetGuideDetail from "../components/services/getguide-detail";
import GetStayList from "../components/services/getstay-list";
import GetStayDetail from "../components/services/getstay-detail";
import GetExperienceList from "../components/services/getexperience-list";
import GetExperienceDetail from "../components/services/getexperience-detail";
import GetInfoList from "../components/services/getinfo-list";
import GetInfoDetail from "../components/services/getinfo-detail";
import GetFlexList from "../components/services/getflex-list";
import GetFlexDetail from "../components/services/getflex-detail";
import ListingFormList from "../components/forms/listing-form-list";
import ListingGetGuideFormDetail from "../components/forms/listing-getguide-form-detail";
import ListingGetStayFormDetail from "../components/forms/listing-getstay-form-detail";
import ListingGetExperienceFormDetail from "../components/forms/listing-getexperience-form-detail";
import BergabungList from "../components/forms/bergabung-list";
import BergabungDetail from "../components/forms/bergabung-detail";
import BookingFormList from "../components/bookings/booking-form-list";
import BookingFormDetail from "../components/bookings/booking-form-detail";
import MemberList from "../components/community/member-list";
import MemberDetail from "../components/community/member-detail";
import UserUpdateRequestList from "../components/community/user-update-request-list";
import UserUpdateRequestDetail from "../components/community/user-update-request-detail";
import BlogList from "../components/blogs/blog-list";
import BlogDetail from "../components/blogs/blog-detail";
import CountryCityList from "../components/country/city-list";
import CountryCityDetail from "../components/country/city-detail";
import PopularCities from "../components/country/popular-cities";
import FreeActivitiesDetail from "../components/country/free-activities/FreeActivitiesDetail";
import RecommendedItineraryDetail from "../components/country/recommended-itinerary/RecommendedItineraryDetail";
import CmsBeranda from "../components/cms/beranda";
import CmsTentangKami from "../components/cms/tentang-kami";
import CmsTNCFAQ from "../components/cms/tnc-faq-general";
import ProjectManagementList from "../components/project-management/pm-list";
import ProjectManagementDetail from "../components/project-management/pm-detail";
import ProjectManagementOverview from "../components/project-management/pm-overview";
import TeamGetGoing from "../components/teams/team-getgoing";
import Chat from "../components/chat/Chat";
import UserList from "../components/crm/users/user-list";
import UserDetail from "../components/crm/users/user-detail";
import ItineraryList from "../components/crm/itinerary/itinerary-list";
import ItineraryGroupDetail from "../components/crm/itinerary/itinerary-group-detail";
import ItineraryPersonalDetail from "../components/crm/itinerary/itinerary-personal-detail";
import ItineraryItem from "../components/crm/itinerary/itinerary-item";
import DepartureDetail from "../components/services/GetFlexInput/DepartureDetail";
import CountryList from "../components/country/country-list";
import CountryDetail from "../components/country/country-detail";
import TNCFAQGeneral from "../components/cms/tnc-faq-general";
import PopularProducts from "../components/services/PopularProducts";

export const routes = [
  { path: "/dashboard", Component: Dashboard },
  { path: "/services/getguide-list", Component: GetGuideList },
  { path: "/services/getguide-detail/:id", Component: GetGuideDetail },
  { path: "/services/getstay-list", Component: GetStayList },
  { path: "/services/getstay-detail/:id", Component: GetStayDetail },
  { path: "/services/getexperience-list", Component: GetExperienceList },
  {
    path: "/services/getexperience-detail/:id",
    Component: GetExperienceDetail,
  },
  { path: "/services/getinfo-list", Component: GetInfoList },
  { path: "/services/getinfo-detail/:id", Component: GetInfoDetail },
  { path: "/services/getflex-list", Component: GetFlexList },
  { path: "/services/getflex-detail/:id", Component: GetFlexDetail },
  { path: "/services/popular-products", Component: PopularProducts },
  { path: "/forms/listing-form-list", Component: ListingFormList },
  {
    path: "/forms/listing-form-detail/getguide/:id",
    Component: ListingGetGuideFormDetail,
  },
  {
    path: "/forms/listing-form-detail/getstay/:id",
    Component: ListingGetStayFormDetail,
  },
  {
    path: "/forms/listing-form-detail/getexperience/:id",
    Component: ListingGetExperienceFormDetail,
  },
  { path: "/forms/bergabung-forms", Component: BergabungList },
  { path: "/forms/bergabung-detail/:id", Component: BergabungDetail },
  { path: "/bookings/booking-form-list", Component: BookingFormList },
  { path: "/bookings/booking-form-detail/:id", Component: BookingFormDetail },
  { path: "/community/member-list", Component: MemberList },
  { path: "/community/member-detail/:id", Component: MemberDetail },
  {
    path: "/community/user-update-request-list",
    Component: UserUpdateRequestList,
  },
  {
    path: "/community/user-update-request-detail/:id",
    Component: UserUpdateRequestDetail,
  },
  { path: "/blogs/blog-list", Component: BlogList },
  { path: "/blogs/blog-detail/:id", Component: BlogDetail },
  { path: "/country/city-list", Component: CountryCityList },
  { path: "/country/city-detail/:country/:city", Component: CountryCityDetail },
  { path: "/country/country-list", Component: CountryList },
  { path: "/country/country-detail/:country", Component: CountryDetail },
  { path: "/country/popular-cities", Component: PopularCities },
  {
    path: "/free-activity/:country/:city/:id",
    Component: FreeActivitiesDetail,
  },
  {
    path: "/recommended-itinerary/:country/:city/:id",
    Component: RecommendedItineraryDetail,
  },
  { path: "/cms/beranda", Component: CmsBeranda },
  { path: "/cms/tentang-kami", Component: CmsTentangKami },
  { path: "/cms/tnc-faq", Component: TNCFAQGeneral },
  {
    path: "/project-management/:channel",
    Component: ProjectManagementList,
  },
  {
    path: "/project-management/:channel/:id",
    Component: ProjectManagementDetail,
  },
  {
    path: "/project-management-overview",
    Component: ProjectManagementOverview,
  },
  {
    path: "/teams/team-getgoing",
    Component: TeamGetGoing,
  },
  {
    path: "/crm/chat",
    Component: Chat,
  },
  {
    path: "/users/user-list",
    Component: UserList,
  },
  { path: "/users/user-detail/:id", Component: UserDetail },
  { path: "/itinerary/itinerary-list", Component: ItineraryList },
  { path: "/itinerary/itinerary-group/:id", Component: ItineraryGroupDetail },
  {
    path: "/itinerary/itinerary-personal/:id",
    Component: ItineraryPersonalDetail,
  },
  {
    path: "/itinerary/itinerary-detail/:id",
    Component: ItineraryItem,
  },
  {
    path: "/getflex/departure/:getFlexId/:id",
    Component: DepartureDetail,
  },
];
