import React, { useState } from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import SelectCountryCity from "../../common/SelectCountryCity";
import { upperCaseFirst } from "../../../data/helper";

const CountryCityDestinationInput = (props) => {
  const { idx, state, handleChange, disableDelete, deleteInput } = props;

  const setSelectedCity = (e, idx) => {
    handleChange("city", e, idx);
  };

  const setSelectedCountry = (e, idx) => {
    handleChange("country", e, idx);
  };

  return (
    <div key={`activity-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <Col md="1" className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <Col md="1">
              <h5>{idx + 1}</h5>
            </Col>
            <Col md="10">
              <FormGroup>
                {state[idx + 1] ? (
                  <div>
                    <p>Negara: {upperCaseFirst(state[idx].country)}</p>
                    <p>Kota: {upperCaseFirst(state[idx].city)}</p>
                  </div>
                ) : (
                  <SelectCountryCity
                    userCountry={state[idx].country}
                    userCity={state[idx].city}
                    selectedCountry={(e) => setSelectedCountry(e, idx)}
                    selectedCity={(e) => setSelectedCity(e, idx)}
                    freeCountryCityInput
                    fixedSelectedInput
                  />
                )}
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default CountryCityDestinationInput;
