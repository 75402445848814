import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Select from "react-select";
import firebase from "../../../data/firebase";
import FileDownload from "../../common/FileDownload";
import OpeningHoursInput from "../../common/OpeningHoursInput";

const RecommendedItineraryDetail = () => {
  let { id, country, city } = useParams();

  const recommendedItineraryRef = firebase
    .firestore()
    .collection("countries")
    .doc(country)
    .collection("cities")
    .doc(city)
    .collection("recommendedItinerary")
    .doc(id);

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [recommendedItineraryData, setRecommendedItineraryData] = useState({});
  const [loading, setLoading] = useState(true);
  const [newItinerary, setNewItinerary] = useState(true);

  const [mainPhoto, setMainPhoto] = useState({});
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      accept: "image/*",
      minSize: 0,
      maxSize: 12582912, //12mb
      multiple: false,
      required: true,
      onDrop: (acceptedFiles) => {
        setPhotoUploadFinished(false);
        var ref = firebase.storage().ref();
        var file = acceptedFiles[0];
        const fileName = uuidv4();
        ref
          .child("getinfoImage/" + fileName)
          .put(file)
          .then(() => {
            ref
              .child("getinfoImage/" + fileName)
              .getDownloadURL()
              .then((url) => {
                var photo = Object.assign(file, {
                  preview: url,
                });
                setMainPhoto(photo);
                return setPhotoUploadFinished(true);
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });

  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 12582912, //12mb
    multiple: true,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotos([]);
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();

      Promise.all(
        acceptedFiles.map((file) => {
          const fileName = uuidv4();
          ref
            .child("getinfoImage/" + fileName)
            .put(file)
            .then(() => {
              ref
                .child("getinfoImage/" + fileName)
                .getDownloadURL()
                .then((url) => {
                  file["preview"] = url;
                  setPhotos((photos) => [...photos, file]);
                  return setPhotoUploadFinished(true);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
    },
  });

  const categories = ["museum", "park", "festival", "religion", "monument"];

  const categoriesMap = {
    museum: "Museum",
    park: "Taman",
    festival: "Festival",
    religion: "Religi",
    monument: "Monumen",
  };

  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const daysMap = {
    monday: "Senin",
    tuesday: "Selasa",
    wednesday: "Rabu",
    thursday: "Kamis",
    friday: "Jumat",
    saturday: "Sabtu",
    sunday: "Minggu",
  };

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        var galleryPhotos = [];
        const response = await recommendedItineraryRef.get();
        if (response.exists) {
          data = response.data();
          data.gallery.forEach((photo) => {
            galleryPhotos.push({ preview: photo.img, name: photo.title });
          });

          setDaysInput(data.days);
        }

        setRecommendedItineraryData(data);
        setPhotos(galleryPhotos);
        setMainPhoto({ preview: data.img });
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    if (id !== "new") {
      setNewItinerary(false);
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  const getCategoryOptions = () => {
    const options = [];
    categories.map((cat) => {
      options.push({
        value: cat,
        label: categoriesMap[cat],
      });
    });
    return options;
  };

  const handleCategoryOptionChange = (e) => {
    setRecommendedItineraryData({
      ...recommendedItineraryData,
      category: e.value,
    });
  };

  const onOpeningHoursChange = (e, id) => {
    const openingHoursTemp = recommendedItineraryData.openingHours
      ? recommendedItineraryData.openingHours
      : ["", "", "", "", "", "", ""];
    openingHoursTemp[id] = e;
    setRecommendedItineraryData({
      ...recommendedItineraryData,
      openingHours: openingHoursTemp,
    });
  };

  const [daysInput, setDaysInput] = useState({
    1: {
      1: {
        value: false,
        startTime: "",
      },
    },
    2: {
      1: {
        value: false,
        startTime: "",
      },
      2: {
        value: false,
        startTime: "",
      },
    },
    3: {
      1: {
        value: false,
        startTime: "",
      },
      2: {
        value: false,
        startTime: "",
      },
      3: {
        value: false,
        startTime: "",
      },
    },
  });
  const onCheckboxDayChange = (e, totalDays, day) => {
    const value = e.target.value;
    daysInput[totalDays][day]["value"] = !value;
    setDaysInput({ ...daysInput });
  };

  const onStartTimeChanged = (totalDays, day, value) => {
    daysInput[totalDays][day]["startTime"] = value.target.value;
    setDaysInput({ ...daysInput });
  };

  const deleteFromCity = () => {
    firebase
      .firestore()
      .collection("countries")
      .doc(country)
      .collection("cities")
      .doc(city)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        var recommendedItineraryObj = data["recommendedItinerary"];

        if (recommendedItineraryObj) {
          var newObj = {};
          Object.keys(recommendedItineraryObj).forEach((key) => {
            if (key !== id) {
              newObj[key] = recommendedItineraryObj[key];
            }
          });

          firebase
            .firestore()
            .collection("countries")
            .doc(country)
            .collection("cities")
            .doc(city)
            .update({
              recommendedItinerary: newObj,
            })
            .then(() => {
              toast.success("Berhasil dihapus", {
                position: toast.POSITION.TOP_CENTER,
              });
              history.push(
                `${process.env.PUBLIC_URL}/country/city-detail/${country}/${city}`
              );
            })
            .catch((err) => console.log(err));
        }
      });
  };

  const deleteItinerary = () => {
    recommendedItineraryRef
      .delete()
      .then(() => {
        deleteFromCity();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const updateCity = async (docId, docData) => {
    const recommendedItineraryData = {
      days: docData.days,
      name: docData.name,
      description: docData.description,
      category: docData.category,
      img: docData.img,
      city: docData.city,
      country: docData.country,
      address: docData.address,
    };
    await firebase
      .firestore()
      .collection("countries")
      .doc(docData.country)
      .collection("cities")
      .doc(docData.city)
      .set(
        {
          recommendedItinerary: {
            [docId]: recommendedItineraryData,
          },
        },
        { merge: true }
      )
      .catch((err) => console.log(err));
  };

  const onSubmit = (data) => {
    var docId = "";
    var swiper = [];
    var coordinates = { latitude: "", longitude: "" };

    if (data !== "") {
      coordinates.latitude = data["coordinatesLat"];
      coordinates.longitude = data["coordinatesLong"];

      var contact = {
        phone: data["phone"] || "",
        email: data["email"] || "",
        website: data["website"] || "",
      };

      if (newItinerary) {
        docId = uuidv4();
      } else {
        docId = id;
      }

      photos.forEach((photo) => {
        swiper.push({
          img: photo["preview"],
          title: photo["name"],
        });
      });

      const docData = {
        days: daysInput,
        name: data["name"] || "",
        address: data["address"] || "",
        zip: data["zip"] || "",
        city,
        country,
        coordinates,
        description: data["description"] || "",
        category: recommendedItineraryData["category"] || "",
        contact,
        openingHours: recommendedItineraryData["openingHours"] || [],
        gallery: swiper,
        img: mainPhoto.preview || "",
      };

      firebase
        .firestore()
        .collection("countries")
        .doc(country)
        .collection("cities")
        .doc(city)
        .collection("recommendedItinerary")
        .doc(docId)
        .set(docData, { merge: true })
        .then(() => {
          updateCity(docId, docData).then(() => {
            toast.success("Berhasil disimpan", {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(
              `${process.env.PUBLIC_URL}/country/city-detail/${country}/${city}`
            );
          });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus Saran Perjalanan?</ModalBody>
          <Button
            className="btn-success-gradien mr-2"
            onClick={deleteItinerary}
          >
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2 className="mb-5">Saran Perjalanan</h2>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <Label for="mainImage" className="d-block form-label">
                    Foto Utama <span className="text-danger">*</span>
                  </Label>

                  {!newItinerary && mainPhoto["preview"] && (
                    <FileDownload url={mainPhoto["preview"]} />
                  )}

                  <div
                    {...getRootProps2({ className: "dropzone dz-clickable" })}
                  >
                    <input {...getInputProps2()} />
                    <div className="dz-message text-muted">
                      <p>Klik untuk ubah foto baru (max 1)</p>
                      <img
                        src={mainPhoto["preview"]}
                        alt={mainPhoto["preview"]}
                        className="avatar avatar-large mb-2"
                      />
                      {!photoUploadFinished && (
                        <div className="loader-box">
                          <div className="loader-3"></div>
                        </div>
                      )}
                    </div>
                  </div>

                  <FormGroup>
                    <Label className="col-form-label">
                      Hari <span className="text-danger">*</span>
                    </Label>
                    {Object.keys(daysInput).map((totalDays, i) => (
                      <div key={i} className="mb-5">
                        <h4>Trip {totalDays} Hari</h4>
                        {Object.keys(daysInput[totalDays]).map((day, j) => (
                          <div key={j}>
                            <FormGroup check key={i}>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  id={totalDays}
                                  key={totalDays}
                                  name={totalDays}
                                  value={
                                    daysInput &&
                                    (daysInput[totalDays][day].value || "")
                                  }
                                  defaultChecked={
                                    daysInput
                                      ? daysInput[totalDays][day].value
                                      : false
                                  }
                                  onChange={(e) =>
                                    onCheckboxDayChange(e, totalDays, day)
                                  }
                                />{" "}
                                Hari ke-{day}
                                <FormGroup>
                                  <Label className="col-form-label">
                                    Jam Mulai{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="time"
                                    name="startTime"
                                    innerRef={register({ required: false })}
                                    defaultValue={
                                      daysInput[totalDays][day].startTime
                                    }
                                    onChange={(value) =>
                                      onStartTimeChanged(totalDays, day, value)
                                    }
                                  />
                                </FormGroup>
                              </Label>
                            </FormGroup>
                          </div>
                        ))}
                      </div>
                    ))}
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Nama <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="name"
                      innerRef={register({ required: true })}
                      defaultValue={recommendedItineraryData.name}
                    />
                    <span style={{ color: "red" }}>
                      {errors.name && "Harus diisi"}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Kategori <span className="text-danger">*</span>
                    </Label>
                    <Select
                      defaultValue={{
                        value: recommendedItineraryData.category,
                        label: recommendedItineraryData.category
                          ? categoriesMap[recommendedItineraryData.category]
                          : "",
                      }}
                      name="category"
                      options={getCategoryOptions()}
                      onChange={handleCategoryOptionChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Deskripsi <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="description"
                      innerRef={register({ required: true })}
                      defaultValue={recommendedItineraryData.description}
                    />
                    <span style={{ color: "red" }}>
                      {errors.description && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Alamat<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="address"
                      innerRef={register({ required: true })}
                      defaultValue={recommendedItineraryData.address}
                    />
                    <span style={{ color: "red" }}>
                      {errors.address && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      ZIP<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="zip"
                      innerRef={register({ required: true })}
                      defaultValue={recommendedItineraryData.zip}
                    />
                    <span style={{ color: "red" }}>
                      {errors.zip && "Harus diisi"}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Kota, Negara</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="city"
                      innerRef={register({ required: false })}
                      defaultValue={`${city}, ${country}`}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Koordinat<span className="text-danger">*</span>
                      {"  "}
                      <span>
                        <a
                          href="https://www.latlong.net/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Cari Koordinat
                        </a>
                      </span>
                    </Label>
                    <Row>
                      <Col lg="3">
                        <Input
                          className="form-control"
                          type="text"
                          name="coordinatesLat"
                          innerRef={register({ required: true })}
                          defaultValue={
                            recommendedItineraryData.coordinates &&
                            recommendedItineraryData.coordinates.latitude
                          }
                        />
                        <span style={{ color: "red" }}>
                          {errors.coordinatesLat && "Harus diisi"}
                        </span>
                      </Col>
                      <Col lg="3">
                        <Input
                          className="form-control"
                          type="text"
                          name="coordinatesLong"
                          innerRef={register({ required: true })}
                          defaultValue={
                            recommendedItineraryData.coordinates &&
                            recommendedItineraryData.coordinates.longitude
                          }
                        />
                        <span style={{ color: "red" }}>
                          {errors.coordinatesLong && "Harus diisi"}
                        </span>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Kontak</Label>
                    <Row>
                      <Col lg="2">
                        <p>Phone</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="phone"
                          name="phone"
                          innerRef={register({ required: false })}
                          defaultValue={
                            recommendedItineraryData.contact &&
                            recommendedItineraryData.contact.phone
                          }
                        />
                      </Col>
                      <Col lg="2">
                        <p>Email</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="email"
                          name="email"
                          innerRef={register({ required: false })}
                          defaultValue={
                            recommendedItineraryData.contact &&
                            recommendedItineraryData.contact.email
                          }
                        />
                      </Col>
                      <Col lg="2">
                        <p>Website</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="text"
                          name="website"
                          innerRef={register({ required: false })}
                          defaultValue={
                            recommendedItineraryData.contact &&
                            recommendedItineraryData.contact.website
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Jam Buka</Label>

                    {days.map((el, id) => (
                      <Row key={id} className="mb-5">
                        <Col lg="2">
                          <p>{daysMap[el]}</p>
                        </Col>
                        <Col lg="10">
                          <OpeningHoursInput
                            value={
                              recommendedItineraryData.openingHours &&
                              recommendedItineraryData.openingHours[id]
                            }
                            setValue={(e) => onOpeningHoursChange(e, id)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label pt-0">Foto Galeri</Label>
                    <Row className="ml-n1 mr-n1">
                      {photos &&
                        photos.map((file, index) => (
                          <Col key={index} lg="4" xs="6" className="px-1 mb-2">
                            <img
                              src={file.preview}
                              alt={file.preview}
                              className="img-fluid"
                            />

                            <FileDownload url={file.preview} />
                          </Col>
                        ))}
                    </Row>
                    <div
                      {...getRootProps({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps()} />

                      <div className="dz-message text-muted">
                        <p>Upload file-file baru (minimal 2)</p>
                        {!newItinerary && (
                          <p>Foto-foto lama akan diganti dengan foto baru</p>
                        )}
                        {!photoUploadFinished && (
                          <div className="loader-box">
                            <div className="loader-3"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup className="form-row mt-3 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      disabled={!photoUploadFinished}
                    >
                      Simpan
                    </Button>
                    {!newItinerary && (
                      <Button
                        type="button"
                        onClick={toggle}
                        color="mr-2"
                        className="btn-secondary-gradien mr-2"
                      >
                        Hapus Kegiatan
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default RecommendedItineraryDetail;
