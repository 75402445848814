import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Row,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import idLocale from "moment/locale/id";
import firebase from "../../data/firebase";
import { upperCaseFirst } from "../../data/helper";
import { formatPriceRange } from "./utils";
import { formatDate } from "../common/TableUtils";

const getInfoCategoriesMap = {
  restaurant: "Restoran",
  supermarkt: "Supermarket",
  embassy: "Kedutaan",
};

const PopularProducts = () => {
  const [loading, setLoading] = useState(true);
  const [allGetFlexList, setAllGetFlexList] = useState([]);
  const [allGetGuideList, setAllGetGuideList] = useState([]);
  const [allGetInfoList, setAllGetInfoList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductOptions, setSelectedProductOptions] = useState([]);

  const sectionIndex = {
    getFlex: 2,
    getGuide: 3,
    getInfo: 4,
    other: 5,
  };

  const invSectionIndex = {
    2: "GetFlex",
    3: "GetGuide",
    4: "GetInfo",
    5: "Lainnya",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("popularProducts")
          .get();
        var responseData = response.data();
        const selectedProductsOptions = [];
        const selectedProductsArr = [];
        responseData["popularProducts"].forEach((item) => {
          const itemArr = item.split(",,");
          const title = itemArr[2];
          const subtitle = itemArr[3];
          selectedProductsOptions.push({
            value: item,
            label: title + " | " + subtitle,
          });
          selectedProductsArr.push(item);
        });
        setSelectedProducts(selectedProductsArr);
        setSelectedProductOptions(selectedProductsOptions);

        const allGetFlexArr = [];
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("getFlexList")
          .get()
          .then((response) => {
            const data = response.data();
            for (const key in data) {
              const getFlexData = data[key];

              const dates = getFlexData.dates;

              dates.forEach((date) => {
                const dateObj = date;

                const id = key + "::" + dateObj.id;
                const startDate = moment(dateObj.startDate.toDate()).format(
                  "DD MMM YYYY"
                );
                const title = getFlexData.title;
                const subtitle = startDate + " | " + formatPriceRange(dateObj);
                const imgUrl = getFlexData.image;
                allGetFlexArr.push({
                  value:
                    sectionIndex.getFlex +
                    ",," +
                    id +
                    ",," +
                    title +
                    ",," +
                    subtitle +
                    ",," +
                    imgUrl,
                  label: title + " | " + subtitle,
                });
              });
            }
          });
        setAllGetFlexList(allGetFlexArr);

        const allGetGuideArr = [];
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("getGuideList")
          .get()
          .then((response) => {
            const data = response.data();
            for (const key in data) {
              const getGuideData = data[key];
              const id = key;
              const displayName = getGuideData.author.displayName;
              const imgUrl = getGuideData.author.avatar;
              const city = getGuideData.city;
              const country = getGuideData.country;
              const title = displayName;
              const subtitle =
                upperCaseFirst(city) + ", " + upperCaseFirst(country);

              allGetGuideArr.push({
                value:
                  sectionIndex.getGuide +
                  ",," +
                  id +
                  ",," +
                  title +
                  ",," +
                  subtitle +
                  ",," +
                  imgUrl,
                label: title + " | " + subtitle,
              });
            }
          });
        setAllGetGuideList(allGetGuideArr);

        const allGetInfoArr = [];
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("getInfoList")
          .get()
          .then((response) => {
            const data = response.data();
            for (const key in data) {
              const getInfoData = data[key];
              const id = key;
              const title = getInfoData.name;
              const imgUrl = getInfoData.image;
              const city = getInfoData.city;
              const country = getInfoData.country;
              const category = getInfoCategoriesMap[getInfoData.category];
              const subtitle =
                category +
                " | " +
                upperCaseFirst(city) +
                ", " +
                upperCaseFirst(country);
              allGetInfoArr.push({
                value:
                  sectionIndex.getInfo +
                  ",," +
                  id +
                  ",," +
                  title +
                  ",," +
                  subtitle +
                  ",," +
                  imgUrl,
                label: title + " | " + subtitle,
              });
            }
          });
        setAllGetInfoList(allGetInfoArr);

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const setSelected = (e, i) => {
    var selected = selectedProducts;
    selected[i] = e.value;
    setSelectedProducts(selected);
  };

  const { handleSubmit } = useForm();

  const onSubmit = (data) => {
    firebase
      .firestore()
      .collection("aggregateList")
      .doc("popularProducts")
      .set(
        {
          popularProducts: selectedProducts,
        },
        { merge: true }
      )
      .then(() => {
        toast.success("Berhasil disimpan", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch(function (error) {
        console.log("error " + error);
      });
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const SelectItem = (props) => {
    const { index } = props;
    const [sectionIndex, setSectionIndex] = useState(2);

    const options =
      sectionIndex == 2
        ? allGetFlexList
        : sectionIndex == 3
        ? allGetGuideList
        : sectionIndex == 4
        ? allGetInfoList
        : [];

    return (
      <Row key={index}>
        <Col lg="3">
          {[2, 3, 4].map((item) => (
            <FormGroup key={item} style={{ marginLeft: 20 }}>
              <Label>
                <Input
                  type="radio"
                  id={item}
                  key={item}
                  name={item}
                  value={item}
                  checked={item == sectionIndex}
                  onChange={(e) => setSectionIndex(e.target.value)}
                />
                {invSectionIndex[item]}
              </Label>
            </FormGroup>
          ))}
        </Col>
        <Col>
          <Select
            id={`reactselect-${index}`}
            instanceId={index}
            className="search-form-banner-text border-0 shadow-0"
            isSearchable={true}
            name="location"
            options={options}
            menuPlacement="bottom"
            onChange={(e) => setSelected(e, index)}
            defaultValue={selectedProductOptions[index]}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2>Sedang Populer</h2>

                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                    <Row key={i} className="my-3">
                      <Col lg="1">
                        <h4>{i + 1}:</h4>
                      </Col>
                      <Col lg="10">
                        <SelectItem index={i} />
                      </Col>
                    </Row>
                  ))}

                  <FormGroup className="form-row mt-5 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                    >
                      Simpan
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default PopularProducts;
