import React from "react";
import MUIDataTable from "mui-datatables";
import CustomFooter from "./CustomFooter";
import { debounceSearchRender } from "./CustomSearchInput";

export const MaterialUiTable = (props) => {
  const {
    columns,
    data,
    sortInitialColumn,
    initialColDirection,
    searchableCols,
    defaultToolbar,
  } = props;

  const options = {
    print: false,
    viewColumns: false,
    download: false,
    filter: defaultToolbar !== undefined ? defaultToolbar : true,
    selectableRows: "none",
    search: defaultToolbar !== undefined ? defaultToolbar : true,
    fixedHeader: false,
    searchOpen: true,
    searchPlaceholder: `Cari... (${searchableCols})`,

    onRowClick(rowNode) {
      var id = rowNode[0];
      if (props.twoRowsId) {
        var id2 = rowNode[1];
        props.toItemDetail(id, id2);
      } else {
        props.toItemDetail(id);
      }
    },

    customSearchRender: debounceSearchRender(200),

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      );
    },

    textLabels: {
      body: {
        noMatch: "Tidak ada data",
        toolTip: "Urutkan",
        columnHeaderTooltip: (column) => `Urutkan ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Baris per halaman:",
        displayRows: "dari",
      },
    },

    sortOrder: {
      name: sortInitialColumn,
      direction: initialColDirection ? initialColDirection : "asc",
    },
  };

  return <MUIDataTable data={data} columns={columns} options={options} />;
};

export default MaterialUiTable;
