function formatNumThousand(value) {
  return value ? value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") : 0;
}

function kFormatter(value) {
  if (!value) return 0;
  return Math.abs(value) > 999
    ? "Rp " + formatNumThousand(Math.abs(value) / 1000) + "K"
    : "Rp " + Math.abs(value);
}

function formatNumMillion(value) {
  return value
    ? value
        .toFixed(2)
        .replace(/(\d)(?=(\d{6})+(?!\d))/g, "$1.")
        .replace(".", ",")
    : 0;
}

function mFormatter(value) {
  if (!value) return 0;
  return Math.abs(value) > 999999
    ? "Rp " + formatNumMillion(Math.abs(value) / 1000000) + " Jt"
    : kFormatter(value);
}

const sortDesc = (list) => {
  if (list) {
    const sorted = list.sort((a, b) =>
      parseFloat(a.price) > parseFloat(b.price) ? -1 : 1
    );
    return sorted;
  }
  return [];
};

export const formatPriceRange = (item) => {
  if (item && item.priceList) {
    const priceList = sortDesc(item.priceList);
    const highest = priceList[0].price;
    const lowest = priceList[priceList.length - 1].price;
    return mFormatter(lowest) + " - " + mFormatter(highest);
  }
  return 0;
};
