import React, { useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import FileDownload from "../../../common/FileDownload";
import Dropzone from "../../../common/Dropzone";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Accommodation = (props) => {
  const { itineraryData, register, setDate, setTime, handleTicketChanged } =
    props;

  const [focused, setFocused] = useState({ focused: false });
  const [focused1, setFocused1] = useState({ focused: false });

  const getDate = (date) => {
    var dateMoment = moment();
    if (moment.isMoment(date)) {
      dateMoment = date;
    } else if (date instanceof Date) {
      dateMoment = moment(date);
    } else if (date) {
      dateMoment = moment(date.toDate());
    }

    return dateMoment;
  };

  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(1);
  }

  const [pageNumber2, setPageNumber2] = useState(1);
  function onDocumentLoadSuccess2({ numPages }) {
    setPageNumber2(1);
  }

  return (
    <Col>
      <Label className="col-form-label pt-3">
        Tipe: <span>{itineraryData.type}</span>
      </Label>

      <FormGroup>
        <Label className="col-form-label">Judul / Nama Tempat</Label>
        <Input
          className="form-control"
          type="text"
          name="title"
          innerRef={register({ required: true })}
          defaultValue={itineraryData.title}
        />
      </FormGroup>

      <Row>
        <Col lg="3">
          <Label>Kota</Label>
          <Input
            className="form-control"
            type="text"
            name="city"
            innerRef={register({ required: true })}
            defaultValue={itineraryData.city}
          />
        </Col>
        <Col lg="3">
          <Label>Negara</Label>
          <Input
            className="form-control"
            type="text"
            name="country"
            innerRef={register({ required: true })}
            defaultValue={itineraryData.country}
          />
        </Col>
      </Row>

      <FormGroup>
        <Label className="col-form-label">Alamat Lengkap</Label>
        <span>(Alamat, Kode Pos)</span>
        <Input
          className="form-control"
          type="text"
          name="address"
          innerRef={register({ required: false })}
          defaultValue={itineraryData?.address}
        />

        <Label className="col-form-label">
          Koordinat
          {"  "}
          <span>
            <a
              href="https://www.latlong.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cari Koordinat
            </a>
          </span>
        </Label>
        <Row>
          <Col>
            <Label>Latitude</Label>
            <Input
              className="form-control"
              type="text"
              name="latitude"
              innerRef={register({ required: false })}
              defaultValue={itineraryData?.coordinates?.latitude}
            />
          </Col>
          <Col>
            <Label>Longitude</Label>
            <Input
              className="form-control"
              type="text"
              name="longitude"
              innerRef={register({ required: false })}
              defaultValue={itineraryData?.coordinates?.longitude}
            />
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label">Bintang</Label>
        <Input
          className="form-control"
          type="number"
          name="hotelRating"
          innerRef={register({ required: false })}
          defaultValue={itineraryData.hotelRating}
        />
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label">Tiket Grup</Label>
        <Row>
          <Col lg="4">
            <Dropzone
              path="itineraryFiles"
              name={"voucherGroup"}
              handlePhotoUploaded={(e) => handleTicketChanged("groupTicket", e)}
              itineraryFile
            />
          </Col>
          <Col lg="8">
            <Document
              file={itineraryData.groupTicket}
              onLoadSuccess={onDocumentLoadSuccess2}
              className="pdf-preview"
            >
              <Page pageNumber={pageNumber2} renderTextLayer={false} />
            </Document>

            {itineraryData.groupTicket && (
              <FileDownload url={itineraryData.groupTicket} />
            )}
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label">Tiket Individual</Label>
        <Row>
          <Col lg="4">
            <Dropzone
              path="itineraryFiles"
              name={"voucher"}
              handlePhotoUploaded={(e) => handleTicketChanged("ticket", e)}
              itineraryFile
            />
          </Col>
          <Col lg="8">
            <Document
              file={itineraryData.ticket}
              onLoadSuccess={onDocumentLoadSuccess}
              className="pdf-preview"
            >
              <Page pageNumber={pageNumber} renderTextLayer={false} />
            </Document>

            {itineraryData.ticket && (
              <FileDownload url={itineraryData.ticket} />
            )}
          </Col>
        </Row>
      </FormGroup>

      <h2>Check In</h2>

      <FormGroup>
        <Label className="col-form-label">Tanggal, Waktu</Label>
        <Row>
          <Col lg="4">
            <SingleDatePicker
              date={getDate(itineraryData?.checkIn?.date)}
              onDateChange={(date) => setDate("checkIn", date)}
              focused={focused.focused}
              onFocusChange={(focused) => setFocused(focused)}
              id="checkIndate"
              placeholder="Tanggal"
              numberOfMonths={1}
              orientation="horizontal"
              isOutsideRange={() => false}
            />
          </Col>
          <Col lg="4">
            <Input
              type="time"
              name="time"
              id="time"
              className="checkInTime"
              defaultValue={itineraryData?.checkIn?.time}
              onChange={(value) => setTime("checkIn", value.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>

      <h2>Check Out</h2>

      <FormGroup>
        <Label className="col-form-label">Tanggal, Waktu</Label>
        <Row>
          <Col lg="4">
            <SingleDatePicker
              date={getDate(itineraryData?.checkOut?.date)}
              onDateChange={(date) => setDate("checkOut", date)}
              focused={focused1.focused}
              onFocusChange={(focused) => setFocused1(focused)}
              id="checkOutdate"
              placeholder="Tanggal"
              numberOfMonths={1}
              orientation="horizontal"
              isOutsideRange={() => false}
            />
          </Col>
          <Col lg="4">
            <Input
              type="time"
              name="time"
              id="checkOuttime"
              className="checkOutTime"
              defaultValue={itineraryData?.checkOut?.time}
              onChange={(value) => setTime("checkOut", value.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>
    </Col>
  );
};

export default Accommodation;
