import moment from "moment";

export const formatDate = (date) => {
  if (!(date instanceof Date)) {
    date = date.toDate();
  }
  const dateFormat = moment(date);

  return {
    day: dateFormat.format("dddd"),
    hour: dateFormat.format("HH:mm"),
    date: dateFormat.format("DD MMMM YYYY"),
    datenumber: dateFormat.format("DD/MM/YY")
  };
};
