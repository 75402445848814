import React from "react";
import Select from "react-select";
import { countryCodeList } from "../../util/CountryCodes";

const SelectCountryCode = (props) => {
  const { countryCode, setCountryCode } = props;
  const getOption = () => {
    const options = [];
    countryCodeList.forEach((c) => {
      options.push({
        value: c.code + "_" + c.name + c.dial_code,
        label: c.code + " (" + c.dial_code + ")",
      });
    });
    return options;
  };

  const handleCountryCodeOptionChange = (e) => {
    const value = e.value;
    const countryCode = value.split("_")[0];
    setCountryCode(countryCode);
  };

  return (
    // <div style={{ width: 80 }}>
    <Select
      id="countryCode"
      instanceId="countryCode"
      name="countryCode"
      options={getOption()}
      onChange={handleCountryCodeOptionChange}
      isSearchable
      value={getOption().filter(
        (option) => option.value.split("_")[0] === countryCode
      )}
    />
    // </div>
  );
};

export default SelectCountryCode;
