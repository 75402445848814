import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import PriceTypeComponent from "./PriceTypeComponent";

const PriceRangeInput = (props) => {
  const {
    idx,
    state,
    handleChange,
    handlePriceTypeChange,
    disableDelete,
    deleteInput,
    setDefaultPrice,
  } = props;

  const maxAgeId = `maxAgeId-${idx}`;
  const minAgeId = `minAgeId-${idx}`;
  const priceId = `priceId-${idx}`;

  return (
    <div key={`priceRange-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <div className="mr-3">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </div>
            )}
            <h5>{idx + 1}</h5>
            <Col md="11">
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Label className="form-label" for={minAgeId}>
                      Dari Umur
                    </Label>
                    <Input
                      type="number"
                      step="1"
                      input={minAgeId}
                      name={minAgeId}
                      id={minAgeId}
                      data-id={idx}
                      className="minAge"
                      value={state[idx].minAge || ""}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <Label className="form-label" for={maxAgeId}>
                      Sampai Umur
                    </Label>
                    <Input
                      type="number"
                      step="1"
                      input={maxAgeId}
                      name={maxAgeId}
                      id={maxAgeId}
                      data-id={idx}
                      className="maxAge"
                      value={state[idx].maxAge || ""}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <PriceTypeComponent
                value={state[idx].priceTypes}
                setValue={(e) => handlePriceTypeChange(idx, e)}
                priceRangeId={idx}
                setDefaultPrice={setDefaultPrice}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default PriceRangeInput;
