import React, { useState, useEffect } from "react";
import { Card, CardHeader, Col, FormGroup, Label, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import LinkButton from "../../../common/LinkButton";
import moment from "moment";
import firebase from "../../../../data/firebase";
import MaterialUiTable from "../../../common/MaterialUiTable";

const GetFlex = (props) => {
  const history = useHistory();
  const { itineraryData, date, setGetFlexItineraryList } = props;
  const [itineraryList, setItineraryList] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatDate = (value) => {
    return value ? moment(value.toDate()).format("DD MMM YYYY") : "";
  };

  const columnsItinerary = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "type",
      label: "TIPE",
    },
    {
      name: "dateArr",
      label: "TANGGAL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <p>{value[0]}</p>
              {value.length > 0 ? <p>{value[1]}</p> : null}
            </div>
          );
        },
      },
    },
    {
      name: "timeArr",
      label: "WAKTU",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <p>{value[0]}</p>
              {value.length > 0 ? <p>{value[1]}</p> : null}
            </div>
          );
        },
        filter: false,
      },
    },
    {
      name: "info",
      label: "INFO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ whiteSpace: "pre-line" }}>{value}</span>;
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const toItineraryDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/itinerary/itinerary-detail/${id}`);
  };

  useEffect(() => {
    const getFlexId = itineraryData.productId;
    const departureId = itineraryData.departureId;
    firebase
      .firestore()
      .collection("getFlex")
      .doc(getFlexId)
      .collection("departures")
      .doc(departureId)
      .get()
      .then((response) => {
        if (response.exists) {
          const data = response.data();
          const itineraryList = data.itineraryList;
          if (itineraryList && itineraryList.length > 0) {
            setItineraryList(itineraryList);
            setGetFlexItineraryList(itineraryList);
          }
        }
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Col>
      <Label className="col-form-label pt-3">
        Tipe: <span>{itineraryData.type}</span>
      </Label>

      <FormGroup>
        <Label className="col-form-label">Booking Id</Label>
        <p>{itineraryData.bookingId}</p>
        <LinkButton
          title="Lihat Booking"
          url={`${process.env.PUBLIC_URL}/bookings/booking-form-detail/${itineraryData.bookingId}`}
        />
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label">GetFlex</Label>
        <p>{itineraryData.productId}</p>
        <LinkButton
          title="Lihat GetFlex"
          url={`${process.env.PUBLIC_URL}/services/getflex-detail/${itineraryData.productId}`}
        />
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label">GetFlex Periode Keberangkatan</Label>
        <p>{itineraryData.departureId}</p>
        <LinkButton
          title="Lihat Keberangkatan"
          url={`${process.env.PUBLIC_URL}/getflex/departure/${itineraryData.productId}/${itineraryData.departureId}`}
        />
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label">Tanggal</Label>
        <p>{formatDate(date)}</p>
      </FormGroup>

      <Card className="mx-4">
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Itinerary List</h5>
            <p className="text-muted">(total {itineraryList.length})</p>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columnsItinerary}
            data={itineraryList}
            toItemDetail={toItineraryDetail}
            sortInitialColumn="date"
            searchableCols="Nama"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Col>
  );
};

export default GetFlex;
