import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { Plus } from "react-feather";

import TicketAgeRangeInput from "./TicketAgeRangeInput";

const TicketAgeRangeComponent = (props) => {
  const { value, setValue, providerId } = props;
  const firstInput = {};
  const [state, setState] = useState(value || []);

  const addInput = () => {
    setState([...state, { ...firstInput }]);
  };

  const handleChange = (e, idx) => {
    const updatedState = [...state];
    var fieldName = "";
    var value = "";
    var id = 0;

    const className = e.target.className;
    fieldName = className.split(" form-control")[0];
    value = e.target.value;
    id = e.target.dataset.id;

    updatedState[id][fieldName] = value;
    setState(updatedState);
    setValue(providerId, updatedState);
  };

  const deleteInput = (id) => {
    const updatedState = [...state];
    updatedState.splice(id, 1);
    setState(updatedState);
    setValue(providerId, updatedState);
  };

  return (
    <Col className="ml-auto">
      {state &&
        state.map((val, idx) => (
          <TicketAgeRangeInput
            key={`ticketAgeRange-${idx}`}
            idx={idx}
            state={state}
            handleChange={handleChange}
            deleteInput={deleteInput}
          />
        ))}

      <Button
        type="button"
        color="primary"
        className="btn-info-gradien mr-2 px-3 button-vertical-center"
        onClick={addInput}
      >
        <Plus /> Tambah Kategori Umur
      </Button>
    </Col>
  );
};

export default TicketAgeRangeComponent;
