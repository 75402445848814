import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";

const ActivityInputs = (props) => {
  const { idx, activityState, handleActivityChange } = props;

  const titleId = `title-${idx}`;
  const descId = `desc-${idx}`;
  return (
    <div key={`activity-${idx}`}>
      {activityState[idx] && (
        <div>
          <Row>
            {!props.disableDelete && (
              <Col md="1" className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => props.deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <Col md="11">
              <FormGroup>
                <Label className="form-label" for={titleId}>
                  Judul Kegiatan {idx + 1}
                </Label>
                <Input
                  type="text"
                  input={titleId}
                  name={titleId}
                  id={titleId}
                  data-id={idx}
                  className="title"
                  value={activityState[idx].title || ""}
                  onChange={handleActivityChange}
                />
              </FormGroup>
              <FormGroup className="mb-5">
                <Label className="form-label">
                  Deskripsi Kegiatan {idx + 1}
                </Label>
                <Input
                  type="textarea"
                  input={descId}
                  name={descId}
                  id={descId}
                  data-id={idx}
                  rows="5"
                  className="description"
                  value={activityState[idx].description || ""}
                  onChange={handleActivityChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ActivityInputs;
