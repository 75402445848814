import {
  Speedometer,
  Headset,
  People,
  ShopWindow,
  Back,
  GeoAlt,
  ViewList,
  PersonBadge,
} from "react-bootstrap-icons";

import firebase from "../../data/firebase";

var projectManagementMenu = {
  title: "Project Management",
  icon: ViewList,
  type: "sub",
  active: false,
};

var teamMenu = [
  {
    title: "TEAMS",
    type: "blockTitle",
  },
  {
    title: "TEAM GETGOING",
    icon: PersonBadge,
    active: false,
    type: "link",
    path: `${process.env.PUBLIC_URL}/teams/team-getgoing`,
  },
];

const fetchData = async () => {
  try {
    const response = await firebase
      .firestore()
      .collection("projectManagement")
      .doc("channels")
      .get();
    var responseData = response.data().channelList;

    // sort by channel name
    responseData.sort((a, b) => {
      return a.localeCompare(b);
    });

    var channelMenu = [
      {
        path: `${process.env.PUBLIC_URL}/project-management-overview`,
        title: "Overview",
        type: "link",
      },
    ];

    responseData.forEach((c) => {
      channelMenu.push({
        path: `${process.env.PUBLIC_URL}/project-management/${c}`,
        title: `#${c}`,
        type: "link",
      });
    });
    projectManagementMenu.children = channelMenu;
  } catch (err) {
    console.error(err);
  }
};

fetchData();

var MENUITEMS = [
  {
    title: "Dashboard Gue",
    icon: Speedometer,
    type: "link",
    active: false,
    path: `${process.env.PUBLIC_URL}/`,
  },
  {
    title: "CRM",
    icon: Headset,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/crm/chat`,
        title: "Chat",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/itinerary/itinerary-list`,
        title: "Itinerary",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/bookings/booking-form-list`,
        title: "Bookings",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/users/user-list`,
        title: "Users",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/`,
        title: "Newsletter",
        type: "link",
      },
    ],
  },
  {
    title: "Komunitas",
    icon: People,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/forms/bergabung-forms`,
        title: "Bergabung",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/`,
        title: "Partner",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/community/member-list`,
        title: "Member",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/community/user-update-request-list`,
        title: "Permintaan Update Profil",
        type: "link",
      },
    ],
  },
  {
    title: "Services",
    icon: ShopWindow,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/services/getguide-list`,
        title: "GetGuide",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/services/getstay-list`,
        title: "GetStay",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/services/getexperience-list`,
        title: "GetExperience",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/services/getinfo-list`,
        title: "GetInfo",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/services/getflex-list`,
        title: "GetFlex",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/services/popular-products`,
        title: "Sedang Populer",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/forms/listing-form-list`,
        title: "Formulir Service",
        type: "link",
      },
    ],
  },
  {
    title: "CMS",
    icon: Back,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/cms/beranda`,
        title: "Beranda",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/cms/tentang-kami`,
        title: "Tentang Kami",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/blogs/blog-list`,
        title: "Blogs",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/cms/tnc-faq`,
        title: "General TNC & FAQ",
        type: "link",
      },
    ],
  },
  {
    title: "Negara & Kota",
    icon: GeoAlt,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/country/country-list`,
        title: "List Negara",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/country/city-list`,
        title: "List Kota",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/country/popular-cities`,
        title: "Kota Populer",
        type: "link",
      },
    ],
  },
];

MENUITEMS = [...MENUITEMS, projectManagementMenu, ...teamMenu];

export var MENUITEMS;
