import React, { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import idLocale from "moment/locale/id";
import { v4 as uuidv4 } from "uuid";
import * as firebaseApp from "firebase/app";
import firebase from "../../data/firebase";
import SelectCountryCity from "../common/SelectCountryCity";
import FileDownload from "../common/FileDownload";
import { getLanguageOptions, getUserLanguages } from "../../data/helper";
import LinkButton from "../common/LinkButton";
import SelectCountryCode from "../common/SelectCountryCode";

const BergabungDetail = () => {
  let { id } = useParams();

  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  moment.locale("id", idLocale);
  const [focused, setFocused] = useState({ focused: false });
  const [dateOfBirth, setDateOfBirth] = useState(moment());
  const [focused2, setFocused2] = useState({ focused: false });
  const [contactDate, setContactDate] = useState(moment());
  const [bergabungData, setBergabungData] = useState({});
  const [loading, setLoading] = useState(true);
  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [checkboxInput, setCheckboxInput] = useState({
    categories: {
      cityTour: false,
      culinary: false,
      halalTrip: false,
      history: false,
      photography: false,
      shopping: false,
    },
    services: {
      GetGuide: false,
      GetStay: false,
      GetExperience: false,
    },
  });

  const categoriesMap = {
    cityTour: "City Tour",
    culinary: "Kuliner",
    halalTrip: "Halal Trip",
    history: "Sejarah",
    photography: "Fotografi",
    shopping: "Belanja",
  };

  const [allAdmins, setAllAdmins] = useState([]);
  const getAllAdmins = () => {
    var adminArr = [];

    allAdmins.forEach((admin) => {
      adminArr.push({
        value: admin.name,
        label: admin.name,
      });
    });

    return adminArr;
  };

  const [userDetail, setUserDetail] = useState();

  const [photo, setPhoto] = useState({});
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: false,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      var file = acceptedFiles[0];
      const fileName = uuidv4();
      ref
        .child("avatar/" + fileName)
        .put(file)
        .then(() => {
          ref
            .child("avatar/" + fileName)
            .getDownloadURL()
            .then((url) => {
              var photo = Object.assign(file, {
                preview: url,
              });
              setPhoto(photo);
              setPhotoUploadFinished(true);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });

  const statusOptions = [
    { value: "new", label: "Baru" },
    { value: "verified", label: "Terverifikasi" },
    { value: "unverified", label: "Tidak Terverifikasi" },
    { value: "contacted", label: "Dikontak" },
    { value: "photoCheck", label: "Cek Foto" },
  ];

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("bergabungForms")
          .doc(id)
          .get();
        if (response.exists) {
          data = response.data();
        }

        const phoneNumberSplit = data.phone && data.phone.split("_");

        var countryCode = "";
        var phone = "";
        if (phoneNumberSplit.length == 2) {
          countryCode = phoneNumberSplit[0];
          phone = phoneNumberSplit[1];
        } else if (phoneNumberSplit.length == 1) {
          phone = phoneNumberSplit[0];
        }

        data.countryCode = countryCode;
        data.phone = phone;

        setBergabungData(data);
        setPhoto({ preview: data["photo"] });
        setDateOfBirth(
          typeof data["dateOfBirth"] !== "string"
            ? moment(data["dateOfBirth"].toDate())
            : moment()
        );
        setContactDate(
          data["contactDate"] ? moment(data["contactDate"].toDate()) : moment()
        );
        setCheckboxInput({
          categories: data.categories
            ? data.categories
            : {
                cityTour: false,
                culinary: false,
                halalTrip: false,
                history: false,
                photography: false,
                shopping: false,
              },
          services: data.services,
        });

        // get user if exists
        firebase
          .firestore()
          .collection("users")
          .where("email", "==", data.email)
          .get()
          .then((response) => {
            if (!response.empty) {
              const firstDoc = response.docs[0];
              const userId = firstDoc.id;
              const userData = firstDoc.data();
              setUserDetail({ id: userId, ...userData });
            }
          });
      } catch (err) {
        console.error(err);
      }
    };

    const fetchAllAdmin = async () => {
      // getAllAdmin data
      try {
        var allAdmins = [];
        await firebase
          .firestore()
          .collection("users")
          .where("role", "in", ["superadmin", "admin"])
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              allAdmins.push({
                name: doc.data()["fullName"],
              });
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
        setAllAdmins(allAdmins);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData().then(() => fetchAllAdmin().then(setLoading(false)));
  }, []);

  const setCountryCode = (value) => {
    setBergabungData({ ...bergabungData, countryCode: value });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const onCheckboxChange = (key, e) => {
    const value = e.target.value;
    checkboxInput[key][e.target.id] = !value;
    setCheckboxInput({ ...checkboxInput });
  };

  const setSelectedValue = (key, value) => {
    setBergabungData({ ...bergabungData, [key]: value });
  };

  const handleLanguageOptionChange = (languages) => {
    var langArr = [];

    languages.map((option) => {
      langArr.push(option.value.split(",")[0]);
    });

    setBergabungData({ ...bergabungData, languages: langArr });
  };

  const handleFormStatusChange = (status) => {
    setBergabungData({ ...bergabungData, status: status.value });
  };

  const updateBergabungForm = (status) => {
    var formUpdate = {};
    formUpdate[`${id}.status`] = status;
    firebase
      .firestore()
      .collection("aggregateList")
      .doc("bergabungFormList")
      .update(formUpdate);
  };

  const saveFormData = async (data) => {
    const phone = bergabungData.countryCode + "_" + data["phone"];

    var formData = {
      photo: photo.preview,
      name: data["name"],
      displayName: data["displayName"],
      email: data["email"],
      instagram: data["instagram"],
      phone,
      city: bergabungData["city"],
      country: bergabungData["country"],
      // lengthStay: data["lengthStay"],
      resideSince: data["resideSince"],
      languageLevel: data["languageLevel"],
      dateOfBirth: new Date(dateOfBirth),
      photoPermission: data["photoPermission"],
      // joinedSince: data["joinedSince"] ? data["joinedSince"] : "",
      description: data["description"] || "",
      categories: checkboxInput["categories"],
      services: checkboxInput["services"],
      languages: bergabungData["languages"],
      contactPerson: bergabungData["contactPerson"]
        ? bergabungData["contactPerson"]
        : "",
      contactDate: contactDate ? new Date(contactDate) : "",
      status: bergabungData["status"],
    };

    await firebase
      .firestore()
      .collection("bergabungForms")
      .doc(id)
      .set(formData, { merge: true })
      .catch(function (error) {
        // Handle any errors
        console.log("error " + error);
      });
    return formData;
  };

  const onSubmitSaveForm = handleSubmit((data) => {
    if (bergabungData.status === "verified") {
      onSubmitVerifyUser(data);
    } else {
      saveFormData(data).then(() => {
        updateBergabungForm(bergabungData.status);
        toast.success("Formulir berhasil disimpan", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  });

  const onSubmitVerifyUser = (data) => {
    saveFormData(data).then((formData) => {
      let docId = "";
      if (userDetail) {
        docId = userDetail.id;
      } else {
        docId = uuidv4();
      }
      firebase
        .firestore()
        .collection("users")
        .doc(docId)
        .set(
          {
            avatar: formData["photo"],
            fullName: formData["name"],
            displayName: formData["displayName"],
            email: formData["email"],
            instagram: formData["instagram"],
            phone: formData["phone"],
            cityOfResidence: formData["city"],
            countryOfResidence: formData["country"],
            // lengthStay: formData["lengthStay"],
            resideSince: data["resideSince"],
            languageLevel: data["languageLevel"],
            dateOfBirth: formData["dateOfBirth"],
            photoPermission: formData["photoPermission"],
            // joinedSince: formData["joinedSince"],
            description: formData["description"] || "",
            categories: formData["categories"],
            languages: formData["languages"],
            status: "verified",
            role: "member",
            roles: firebaseApp.firestore.FieldValue.arrayUnion("member"),
          },
          { merge: true }
        )
        .then(() => {
          updateBergabungForm("verified");
          toast.success("User terverifikasi", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.push(`${process.env.PUBLIC_URL}/forms/bergabung-forms`);
        })
        .catch(function (error) {
          // Handle any errors
          console.log("error " + error);
        });
    });
  };

  const deleteForm = () => {
    firebase
      .firestore()
      .collection("bergabungForms")
      .doc(id)
      .delete()
      .then(() => {
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("bergabungFormList")
          .update({
            [id]: firebaseApp.firestore.FieldValue.delete(),
          })
          .then(() => {
            toast.success("Berhasil dihapus", {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/forms/bergabung-forms`);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus Formulir?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteForm}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <Form className="theme-form">
                  <h2>Formulir Bergabung: {bergabungData.name}</h2>
                  <h4>Status: {bergabungData.status || "Baru"}</h4>
                  <hr />

                  {userDetail && (
                    <div>
                      <h4>
                        User dengan email {userDetail.email} sudah terdaftar
                      </h4>
                      <Row lg="6">
                        <Col lg="4">Id: {userDetail.id}</Col>
                        <Col>
                          <LinkButton
                            title="Lihat User"
                            url={`${process.env.PUBLIC_URL}/users/user-detail/${userDetail.id}`}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Label className="col-form-label pt-2">
                          Nama Lengkap <span className="text-danger">*</span>
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="name"
                          innerRef={register({ required: true })}
                          defaultValue={bergabungData.name}
                        />
                        <span style={{ color: "red" }}>
                          {errors.name && "Harus diisi"}
                        </span>
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">
                          Nama Panggilan <span className="text-danger">*</span>
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="displayName"
                          innerRef={register({ required: true })}
                          defaultValue={bergabungData.displayName}
                        />
                        <span style={{ color: "red" }}>
                          {errors.displayName && "Harus diisi"}
                        </span>
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">
                          Email <span className="text-danger">*</span>
                        </Label>
                        <Input
                          className="form-control"
                          type="email"
                          name="email"
                          innerRef={register({ required: true })}
                          defaultValue={bergabungData.email}
                        />
                        <span style={{ color: "red" }}>
                          {errors.email && "Harus diisi"}
                        </span>
                      </FormGroup>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="col-form-label">
                              No. Telepon
                            </Label>
                            <Row>
                              <Col lg="5">
                                <SelectCountryCode
                                  countryCode={bergabungData.countryCode}
                                  setCountryCode={setCountryCode}
                                />
                              </Col>

                              <Col lg="5">
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="phone"
                                  innerRef={register({ required: false })}
                                  defaultValue={bergabungData.phone}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          Foto Profil (Download dan pastikan foto profile
                          memiliku rasio 1:1 atau persegi, jika tidak maka harus
                          di CROP dan pastikan wajah PARTNER terlihat jelas)
                        </Label>
                      </FormGroup>

                      <div
                        {...getRootProps({
                          className: "dropzone dz-clickable",
                        })}
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message text-muted">
                          <p>Klik untuk ubah foto dan upload foto baru</p>
                          <img
                            src={photo.preview}
                            alt={bergabungData.nama}
                            className="avatar avatar-xxl avatar-square mb-1"
                          />
                          {!photoUploadFinished && (
                            <div className="loader-box">
                              <div className="loader-3"></div>
                            </div>
                          )}
                        </div>
                      </div>
                      <FileDownload url={photo.preview} className="mt-4" />
                    </Col>
                  </Row>
                  <Col lg="6">
                    <SelectCountryCity
                      userCountry={bergabungData.country}
                      userCity={bergabungData.city}
                      selectedCountry={(value) =>
                        setSelectedValue("country", value)
                      }
                      selectedCity={(value) => setSelectedValue("city", value)}
                      listingForm
                    />
                  </Col>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          Mengizinkan GetGoing untuk menggunakan foto untuk
                          marketing atau content, baik itu website, aplikasi,
                          instagram, serta media sosial lainnya
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="photoPermission"
                          innerRef={register({ required: true })}
                          defaultValue={bergabungData.photoPermission}
                        />
                        <span style={{ color: "red" }}>
                          {errors.photoPermission && "Harus diisi"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <FormGroup>
                    <Label className="col-form-label">Layanan</Label>
                    <ul className="list-group mb-0">
                      {["GetGuide", "GetStay", "GetExperience"].map(
                        (keyName, i) => (
                          <FormGroup check key={i}>
                            <Label check>
                              <Input
                                type="checkbox"
                                id={keyName}
                                key={keyName}
                                name={keyName}
                                value={checkboxInput["services"][keyName] || ""}
                                checked={
                                  checkboxInput["services"][keyName] || false
                                }
                                onChange={(e) =>
                                  onCheckboxChange("services", e)
                                }
                              />{" "}
                              {keyName}
                            </Label>
                          </FormGroup>
                        )
                      )}
                    </ul>
                  </FormGroup> */}

                  <h3>Informasi Tambahan:</h3>

                  <FormGroup>
                    <Label className="col-form-label">
                      Tanggal Lahir <span className="text-danger">*</span>
                    </Label>
                    <div className="d-block">
                      <SingleDatePicker
                        date={dateOfBirth}
                        onDateChange={(date) => setDateOfBirth(date)}
                        focused={focused.focused}
                        onFocusChange={(focused) => setFocused(focused)}
                        id="dateOfBirth"
                        placeholder="Tanggal"
                        numberOfMonths={1}
                        orientation="horizontal"
                        isOutsideRange={() => false}
                        required
                      />
                    </div>
                    {typeof bergabungData.dateOfBirth === "string" && (
                      <p>{bergabungData.dateOfBirth}</p>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Instagram</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="instagram"
                      innerRef={register({ required: false })}
                      defaultValue={bergabungData.instagram}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Deskripsi</Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="description"
                      innerRef={register({ required: false })}
                      defaultValue={bergabungData.description}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Bahasa <span className="text-danger">*</span>
                    </Label>
                    <Row>
                      <Col lg="6">
                        <Select
                          defaultValue={
                            bergabungData.languages instanceof Array &&
                            getUserLanguages(bergabungData.languages)
                          }
                          isMulti
                          name="languages"
                          options={getLanguageOptions()}
                          classNamePrefix="select"
                          onChange={handleLanguageOptionChange}
                        />
                      </Col>
                      <Col lg="6">
                        <Input
                          type="text"
                          defaultValue={bergabungData.languages}
                          disabled
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Level Bahasa <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="languageLevel"
                      innerRef={register({ required: true })}
                      defaultValue={bergabungData.languageLevel}
                    />
                    <span style={{ color: "red" }}>
                      {errors.languageLevel && "Harus diisi"}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Domisili sejak <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="resideSince"
                      innerRef={register({ required: true })}
                      defaultValue={bergabungData.resideSince}
                    />
                    <span style={{ color: "red" }}>
                      {errors.lengthStay && "Harus diisi"}
                    </span>
                  </FormGroup>

                  {/* <FormGroup>
                    <Label className="col-form-label">
                      Lama Tinggal di Kota{" "}
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="lengthStay"
                      innerRef={register({ required: true })}
                      defaultValue={bergabungData.lengthStay}
                    />
                    <span style={{ color: "red" }}>
                      {errors.lengthStay && "Harus diisi"}
                    </span>
                  </FormGroup> */}
                  <Row>
                    <Col lg="6">
                      {/* <FormGroup>
                        <Label className="col-form-label">
                          Bergabung Sejak
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="joinedSince"
                          defaultValue={bergabungData.joinedSince}
                          innerRef={register({ required: false })}
                        />
                      </FormGroup> */}
                      <Label for="categories" className="form-label">
                        Tipe Jalan <span className="text-danger">*</span>
                      </Label>
                      {Object.keys(categoriesMap).map((keyName, i) => (
                        <FormGroup check key={i}>
                          <Label check>
                            <Input
                              type="checkbox"
                              id={keyName}
                              key={keyName}
                              name={keyName}
                              value={checkboxInput["categories"][keyName] || ""}
                              checked={
                                checkboxInput["categories"][keyName] || false
                              }
                              onChange={(e) =>
                                onCheckboxChange("categories", e)
                              }
                            />{" "}
                            {categoriesMap[keyName]}
                          </Label>
                        </FormGroup>
                      ))}
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col>
                      <FormGroup className="w-50">
                        <Label className="col-form-label">Dikontak oleh</Label>
                        <Select
                          defaultValue={
                            bergabungData.contactPerson && {
                              value: bergabungData.contactPerson,
                              label: bergabungData.contactPerson,
                            }
                          }
                          name="languages"
                          options={getAllAdmins()}
                          classNamePrefix="select"
                          onChange={(value) =>
                            setSelectedValue("contactPerson", value.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="w-50">
                        <Label className="col-form-label">Tanggal Kontak</Label>
                        <div className="d-block">
                          <SingleDatePicker
                            date={contactDate}
                            onDateChange={(contactDate) =>
                              setContactDate(contactDate)
                            }
                            focused={focused2.focused}
                            onFocusChange={(focused) => setFocused2(focused)}
                            id="date"
                            placeholder="Tanggal"
                            numberOfMonths={1}
                            orientation="horizontal"
                            isOutsideRange={() => false}
                            required
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          Status<span className="text-danger">*</span>
                        </Label>
                        <Select
                          defaultValue={statusOptions.filter(
                            (option) => option.value === bergabungData.status
                          )}
                          name="status"
                          options={statusOptions}
                          classNamePrefix="select"
                          onChange={handleFormStatusChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup className="form-row mt-5 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      name="saveForm"
                      disabled={!photoUploadFinished}
                      className={`${
                        bergabungData.status === "verified"
                          ? "btn-success-gradien"
                          : "btn-primary-gradien"
                      }`}
                      onClick={onSubmitSaveForm}
                    >
                      {bergabungData.status === "verified" ? (
                        <span>Simpan Member Baru</span>
                      ) : (
                        <span>Simpan Formulir</span>
                      )}
                    </Button>
                    <Button
                      color="mr-2 ml-auto float-right"
                      type="button"
                      onClick={toggle}
                      className="btn-secondary-gradien"
                    >
                      Hapus Formulir
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default BergabungDetail;
