import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import * as firebaseApp from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../data/firebase";
import SelectCountryCity from "../common/SelectCountryCity";
import FileDownload from "../common/FileDownload";
import LinkButton from "../common/LinkButton";

const GetGuideDetail = () => {
  let { id } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [deleteFormModal, setDeleteFormModal] = useState(false);
  const toggleDeleteForm = () => setDeleteFormModal(!deleteFormModal);

  const [guideData, setData] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: true,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotos([]);
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      Promise.all(
        acceptedFiles.map((file) => {
          const fileName = uuidv4();
          ref
            .child("getguideImage/" + fileName)
            .put(file)
            .then(() => {
              ref
                .child("getguideImage/" + fileName)
                .getDownloadURL()
                .then((url) => {
                  setPhotoUploadFinished(true);
                  return setFormData({ ...formData, guidePhoto: url });
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
    },
  });

  useEffect(() => {
    let formData = {};
    let guideData = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("getGuide")
          .doc(id)
          .get();
        if (response.exists) {
          guideData = response.data();
          formData = guideData.newData;

          // var swiperPhotos = [];
          // if (formData.action !== "delete") {
          //   formData.photoUrls.forEach((url) => {
          //     swiperPhotos.push({ preview: url, name: url });
          //   });
          //   setPhotos(swiperPhotos);
          // }
          // if (formData.action === "update" || formData.status === "pending_update_confirmation") {
          //   try {
          //     const response2 = await firebase
          //       .firestore()
          //       .collection("getGuide")
          //       .doc(formData.id)
          //       .get();
          //     guideData = response2.data();
          //   } catch (err) {
          //     console.error(err);
          //   }
          // }

          firebase
            .firestore()
            .collection("countries")
            .doc(guideData.country)
            .collection("cities")
            .doc(guideData.city)
            .get()
            .then((response) => {
              const cityData = response.data();
              guideData.defaultGetGuidePrice = cityData.getGuidePrice;
              guideData.minGetGuidePrice = cityData.minGetGuidePrice;
              guideData.maxGetGuidePrice = cityData.maxGetGuidePrice;
              guideData.defaultGetGuidePriceExtra = cityData.getGuidePriceExtra;
              guideData.minGetGuidePriceExtra = cityData.minGetGuidePriceExtra;
              guideData.maxGetGuidePriceExtra = cityData.maxGetGuidePriceExtra;

              setFormData(formData);
              setData(guideData);
              setLoading(false);
            });
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const setSelectedCountry = (country) => {
    setFormData({ ...formData, country: country });
  };

  const setSelectedCity = (city) => {
    setFormData({ ...formData, city: city });
  };

  const setStatus = (status) => {
    var formUpdate = {};
    formUpdate[`${id}.action`] = status;
    firebase
      .firestore()
      .collection("aggregateList")
      .doc("listingFormList")
      .update(formUpdate)
      .then(() => {
        console.log("success");
      });
  };

  const sendConfirmationEmail = (email, link) => {
    firebase
      .firestore()
      .collection("mails")
      .add({
        to: email,
        replyTo: "we@getgoing.co.id",
        template: {
          name: "listingConfirmation",
          data: {
            servicename: "GetGuide",
            link: link,
          },
        },
      });
  };

  const deleteService = () => {
    firebase
      .firestore()
      .collection("getGuide")
      .doc(formData.id)
      .delete()
      .then(() => {
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/forms/listing-form-list`);
        setStatus("archived");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteForm = () => {
    firebase
      .firestore()
      .collection("getGuideForms")
      .doc(id)
      .delete()
      .then(() => {
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("listingFormList")
          .update({
            [id]: firebaseApp.firestore.FieldValue.delete(),
          })
          .then(() => {
            toast.success("Berhasil dihapus", {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/forms/listing-form-list`);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    if (data) {
      firebase
        .firestore()
        .collection("getGuide")
        .doc(id)
        .set(
          {
            getGuidePrice: data.getGuidePrice,
            getGuidePriceExtra: data.getGuidePriceExtra,
            guidePhoto: formData.guidePhoto,
            status: "ok",
            newData: {},
          },
          { merge: true }
        )
        .then(() => {
          history.push(`${process.env.PUBLIC_URL}/forms/listing-form-list`);
          setStatus("ok");
          firebase
            .firestore()
            .collection("users")
            .doc(guideData.author.uid)
            .get()
            .then((response) => {
              const user = response.data();
              var link = "https://getgoing.co.id/getguide/guide?id=" + id;
              sendConfirmationEmail(user.email, link);
              toast.success("Berhasil disimpan", {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    }
    // var docId = "";
    // var swiper = [];
    // var city = formData["city"] ? formData["city"] : data["city"];
    // var country = formData["country"] ? formData["country"] : data["country"];

    // if (formData.action === "create") {
    //   docId = id;
    // } else {
    //   docId = formData.id;
    // }

    // if (formData.action === "create" || formData.action === "update") {
    //   photos.forEach((photo) => {
    //     swiper.push({
    //       img: photo["preview"],
    //       title: photo["name"],
    //     });
    //   });

    //   // get getguide price
    //   firebase
    //     .firestore()
    //     .collection("countries")
    //     .doc(country)
    //     .collection("cities")
    //     .doc(city)
    //     .get()
    //     .then((doc) => {
    //       var cityData = doc.data();
    //       var getGuidePrice = cityData.getGuidePrice;

    //       // get user data
    //       firebase
    //         .firestore()
    //         .collection("users")
    //         .doc(formData.author.uid)
    //         .get()
    //         .then((user) => {
    //           var userData = user.data();
    //           var author = {
    //             avatar: formData.author.avatar,
    //             fullName: formData.author.fullName,
    //             displayName: formData.author.displayName,
    //             uid: formData.author.uid,
    //           };
    //           var slug = "getguide-" + city + "-" + author.displayName;
    //           firebase
    //             .firestore()
    //             .collection("getGuide")
    //             .doc(docId)
    //             .set(
    //               {
    //                 author,
    //                 categories: userData.categories,
    //                 description: userData.description,
    //                 languages: userData.languages,
    //                 getGuidePrice: getGuidePrice ? getGuidePrice : "",
    //                 city,
    //                 country,
    //                 swiper,
    //                 slug,
    //               },
    //               { merge: true }
    //             )
    //             .then(() => {
    //               history.push(
    //                 `${process.env.PUBLIC_URL}/forms/listing-form-list`
    //               );
    //               setStatus("ok");
    //               var link =
    //                 "https://getgoing.co.id/getguide/" + slug + "?id=" + docId;
    //               sendConfirmationEmail(userData.email, link);
    //               toast.success("Berhasil disimpan", {
    //                 position: toast.POSITION.TOP_CENTER,
    //               });
    //             })
    //             .catch(function (error) {
    //               console.error("Error writing document: ", error);
    //             });
    //         });
    //     });
    // }
  };
  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>
        <ModalBody>Hapus Layanan?</ModalBody>
        <ModalFooter>
          <Button className="btn-success-gradien mr-2" onClick={deleteService}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={deleteFormModal} toggle={toggleDeleteForm}>
        <ModalHeader toggle={toggleDeleteForm}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus Formulir?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteForm}>
            Ya
          </Button>{" "}
          <Button
            className="btn-secondary-gradien mr-2"
            onClick={toggleDeleteForm}
          >
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <Label className="col-form-label pt-0">Status</Label>
                    <span>: {guideData.status}</span>
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label pt-0">
                          ID GetGuide
                        </Label>
                        <span>: {id}</span>
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <LinkButton
                        title="Lihat Halaman Service"
                        url={`https://getgoing.co.id/getguide/getguide?id=${id}`}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label pt-0">
                          Nama Guide:
                        </Label>
                        <span> {guideData.author.fullName}</span>
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <LinkButton
                        title="Lihat Profil Partner"
                        url={`https://getgoing.co.id/user-profile/${guideData.author.uid}`}
                      />
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label className="col-form-label">Guide Photo</Label>
                    <Row>
                      <Col>
                        <img
                          src={guideData.guidePhoto}
                          alt={guideData.author.fullName}
                          className="avatar avatar-xxl avatar-square mb-4"
                        ></img>
                      </Col>

                      <Col>
                        <img
                          src={formData.guidePhoto}
                          alt={guideData.author.fullName}
                          className="avatar avatar-xxl avatar-square mb-4"
                        />
                        <FileDownload
                          url={formData.guidePhoto}
                          className="mx-3"
                        />
                        <div
                          {...getRootProps({
                            className: "dropzone dz-clickable",
                          })}
                        >
                          <input {...getInputProps()} />

                          <div className="dz-message text-muted mx-3">
                            <p>Ubah foto dan upload foto baru</p>
                            {!photoUploadFinished && (
                              <div className="loader-box">
                                <div className="loader-3"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </FormGroup>

                  <div>
                    <Row>
                      <Col>
                        Default Harga GetGuide di Kota {guideData.city}:{" "}
                      </Col>
                      <Col>{guideData.defaultGetGuidePrice}</Col>
                    </Row>
                    <Row>
                      <Col>
                        Minimal Harga GetGuide di Kota {guideData.city}:{" "}
                      </Col>
                      <Col>{guideData.minGetGuidePrice}</Col>
                    </Row>
                    <Row>
                      <Col>
                        Maksimal Harga GetGuide di Kota {guideData.city}:{" "}
                      </Col>
                      <Col>{guideData.maxGetGuidePrice}</Col>
                    </Row>
                  </div>

                  <FormGroup>
                    <Label className="col-form-label pt-3">
                      Harga GetGuide Spesifik per Grup per 5 Jam (dalam RUPIAH)
                    </Label>
                    <Row>
                      <Col>
                        <Input
                          className="form-control"
                          type="number"
                          step="1"
                          name="getGuidePriceInit"
                          defaultValue={guideData.getGuidePrice}
                          disabled
                        />
                      </Col>
                      <Col>
                        <Input
                          className="form-control"
                          type="number"
                          step="1"
                          name="getGuidePrice"
                          innerRef={register({ required: true })}
                          defaultValue={formData.getGuidePrice}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <div>
                    <Row>
                      <Col>
                        Default Harga GetGuide per Ekstra Jam di Kota{" "}
                        {guideData.city}:{" "}
                      </Col>
                      <Col>{guideData.defaultGetGuidePriceExtra}</Col>
                    </Row>
                    <Row>
                      <Col>
                        Minimal Harga GetGuide per Ekstra Jam di Kota{" "}
                        {guideData.city}:{" "}
                      </Col>
                      <Col>{guideData.minGetGuidePriceExtra}</Col>
                    </Row>
                    <Row>
                      <Col>
                        Maksimal Harga GetGuide per Ekstra Jam di Kota{" "}
                        {guideData.city}:{" "}
                      </Col>
                      <Col>{guideData.maxGetGuidePriceExtra}</Col>
                    </Row>
                  </div>

                  <FormGroup>
                    <Label className="col-form-label pt-3">
                      Harga GetGuide Spesifik per Ekstra Jam (dalam RUPIAH)
                    </Label>
                    <Row>
                      <Col>
                        <Input
                          className="form-control"
                          type="number"
                          step="1"
                          name="getGuidePriceExtraInit"
                          defaultValue={guideData.getGuidePriceExtra}
                          disabled
                        />
                      </Col>
                      <Col>
                        <Input
                          className="form-control"
                          type="number"
                          step="1"
                          name="getGuidePriceExtra"
                          innerRef={register({ required: true })}
                          defaultValue={formData.getGuidePriceExtra}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  {/* <Row>
                    {(formData.action === "update" ||
                      formData.action === "create") && (
                      <Row className="mx-3">
                        {formData.action === "update" && (
                          <Col lg="6">
                            <h3>Data Sekarang</h3>
                            <FormGroup>
                              <Label className="col-form-label">Negara</Label>
                              <Input
                                className="form-control"
                                type="text"
                                name="countryOfResidence"
                                defaultValue={data && data.country}
                                disabled
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label className="col-form-label">Kota</Label>
                              <Input
                                className="form-control"
                                type="text"
                                name="cityOfResidence"
                                defaultValue={data && data.city}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        )}
                        <h3>Data Dari Formulir</h3>
                        <SelectCountryCity
                          userCountry={formData.country}
                          userCity={formData.city}
                          selectedCountry={setSelectedCountry}
                          selectedCity={setSelectedCity}
                          listingForm
                        />
                      </Row>
                    )} */}

                  {/* {(formData.action === "update" ||
                      formData.action === "create") && (
                      <FormGroup>
                        <Label className="col-form-label">Foto</Label>
                        <Row>
                          {formData.action === "update" && (
                            <Row className="ml-n1 mr-n1">
                              {data &&
                                data.swiper &&
                                data.swiper.map((item, index) => (
                                  <Col
                                    key={index}
                                    lg="4"
                                    xs="6"
                                    className="px-1 mb-2"
                                  >
                                    <img
                                      src={item.img}
                                      alt={item.img}
                                      className="img-fluid"
                                    />

                                    <FileDownload url={item.img} />
                                  </Col>
                                ))}
                            </Row>
                          )}

                          <Row className="ml-n1 mr-n1 mx-3">
                            {photos &&
                              photos.map((file, index) => (
                                <Col
                                  key={index}
                                  lg="4"
                                  xs="6"
                                  className="px-1 mb-2"
                                >
                                  <img
                                    src={file.preview}
                                    alt={file.name ? file.name : file.preview}
                                    className="img-fluid mx-3"
                                  />
                                  <FileDownload
                                    url={file.preview}
                                    className="mx-3"
                                  />
                                </Col>
                              ))}
                          </Row>
                          <div
                            {...getRootProps({
                              className: "dropzone dz-clickable",
                            })}
                          >
                            <input {...getInputProps()} />

                            <div className="dz-message text-muted mx-3">
                              <p>Ubah foto dan upload foto baru</p>
                              {!photoUploadFinished && (
                                <div className="loader-box">
                                  <div className="loader-3"></div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Row>
                      </FormGroup>
                    )}
                  </Row> */}

                  {/* {formData.action === "delete" && (
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">Negara</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="countryOfResidence"
                          defaultValue={formData && formData.country}
                          disabled
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label className="col-form-label">Kota</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="cityOfResidence"
                          defaultValue={formData && formData.city}
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">Alasan dihapus</Label>
                        <Input
                          className="form-control"
                          type="textarea"
                          name="reason"
                          defaultValue={formData && formData.reason}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  )} */}

                  <FormGroup
                    className={`form-row mt-5 mb-0 ${
                      formData.action === "update" ? "float-right" : ""
                    }`}
                  >
                    {/* {(formData.action === "create" ||
                      formData.action === "update") && (
                      <Button
                        color="mr-2"
                        type="submit"
                        className="btn-success-gradien mr-2"
                        disabled={!photoUploadFinished}
                      >
                        Simpan
                      </Button>
                    )} */}
                    {guideData.status !== "ok" && (
                      <Button
                        color="mr-2"
                        type="submit"
                        className="btn-success-gradien mr-2"
                        disabled={!photoUploadFinished}
                      >
                        Simpan
                      </Button>
                    )}
                    {formData.action === "delete" && (
                      <Button
                        color="danger mr-2"
                        type="button"
                        onClick={toggle}
                      >
                        Hapus Layanan
                      </Button>
                    )}
                    <Button
                      color="mr-2 ml-auto float-right"
                      type="button"
                      className="btn-secondary-gradien mr-2"
                      onClick={toggleDeleteForm}
                    >
                      Hapus Formulir
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default GetGuideDetail;
