import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Button, Row } from "reactstrap";
import { Plus } from "react-feather";
import { useHistory } from "react-router-dom";
import firebase from "../../data/firebase";
import { formatDate } from "../common/TableUtils";
import MaterialUiTable from "../common/MaterialUiTable";
import { replaceImageUrl } from "../../data/helper";

const BlogList = () => {
  const history = useHistory();
  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState(true);

  const toBlogDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/blogs/blog-detail/${id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("blogList")
          .get();
        var responseData = response.data();
        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          data.push({
            id: key,
            img: value.img,
            title: value.title,
            date: value.date,
          });
        }
        setBlogList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "img",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
              className="avatar-square"
              src={replaceImageUrl(value, 80, 80)}
              alt={value}
            />
          );
        },
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "title",
      label: "JUDUL BLOG",
      options: {
        filter: false,
      },
    },
    {
      name: "date",
      label: "TANGGAL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <React.Fragment>{formatDate(value)}</React.Fragment>;
        },
        searchable: false,
        filter: false,
      },
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Blogs</h5>
            <p className="text-muted">(total {blogList.length})</p>
            <Button
              type="button"
              color="primary"
              className="px-3 button-vertical-center ml-auto mr-3"
              onClick={() => toBlogDetail("new")}
            >
              <Plus /> Tulis Blog
            </Button>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={blogList}
            toItemDetail={toBlogDetail}
            sortInitialColumn="title"
            searchableCols="Judul"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default BlogList;
