import React, { Fragment, useEffect, useState } from "react";
import { Card, Row, CardHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import firebase from "../../../data/firebase";
import MaterialUiTable from "../../common/MaterialUiTable";
import { Plus } from "react-feather";

const DepartureTables = (props) => {
  let { getFlexId } = props;
  const history = useHistory();
  const [departureList, setDepartureList] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatDate = (value) => {
    return value ? moment(value.toDate()).format("DD MMM YYYY") : "";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("getFlex")
          .doc(getFlexId)
          .get();

        if (response) {
          const data = response.data();
          const dateList = data.dates;
          const departureDraft = data.departureDraft;
          const draftIds = departureDraft
            ? departureDraft.map((item) => item.id)
            : [];

          let dateTableList = [];
          dateList.forEach((item) => {
            const id = item.id;
            // published versions
            if (!draftIds.includes(id)) {
              dateTableList.push({
                id,
                paxAvailable: item.paxAvailable,
                paxTotal: item.paxTotal,
                startDate: formatDate(item.startDate),
                endDate: formatDate(item.endDate),
                status: "PUBLISHED",
              });
            }
          });
          departureDraft.forEach((item) => {
            const id = item.id;
            dateTableList.push({
              id,
              paxAvailable: item.paxAvailable,
              paxTotal: item.paxTotal,
              startDate: formatDate(item.startDate),
              endDate: formatDate(item.endDate),
              status: "DRAFT",
            });
          });
          setDepartureList(dateTableList);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData().then(() => setLoading(false));
  }, []);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "startDate",
      label: "MULAI HARI",
    },

    {
      name: "endDate",
      label: "SAMPAI HARI",
    },
    {
      name: "paxAvailable",
      label: "PAX TERSEDIA",
    },
    {
      name: "paxTotal",
      label: "PAX TOTAL",
    },
    {
      name: "status",
      label: "STATUS",
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const toDepartureDetail = (id) => {
    history.push(
      `${process.env.PUBLIC_URL}/getflex/departure/${getFlexId}/${id}`
    );
  };

  return (
    <Fragment>
      <Card className="mx-4">
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Periode Keberangkatan</h5>
            <p className="text-muted">(total {departureList.length})</p>
            <Button
              type="button"
              className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
              onClick={() => toDepartureDetail("new")}
            >
              <Plus /> Tambah Keberangkatan
            </Button>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={departureList}
            toItemDetail={toDepartureDetail}
            sortInitialColumn="date"
            searchableCols="Nama"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default DepartureTables;
