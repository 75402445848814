import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Plus } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { toast } from "react-toastify";
import firebase from "../../data/firebase";
import ActivityInputs from "../common/ActivityInputs";
import SelectCountryCity from "../common/SelectCountryCity";
import FileDownload from "../common/FileDownload";
import LinkButton from "../common/LinkButton";

const GetExperienceDetail = () => {
  let { id } = useParams();

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [getExperienceData, setGetExperienceData] = useState({});
  const [userList, setUserList] = useState({});
  const [loading, setLoading] = useState(true);
  const [newService, setNewService] = useState(true);
  const [formData, setFormData] = useState({});

  const firstActivity = {};
  const [activityState, setActivityState] = useState([]);

  const addActivity = () => {
    setActivityState([...activityState, { ...firstActivity }]);
  };

  const handleActivityChange = (e) => {
    const updatedActivities = [...activityState];
    var fieldName = "";
    var className = e.target.className;
    if (className === "title form-control") {
      fieldName = "title";
    } else if (className === "description form-control") {
      fieldName = "description";
    }
    updatedActivities[e.target.dataset.id][fieldName] = e.target.value;
    setActivityState(updatedActivities);
  };

  const deleteInput = (id) => {
    const updatedActivities = [...activityState];
    updatedActivities.splice(id, 1);
    setActivityState(updatedActivities);
  };

  const [photos, setPhotos] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: true,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotos([]);
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      Promise.all(
        acceptedFiles.map((file) => {
          const fileName = uuidv4();
          ref
            .child("getexperienceImage/" + fileName)
            .put(file)
            .then(() => {
              ref
                .child("getexperienceImage/" + fileName)
                .getDownloadURL()
                .then((url) => {
                  file["preview"] = url;
                  setPhotos((photos) => [...photos, file]);
                  return setPhotoUploadFinished(true);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
    },
  });

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        var swiperPhotos = [];
        const response = await firebase
          .firestore()
          .collection("getExperience")
          .doc(id)
          .get();
        if (response.exists) {
          data = response.data();
          data.swiper.forEach((swiper) => {
            swiperPhotos.push({ preview: swiper.img, name: swiper.title });
          });
        }
        if (activityState.length === 0) {
          setActivityState(data.itinerary);
        }
        setGetExperienceData(data);
        setPhotos(swiperPhotos);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchUserList = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("userList")
          .get();
        if (response.exists) {
          data = response.data();
        }
        setUserList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    if (id !== "new") {
      setNewService(false);
      fetchData();
    } else {
      // get list of users
      fetchUserList();
    }
  }, []);

  const getUserOption = () => {
    var userOption = [];
    Object.keys(userList).forEach((keyName, i) => {
      userOption.push({
        value: keyName,
        label:
          userList[keyName]["displayName"] +
          " | " +
          userList[keyName]["city"] +
          " | " +
          keyName,
      });
    });
    return userOption;
  };

  const setSelectedCountry = (country) => {
    setFormData({ ...formData, country: country });
  };

  const setSelectedCity = (city) => {
    setFormData({ ...formData, city: city });
  };

  const onSelectChange = (name, e) => {
    setFormData({ ...formData, [name]: e.value });
  };

  const deleteService = () => {
    firebase
      .firestore()
      .collection("getExperience")
      .doc(id)
      .delete()
      .then(() => {
        toast.success("Berhasil disimpan", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/services/getexperience-list`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  var itinerary = [];

  const onSubmit = (data) => {
    if (data !== "") {
      itinerary = activityState;

      var docId = "";
      var city = formData["city"]
        ? formData["city"]
        : getExperienceData["city"];
      var country = formData["country"]
        ? formData["country"]
        : getExperienceData["country"];
      var slug =
        "getexperience-" + city + "-" + data["title"].replace(/ /g, "-");
      var author = {};
      var swiper = [];
      var included = data["included"].replace(/\r\n|\r|\n/g, ",");

      if (newService) {
        docId = uuidv4();
        author = userList[formData["author"]];
        author = {
          avatar: author["avatar"],
          fullName: author["fullName"],
          displayName: author["displayName"],
          uid: formData["author"],
        };
      } else {
        docId = id;
        author = {
          avatar: getExperienceData.author.avatar,
          fullName: getExperienceData.author.fullName,
          displayName: getExperienceData.author.displayName,
          uid: getExperienceData.author.uid,
        };
      }

      photos.forEach((photo) => {
        swiper.push({
          img: photo["preview"],
          title: photo["name"],
        });
      });

      firebase
        .firestore()
        .collection("getExperience")
        .doc(docId)
        .set(
          {
            author,
            city,
            country,
            title: data["title"],
            description: data["description"],
            price: data["price"],
            extraPersonPrice: data["extraPersonPrice"],
            groupSize: data["groupSize"],
            itinerary,
            included,
            slug,
            duration: data["duration"],
            swiper,
          },
          { merge: true }
        )
        .then(() => {
          firebase
            .firestore()
            .collection("aggregateList")
            .doc("getExperienceList")
            .update({
              [docId]: {
                authorName: author.fullName,
                authorAvatar: author.avatar,
                country,
                city,
                title: data["title"],
              },
            })
            .then(function () {
              toast.success("Berhasil disimpan", {
                position: toast.POSITION.TOP_CENTER,
              });
              history.push(
                `${process.env.PUBLIC_URL}/services/getexperience-list`
              );
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus GetExperience?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteService}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2 className="mb-5">GetExperience</h2>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  {newService ? (
                    <div>
                      <FormGroup>
                        <Label className="col-form-label pt-0">Partner</Label>
                        <Select
                          id="author"
                          instanceId="author"
                          name="author"
                          options={getUserOption()}
                          isSearchable
                          onChange={(e) => onSelectChange("author", e)}
                          input
                        />
                      </FormGroup>
                    </div>
                  ) : (
                    <div>
                      <Row>
                        <Col lg="3">
                          <div>
                            <img
                              src={
                                getExperienceData.author &&
                                getExperienceData.author.avatar
                              }
                              alt=""
                              className="avatar avatar-xxl avatar-square mb-4"
                            ></img>
                          </div>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <div className="col-form-label pt-0 pb-0">
                              <h2>
                                {" "}
                                {getExperienceData.author &&
                                  getExperienceData.author.fullName}{" "}
                                (
                                {getExperienceData.author &&
                                  getExperienceData.author.displayName}
                                )
                              </h2>
                            </div>
                          </FormGroup>
                          <Row>
                            <Col lg="6">
                              <LinkButton
                                title="Lihat Halaman Service"
                                url={`https://getgoing.co.id/getexperience/${getExperienceData.slug}?id=${id}`}
                              />
                            </Col>
                            <Col lg="6">
                              <LinkButton
                                title="Lihat Profil Partner"
                                url={`https://getgoing.co.id/user-profile/${getExperienceData.author.uid}`}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg="1"></Col>
                      </Row>
                    </div>
                  )}
                  <SelectCountryCity
                    userCountry={getExperienceData.country}
                    userCity={getExperienceData.city}
                    selectedCountry={setSelectedCountry}
                    selectedCity={setSelectedCity}
                  />
                  <FormGroup>
                    <Label className="col-form-label">
                      Judul <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="title"
                      innerRef={register({ required: true })}
                      defaultValue={getExperienceData.title}
                    />
                    <span style={{ color: "red" }}>
                      {errors.title && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Deskripsi <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="description"
                      innerRef={register({ required: true })}
                      defaultValue={getExperienceData.description}
                    />
                    <span style={{ color: "red" }}>
                      {errors.description && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Termasuk (1 item per baris)
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="included"
                      innerRef={register({ required: false })}
                      defaultValue={
                        getExperienceData.included &&
                        getExperienceData.included.replace(",", "\n")
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Harga dalam RUPIAH<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="price"
                      innerRef={register({ required: true })}
                      defaultValue={getExperienceData.price}
                    />
                    <span style={{ color: "red" }}>
                      {errors.price && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Durasi <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="duration"
                      innerRef={register({ required: true })}
                      defaultValue={getExperienceData.duration}
                    />
                    <span style={{ color: "red" }}>
                      {errors.duration && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label className="col-form-label">
                      Min. Jumlah Orang per Grup{" "}
                      <span className="text-danger">*</span>
                    </Label>

                    <Input
                      type="number"
                      name="groupSize"
                      id="groupSize"
                      min="1"
                      max="20"
                      defaultValue={getExperienceData.groupSize}
                      innerRef={register({ required: true })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Harga Tambahan per Orang
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="0.01"
                      name="extraPersonPrice"
                      innerRef={register({ required: true })}
                      defaultValue={getExperienceData.extraPersonPrice}
                    />
                    <span style={{ color: "red" }}>
                      {errors.extraPersonPrice && "Harus diisi"}
                    </span>
                  </FormGroup>

                  <Label className="col-form-label">Itinerary</Label>
                  <Col className="ml-auto">
                    {activityState &&
                      activityState.map((val, idx) => (
                        <ActivityInputs
                          key={`act-${idx}`}
                          idx={idx}
                          activityState={activityState}
                          handleActivityChange={handleActivityChange}
                          deleteInput={deleteInput}
                        />
                      ))}

                    <Button
                      type="button"
                      color="primary"
                      className="btn-info-gradien mr-2 px-3 button-vertical-center"
                      onClick={addActivity}
                    >
                      <Plus /> Tambah Kegiatan
                    </Button>
                  </Col>

                  <FormGroup className="mt-3">
                    <Label className="col-form-label">Foto Galeri</Label>
                    <Row className="ml-n1 mr-n1">
                      {photos &&
                        photos.map((file, index) => (
                          <Col key={index} lg="4" xs="6" className="px-1 mb-2">
                            <img
                              src={file.preview}
                              alt={file.preview}
                              className="img-fluid"
                            />

                            <FileDownload url={file.preview} />
                          </Col>
                        ))}
                    </Row>
                    <div
                      {...getRootProps({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps()} />

                      <div className="dz-message text-muted">
                        <p>Upload file-file baru (minimal 2)</p>
                        {!newService && (
                          <p>Foto-foto lama akan diganti dengan foto baru</p>
                        )}
                        {!photoUploadFinished && (
                          <div className="loader-box">
                            <div className="loader-3"></div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* {uploadError &&
                                        <p className="text-danger">Harap upload min. 2, maks. 10 fotomu.</p>
                                    } */}
                  </FormGroup>

                  <FormGroup className="form-row mt-3 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      disabled={!photoUploadFinished}
                    >
                      Simpan
                    </Button>
                    {!newService && (
                      <Button
                        type="button"
                        onClick={toggle}
                        color="mr-2"
                        className="btn-secondary-gradien mr-2"
                      >
                        Hapus Layanan
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default GetExperienceDetail;
