import React, { Fragment, useEffect, useState } from "react";
import { Card, Row, Col, CardHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import firebase from "../../../data/firebase";
import { replaceImageUrl } from "../../../data/helper";
import MaterialUiTable from "../../common/MaterialUiTable";
import { Plus } from "react-feather";
import { itineraryTableInfo } from "../itinerary/util";
import AddItinerary from "../itinerary/add-itinerary";

const UserItineraryList = (props) => {
  const { userId, setSelectedItinerary } = props;
  const history = useHistory();
  const [itineraryList, setItineraryList] = useState([]);
  const [allItineraryList, setAllItineraryList] = useState([]);
  const [allItineraryTableData, setAllItineraryTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addItineraryOpen, setAddItineraryOpen] = useState(false);

  const formatDate = (value) => {
    return value ? moment(value.toDate()).format("DD MMM YYYY") : "";
  };

  const sortByTime = (list) => {
    return list.sort((a, b) => (a.time > b.time ? 1 : -1));
  };

  useEffect(() => {
    const fetchAllItineraryData = async () => {
      const response = await firebase
        .firestore()
        .collection("aggregateList")
        .doc("itineraryList")
        .get();

      var data = response.data();
      const allItineraryData = data;

      var allItineraryTableData = [];
      var allItineraryList = [];
      Object.keys(data).forEach((itineraryId) => {
        const value = allItineraryData[itineraryId];
        allItineraryList.push({ id: itineraryId, ...value });
        if (value.type === "transportation") {
          const firstDeparture =
            value.vehicles &&
            value.vehicles.length > 0 &&
            value.vehicles[0].departure;
          const lastArrival =
            value.vehicles &&
            value.vehicles.length > 0 &&
            value.vehicles[value.vehicles.length - 1].arrival;
          allItineraryTableData.push({
            id: itineraryId,
            type: value.type,
            dateArr: [
              formatDate(firstDeparture.date),
              formatDate(lastArrival.date),
            ],
            timeArr: [firstDeparture.time, lastArrival.time],
            info: itineraryTableInfo(value),
          });
        } else if (value.type === "accommodation") {
          allItineraryTableData.push({
            id: itineraryId,
            type: value.type,
            dateArr: [
              formatDate(value["checkIn"].date),
              formatDate(value["checkOut"].date),
            ],
            timeArr: [value["checkIn"].time, value["checkOut"].time],
            info: itineraryTableInfo(value),
          });
        } else {
          const date = [formatDate(value.date)];
          const time = [value.time];
          allItineraryTableData.push({
            id: itineraryId,
            type: value.type,
            dateArr: date,
            timeArr: time,
            info: itineraryTableInfo(value),
          });
        }
      });
      setAllItineraryList(allItineraryList);
      setAllItineraryTableData(allItineraryTableData);
    };

    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .collection("itinerary")
          .doc("itinerary")
          .get();
        var responseData = response.data();

        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          const image = replaceImageUrl(value.image, 80, 80);

          if (value.type === "transportation") {
            const firstDeparture =
              value.vehicles &&
              value.vehicles.length > 0 &&
              value.vehicles[0].departure;
            const lastArrival =
              value.vehicles &&
              value.vehicles.length > 0 &&
              value.vehicles[value.vehicles.length - 1].arrival;
            data.push({
              id: key,
              type: "departure",
              date: formatDate(firstDeparture.date),
              time: firstDeparture.time,
              info: itineraryTableInfo(value),
            });
            data.push({
              id: key,
              type: "arrival",
              date: formatDate(lastArrival.date),
              time: lastArrival.time,
              info: itineraryTableInfo(value),
            });
          } else if (value.type === "accommodation") {
            ["checkIn", "checkOut"].map((t) => {
              data.push({
                id: key,
                type: t,
                date: formatDate(value[t].date),
                time: value[t].time,
                info: itineraryTableInfo(value),
              });
            });
          } else {
            const date = formatDate(value.date);
            const time = value.time;
            data.push({
              id: key,
              type: value.type,
              date,
              time,
              info: itineraryTableInfo(value),
            });
          }
        }
        const sortedList = sortByTime(data);
        setItineraryList(sortedList);
      } catch (err) {
        console.error(err);
      }
    };
    fetchAllItineraryData().then(() =>
      fetchData().then(() => setLoading(false))
    );
  }, []);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "image",
      label: " ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img className="avatar-sm" src={value} alt={value} />;
        },
        searchable: false,
      },
    },
    {
      name: "date",
      label: "TANGGAL",
    },
    {
      name: "time",
      label: "WAKTU",
      options: {
        filter: false,
      },
    },
    {
      name: "type",
      label: "TIPE",
    },
    {
      name: "info",
      label: "INFO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ whiteSpace: "pre-line" }}>{value}</span>;
        },
        filter: false,
        sort: false,
      },
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const toItineraryDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/itinerary/itinerary-detail/${id}`);
  };

  const handleItinerarySelectedRowChange = (idxs) => {
    var selectedRows = [];
    idxs.forEach((id) => {
      const value = allItineraryList[id];

      if (value.type === "transportation") {
        const firstDeparture =
          value.vehicles &&
          value.vehicles.length > 0 &&
          value.vehicles[0].departure;
        const lastArrival =
          value.vehicles &&
          value.vehicles.length > 0 &&
          value.vehicles[value.vehicles.length - 1].arrival;
        (value["dateArr"] = [
          formatDate(firstDeparture.date),
          formatDate(lastArrival.date),
        ]),
          (value["timeArr"] = [firstDeparture.time, lastArrival.time]);
      } else if (value.type === "accommodation") {
        value["dateArr"] = [
          formatDate(value["checkIn"].date),
          formatDate(value["checkOut"].date),
        ];
        value["timeArr"] = [value["checkIn"].time, value["checkOut"].time];
      } else {
        value["dateArr"] = [formatDate(value.date)];
        value["timeArr"] = [value.time];
      }
      value["info"] = itineraryTableInfo(value);

      selectedRows.push(allItineraryList[id]);
    });
    setSelectedItinerary(selectedRows);
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Card className="mx-4">
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Itinerary List</h5>
            <p className="text-muted">(total {itineraryList.length})</p>
            <Button
              type="button"
              className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
              onClick={() => setAddItineraryOpen(!addItineraryOpen)}
            >
              <Plus /> Edit Itinerary
            </Button>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={itineraryList}
            toItemDetail={toItineraryDetail}
            sortInitialColumn="date"
            searchableCols="Tipe, Tanggal"
            defaultSearchInput={true}
          />
        </div>
      </Card>

      {addItineraryOpen && (
        <AddItinerary
          allItineraryList={allItineraryTableData}
          preselectedList={itineraryList}
          handleSelectedRowChange={handleItinerarySelectedRowChange}
        />
      )}
    </Fragment>
  );
};

export default UserItineraryList;
