import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Input, Label, Form, Button, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import AppContext, { ChatContext } from "../helper/Context";
import classNames from "classnames";
import { useAuth } from "../../../services/auth-context";
import Dropzone from "../../common/Dropzone";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MessageTextArea = ({ thread }) => {
  const auth = useAuth();
  const currentUserId = auth.user.uid;
  const { isDark, isRTL } = useContext(AppContext);
  const {
    messages,
    messagesDispatch,
    threadsDispatch,
    textAreaInitialHeight,
    setTextAreaInitialHeight,
    activeMessages,
    setMessageData,
  } = useContext(ChatContext);
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState([]);

  const toggleDropzone = () => {
    setUploadFile(!uploadFile);
    setImageUrl("");
    setFileUrl("");
  };

  const setFile = (e) => {
    setFileUrl(e.preview);
    setAttachment([e.preview]);
    setFileName(e.name);
  };

  const setPhoto = (e) => {
    setImageUrl(e.preview);
    setAttachment([e.preview]);
  };

  const isMountedRef = useRef(null);

  //Emoji box hiding Controller
  useEffect(() => {
    const handleClickOutsideEmojiBox = (e) => {
      if (e.target.closest(".emoji-mart") || e.target.closest(".textarea"))
        return;
      setPreviewEmoji(false);
    };

    if (previewEmoji) {
      document.addEventListener("click", handleClickOutsideEmojiBox, false);
    } else {
      document.removeEventListener("click", handleClickOutsideEmojiBox, false);
    }

    return () =>
      document.removeEventListener("click", handleClickOutsideEmojiBox, false);
  }, [previewEmoji]);

  useEffect(() => {
    //TextBox and message body height controlling
    isMountedRef.current = true;
    let textAreaPreviousHeight = textAreaInitialHeight;
    const autoExpand = function (field) {
      // Reset field height
      field.style.height = "2rem";

      // Calculate the height
      const textAreaCurrentHeight = field.scrollHeight;

      if (
        textAreaCurrentHeight <= 160 &&
        document.querySelector(".card-chat-pane")
      ) {
        if (
          textAreaPreviousHeight !== textAreaCurrentHeight &&
          isMountedRef.current
        ) {
          document.querySelector(
            ".card-chat-pane"
          ).style.height = `calc(100% - ${textAreaCurrentHeight}px)`;
          setTextAreaInitialHeight(
            (textAreaPreviousHeight = textAreaCurrentHeight)
          );
        }
      }

      field.style.height = textAreaCurrentHeight + "px";
    };
    if (document.querySelector(".textarea")) {
      document.addEventListener(
        "input",
        function (event) {
          if (event.target.className === "textarea");
          autoExpand(event.target);
        },
        false
      );
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [textAreaInitialHeight, setTextAreaInitialHeight]);

  const addEmoji = (e) => {
    let emoji = e.native;
    setMessage(message + emoji);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const date = new Date();

    let newMessage = {
      senderUserId: currentUserId,

      ...(attachment.length > 0
        ? {
            message: {
              text: fileUrl
                ? fileName
                : `${message.replace(/(?:\r\n|\r|\n)/g, "<br>")}`,
              attachment: attachment,
              type: imageUrl ? "image" : "file",
            },
          }
        : { message: `${message.replace(/(?:\r\n|\r|\n)/g, "<br>")}` }),
      status: "delivered",
      time: date,
    };
    setAttachment([]);
    setImageUrl("");
    setFileUrl("");
    setUploadFile(false);

    const { content } = activeMessages;

    if (message || attachment.length > 0) {
      messagesDispatch({
        type: "EDIT",
        payload: { id: thread.messagesId, content: [...content, newMessage] },
        id: thread.messagesId,
      });

      setMessageData({
        id: thread.messagesId,
        content: [...content, newMessage],
      });

      thread.lastMessage = newMessage;

      threadsDispatch({
        type: "EDIT",
        payload: thread,
        id: thread.messagesId,
        isUpdatedStart: true,
        contentType: "thread",
      });
    }

    setMessage("");

    document.querySelector(".textarea").style.height = "2rem";
    document.querySelector(
      ".card-chat-pane"
    ).style.height = `calc(100% - 2rem)`;
  };

  return (
    <Form className="chat-editor-area bg-white" onSubmit={handleSubmit}>
      <Input
        className="d-none"
        type="button"
        id="chat-file-upload"
        onClick={toggleDropzone}
      />
      <Label
        for="chat-file-upload"
        className="mb-0 p-1 chat-file-upload cursor-pointer"
      >
        <FontAwesomeIcon icon="paperclip" />
      </Label>

      {uploadFile && (
        <Row
          style={{
            position: "absolute",
            bottom: "100%",
            left: isRTL ? "2%" : "auto",
            right: isRTL ? "auto" : "2%",
            padding: 0,
            zIndex: 1,
            backgroundColor: "#eee",
            borderWidth: 2,
          }}
        >
          {imageUrl || fileUrl ? (
            <div style={{ height: 300, width: 300, padding: 20 }}>
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt={imageUrl}
                  style={{ maxWidth: "100%" }}
                />
              )}
              {fileUrl && (
                <Document
                  file={fileUrl}
                  onLoadSuccess={() => console.log("success")}
                  className="pdf-preview"
                >
                  <Page pageNumber={1} renderTextLayer={false} />
                </Document>
              )}
            </div>
          ) : (
            <Row>
              <Dropzone
                path="chatMedia"
                title="Upload Foto"
                handlePhotoUploaded={(e) => setPhoto(e)}
              />

              <Dropzone
                path="chatMedia"
                title="Upload File"
                handlePhotoUploaded={(e) => setFile(e)}
                itineraryFile
              />
            </Row>
          )}
        </Row>
      )}

      <Input
        className="border-0 rounded-0 outline-none resize-none textarea bg-white"
        type="textarea"
        placeholder="Type your message"
        bsSize="sm"
        value={message}
        onChange={({ target }) => setMessage(target.value)}
        style={{
          height: "2rem",
          maxHeight: "10rem",
          paddingRight: isRTL ? "0.75rem" : "7rem",
          paddingLeft: isRTL ? "7rem" : "0.75rem",
        }}
      />
      <FontAwesomeIcon
        icon={["far", "laugh-beam"]}
        transform="grow-5"
        className="emoji-icon"
        onClick={() => setPreviewEmoji(!previewEmoji)}
      />
      {previewEmoji && (
        <Picker
          set="google"
          onSelect={addEmoji}
          sheetSize={20}
          style={{
            position: "absolute",
            bottom: "100%",
            left: isRTL ? "2%" : "auto",
            right: isRTL ? "auto" : "2%",
            padding: 0,
            zIndex: 1,
            backgroundColor: "#eee",
          }}
          theme={isDark ? "dark" : "light"}
          showPreview={false}
          showSkinTones={false}
        />
      )}
      <Button
        color="transparent"
        size="sm"
        className={classNames(`btn-send outline-none ml-1`, {
          "text-primary": message.length > 0,
        })}
        type="submit"
      >
        Send
      </Button>
    </Form>
  );
};

MessageTextArea.propTypes = {
  thread: PropTypes.object.isRequired,
};

export default MessageTextArea;
