import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import moment from "moment";
import * as firebaseApp from "firebase/app";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import firebase from "../../../data/firebase";
import BookingsTable from "./BookingsTable";
import TravellersTable from "../../common/TravellersTable";
import PriceRangeComponent from "./PriceRangeComponent";
import ItineraryTable from "./ItineraryTable";

const DepartureDetail = () => {
  let { getFlexId, id } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);
  const [focused, setFocused] = useState({ focused: false });
  const [focused2, setFocused2] = useState({ focused: false });

  const [getFlexDepartureData, setGetFlexDepartureData] = useState({
    maxPaymentDate: moment(),
    maxDPDate: moment(),
  });
  const [loading, setLoading] = useState(true);
  const [newDeparture, setNewDeparture] = useState(true);
  const [dateFocused, setDateFocused] = useState(null);
  const [itineraryList, setItineraryList] = useState([]);

  const handleDatesChange = (dates) => {
    setGetFlexDepartureData({
      ...getFlexDepartureData,
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
  };

  const setGetFlexDepartureDataValue = (property, value) => {
    setGetFlexDepartureData({ ...getFlexDepartureData, [property]: value });
  };

  const setDefaultPrice = (rangeId, typeId) => {
    const priceList = getFlexDepartureData.priceList;

    // if default price set => set other checkboxes to false
    for (let i = 0; i < priceList.length; i++) {
      const priceType = priceList[i].priceTypes;
      if (priceType && priceType.length > 0) {
        for (let j = 0; j < priceType.length; j++) {
          if (i != rangeId || j != typeId) {
            priceType[j]["isDefaultPrice"] = false;
          }
        }
      }
    }
    getFlexDepartureData.isDefaultPriceRange = false;
  };

  const setDefaultPriceRange = (e) => {
    const value = e.target.checked;
    setGetFlexDepartureData({
      ...getFlexDepartureData,
      isDefaultPriceRange: value,
    });

    if (value) {
      const priceList = getFlexDepartureData.priceList;
      if (priceList) {
        priceList.forEach((range) => {
          const priceType = range.priceTypes;
          priceType &&
            priceType.length > 0 &&
            priceType.forEach((type) => {
              type.isDefaultPrice = false;
            });
        });
      }
    }
  };

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("getFlex")
          .doc(getFlexId)
          .collection("departures")
          .doc(id)
          .get();
        if (response.exists) {
          data = response.data();
          if (data.version) {
            const lastVersion = data.version;
            data = {
              ...data,
              ...data.versions[lastVersion],
              itineraryList: data.itineraryList,
            };
          }

          data.startDate = moment(data.startDate.toDate());
          data.endDate = moment(data.endDate.toDate());
          setItineraryList(data.itineraryList);
          console.log(data.itineraryList);
          data.maxPaymentDate = data.maxPaymentDate
            ? moment(data.maxPaymentDate.toDate())
            : moment();

          data.maxDPDate = data.maxDPDate
            ? moment(data.maxDPDate.toDate())
            : moment();
        }
        setGetFlexDepartureData(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    if (id !== "new") {
      setNewDeparture(false);
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  const removeFromGetFlex = () => {
    firebase
      .firestore()
      .collection("getFlex")
      .doc(getFlexId)
      .get()
      .then((response) => {
        const data = response.data();
        const dates = data.dates;
        const newDates = dates.filter((date) => date.id !== id);

        const draftDates = data.departureDraft;
        const newDepartureDates = draftDates.filter((date) => date.id !== id);

        firebase.firestore().collection("getFlex").doc(getFlexId).set(
          {
            dates: newDates,
            departureDraft: newDepartureDates,
          },
          { merge: true }
        );

        // remove from getflex list
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("getFlexList")
          .set(
            {
              [getFlexId]: {
                dates: newDates,
              },
            },
            { merge: true }
          );

        const countryCityList = data["cities"];
        // remove from city document
        if (countryCityList && countryCityList.length > 0) {
          countryCityList.forEach((item) => {
            const country = item.country;
            const city = item.city;
            firebase
              .firestore()
              .collection("countries")
              .doc(country)
              .collection("cities")
              .doc(city)
              .collection("getFlex")
              .doc(getFlexId)
              .set(
                {
                  dates: newDates,
                },
                { merge: true }
              );
          });
        }
      });
  };
  const deleteDeparture = () => {
    firebase
      .firestore()
      .collection("getFlex")
      .doc(getFlexId)
      .collection("departures")
      .doc(id)
      .delete()
      .then(() => {
        removeFromGetFlex();
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(
          `${process.env.PUBLIC_URL}/services/getflex-detail/${getFlexId}`
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const saveToGetFlex = (newDepartureData) => {
    firebase
      .firestore()
      .collection("getFlex")
      .doc(getFlexId)
      .get()
      .then((response) => {
        const data = response.data();
        let departures = data["dates"];

        // delete from drafts
        let departureDraft = data["departureDraft"];
        if (departureDraft && departureDraft.length > 0) {
          departureDraft = departureDraft.filter(
            (date) => date.id !== newDepartureData.id
          );
        }

        if (departures && departures.length > 0) {
          const departureIndex = departures.findIndex(
            (date) => date.id === newDepartureData.id
          );
          if (departureIndex > -1) {
            departures[departureIndex] = newDepartureData;
            firebase.firestore().collection("getFlex").doc(getFlexId).set(
              {
                departureDraft,
                dates: departures,
              },
              { merge: true }
            );

            // save to getflex list
            firebase
              .firestore()
              .collection("aggregateList")
              .doc("getFlexList")
              .set(
                {
                  [getFlexId]: {
                    dates: departures,
                  },
                },
                { merge: true }
              );

            const countryCityList = data["cities"];
            // save to city document
            if (countryCityList && countryCityList.length > 0) {
              countryCityList.forEach((item) => {
                const country = item.country;
                const city = item.city;
                firebase
                  .firestore()
                  .collection("countries")
                  .doc(country)
                  .collection("cities")
                  .doc(city)
                  .collection("getFlex")
                  .doc(getFlexId)
                  .set(
                    {
                      dates: departures,
                    },
                    { merge: true }
                  );
              });
            }

            return;
          }
        }

        firebase
          .firestore()
          .collection("getFlex")
          .doc(getFlexId)
          .set(
            {
              departureDraft,
              dates:
                firebaseApp.firestore.FieldValue.arrayUnion(newDepartureData),
            },
            { merge: true }
          );

        // save to getflex list
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("getFlexList")
          .set(
            {
              [getFlexId]: {
                dates:
                  firebaseApp.firestore.FieldValue.arrayUnion(newDepartureData),
              },
            },
            { merge: true }
          );

        const countryCityList = data["cities"];
        // save to city document
        if (countryCityList && countryCityList.length > 0) {
          countryCityList.forEach((item) => {
            const country = item.country;
            const city = item.city;
            firebase
              .firestore()
              .collection("countries")
              .doc(country)
              .collection("cities")
              .doc(city)
              .collection("getFlex")
              .doc(getFlexId)
              .set(
                {
                  dates:
                    firebaseApp.firestore.FieldValue.arrayUnion(
                      newDepartureData
                    ),
                },
                { merge: true }
              );
          });
        }
      });
  };

  const formatData = (data) => {
    var docId = "";

    if (data !== "") {
      if (newDeparture) {
        docId = uuidv4();
      } else {
        docId = id;
      }

      const startDate = getFlexDepartureData["startDate"]
        ? new Date(getFlexDepartureData["startDate"])
        : "";
      const endDate = getFlexDepartureData["endDate"]
        ? new Date(getFlexDepartureData["endDate"])
        : "";

      // TODO: set default price based on checkboxes
      var defaultPrice = 0;
      var isDiscounted = false;
      var isDefaultSinglePrice = false;
      var isDefaultPriceRange = false;
      var normalPrice = 0;
      var discountPrice = 0;
      const priceList = getFlexDepartureData["priceList"];

      if (priceList && priceList.length > 0) {
        priceList.forEach((range) => {
          const priceTypes = range.priceTypes;

          if (priceTypes) {
            priceTypes.forEach((type) => {
              var price = 0;
              if (type.isDiscounted) {
                isDiscounted = true;
                price = type.discountPrice;
              } else {
                price = type.normalPrice;
              }
              range.price = price;

              if (type.isDefaultPrice) {
                isDefaultSinglePrice = true;
                defaultPrice = price;
                normalPrice = type.normalPrice;
                discountPrice = type.discountPrice;
              }
            });
          }
        });

        // if checked, or both unchecked
        if (
          getFlexDepartureData.isDefaultPriceRange ||
          (!isDefaultSinglePrice && !getFlexDepartureData.isDefaultPriceRange)
        ) {
          isDefaultPriceRange = true;
          const max = getFlexDepartureData["priceList"].reduce(
            (prev, current) =>
              parseFloat(prev.price) > parseFloat(current.price)
                ? prev
                : current,
            1
          );

          defaultPrice = max.price;
        }
      }

      const itemData = {
        id: docId,
        paxAvailable: data["paxAvailable"] || "",
        paxTotal: data["paxTotal"] || "",
        startDate,
        endDate,
        priceList: getFlexDepartureData["priceList"] || "",
        defaultPrice,
        isDiscounted,
        normalPrice,
        discountPrice: discountPrice || 0,
        isDefaultSinglePrice,
        isDefaultPriceRange,
        dpPerPerson: data.dpPerPerson || "",
        maxPaymentDate: getFlexDepartureData["maxPaymentDate"]
          ? getFlexDepartureData["maxPaymentDate"].toDate()
          : "",

        maxDPDate: getFlexDepartureData["maxDPDate"]
          ? getFlexDepartureData["maxDPDate"].toDate()
          : "",
        version: newDeparture
          ? 1
          : getFlexDepartureData.version
          ? getFlexDepartureData.version + 1
          : 1,
        itineraryList: itineraryList || [],
      };

      return itemData;
    }

    return {};
  };

  const onSaveDraft = (data) => {
    const itemData = { ...formatData(data), status: "DRAFT" };

    const initialVersion = itemData.version === 1 ? itemData : {};

    firebase
      .firestore()
      .collection("getFlex")
      .doc(getFlexId)
      .collection("departures")
      .doc(itemData.id)
      .set(
        {
          ...initialVersion,
          version: itemData.version,
          versions: {
            [itemData.version]: itemData,
          },
        },
        { merge: true }
      )
      .then(() => {
        firebase
          .firestore()
          .collection("getFlex")
          .doc(getFlexId)
          .get()
          .then((response) => {
            const data = response.data();
            let departures = data["departureDraft"];

            const departureData = {
              ...itemData,
              publishedVersion: getFlexDepartureData.publishedVersion || 0,
            };

            if (departures && departures.length > 0) {
              const departureIndex = departures.findIndex(
                (date) => date.id === itemData.id
              );
              if (departureIndex > -1) {
                departures[departureIndex] = departureData;
                firebase.firestore().collection("getFlex").doc(getFlexId).set(
                  {
                    departureDraft: departures,
                  },
                  { merge: true }
                );
              } else {
                firebase
                  .firestore()
                  .collection("getFlex")
                  .doc(getFlexId)
                  .set(
                    {
                      departureDraft:
                        firebaseApp.firestore.FieldValue.arrayUnion(
                          departureData
                        ),
                    },
                    { merge: true }
                  );
              }
            } else {
              firebase
                .firestore()
                .collection("getFlex")
                .doc(getFlexId)
                .set(
                  {
                    departureDraft:
                      firebaseApp.firestore.FieldValue.arrayUnion(
                        departureData
                      ),
                  },
                  { merge: true }
                );
            }
          });

        toast.success("Keberangkatan berhasil disimpan.", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(
          `${process.env.PUBLIC_URL}/services/getflex-detail/${getFlexId}`
        );
      });
  };

  const onSubmit = (data) => {
    const itemData = formatData(data);
    itemData.publishedVersion = itemData.version;
    itemData.status = "PUBLISHED";

    firebase
      .firestore()
      .collection("getFlex")
      .doc(getFlexId)
      .collection("departures")
      .doc(itemData.id)
      .set(
        {
          ...itemData,
          versions: {
            [itemData.version]: itemData,
          },
        },
        { merge: true }
      )
      .then(() => {
        const departureData = itemData;

        saveToGetFlex(departureData);

        toast.success("Keberangkatan berhasil dipublish.", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(
          `${process.env.PUBLIC_URL}/services/getflex-detail/${getFlexId}`
        );
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus Keberangkatan GetFlex?</ModalBody>
          <Button
            className="btn-success-gradien mr-2"
            onClick={deleteDeparture}
          >
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2 className="mb-5">GetFlex Keberangkatan</h2>

                <h4>Status: {getFlexDepartureData.status}</h4>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <Label className="col-form-label pt-2">
                      Tanggal <span className="text-danger">*</span>
                    </Label>
                    <p>mulai - selesai</p>
                    <DateRangePicker
                      startDate={
                        getFlexDepartureData.startDate
                          ? getFlexDepartureData.startDate
                          : moment()
                      }
                      startDateId="startDate"
                      startDatePlaceholderText="Dari"
                      endDate={
                        getFlexDepartureData.endDate
                          ? getFlexDepartureData.endDate
                          : moment()
                      }
                      endDateId="endDate"
                      endDatePlaceholderText="Sampai"
                      block={true}
                      onDatesChange={({ startDate, endDate }) =>
                        handleDatesChange({ startDate, endDate })
                      }
                      focusedInput={dateFocused}
                      onFocusChange={(dateFocused) =>
                        setDateFocused(dateFocused)
                      }
                      orientation={"horizontal"}
                      required
                      isOutsideRange={() => false}
                    />
                  </FormGroup>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">Pax Tersedia</Label>
                        <Input
                          className="form-control"
                          type="number"
                          name="paxAvailable"
                          innerRef={register({ required: false })}
                          defaultValue={getFlexDepartureData.paxAvailable}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">Pax Total</Label>
                        <Input
                          className="form-control"
                          type="number"
                          name="paxTotal"
                          innerRef={register({ required: false })}
                          defaultValue={getFlexDepartureData.paxTotal}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup className="mt-5 mb-5">
                    <h4>Daftar Harga</h4>
                    <PriceRangeComponent
                      value={getFlexDepartureData.priceList}
                      setValue={(e) =>
                        setGetFlexDepartureDataValue("priceList", e)
                      }
                      setDefaultPrice={setDefaultPrice}
                    />
                    <div className="mt-3">
                      <h5>Tampilan harga lain</h5>
                    </div>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="isDefaultPriceRange"
                        key="isDefaultPriceRange"
                        name="isDefaultPriceRange"
                        className="isDefaultPriceRange"
                        checked={getFlexDepartureData.isDefaultPriceRange}
                        onChange={setDefaultPriceRange}
                      />{" "}
                      Range Harga dari paling murah ke paling mahal, seperti: Rp
                      19,90 Jt - Rp 30,90 Jt
                    </Label>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      DP per orang (dalam RUPIAH)
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      name="dpPerPerson"
                      innerRef={register({ required: true })}
                      defaultValue={getFlexDepartureData.dpPerPerson}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Pembayaran DP maksimal tanggal{" "}
                    </Label>
                    <SingleDatePicker
                      date={getFlexDepartureData.maxDPDate}
                      onDateChange={(date) =>
                        setGetFlexDepartureDataValue("maxDPDate", date)
                      }
                      focused={focused2.focused}
                      onFocusChange={(focused) => setFocused2(focused)}
                      id="dateOfBirth"
                      placeholder="Tanggal"
                      numberOfMonths={1}
                      orientation="horizontal"
                      isOutsideRange={() => false}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Lunas maksimal tanggal{" "}
                    </Label>
                    <SingleDatePicker
                      date={getFlexDepartureData.maxPaymentDate}
                      onDateChange={(date) =>
                        setGetFlexDepartureDataValue("maxPaymentDate", date)
                      }
                      focused={focused.focused}
                      onFocusChange={(focused) => setFocused(focused)}
                      id="dateOfBirth"
                      placeholder="Tanggal"
                      numberOfMonths={1}
                      orientation="horizontal"
                      isOutsideRange={() => false}
                      required
                    />
                  </FormGroup>

                  {!newDeparture && (
                    <div>
                      <ItineraryTable
                        itineraries={itineraryList}
                        getFlexId={getFlexId}
                        departureId={id}
                        itineraryLevel="departure"
                      />
                      <BookingsTable
                        bookings={getFlexDepartureData.bookings}
                        getFlexId={getFlexId}
                        departureId={id}
                      />

                      <TravellersTable
                        travellersUid={getFlexDepartureData.travellersUid}
                      />
                    </div>
                  )}
                  <FormGroup className="form-row mt-5 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      onClick={handleSubmit(onSaveDraft)}
                    >
                      Simpan ke Draft
                    </Button>
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                    >
                      Simpan dan Publish
                    </Button>
                    {!newDeparture && (
                      <Button
                        type="button"
                        onClick={toggle}
                        color="mr-2"
                        className="btn-secondary-gradien mr-2"
                      >
                        Hapus Keberangkatan
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default DepartureDetail;
