export class ConfigDB {
  static data = {
    settings: {
      layout_type: "ltr",
      sidebar: {
        type: "default",
        body_type: "default",
      },
      sidebar_setting: "iconcolor-sidebar",
      sidebar_background_setting: "color3-sidebar",
    },
    color: {
      layout_version: "null",
      color: "color-1",
      primary_color: "#158df7",
      secondary_color: "#fb2e63",
      mix_background_layout: "default",
    },
    router_animation: "fadeIn",
  };
}
export default ConfigDB;
