import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Plus } from "react-feather";
import { toast } from "react-toastify";
import * as firebaseApp from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../data/firebase";
import ActivityInputs from "../common/ActivityInputs";
import SelectCountryCity from "../common/SelectCountryCity";
import FileDownload from "../common/FileDownload";
import LinkButton from "../common/LinkButton";

const GetExperienceFormDetail = () => {
  let { id } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [deleteFormModal, setDeleteFormModal] = useState(false);
  const toggleDeleteForm = () => setDeleteFormModal(!deleteFormModal);

  const [getExperienceData, setGetExperienceData] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: true,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotos([]);
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      Promise.all(
        acceptedFiles.map((file) => {
          const fileName = uuidv4();
          ref
            .child("getguideImage/" + fileName)
            .put(file)
            .then(() => {
              ref
                .child("getguideImage/" + fileName)
                .getDownloadURL()
                .then((url) => {
                  file["preview"] = url;
                  setPhotoUploadFinished(true);
                  return setPhotos((photos) => [...photos, file]);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
    },
  });

  useEffect(() => {
    let formData = {};
    let getexperienceData = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("getExperienceForms")
          .doc(id)
          .get();
        if (response.exists) {
          formData = response.data();
          if (activityState.length === 0) {
            setActivityState(formData.itinerary);
          }

          var swiperPhotos = [];

          if (formData.action !== "delete") {
            formData.photoUrls.forEach((url) => {
              swiperPhotos.push({ preview: url, name: url });
            });
            setPhotos(swiperPhotos);
          }
          if (formData.action === "update") {
            try {
              const response2 = await firebase
                .firestore()
                .collection("getExperience")
                .doc(formData.id)
                .get();
              getexperienceData = response2.data();
              setPreviousActivityState(getexperienceData.itinerary);
            } catch (err) {
              console.error(err);
            }
          }
        }
        setFormData(formData);
        setGetExperienceData(getexperienceData);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const firstActivity = {};
  const [activityState, setActivityState] = useState([]);
  const [previousActivityState, setPreviousActivityState] = useState([]);

  const addActivity = () => {
    setActivityState([...activityState, { ...firstActivity }]);
  };

  const handleActivityChange = (e) => {
    const updatedActivities = [...activityState];
    var fieldName = "";
    var className = e.target.className;
    if (className === "title form-control") {
      fieldName = "title";
    } else if (className === "description form-control") {
      fieldName = "description";
    }
    updatedActivities[e.target.dataset.id][fieldName] = e.target.value;
    setActivityState(updatedActivities);
  };

  const deleteInput = (id) => {
    const updatedActivities = [...activityState];
    updatedActivities.splice(id, 1);
    setActivityState(updatedActivities);
  };

  const setStatus = (status) => {
    var formUpdate = {};
    formUpdate[`${id}.action`] = status;

    firebase.firestore().collection("getExperienceForms").doc(id).update({
      action: status,
    });
    firebase
      .firestore()
      .collection("aggregateList")
      .doc("listingFormList")
      .update(formUpdate)
      .then(() => {
        console.log("success");
      });
  };

  const setSelectedCountry = (country) => {
    setFormData({ ...formData, country: country });
  };

  const setSelectedCity = (city) => {
    setFormData({ ...formData, city: city });
  };

  const deleteService = () => {
    firebase
      .firestore()
      .collection("getExperience")
      .doc(formData.id)
      .delete()
      .then(() => {
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/forms/listing-form-list`);
        setStatus("archived");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const sendConfirmationEmail = (email, link) => {
    firebase
      .firestore()
      .collection("mails")
      .add({
        to: email,
        replyTo: "we@getgoing.co.id",
        template: {
          name: "listingConfirmation",
          data: {
            servicename: "GetExperience",
            link: link,
          },
        },
      });
  };

  const deleteForm = () => {
    firebase
      .firestore()
      .collection("getExperienceForms")
      .doc(id)
      .delete()
      .then(() => {
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("listingFormList")
          .update({
            [id]: firebaseApp.firestore.FieldValue.delete(),
          })
          .then(() => {
            toast.success("Berhasil dihapus", {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/forms/listing-form-list`);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  var itinerary = [];

  const onSubmit = (data) => {
    if (data !== "") {
      itinerary = activityState;
      var docId = "";
      var swiper = [];
      var city = formData["city"]
        ? formData["city"]
        : getExperienceData["city"];
      var country = formData["country"]
        ? formData["country"]
        : getExperienceData["country"];
      var slug =
        "getexperience-" + city + "-" + data["title"].replace(/ /g, "-");

      var included = data["included"].replace(
        /\r\n|\r|\n/g,
        "<i className='fas fa-check'></i>"
      );

      if (formData.action === "create") {
        docId = id;
      } else {
        docId = formData.id;
      }

      if (formData.action === "create" || formData.action === "update") {
        if (photos && photos.length > 0) {
          photos.forEach((photo) => {
            swiper.push({
              img: photo["preview"],
              title: photo["name"],
            });
          });
        } else {
          formData.photoUrls.forEach((photoUrl) => {
            swiper.push({
              img: photoUrl,
              title: photoUrl,
            });
          });
        }

        firebase
          .firestore()
          .collection("users")
          .doc(formData.author.uid)
          .get()
          .then((user) => {
            var userData = user.data();
            var author = {
              avatar: userData.avatar,
              fullName: userData.fullName,
              displayName: userData.displayName,
              uid: user.id,
            };
            return firebase
              .firestore()
              .collection("getExperience")
              .doc(docId)
              .set(
                {
                  author,
                  city,
                  country,
                  title: data["title"],
                  description: data["description"],
                  price: data["price"],
                  extraPersonPrice: data["extraPersonPrice"],
                  itinerary,
                  slug,
                  duration: data["duration"],
                  included,
                  groupSize: data["groupSize"],
                  swiper,
                },
                { merge: true }
              )
              .then(() => {
                history.push(
                  `${process.env.PUBLIC_URL}/forms/listing-form-list`
                );
                setStatus("ok");
                toast.success("Berhasil disimpan", {
                  position: toast.POSITION.TOP_CENTER,
                });

                var link =
                  "https://getgoing.co.id/getexperience/" +
                  slug +
                  "?id=" +
                  docId;
                sendConfirmationEmail(userData.email, link);
              })
              .catch(function (error) {
                console.error("Error writing document: ", error);
              });
          });
      }
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus Layanan?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteService}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={deleteFormModal} toggle={toggleDeleteForm}>
        <ModalHeader toggle={toggleDeleteForm}>Konfirmasi</ModalHeader>
        <ModalBody>Hapus Formulir?</ModalBody>
        <ModalFooter>
          <Button className="btn-success-gradien mr-2" onClick={deleteForm}>
            Ya
          </Button>{" "}
          <Button
            className="btn-secondary-gradien mr-2"
            onClick={toggleDeleteForm}
          >
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <Label className="col-form-label pt-0">Action</Label>
                    <span>: {formData.action}</span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label pt-0">ID Formulir</Label>
                    <span>: {id}</span>
                  </FormGroup>
                  {formData.action !== "create" &&
                    formData.action !== "archived" && (
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="col-form-label pt-0">
                              ID GetExperience
                            </Label>
                            <span>: {formData.id}</span>
                          </FormGroup>
                        </Col>
                        <Col>
                          <LinkButton
                            title="Lihat Halaman Service"
                            url={`https://getgoing.co.id/getexperience/${formData.slug}?id=${formData.id}`}
                          />
                        </Col>
                      </Row>
                    )}

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label pt-0">
                          ID Partner
                        </Label>
                        <span>: {formData.author && formData.author.uid}</span>
                      </FormGroup>
                    </Col>
                    <Col>
                      <LinkButton
                        title="Lihat Profil Partner"
                        url={`https://getgoing.co.id/user-profile/${formData.author.uid}`}
                      />
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label className="col-form-label pt-0">Nama Partner</Label>
                    <span>: {formData.author.fullName}</span>
                  </FormGroup>
                  {(formData.action === "create" ||
                    formData.action === "update") && (
                    <Row>
                      {formData.action === "update" && (
                        <Col lg="6">
                          <h3>Data Sekarang</h3>
                          <FormGroup>
                            <Label className="col-form-label">Negara</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={
                                getExperienceData && getExperienceData.country
                              }
                              disabled
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Kota</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={
                                getExperienceData && getExperienceData.city
                              }
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Judul</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={getExperienceData.title}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Deskripsi</Label>
                            <Input
                              className="form-control"
                              type="textarea"
                              defaultValue={getExperienceData.description}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Harga </Label>
                            <Input
                              className="form-control"
                              type="number"
                              step="0.01"
                              defaultValue={getExperienceData.price}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Durasi</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={getExperienceData.duration}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Termasuk </Label>
                            <Input
                              className="form-control"
                              type="textarea"
                              defaultValue={getExperienceData.included}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <Label className="col-form-label">
                              Jumlah Orang Min. per Grup
                            </Label>
                            <Input
                              type="number"
                              name="groupSize"
                              id="groupSize"
                              min="1"
                              max="20"
                              defaultValue={getExperienceData.groupSize}
                              disabled
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Harga Tambahan per Orang
                            </Label>
                            <Input
                              className="form-control"
                              type="number"
                              defaultValue={getExperienceData.extraPersonPrice}
                              disabled
                            />
                          </FormGroup>
                          {previousActivityState &&
                            previousActivityState.map((val, idx) => (
                              <ActivityInputs
                                key={`oldact-${idx}`}
                                idx={idx}
                                activityState={previousActivityState}
                                disableDelete
                              />
                            ))}
                          <Row className="ml-n1 mr-n1">
                            {getExperienceData.swiper &&
                              getExperienceData.swiper.map((item, index) => (
                                <Col
                                  key={index}
                                  lg="4"
                                  xs="6"
                                  className="px-1 mb-2"
                                >
                                  <img
                                    src={item.img}
                                    alt={item.img}
                                    className="img-fluid"
                                  />

                                  <FileDownload url={item.img} />
                                </Col>
                              ))}
                          </Row>
                        </Col>
                      )}
                      <Col lg="6">
                        <h3>Data Dari Formulir</h3>
                        <SelectCountryCity
                          userCountry={formData.country}
                          userCity={formData.city}
                          selectedCountry={setSelectedCountry}
                          selectedCity={setSelectedCity}
                          listingForm
                        />
                        <FormGroup>
                          <Label className="col-form-label">
                            Judul <span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="title"
                            innerRef={register({ required: true })}
                            defaultValue={formData.title}
                          />
                          <span style={{ color: "red" }}>
                            {errors.title && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            Deskripsi <span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="textarea"
                            name="description"
                            innerRef={register({ required: true })}
                            defaultValue={formData.description}
                          />
                          <span style={{ color: "red" }}>
                            {errors.description && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            Harga <span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="number"
                            step="0.01"
                            name="price"
                            innerRef={register({ required: true })}
                            defaultValue={formData.price}
                          />
                          <span style={{ color: "red" }}>
                            {errors.price && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            Durasi <span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="duration"
                            innerRef={register({ required: true })}
                            defaultValue={formData.duration}
                          />
                          <span style={{ color: "red" }}>
                            {errors.duration && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            Termasuk (1 item per baris)
                          </Label>
                          <Input
                            className="form-control"
                            type="textarea"
                            name="included"
                            innerRef={register({ required: false })}
                            defaultValue={formData.included}
                          />
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Label className="col-form-label">
                            Jumlah Orang Min. per Grup{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            name="groupSize"
                            id="groupSize"
                            min="1"
                            max="20"
                            defaultValue={formData.groupSize}
                            innerRef={register({ required: true })}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            Harga Tambahan per Orang
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="number"
                            step="0.01"
                            name="extraPersonPrice"
                            innerRef={register({ required: true })}
                            defaultValue={formData.extraPersonPrice}
                          />
                          <span style={{ color: "red" }}>
                            {errors.extraPersonPrice && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <Col className="ml-auto">
                          {activityState &&
                            activityState.map((val, idx) => (
                              <ActivityInputs
                                key={`act-${idx}`}
                                idx={idx}
                                activityState={activityState}
                                handleActivityChange={handleActivityChange}
                                deleteInput={deleteInput}
                              />
                            ))}

                          <Button
                            type="button"
                            color="primary"
                            className="btn-info-gradien mr-2 px-3 button-vertical-center"
                            onClick={addActivity}
                          >
                            <Plus /> Tambah Kegiatan
                          </Button>
                        </Col>

                        <Row className="ml-n1 mr-n1">
                          {photos &&
                            photos.map((file, index) => (
                              <Col
                                key={index}
                                lg="4"
                                xs="6"
                                className="px-1 mb-2"
                              >
                                <img
                                  src={file.preview}
                                  alt={file.preview}
                                  className="img-fluid"
                                />
                                <FileDownload url={file.preview} />
                              </Col>
                            ))}
                        </Row>

                        <div
                          {...getRootProps({
                            className: "dropzone dz-clickable",
                          })}
                        >
                          <input {...getInputProps()} />

                          <div className="dz-message text-muted">
                            <p>Ubah foto dan upload foto baru</p>
                            {!photoUploadFinished && (
                              <div className="loader-box">
                                <div className="loader-3"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {formData.action === "delete" && (
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">Negara</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="countryOfResidence"
                          defaultValue={formData && formData.country}
                          disabled
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label className="col-form-label">Kota</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="cityOfResidence"
                          defaultValue={formData && formData.city}
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">Alasan dihapus</Label>
                        <Input
                          className="form-control"
                          type="textarea"
                          name="reason"
                          defaultValue={formData && formData.reason}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <FormGroup className="form-row mt-3 mb-0">
                    {(formData.action === "create" ||
                      formData.action === "update") && (
                      <Button
                        color="mr-2"
                        type="submit"
                        className="btn-success-gradien mr-2"
                        disabled={!photoUploadFinished}
                      >
                        Simpan
                      </Button>
                    )}
                    {formData.action === "delete" && (
                      <Button
                        color="mr-2"
                        type="button"
                        className="btn-secondary-gradien mr-2"
                        onClick={toggle}
                      >
                        Hapus Layanan
                      </Button>
                    )}
                    <Button
                      color="mr-2 ml-auto float-right"
                      type="button"
                      className="btn-secondary-gradien mr-2"
                      onClick={toggleDeleteForm}
                    >
                      Hapus Formulir
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default GetExperienceFormDetail;
