import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";

const PriceTypeInput = (props) => {
  const { idx, state, handleChange, disableDelete, deleteInput } = props;

  const priceTypeId = `priceType-${idx}`;
  const normalPriceId = `normalPrice-${idx}`;
  const discountPriceId = `discountPrice-${idx}`;

  return (
    <div key={`priceRange-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <Button
                type="button"
                color="danger"
                data-id={idx}
                onClick={() => deleteInput(idx)}
              >
                x
              </Button>
            )}
            <h5 className="ml-3">Tipe {idx + 1}</h5>
            <Col md="11">
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Label className="form-label" for={priceTypeId}>
                      Nama Jenis Harga
                    </Label>
                    <Input
                      type="text"
                      input={priceTypeId}
                      name={priceTypeId}
                      id={priceTypeId}
                      data-id={idx}
                      className="priceType"
                      value={state[idx].priceType || ""}
                      onChange={handleChange}
                      placeholder="Twin Bed"
                    />
                  </FormGroup>

                  <Label check>
                    <Input
                      type="checkbox"
                      id="isDefaultPrice"
                      key="isDefaultPrice"
                      name="isDefaultPrice"
                      className="isDefaultPrice"
                      data-id={idx}
                      checked={state[idx].isDefaultPrice}
                      onChange={handleChange}
                    />{" "}
                    Tampilkan sebagai harga di utama
                  </Label>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <Label className="form-label" for={normalPriceId}>
                      Harga Normal dalam RUPIAH
                    </Label>
                    <Input
                      type="number"
                      step="1"
                      input={normalPriceId}
                      name={normalPriceId}
                      id={normalPriceId}
                      data-id={idx}
                      className="normalPrice"
                      value={state[idx].normalPrice || ""}
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <Label check>
                    <Input
                      type="checkbox"
                      id="isDiscounted"
                      key="isDiscounted"
                      name="isDiscounted"
                      className="isDiscounted"
                      data-id={idx}
                      value={state[idx].isDiscounted || false}
                      defaultChecked={
                        state[idx] ? state[idx].isDiscounted : false
                      }
                      onChange={handleChange}
                    />{" "}
                    Harga Diskon
                  </Label>

                  {state[idx].isDiscounted && (
                    <FormGroup className="mb-3">
                      <Input
                        type="number"
                        step="1"
                        input={discountPriceId}
                        name={discountPriceId}
                        id={discountPriceId}
                        data-id={idx}
                        className="discountPrice"
                        value={state[idx].discountPrice || ""}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default PriceTypeInput;
