import React from "react";
import { useSelector } from "react-redux";
import UserActivity from "./UserActivity";
import BellDropdown from "./BellDropdown";

const Rightbar = () => {
  const mobileRightTog = useSelector((state) => state.Common.mobileRightToggle);
  return (
    <div className="nav-right col pull-right right-menu">
      <ul className={`nav-menus ${mobileRightTog ? "open" : ""}`}>
        <li>
          <BellDropdown />
        </li>
        <li>
          <UserActivity />
        </li>
      </ul>
    </div>
  );
};

export default Rightbar;
