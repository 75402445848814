import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col } from "reactstrap";
import app from "../data/firebase";

const Error403 = () => {
  const handleSignOut = () => {
    app
      .auth()
      .signOut()
      .then(() => {
        console.log("logged out");
      });
  };
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="error-wrapper">
          <Container>
            <div className="error-heading">
              <h2 className="headline font-success">403</h2>
            </div>
            <Col md="8 offset-md-2">
              <p className="sub-content">
                Anda tidak dapat mengakses halaman ini
              </p>
            </Col>
            <a href="https://getgoing.co.id">
              <Button color="danger" size="lg">
                Ke website GetGoing
              </Button>
            </a>
            <br />
            <Link
              to={`${process.env.PUBLIC_URL}/login`}
              onClick={() => handleSignOut()}
            >
              <Button color="success" size="lg">
                Login
              </Button>
            </Link>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default Error403;
