import React, { useState } from "react";
import { Input, Media } from "reactstrap";
import { Circle, CheckCircle } from "react-feather";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { formatDateTime } from "../common/TableUtils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BookingStatus = (props) => {
  const { idx, bookingState, handleBookingChange } = props;
  const [file, setFile] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const commentId = `comment-${idx}`;
  const fileId = `file-${idx}`;

  function onFileChange(event) {
    setFile(event.target.files[0]);
    handleBookingChange(event);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(1);
  }
  return (
    <div key={`status-${idx}`}>
      {bookingState[idx] && bookingState[idx]["status"] && (
        <Media key={`status-${idx}`}>
          <div className="timeline-round m-r-30 timeline-line-1 bg-primary">
            {bookingState[idx].completed ? <CheckCircle /> : <Circle />}
          </div>
          <Media body>
            <h6>
              {bookingState[idx].status}
              <span className="pull-right f-14">
                {bookingState[idx].completed &&
                  formatDateTime(bookingState[idx].date)}
              </span>
            </h6>
            {!bookingState[idx].completed ? (
              <>
                <Input
                  type="textarea"
                  input={commentId}
                  name={commentId}
                  id={commentId}
                  data-id={idx}
                  className="comment"
                  value={bookingState[idx].comment || ""}
                  onChange={handleBookingChange}
                />
                <Input
                  type="file"
                  input={fileId}
                  name={fileId}
                  id={fileId}
                  data-id={idx}
                  className="file"
                  onChange={onFileChange}
                  style={{ width: 100 + "%" }}
                />
                <Document
                  file={file}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className="pdf-preview"
                >
                  <Page pageNumber={pageNumber} renderTextLayer={false} />
                </Document>
              </>
            ) : (
              <>
                {bookingState[idx]["comment"] &&
                  bookingState[idx]["comment"] !== "" && (
                    <Input
                      className="form-control"
                      type="textarea"
                      name={"comment" + idx}
                      disabled
                      defaultValue={bookingState[idx]["comment"]}
                    />
                  )}

                {bookingState[idx]["paymentInfo"] && (
                  <div className="mb-5">
                    <p>Bank: {bookingState[idx]["paymentInfo"].bank}</p>
                    <p>
                      Expiration date:{" "}
                      {formatDateTime(
                        bookingState[idx]["paymentInfo"].expirationDate
                      )}
                    </p>
                    <p>
                      Total Amount:{" "}
                      {bookingState[idx]["paymentInfo"].totalAmount}
                    </p>
                  </div>
                )}

                {bookingState[idx]["file"] && bookingState[idx]["file"] !== "" && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={bookingState[idx]["file"]}
                  >
                    <Document
                      file={bookingState[idx]["file"]}
                      onLoadSuccess={onDocumentLoadSuccess}
                      className="pdf-preview"
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  </a>
                )}
                {/* <Document file={file["file"] && file["file"][0].preview} />*/}
              </>
            )}
          </Media>
        </Media>
      )}
    </div>
  );
};

export default BookingStatus;
