import React from "react";
import { Link } from "react-router-dom";
import { Badge, Row } from "reactstrap";
import { formatDate } from "../common/TableUtils";
import moment from "moment";

const getPriorityColor = (value) => {
  switch (value) {
    case "Prioritas 1":
      return "#DE350B";
    case "Prioritas 2":
      return "#FF8831";
    case "Prioritas 3":
      return "#449AEF";
  }
};

const getDeadlineColor = (value) => {
  var diffDay = moment().diff(value.toDate(), "days");
  if (diffDay >= 0) {
    return "#F2C94C";
  }
  return "#00C950";
};

const getDeadlineDiff = (value) => {
  var diffDay = moment().diff(value.toDate(), "days");
  if (diffDay > 0) {
    return diffDay + " hari yang lalu";
  } else if (diffDay < 0) {
    return diffDay * -1 + " hari lagi";
  }
  return "hari ini";
};

const KanbanCard = (props) => {
  return (
    <React.Fragment>
      {props.card && (
        <Link
          to={`/project-management/${props.channel}/${props.card.id}`}
          style={{ color: "black" }}
        >
          <div
            style={{
              backgroundColor: "#F4F2FF",
              borderRadius: "10px",
              width: "230px",
            }}
            className="my-1 p-3"
            key={props.card.id}
          >
            <h6 className="font-weight-bold">{props.card.title}</h6>
            <Row className="ml-0 mb-1">
              <Badge
                pill
                style={{
                  borderRadius: "5px",
                  backgroundColor: getPriorityColor(props.card.priority),
                }}
                className="mr-1"
              >
                {props.card.priority}
              </Badge>
              <Badge
                pill
                style={{
                  borderRadius: "5px",
                  backgroundColor: getDeadlineColor(props.card.deadline),
                }}
              >
                {getDeadlineDiff(props.card.deadline)}
              </Badge>
            </Row>
            <p>
              <i className="fa fa-clock-o"></i>
              {"  "}
              {formatDate(props.card.deadline)}
            </p>
            <div className="stacked_avatars ml-2">
              {props.card.assignees && props.card.assignees.length > 0 ? (
                props.card.assignees.map((person) => (
                  <div className="stacked_avatars__item" key={person.name}>
                    <img
                      className="avatar"
                      src={person.avatar}
                      alt={person.name}
                    />
                  </div>
                ))
              ) : (
                <span className="text-primary">OPEN TICKET</span>
              )}
            </div>
          </div>
        </Link>
      )}
    </React.Fragment>
  );
};

export default KanbanCard;
