import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Plus } from "react-feather";
import MaterialUiTable from "../../common/MaterialUiTable";

const RecommendedItineraryList = (props) => {
  const { country, city, recommendedItineraryList } = props;
  var recommendedItinerary = [];
  if (recommendedItineraryList) {
    Object.keys(recommendedItineraryList).forEach((id) => {
      const data = recommendedItineraryList[id];
      if (data) {
        var daysString = "";
        if (data.days) {
          const days = data.days;
          Object.keys(days).forEach((totalDay) => {
            Object.keys(days[totalDay]).forEach((day) => {
              if (days[totalDay][day].value) {
                daysString +=
                  "Trip " +
                  totalDay +
                  " hari, Hari ke-" +
                  day +
                  ", Mulai: " +
                  days[totalDay][day].startTime +
                  "\n";
              }
            });
          });
        }
        recommendedItinerary.push({ id, day: daysString, ...data });
      }
    });
  }

  const history = useHistory();

  const toRecommendedItineraryDetail = (id) => {
    history.push(
      `${process.env.PUBLIC_URL}/recommended-itinerary/${country}/${city}/${id}`
    );
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "day",
      label: "HARI, JAM MULAI",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ whiteSpace: "pre-line" }}>{value}</span>;
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "img",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img className="avatar-rect" src={value} alt={value} />;
        },
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "NAMA",
      options: {
        filter: false,
      },
    },
  ];

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row className="ml-1">
                  <h5 className="line-height-inherit mr-3">
                    List Saran Perjalanan
                  </h5>
                  <p className="text-muted">
                    (total{" "}
                    {recommendedItineraryList
                      ? Object.keys(recommendedItineraryList).length
                      : 0}
                    )
                  </p>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
                    onClick={() => toRecommendedItineraryDetail("new")}
                  >
                    <Plus /> Tambah Saran Perjalanan
                  </Button>
                </Row>
              </CardHeader>

              <div className="table-responsive">
                <MaterialUiTable
                  columns={columns}
                  data={recommendedItinerary}
                  toItemDetail={toRecommendedItineraryDetail}
                  sortInitialColumn="day"
                  searchableCols="Hari, Jam mulai, Nama"
                  defaultSearchInput={true}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RecommendedItineraryList;
