import React, { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import SelectCountryCity from "../common/SelectCountryCity";
import FileDownload from "../common/FileDownload";
import firebase from "../../data/firebase";
import { getLanguageOptions, getUserLanguages } from "../../data/helper";
import LanguageMapData from "../../data/languageMapData.json";
import LinkButton from "../common/LinkButton";

const UserUpdateRequestDetail = () => {
  let { id } = useParams();

  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [userData, setUserData] = useState({});
  const [newData, setNewData] = useState({});
  const [loading, setLoading] = useState(true);
  // const [formData, setFormData] = useState({});
  // const [categoriesChanged, setCategoriesChanged] = useState(false)
  // const [checkboxInput, setCheckboxInput] = useState({
  //   categories: {
  //     cityTour: false,
  //     culinary: false,
  //     halalTrip: false,
  //     history: false,
  //     photography: false,
  //     shopping: false,
  //   },
  // });

  const categoriesMap = {
    cityTour: "City Tour",
    culinary: "Kuliner",
    halalTrip: "Halal Trip",
    history: "Sejarah",
    photography: "Fotografi",
    shopping: "Belanja",
  };

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [foto, setFoto] = useState({});
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: false,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      var file = acceptedFiles[0];
      const fileName = uuidv4();
      ref
        .child("avatar/" + fileName)
        .put(file)
        .then(() => {
          ref
            .child("avatar/" + fileName)
            .getDownloadURL()
            .then((url) => {
              var photo = Object.assign(file, { preview: url });
              setFoto(photo);
              setPhotoUploadFinished(true);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });

  const setSelectedCountry = (country) => {
    // setUserData({ ...userData, countryOfResidenceUpdate: country });
    setNewData({ ...newData, countryOfResidence: country });
  };

  const setSelectedCity = (city) => {
    // setUserData({ ...userData, cityOfResidenceUpdate: city });
    setNewData({ ...newData, cityOfResidence: city });
  };

  const handleLanguageOptionChange = (languages) => {
    setNewData({ ...newData, languages: languages });
  };

  const handleBankVerification = (index, value) => {
    const userBankData = userData.bankData;
    userBankData[index].verified = value === "true" ? true : false;
    if (value === "true") {
      userBankData[index].status = "verified";
    } else {
      userBankData[index].status = "rejected";
    }
    setUserData({ ...userData, bankData: userBankData });
  };

  const onCheckboxChange = (e) => {
    const value = e.target.value;
    userData["categoriesUpdate"][e.target.id] = !value;
    // setCategoriesChanged(true)
    // setCheckboxInput({ ...checkboxInput });
    setUserData({
      ...userData,
      categoriesUpdate: userData["categoriesUpdate"],
    });
  };

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("users")
          .doc(id)
          .get();
        if (response.exists) {
          data = response.data();
          setFoto({ preview: data.avatarUpdate || data.avatar });
        }
        setUserData({
          ...data,
        });

        setNewData({
          ...data.newData,
          languages: getUserLanguages(
            data.newData.languagesUpdate || data.languages
          ),
          countryOfResidence:
            data.newData.countryOfResidence || data.countryOfResidence,
          cityOfResidence: data.newData.cityOfResidence || data.cityOfResidence,
        });
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const deleteUser = () => {
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .delete()
      .then(() => {
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/users/user-list`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSubmit = (data) => {
    if (data !== "") {
      var docId = id;
      var avatar = foto["preview"] || userData["avatar"];
      var languages = [];
      if (newData["languages"]) {
        newData["languages"].map((option) => {
          languages.push(option.value.split(",")[0]);
        });
      }

      const newUserData = {
        avatar,
        cityOfResidence: newData["cityOfResidence"],
        countryOfResidence: newData["countryOfResidence"],
        description: newData["description"],
        categories: newData["categories"],
        languages,
        bankData: userData["bankData"] || [],
        newData: {},
        status: "ok",
      };

      firebase
        .firestore()
        .collection("users")
        .doc(docId)
        .set(newUserData, { merge: true })
        .then(() => {
          var formUpdate = {};
          formUpdate[`${docId}.status`] = "ok";
          formUpdate[`${docId}.avatar`] = avatar;
          firebase
            .firestore()
            .collection("aggregateList")
            .doc("userList")
            .update(formUpdate)
            .then(() => {
              toast.success("Berhasil disimpan", {
                position: toast.POSITION.TOP_CENTER,
              });
              history.push(`${process.env.PUBLIC_URL}/users/user-list`);
            });

          // todo: notify user if verified

          // var link = "https://getgoing.co.id/user-profile/" + docId;
          // firebase
          //   .firestore()
          //   .collection("mails")
          //   .add({
          //     to: userData.email,
          //     replyTo: "we@getgoing.co.id",
          //     template: {
          //       name: "updateUserConfirmation",
          //       data: {
          //         link: link,
          //       },
          //     },
          //   });
        })
        .catch(function (error) {
          console.log("error " + error);
        });
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>
        <ModalBody>Hapus Account?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteUser}>
            Ya
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <h2>User Update Request</h2>
              <CardBody>
                <LinkButton
                  title="Lihat Profil User"
                  url={`https://getgoing.co.id/user-profile/${id}`}
                />

                <FormGroup>
                  <Label className="col-form-label">
                    Email<span className="text-danger">*</span>
                  </Label>
                  <Input
                    className="form-control"
                    type="email"
                    name="email"
                    defaultValue={userData.email}
                    disabled
                  />
                </FormGroup>
                <Row>
                  <Col lg="6">
                    <h3>Lama</h3>

                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        Nama Lengkap <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={userData.fullName}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        Nama Panggilan <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="displayName"
                        defaultValue={userData.displayName}
                        disabled
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label className="col-form-label">No. Telepon</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="phone"
                        defaultValue={userData.phone}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="profilePhoto" className="d-block form-label">
                        Avatar <span className="text-danger">*</span>
                      </Label>
                      <Col>
                        <img
                          src={userData.avatar}
                          alt={userData.name}
                          className="avatar avatar-xl avatar-square mb-2"
                        />
                        <FileDownload url={userData.avatar} />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <h3>Baru</h3>

                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        Nama Lengkap <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={newData.fullName}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">
                        Nama Panggilan <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="displayName"
                        defaultValue={newData.displayName}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label className="col-form-label">No. Telepon</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="phone"
                        defaultValue={newData.phone}
                      />
                    </FormGroup>
                    <Label for="profilePhoto" className="d-block form-label">
                      Avatar <span className="text-danger">*</span>
                    </Label>

                    {newData.avatar && <FileDownload url={newData.avatar} />}
                    <div
                      {...getRootProps({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message text-muted">
                        <p>Klik untuk ubah foto dan upload foto baru (max 1)</p>
                        <img
                          src={foto ? foto.preview : newData.avatar}
                          alt={userData.name}
                          className="avatar avatar-xl avatar-square mb-2"
                        />
                        {!photoUploadFinished && (
                          <div className="loader-box">
                            <div className="loader-3"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Label className="col-form-label">Negara</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="countryOfResidence"
                        defaultValue={userData.countryOfResidence}
                        disabled
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label className="col-form-label">Kota</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="cityOfResidence"
                        defaultValue={userData.cityOfResidence}
                        disabled
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <SelectCountryCity
                      userCountry={newData.countryOfResidence}
                      userOtherCountryUpdate={newData.countryOfResidence}
                      userCity={newData.cityOfResidence}
                      userOtherCityUpdate={newData.cityOfResidence}
                      selectedCountry={setSelectedCountry}
                      selectedCity={setSelectedCity}
                    />
                  </Col>
                </Row>

                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          Deskripsi <span className="text-danger">*</span>
                        </Label>
                        <Input
                          className="form-control"
                          type="textarea"
                          name="descriptionOld"
                          defaultValue={userData.description}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          Deskripsi <span className="text-danger">*</span>
                        </Label>
                        <Input
                          className="form-control"
                          type="textarea"
                          name="description"
                          innerRef={register({ required: false })}
                          defaultValue={newData.description}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "Harus diisi"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <Label for="categories" className="form-label">
                        Tipe Jalan <span className="text-danger">*</span>
                      </Label>
                      {Object.keys(categoriesMap).map((keyName, i) => (
                        <FormGroup check key={i}>
                          <Label check>
                            <Input
                              type="checkbox"
                              id={keyName + "old"}
                              key={keyName + "old"}
                              name={keyName + "old"}
                              defaultChecked={
                                userData.categories
                                  ? userData.categories[keyName]
                                  : false
                              }
                              disabled
                            />{" "}
                            {categoriesMap[keyName]}
                          </Label>
                        </FormGroup>
                      ))}

                      <FormGroup>
                        <Label for="languages" className="form-label">
                          Bahasa <span className="text-danger">*</span>
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="languagesOld"
                          defaultValue={userData.languages.map(
                            (lang) => LanguageMapData.isoLangs[lang].nativeName
                          )}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <Label for="categories" className="form-label">
                        Tipe Jalan <span className="text-danger">*</span>
                      </Label>
                      {Object.keys(categoriesMap).map((keyName, i) => (
                        <FormGroup check key={i}>
                          <Label check>
                            <Input
                              type="checkbox"
                              id={keyName}
                              key={keyName}
                              name={keyName}
                              value={newData["categories"][keyName] || ""}
                              defaultChecked={
                                newData.categories
                                  ? newData.categories[keyName]
                                  : false
                              }
                              onChange={(e) => onCheckboxChange(e)}
                            />{" "}
                            {categoriesMap[keyName]}
                          </Label>
                        </FormGroup>
                      ))}

                      <FormGroup>
                        <Label for="languages" className="form-label">
                          Bahasa <span className="text-danger">*</span>
                        </Label>
                        <Select
                          defaultValue={newData["languages"]}
                          isMulti
                          name="languages"
                          options={getLanguageOptions()}
                          classNamePrefix="select"
                          onChange={handleLanguageOptionChange}
                        />
                        {newData.otherLanguage && (
                          <Row>
                            <Label>Bahasa Lain:</Label>
                            <Input
                              className="form-control"
                              type="text"
                              name="languagesOld"
                              defaultValue={newData.otherLanguage}
                              disabled
                            />
                          </Row>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label for="languages" className="form-label">
                      Bank <span className="text-danger">*</span>
                    </Label>
                    {userData.bankData &&
                      userData.bankData.length > 0 &&
                      userData.bankData.map((item, index) => (
                        <Row className="mb-5" key={index}>
                          <Col lg="2">
                            <h5>{index + 1}</h5>
                          </Col>
                          <Col>
                            <Row>
                              <Label>Status: {item.status}</Label>
                            </Row>

                            <Row>
                              <Label>Terverifikasi:</Label>
                              {[true, false].map((isVerified) => (
                                <FormGroup
                                  key={isVerified}
                                  style={{ marginLeft: 50 }}
                                >
                                  <Label>
                                    <Input
                                      type="radio"
                                      id={isVerified}
                                      key={isVerified}
                                      name={isVerified}
                                      value={isVerified}
                                      checked={
                                        item.status !==
                                          "pending_verification" &&
                                        item.verified == isVerified
                                      }
                                      onChange={(e) =>
                                        handleBankVerification(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                    {isVerified ? "ya" : "tidak"}
                                  </Label>
                                </FormGroup>
                              ))}
                            </Row>
                            <Row>
                              <Label>Nama Bank:</Label>
                              <Input
                                className="form-control"
                                type="text"
                                name="bankName"
                                defaultValue={item.name}
                              />
                            </Row>

                            <Row>
                              <Label>Nama Pemilik Akun:</Label>
                              <Input
                                className="form-control"
                                type="text"
                                name="acc_holder"
                                defaultValue={item.acc_holder}
                              />
                            </Row>

                            <Row>
                              <Label>IBAN:</Label>
                              <Input
                                className="form-control"
                                type="text"
                                name="acc_number"
                                defaultValue={item.acc_number}
                              />
                            </Row>

                            <Row>
                              <Label>SWIFT-BIC:</Label>
                              <Input
                                className="form-control"
                                type="text"
                                name="bic"
                                defaultValue={item.bic}
                              />
                            </Row>
                          </Col>
                        </Row>
                      ))}
                  </FormGroup>

                  <FormGroup className="form-row mt-3 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      disabled={!photoUploadFinished}
                    >
                      Simpan User
                    </Button>
                    <Button
                      color="mr-2"
                      type="button"
                      className="btn-secondary-gradien mr-2"
                      onClick={toggle}
                    >
                      Hapus User
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default UserUpdateRequestDetail;
