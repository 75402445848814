import React from "react";

const FileDownload = (props) => {
  const download = (e) => {
    var url = e.target.href;
    const start = `%2F`;
    const end = `?alt`;
    const fileName = url.split(start)[1].split(end)[0];
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      var data = xhr.response;

      var a = document.createElement("a");
      var url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = fileName;
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    };
    xhr.open("GET", url);
    xhr.send();
  };

  return (
    <React.Fragment>
      <a
        className="anchor-button w-100"
        href={props.url}
        target="_blank"
        rel="noopener noreferrer"
        download
        onClick={download}
      >
        Download File
      </a>
    </React.Fragment>
  );
};

export default FileDownload;
