import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  FormGroup,
  Row,
  Input,
  Label,
  Form,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
} from "reactstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { Plus } from "react-feather";
import firebase from "../../data/firebase";
import Dropzone from "../common/Dropzone";
import FileDownload from "../common/FileDownload";
import TestimonialInputs from "../common/TestimonialInputs";
import BannerComponent from "./beranda-components/BannerComponent";

const Beranda = () => {
  const [loading, setLoading] = useState(true);
  const [berandaData, setBerandaData] = useState({});
  const [photo, setPhoto] = useState({});
  const [testimonialState, setTestimonialState] = useState([]);
  const [bannerState, setBannerState] = useState([]);

  const { handleSubmit } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("content")
          .doc("beranda")
          .get();
        var responseData = response.data();
        setBerandaData(responseData);
        setTestimonialState(responseData.testimonials);
        setBannerState(responseData.banner);

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const history = useHistory();

  const handlePhotoUploaded = (block, index, inputTitle, photo) => {
    var newData = berandaData;
    newData[block][index][inputTitle] = photo.preview;
    setBerandaData(newData);
    setPhoto(photo);
  };

  const handleInputChange = (block, index, inputTitle, input) => {
    var newData = berandaData;
    newData[block][index][inputTitle] = input.target
      ? input.target.value
      : input.value;
    setBerandaData({ ...newData });
  };

  const handleBannerLinksChange = (
    indexSwiper,
    indexLinks,
    inputTitle,
    input
  ) => {
    var newData = [...bannerState];

    if (!newData[indexSwiper].icons) {
      newData[indexSwiper].icons = [];
    }

    if (!newData[indexSwiper].icons[indexLinks]) {
      newData[indexSwiper].icons[indexLinks] = {};
    }

    var value = "";

    if (inputTitle === "image") {
      value = input.preview;
    } else {
      value = input.target.value;
    }

    newData[indexSwiper].icons[indexLinks][inputTitle] = value;
    setBannerState(newData);
  };

  const firstBanner = {};
  const addBanner = () => {
    setBannerState([...bannerState, { ...firstBanner }]);
  };

  const handleBannerChange = (e, fieldName, idx) => {
    const updatedBanners = [...bannerState];
    var value = "";
    if (fieldName === "photo") {
      value = e.preview;
    } else if (fieldName === "linksType") {
      value = e.value;
    } else {
      value = e.target.value;
    }

    updatedBanners[idx][fieldName] = value;

    setBannerState(updatedBanners);
  };

  const deleteBannerInput = (id) => {
    const updatedBanners = [...bannerState];
    updatedBanners.splice(id, 1);
    setBannerState(updatedBanners);
  };

  const firstTestimonial = {};
  const addTestimonial = () => {
    setTestimonialState([...testimonialState, { ...firstTestimonial }]);
  };

  const handleTestimonialChange = (e, fieldName, idx) => {
    const updatedTestimonials = [...testimonialState];
    var value = "";
    if (fieldName === "avatar") {
      value = e.preview;
    } else {
      value = e.target.value;
    }

    updatedTestimonials[idx][fieldName] = value;
    setTestimonialState(updatedTestimonials);
  };

  const deleteTestimonialInput = (id) => {
    const updatedTestimonials = [...testimonialState];
    updatedTestimonials.splice(id, 1);
    setTestimonialState(updatedTestimonials);
  };

  const onSubmit = (data) => {
    berandaData.banner = bannerState;
    berandaData.testimonials = testimonialState;
    firebase
      .firestore()
      .collection("content")
      .doc("beranda")
      .set(berandaData, { merge: true })
      .then(() => {
        toast.success("Berhasil disimpan", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch(function (error) {
        console.log("error " + error);
      });
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Col className="p-3">
          <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
            <Card className="mt-4 p-3">
              <div className="mt-3 mb-5">
                <h2>Banner</h2>

                <Col className="ml-auto">
                  {bannerState &&
                    bannerState.map((val, idx) => (
                      <BannerComponent
                        key={`act-${idx}`}
                        idx={idx}
                        bannerState={bannerState}
                        handleBannerChange={handleBannerChange}
                        handleBannerLinksChange={handleBannerLinksChange}
                        deleteInput={deleteBannerInput}
                      />
                    ))}

                  <Button
                    type="button"
                    color="primary"
                    className="btn-info-gradien mr-2 px-3 button-vertical-center"
                    onClick={addBanner}
                  >
                    <Plus /> Tambah Slide
                  </Button>
                </Col>
              </div>
              <hr />
              <div className="mt-3 mb-5">
                <h2>Produk</h2>
                {berandaData.products.map((item, index) => (
                  <Row className="p-3" key={index}>
                    <Col lg="4">
                      <Card className="card-poster mb-4 mb-lg-0">
                        <img src={item.img} alt="alt" className="bg-image" />
                      </Card>
                      {item.img && <FileDownload url={item.img} />}
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <h4>{item.title}</h4>
                        <Dropzone
                          path="webImages"
                          name={item.title}
                          handlePhotoUploaded={(photo) =>
                            handlePhotoUploaded("products", index, "img", photo)
                          }
                        />

                        <Label className="col-form-label">
                          Subtitle <span className="text-danger">*</span>
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name={`${item.title},subtitle`}
                          defaultValue={item.subtitle}
                          onChange={(subtitle) =>
                            handleInputChange(
                              "products",
                              index,
                              "subtitle",
                              subtitle
                            )
                          }
                        />
                        <Label className="col-form-label">
                          Link <span className="text-danger">*</span>
                        </Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              https://getgoing.co.id/
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control"
                            type="text"
                            name={`${item.title},link`}
                            defaultValue={item.link}
                            onChange={(link) =>
                              handleInputChange("products", index, "link", link)
                            }
                            disabled
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                ))}
              </div>
              <hr />
              <div className="mt-3 mb-5">
                <h2>Cara Kerja</h2>
                <Row>
                  {berandaData.caraKerja.map((block, index) => (
                    <Col lg="4" className="px-5" key={index}>
                      <h4>{index + 1}</h4>
                      <div className="pl-lg-5">
                        <Input
                          className="form-control"
                          type="text"
                          name={`caraKerja,title${index}`}
                          defaultValue={block.title}
                          onChange={(title) =>
                            handleInputChange(
                              "caraKerja",
                              index,
                              "title",
                              title
                            )
                          }
                        />
                        <Input
                          className="form-control"
                          type="textarea"
                          rows="10"
                          name={`caraKerja,content${index}`}
                          defaultValue={block.content}
                          onChange={(content) =>
                            handleInputChange(
                              "caraKerja",
                              index,
                              "content",
                              content
                            )
                          }
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              <hr />
              <div className="mt-3 mb-5">
                <h2>Testimonial</h2>

                <Col className="ml-auto">
                  {testimonialState &&
                    testimonialState.map((val, idx) => (
                      <TestimonialInputs
                        key={`act-${idx}`}
                        idx={idx}
                        testimonialState={testimonialState}
                        handleTestimonialChange={handleTestimonialChange}
                        deleteInput={deleteTestimonialInput}
                      />
                    ))}

                  <Button
                    type="button"
                    color="primary"
                    className="btn-info-gradien mr-2 px-3 button-vertical-center"
                    onClick={addTestimonial}
                  >
                    <Plus /> Tambah Testimonial
                  </Button>
                </Col>
              </div>
              <FormGroup className="form-row my-4">
                {/* <Button className="btn-success-gradien mr-2" type="button">
                  Simpan dan Lihat Preview
                </Button> */}
                <Button className="btn-success-gradien mr-2" type="submit">
                  Publish
                </Button>
              </FormGroup>
            </Card>
          </Form>
        </Col>
      </Container>
    </Fragment>
  );
};

export default Beranda;
