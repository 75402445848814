import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import SelectCountryCity from "../../common/SelectCountryCity";

const ItineraryInput = (props) => {
  const {
    idx,
    itineraryState,
    handleItineraryState,
    deleteInput,
    disableDelete,
  } = props;

  const citiesId = `cities-${idx}`;
  const descId = `desc-${idx}`;

  const onCheckboxFreeTimeChanged = (e) => {
    const value = e.target.value;
    handleItineraryState(idx, { freeTime: !value });
  };

  const setSelectedCountry = (country) => {
    handleItineraryState(idx, { country: country });
  };

  const setSelectedCity = (city) => {
    handleItineraryState(idx, { city: city });
  };

  return (
    <div key={`activity-${idx}`}>
      {itineraryState[idx] && (
        <div>
          <Row>
            {!disableDelete && (
              <Col md="1" className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <Col md="2">
              <h5>{idx + 1}</h5>

              <Label check>
                <Input
                  type="checkbox"
                  id="freeTime"
                  key="freeTime"
                  name="freeTime"
                  value={itineraryState[idx].freeTime || ""}
                  defaultChecked={
                    itineraryState[idx] ? itineraryState[idx].freeTime : false
                  }
                  onChange={(e) => onCheckboxFreeTimeChanged(e)}
                />{" "}
                Free Time
              </Label>
            </Col>

            {itineraryState[idx].freeTime ? (
              <Col md="9">
                <SelectCountryCity
                  userCountry={itineraryState[idx].country}
                  userCity={itineraryState[idx].city}
                  selectedCountry={setSelectedCountry}
                  selectedCity={setSelectedCity}
                />
              </Col>
            ) : (
              <Col md="9">
                <FormGroup>
                  <Label className="form-label" for={citiesId}>
                    List Kota
                  </Label>
                  <span> (1 kota per baris)</span>
                  <Input
                    type="textarea"
                    input={citiesId}
                    name={citiesId}
                    id={citiesId}
                    data-id={idx}
                    rows="3"
                    className="cities"
                    value={itineraryState[idx].cities || ""}
                    onChange={(e) => handleItineraryState(idx, e)}
                  />
                </FormGroup>
                <FormGroup className="mb-5">
                  <Label className="form-label">Deskripsi</Label>
                  <Input
                    type="textarea"
                    input={descId}
                    name={descId}
                    id={descId}
                    data-id={idx}
                    rows="3"
                    className="description"
                    value={itineraryState[idx].description || ""}
                    onChange={(e) => handleItineraryState(idx, e)}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </div>
      )}
    </div>
  );
};

export default ItineraryInput;
