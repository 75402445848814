import React, { useState } from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import LinkButton from "../../common/LinkButton";

const AvailabilityInput = (props) => {
  const { idx, state, handleChange, disableDelete, deleteInput } = props;

  const [focused, setFocused] = useState({ focused: false });

  const [date, setDate] = useState(
    state[idx].date
      ? state[idx].date instanceof Date
        ? moment(state[idx].date)
        : moment(state[idx].date.toDate())
      : null
  );

  const dateId = `date-${idx}`;
  const fromTimeId = `fromTime-${idx}`;
  const toTimeId = `toTime-${idx}`;

  const handleDateChanged = (value) => {
    handleChange(value, idx);
    setDate(value);
  };

  return (
    <div key={`availability-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <Col className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}

            <Col md="11">
              <FormGroup className="mb-3">
                <Row>
                  <Col lg="3">
                    <Label className="form-label" for={dateId}>
                      Tanggal
                    </Label>
                  </Col>
                  <Col lg="3">
                    <SingleDatePicker
                      date={date}
                      onDateChange={(date) => handleDateChanged(date)}
                      focused={focused.focused}
                      onFocusChange={(focused) => setFocused(focused)}
                      id="date"
                      placeholder="Tanggal"
                      numberOfMonths={1}
                      orientation="horizontal"
                      isOutsideRange={() => false}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="mb-3">
                <Row>
                  <Col lg="3">
                    <Label className="form-label">Jam Mulai Awal</Label>
                  </Col>
                  <Col lg="3">
                    <Input
                      className="fromTime"
                      type="time"
                      input={fromTimeId}
                      name={fromTimeId}
                      id={fromTimeId}
                      data-id={idx}
                      value={state[idx].fromTime || ""}
                      onChange={handleChange}
                      step="3600000" // hourly
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="mb-3">
                <Row>
                  <Col lg="3">
                    <Label className="form-label">Sampai jam</Label>
                  </Col>
                  <Col lg="3">
                    <Input
                      className="toTime"
                      type="time"
                      input={toTimeId}
                      name={toTimeId}
                      id={toTimeId}
                      data-id={idx}
                      value={state[idx].toTime || ""}
                      onChange={handleChange}
                      step="3600000"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="mb-3">
                <Row>
                  <Col lg="3">
                    <Label className="form-label">Booked?</Label>
                  </Col>
                  <Col lg="3">
                    <p>
                      {state[idx].booked ? "Sudah dipesan" : "Belum dipesan"}
                    </p>
                  </Col>
                </Row>
              </FormGroup>
              {state[idx].booked && state[idx].bookingDetail && (
                <div>
                  <Row>
                    <Col lg="3">
                      <Label className="form-label">Booking Id</Label>
                    </Col>
                    <Col lg="3">
                      <p>{state[idx].bookingDetail.id}</p>
                    </Col>
                    <Col lg="3">
                      <LinkButton
                        title="Lihat Booking"
                        url={`${process.env.PUBLIC_URL}/bookings/booking-form-detail/${state[idx].bookingDetail.id}`}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <Label className="form-label">Pemesan</Label>
                    </Col>
                    <Col lg="3">
                      <p>{state[idx].bookingDetail.clientDetail.name}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <Label className="form-label">Waktu mulai</Label>
                    </Col>
                    <Col lg="3">
                      <p>{state[idx].bookingDetail.startTime}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="3">
                      <Label className="form-label">Durasi</Label>
                    </Col>
                    <Col lg="3">
                      <p>{state[idx].bookingDetail.duration} jam</p>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AvailabilityInput;
