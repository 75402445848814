import { MOBILE_RIGHT_TOGGLE, SWITCH_TOGGLE, PM_TOGGLE } from "../actionTypes";

export const MobileRightToggle = (toggleVal) => {
  return {
    type: MOBILE_RIGHT_TOGGLE,
    toggleVal,
  };
};

export const SwitchToggle = (toggleVal) => {
  return {
    type: SWITCH_TOGGLE,
    toggleVal,
  };
};

export const ProjectManagementToggle = (toggleVal) => {
  return {
    type: PM_TOGGLE,
    toggleVal,
  };
};
