import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Scrollbar from "react-scrollbars-custom";
import { useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import Message from "./Message";
import ThreadInfo from "./ThreadInfo";
import { isIterableArray } from "../helper/utils";
import AppContext, { ChatContext } from "../helper/Context";
import ChatContentBodyIntro from "./ChatContentBodyIntro";
import firebase from "../../../data/firebase";

const ChatContentBody = ({ thread, isOpenThreadInfo }) => {
  let lastDate = null;
  const { isRTL } = useContext(AppContext);
  const {
    getUser,
    textAreaInitialHeight,
    loading,
    activeMessagesId,
    updateActiveMessage,
  } = useContext(ChatContext);
  const [scrollHeight, setScrollHeight] = useState(0);

  const user = getUser(thread);

  const isGroup = isIterableArray(thread.customerUid);

  const messagesRef = firebase
    .firestore()
    .collection("chats")
    .doc("threads")
    .collection("messages")
    .doc(activeMessagesId);

  const [messagesData, loadingMessages, errorMessages] = useDocument(
    messagesRef,
    {}
  );

  useEffect(() => {
    if (messagesData) {
      updateActiveMessage({
        id: activeMessagesId,
        content: messagesData.data().content,
        threadId: thread.messagesId,
      });
    }
  }, [messagesData]);

  useEffect(() => {
    if (
      document.getElementsByClassName("chat-content-scroll-area") &&
      document.getElementsByClassName("chat-content-scroll-area")[0]
    ) {
      setTimeout(() => {
        setScrollHeight(
          document.getElementsByClassName("chat-content-scroll-area")[0]
            .scrollHeight
        );
      }, 500);
    }
  }, [textAreaInitialHeight, loadingMessages]);

  if (loadingMessages) {
    return <p>loading...</p>;
  }

  const formatDate = (date) => {
    const dateFormat = moment(date.toDate());

    return {
      day: dateFormat.format("dddd"),
      hour: dateFormat.format("HH:mm"),
      date: dateFormat.format("DD MMMM YYYY"),
    };
  };

  const { content } = messagesData.data();

  return (
    <div className="chat-content-body" style={{ display: "inherit" }}>
      {loading ? (
        <p>Loading</p>
      ) : (
        <div>
          <ThreadInfo thread={thread} isOpenThreadInfo={isOpenThreadInfo} />
          <Scrollbar
            style={{
              minHeight: 400,
              minWidth: 500,
              height: "60vh",
              display: "block",
            }}
            rtl={isRTL}
            scrollTop={scrollHeight}
            noScrollX
            trackYProps={{
              renderer(props) {
                const { elementRef, ...restProps } = props;
                return (
                  <span {...restProps} ref={elementRef} className="TrackY" />
                );
              },
            }}
          >
            <div className="chat-content-scroll-area">
              <ChatContentBodyIntro user={user} isGroup={isGroup} />
              {isIterableArray(content) &&
                content.map(
                  ({ message, time, senderUserId, status }, index) => {
                    time = formatDate(time);
                    return (
                      <div key={index}>
                        {lastDate !== time.date && (
                          <div className="text-center fs--2 text-500 date-right">{`${time.date}, ${time.hour}`}</div>
                        )}
                        {(() => {
                          lastDate = time.date;
                        })()}
                        <Message
                          message={message}
                          senderUserId={senderUserId}
                          time={time}
                          status={status}
                          isGroup={isGroup}
                        />
                      </div>
                    );
                  }
                )}
            </div>
          </Scrollbar>
        </div>
      )}
    </div>
  );
};

ChatContentBody.propTypes = {
  thread: PropTypes.object.isRequired,
  isOpenThreadInfo: PropTypes.bool,
};
ChatContentBody.defaultProps = {
  isOpenThreadInfo: false,
};
export default ChatContentBody;
