import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { Plus } from "react-feather";

import PriceRangeInput from "./PriceRangeInput";

const PriceRangeComponent = (props) => {
  const { value, setValue, setDefaultPrice } = props;
  const firstInput = {};
  const [state, setState] = useState(value || []);

  const addInput = () => {
    setState([...state, { ...firstInput }]);
  };

  const handleChange = (e, idx) => {
    const updatedState = [...state];
    var fieldName = "";
    var value = "";
    var id = 0;

    var className = e.target.className;
    fieldName = className.split(" form-control")[0];
    value = e.target.value;
    id = e.target.dataset.id;
    updatedState[id][fieldName] = value;

    setState(updatedState);
    setValue(updatedState);
  };

  const handlePriceTypeChange = (idx, newState) => {
    const updatedState = [...state];
    updatedState[idx]["priceTypes"] = newState;
    setState(updatedState);
    setValue(updatedState);
  };

  const deleteInput = (id) => {
    const updatedState = [...state];
    updatedState.splice(id, 1);
    setState(updatedState);
    setValue(updatedState);
  };

  return (
    <Col className="ml-auto">
      {state &&
        state.map((val, idx) => (
          <PriceRangeInput
            key={`priceRange-${idx}`}
            idx={idx}
            state={state}
            handleChange={handleChange}
            deleteInput={deleteInput}
            handlePriceTypeChange={handlePriceTypeChange}
            setDefaultPrice={setDefaultPrice}
          />
        ))}

      <Button
        type="button"
        color="primary"
        className="btn-info-gradien mr-2 px-3 button-vertical-center"
        onClick={addInput}
      >
        <Plus /> Tambah Jenis Harga
      </Button>
    </Col>
  );
};

export default PriceRangeComponent;
