import team1 from "../../../assets/images/user/12.jpg";
import team2 from "../../../assets/images/user/12.jpg";
import team3 from "../../../assets/images/user/12.jpg";
import team4 from "../../../assets/images/user/12.jpg";
import team5 from "../../../assets/images/user/12.jpg";
import team6 from "../../../assets/images/user/12.jpg";
import team7 from "../../../assets/images/user/12.jpg";
import team8 from "../../../assets/images/user/12.jpg";
import team9 from "../../../assets/images/user/12.jpg";
import team10 from "../../../assets/images/user/12.jpg";
import team11 from "../../../assets/images/user/12.jpg";
import team12 from "../../../assets/images/user/12.jpg";
import team13 from "../../../assets/images/user/12.jpg";
import team14 from "../../../assets/images/user/12.jpg";
import team15 from "../../../assets/images/user/12.jpg";
import team16 from "../../../assets/images/user/12.jpg";
import team17 from "../../../assets/images/user/12.jpg";
import team18 from "../../../assets/images/user/12.jpg";
import team19 from "../../../assets/images/user/12.jpg";
import team20 from "../../../assets/images/user/12.jpg";
import team21 from "../../../assets/images/user/12.jpg";
import team22 from "../../../assets/images/user/12.jpg";
import team23 from "../../../assets/images/user/12.jpg";
import team24 from "../../../assets/images/user/12.jpg";
import team25 from "../../../assets/images/user/12.jpg";

export const users = [
  {
    id: 0,
    avatar: team1,
    name: "Emilia Clarke",
    institution: "Technext limited",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
  {
    id: 1,
    avatar: team2,
    name: "Kit Harington",
    institution: "Harvard Korea Society",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    mutual: 5,
    status: "status-away",
  },
  {
    id: 2,
    avatar: team4,
    name: "Peter Dinklage",
    institution: "Art Club, MIT",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    mutual: 8,
    status: "status-online",
  },
  {
    id: 3,
    avatar: team4,
    name: "Admin GetGoing",
    institution: "Art Club, MIT",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    mutual: 8,
    status: "status-online",
  },
];

export const rawThreads = [
  {
    id: 0,
    userId: 1,
    messagesId: 0,
    read: true,
  },
  {
    id: 2,
    userId: 3,
    messagesId: 2,
    read: false,
  },
];
import chatImg1 from "../../../assets/images/user/12.jpg";
import chatImg2 from "../../../assets/images/user/12.jpg";
import chatImg3 from "../../../assets/images/user/12.jpg";
import chatImg4 from "../../../assets/images/user/12.jpg";
import chatImg5 from "../../../assets/images/user/12.jpg";
import chatImg6 from "../../../assets/images/user/12.jpg";
import chatImg7 from "../../../assets/images/user/12.jpg";
import chatImg8 from "../../../assets/images/user/12.jpg";
import chatImg9 from "../../../assets/images/user/12.jpg";
import chatImg10 from "../../../assets/images/user/12.jpg";
import chatImg11 from "../../../assets/images/user/12.jpg";
import chatImg12 from "../../../assets/images/user/12.jpg";

export const rawMessages = [
  {
    id: "lfvQRvdvOib6PDU4d6H2",
    content: [
      {
        senderUserId: "3VCEvnCf3IPG0Jp1LWrEabvRM8R2",
        message:
          "Yes, in an organization stature, this is a must. Besides, we need to quickly establish all other professional appearances, e.g., having a website where members’ profile will be displayed along with additional organizational information. Providing services to existing members is more important than attracting new members at this moment, in my opinion..",
        time: {
          day: "Mon",
          hour: "11:54 AM",
          date: "May 5, 2019",
        },
      },
      {
        senderUserId: "3VCEvnCf3IPG0Jp1LWrEabvRM8R2",
        message: "What are you  doing?",
        time: {
          day: "Thu",
          hour: "11:54 AM",
          date: "May 5, 2019",
        },
      },
      {
        senderUserId: "AJojLUJ0oPMrxoqKexOatkYi87t1",
        message: {
          text: `I took this pic`,
          attachment: chatImg1,
        },
        status: "seen",
        time: {
          day: "Tue",
          hour: "11:54 AM",
          date: "May 5, 2019",
        },
      },
      {
        senderUserId: "AJojLUJ0oPMrxoqKexOatkYi87t1",
        message: "Nothing!",
        status: "seen",
        time: {
          day: "Wed",
          hour: "11:54 AM",
          date: "May 5, 2019",
        },
      },
      {
        senderUserId: "3VCEvnCf3IPG0Jp1LWrEabvRM8R2",
        message: "How are you?",
        time: {
          day: "Mon",
          hour: "11:54 AM",
          date: "May 6, 2019",
        },
      },
      {
        senderUserId: "AJojLUJ0oPMrxoqKexOatkYi87t1",
        message: "Fine",
        status: "delivered",
        time: {
          day: "Mon",
          hour: "11:54 AM",
          date: "May 6, 2019",
        },
      },
      {
        senderUserId: "AJojLUJ0oPMrxoqKexOatkYi87t1",
        message: {
          text: `Check out my pics`,
          attachment: [
            chatImg7,
            chatImg8,
            chatImg9,
            chatImg10,
            chatImg11,
            chatImg12,
          ],
        },
        status: "sent",
        time: {
          day: "Mon",
          hour: "11:54 AM",
          date: "May 7, 2019",
        },
      },

      {
        senderUserId: "3VCEvnCf3IPG0Jp1LWrEabvRM8R2",
        message: "I took some excellent images yesterday.",
        time: {
          day: "Mon",
          hour: "11:54 AM",
          date: "May 7, 2019",
        },
      },
      {
        senderUserId: 3,
        message: "Give me the images.",
        time: {
          day: "Mon",
          hour: "11:54 AM",
          date: "May 8, 2019",
        },
      },
      {
        senderUserId: "3VCEvnCf3IPG0Jp1LWrEabvRM8R2",
        message: {
          attachment: [
            chatImg1,
            chatImg2,
            chatImg3,
            chatImg4,
            chatImg5,
            chatImg6,
          ],
        },
        messageType: "attachment",
        attachment: "6 photos",
        time: {
          day: "Tue",
          hour: "11:54 AM",
          date: "May 8, 2019",
        },
      },
    ],
  },
  {
    id: 2,
    content: [
      {
        senderUserId: "3VCEvnCf3IPG0Jp1LWrEabvRM8R2",
        message: "Hello",
        status: "seen",
        time: {
          day: "Mon",
          hour: "11:54 AM",
          date: "May 5, 2019",
        },
      },
      {
        senderUserId: "3VCEvnCf3IPG0Jp1LWrEabvRM8R2",
        message: "🙂",
        status: "seen",
        time: {
          day: "Sun",
          hour: "11:54 AM",
          date: "May 5, 2019",
        },
      },
    ],
  },
];

export const groups = [
  {
    id: 0,
    name: "Avengers",
    members: [
      {
        userId: 1,
        designation: "Admin",
      },
    ],
  },
];
