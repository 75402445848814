import { useContext } from "react";
import { ChatContext } from "../helper/Context";
import { isIterableArray } from "../helper/utils";
import PropTypes from "prop-types";
import { useAuth } from "../../../services/auth-context";

const LastMessage = (lastMessage, thread) => {
  const auth = useAuth();
  const currentUserId = auth.user.uid;

  const { users, loading } = useContext(ChatContext);
  const user = users.find(({ id }) => id === lastMessage?.senderUserId);
  const name = user?.fullName.split(" ");

  const lastMassagePreview =
    lastMessage?.messageType === "attachment"
      ? `${name[0]} sent ${lastMessage.attachment}`
      : typeof lastMessage?.message === "string"
      ? lastMessage?.message.split("<br>")
      : "";

  if (!!lastMessage) {
    if (lastMessage.senderUserId === currentUserId) {
      return `You: ${lastMassagePreview[0]}`;
    }

    if (isIterableArray(thread.customerUid)) {
      return `${name[0]}: ${lastMassagePreview}`;
    }

    return `${lastMassagePreview}`;
  }

  return "Say hi to your new friend";
};
LastMessage.propTypes = {
  thread: PropTypes.object.isRequired,
  lastMessage: PropTypes.object.isRequired,
};

export default LastMessage;
