import React, { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import idLocale from "moment/locale/id";
import { v4 as uuidv4 } from "uuid";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";
import * as firebaseApp from "firebase/app";
import firebase from "../../data/firebase";

const ProjectManagementDetail = () => {
  let { channel, id } = useParams();

  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [allAdmins, setAllAdmins] = useState([]);
  const [formData, setFormData] = useState({});
  const [prevAssignees, setPrevAssignees] = useState([]);
  const [prevStatus, setPrevStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [newTask, setNewTask] = useState(true);
  const [isOverdue, setOverdue] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  moment.locale("id", idLocale);
  const [range, setRange] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [dateFocused, setDateFocused] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("projectManagement")
          .doc(channel)
          .get();
        var responseData = response.data()[id];
        if (responseData["description"]) {
          var content = htmlToDraft(responseData["description"]);
          const contentState = ContentState.createFromBlockArray(
            content.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
        setFormData({
          title: responseData.title,
          assignees: responseData.assignees,
          status: responseData.status,
          priority: responseData.priority,
        });
        setPrevStatus(responseData.status);
        setPrevAssignees(responseData.assignees);
        setRange({
          startDate: moment(responseData.startDate.toDate()),
          endDate: moment(responseData.endDate.toDate()),
        });

        setOverdue(
          responseData.endDate.toDate() < new Date() &&
            !responseData.status.includes(["Done", "Cancelled", "Postponed"])
        );

        fetchAllAdmin();
      } catch (err) {
        console.error(err);
      }
    };

    const fetchAllAdmin = async () => {
      // getAllAdmin data
      try {
        var allAdmins = [];
        await firebase
          .firestore()
          .collection("users")
          .where("role", "in", ["superadmin", "admin"])
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              allAdmins.push({
                uid: doc.id,
                avatar: doc.data()["avatar"],
                name: doc.data()["fullName"],
                email: doc.data()["email"],
              });
            });
            setLoading(false);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
        setAllAdmins(allAdmins);
      } catch (err) {
        console.error(err);
      }
    };

    if (id !== "new") {
      fetchData();
      setNewTask(false);
    } else {
      fetchAllAdmin();
      setFormData({
        status: "Todo",
      });
    }
  }, []);

  const getAllAdmins = () => {
    var adminArr = [];

    allAdmins.forEach((admin) => {
      adminArr.push({
        value:
          admin.uid + "," + admin.avatar + "," + admin.name + "," + admin.email,
        label: admin.name,
      });
    });

    return adminArr;
  };

  const getCurrentAssignees = (assignees) => {
    var asigneesArray = [];

    assignees.forEach((admin) => {
      asigneesArray.push({
        value:
          admin.uid + "," + admin.avatar + "," + admin.name + "," + admin.email,
        label: admin.name,
      });
    });
    return asigneesArray;
  };

  const handleAssigneesOptionChange = (assignees) => {
    var assigneesArr = [];
    assignees.forEach((curr) => {
      var assigneeStr = curr.value.split(",");
      assigneesArr.push({
        uid: assigneeStr[0],
        avatar: assigneeStr[1],
        name: assigneeStr[2],
        email: assigneeStr[3],
      });
    });
    setFormData({ ...formData, assignees: assigneesArr });
  };

  const getStatusOptions = () => {
    const statuses = [
      "Todo",
      "In Progress",
      "Review",
      "Done",
      "Postponed",
      "Cancelled",
    ];
    var statusOptions = [];
    statuses.forEach((status) => {
      statusOptions.push({ value: status, label: status });
    });
    return statusOptions;
  };

  const getPriorityOptions = () => {
    const priorities = ["Prioritas 1", "Prioritas 2", "Prioritas 3"];
    var options = [];
    priorities.forEach((opt) => {
      options.push({ value: opt, label: opt });
    });
    return options;
  };

  const handleOptionChange = (name, value) => {
    setFormData({ ...formData, [name]: value.value });
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData({ ...formData, [name]: value });
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      var ref = firebase.storage().ref();
      var photoObject = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      const fileName = uuidv4();
      ref
        .child("projectManagementImage/" + fileName)
        .put(file)
        .then(() => {
          ref
            .child("projectManagementImage/" + fileName)
            .getDownloadURL()
            .then((url) => {
              resolve({ data: { link: url } });
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const updateUserTask = (task) => {
    let removedAssignees = prevAssignees.filter(
      (x) => !formData["assignees"].includes(x)
    );

    let addedAssignees = formData["assignees"]?.filter(
      (x) => !prevAssignees.includes(x)
    );

    // remove task from user if assignee was removed
    var removedUidList = removedAssignees.map((a) => a.uid);
    removedUidList.forEach((uid) => {
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("tasks")
        .doc(task.id)
        .delete()
        .catch((e) => {
          console.log(e);
        });

      // update taskStats
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then((doc) => {
          var taskStats = doc.data().taskStats;
          taskStats[prevStatus] = parseInt(taskStats[prevStatus]) - 1;

          firebase.firestore().collection("users").doc(uid).set(
            {
              taskStats: taskStats,
            },
            { merge: true }
          );
        });
    });

    // add / update task to current assignees
    if (formData["assignees"] && formData["assignees"].length > 0) {
      var uidList = formData["assignees"].map((a) => a.uid);
      uidList.forEach((uid) => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("tasks")
          .doc(task.id)
          .set(
            {
              title: task.title,
              endDate: task.endDate,
              status: task.status,
              channel: channel,
              assignees: task.assignees,
              priority: task.priority,
            },
            { merge: true }
          )
          .then(() => {
            // update taskStats
            firebase
              .firestore()
              .collection("users")
              .doc(uid)
              .get()
              .then((doc) => {
                var taskStats = doc.data().taskStats;

                // if task is not new or user is not newly added
                const newlyadded = addedAssignees.filter((a) => a.uid == uid);
                if (!newTask && newlyadded.length == 0) {
                  taskStats[prevStatus] = parseInt(taskStats[prevStatus]) - 1;
                }
                taskStats[task.status] = parseInt(taskStats[task.status]) + 1;

                firebase.firestore().collection("users").doc(uid).set(
                  {
                    taskStats: taskStats,
                  },
                  { merge: true }
                );
              });

            sendEmailNotification(uid, task);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    }
  };

  const sendEmailNotification = (uid, task) => {
    var userToNotify = allAdmins.filter((a) => {
      return a.uid === uid;
    });

    var email = userToNotify[0].email;
    var link = `${process.env.PUBLIC_URL}/project-management/${channel}/${task.id}`;

    var formattedEndDate = task.endDate.toLocaleDateString("id-ID", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    firebase
      .firestore()
      .collection("mails")
      .add({
        to: email,
        replyTo: "we@getgoing.co.id",
        template: {
          name: "projectManagementTask",
          data: {
            taskTitle: task.title,
            message: newTask
              ? "Kamu mempunyai tugas baru!"
              : "Tugas telah diupdate!",
            deadline: formattedEndDate,
            status: task.status,
            description: task.description,
            link: link,
          },
        },
      });
  };

  const deleteTask = () => {
    firebase
      .firestore()
      .collection("projectManagement")
      .doc(channel)
      .set(
        {
          [id]: firebaseApp.firestore.FieldValue.delete(),
        },
        { merge: true }
      )
      .then(() => {
        var uidList = formData["assignees"].map((a) => a.uid);
        uidList.forEach((uid) => {
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .collection("tasks")
            .doc(id)
            .delete()
            .catch((e) => {
              console.log(e);
            });

          // update taskStats
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .get()
            .then((doc) => {
              var taskStats = doc.data().taskStats;
              taskStats[prevStatus] = parseInt(taskStats[prevStatus]) - 1;

              firebase.firestore().collection("users").doc(uid).set(
                {
                  taskStats: taskStats,
                },
                { merge: true }
              );
            });
        });
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/project-management/${channel}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSubmit = (data) => {
    if (data !== "") {
      // replace everything but numbers and text
      var docId = newTask ? data["title"].replace(/[^0-9|a-z|A-Z]/g, "") : id;

      firebase
        .firestore()
        .collection("projectManagement")
        .doc(channel)
        .update({
          [docId]: {
            title: formData["title"],
            assignees: formData["assignees"] ? formData["assignees"] : [],
            description: draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            ),
            startDate: new Date(range.startDate),
            endDate: new Date(range.endDate),
            status: formData["status"],
            priority: formData["priority"]
              ? formData["priority"]
              : "Prioritas 1",
          },
        })
        .then(() => {
          const task = {
            id: docId,
            title: formData["title"],
            endDate: new Date(range.endDate),
            status: formData["status"],
            description: draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            ),
            assignees: formData["assignees"],
            priority: formData["priority"]
              ? formData["priority"]
              : "Prioritas 1",
          };
          updateUserTask(task);
          toast.success("Berhasil disimpan", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.push(
            `${process.env.PUBLIC_URL}/project-management/${channel}`
          );
        })
        .catch(function (error) {
          // Handle any errors
          console.log("error " + error);
        });
    } else {
      errors.showMessages();
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>
            Hapus task? Harap hapus task hanya jika ada kesalahan dalam membuat
            task. Status bisa diubah ke "Done" atau "Cancelled" bila task
            selesai atau dibatalkan.
          </ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteTask}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <Label className="col-form-label pt-2">
                      Judul <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="title"
                      innerRef={register({ required: true })}
                      defaultValue={!newTask ? formData.title : ""}
                      onChange={handleInputChange}
                    />
                    <span style={{ color: "red" }}>
                      {errors.title && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <hr />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label pt-2">
                          Assignees <span className="text-danger">*</span>
                        </Label>
                        <Select
                          defaultValue={
                            !newTask && getCurrentAssignees(formData.assignees)
                          }
                          isMulti
                          name="languages"
                          options={getAllAdmins()}
                          classNamePrefix="select"
                          onChange={handleAssigneesOptionChange}
                          placeholder="Pilih nama... (Kosongkan untuk OPEN TICKET)"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label pt-2">
                          Prioritas <span className="text-danger">*</span>
                        </Label>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          defaultValue={
                            newTask
                              ? {
                                  value: "Prioritas 1",
                                  label: "Prioritas 1",
                                }
                              : {
                                  value: formData.priority
                                    ? formData.priority
                                    : "Prioritas 1",
                                  label: formData.priority
                                    ? formData.priority
                                    : "Prioritas 1",
                                }
                          }
                          name="priority"
                          options={getPriorityOptions()}
                          onChange={(e) => handleOptionChange("priority", e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label pt-2">
                          Deadline <span className="text-danger">*</span>
                        </Label>
                        <p>Tanggal mulai - Tanggal selesai</p>
                        <DateRangePicker
                          startDate={range.startDate}
                          startDateId="startDate"
                          startDatePlaceholderText="Dari"
                          endDate={range.endDate}
                          endDateId="endDate"
                          endDatePlaceholderText="Sampai"
                          block={true}
                          onDatesChange={({ startDate, endDate }) =>
                            setRange({ startDate, endDate })
                          }
                          focusedInput={dateFocused}
                          onFocusChange={(dateFocused) =>
                            setDateFocused(dateFocused)
                          }
                          orientation={"horizontal"}
                          required
                          isOutsideRange={() => false}
                        />
                      </FormGroup>
                      {isOverdue && (
                        <p className="text-danger">Deadline overdue</p>
                      )}
                      <FormGroup>
                        <Label className="col-form-label pt-2">
                          Status <span className="text-danger">*</span>
                        </Label>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          defaultValue={
                            newTask
                              ? {
                                  value: "Todo",
                                  label: "Todo",
                                }
                              : {
                                  value: formData.status,
                                  label: formData.status,
                                }
                          }
                          name="status"
                          options={getStatusOptions()}
                          onChange={(e) => handleOptionChange("status", e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Editor
                      toolbar={{
                        image: {
                          uploadCallback: uploadCallback,
                          previewImage: true,
                          alt: { present: true, mandatory: false },
                          inputAccept:
                            "image/gif,image/jpeg,image/jpg,image/png,image/svg,application/pdf,text/plain",
                        },
                      }}
                      editorState={editorState}
                      wrapperClassName="blog-editor-wrapper"
                      editorClassName="blog-editor"
                      onEditorStateChange={onEditorStateChange}
                    />
                  </Row>

                  <FormGroup className="form-row mt-5 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                    >
                      Simpan
                    </Button>
                    <Button
                      color="mr-2 ml-auto float-right"
                      type="button"
                      onClick={toggle}
                      className="btn-secondary-gradien"
                    >
                      Hapus Task
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default ProjectManagementDetail;
