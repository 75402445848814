import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Button, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Plus } from "react-feather";
import firebase from "../../data/firebase";
import { replaceImageUrl, upperCaseFirst } from "../../data/helper";
import MaterialUiTable from "../common/MaterialUiTable";

const CountryCityList = () => {
  const history = useHistory();
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("countryCityList")
          .get();
        var responseData = response.data();
        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          value.forEach((city) => {
            data.push({
              country: key,
              city: city.split(",")[0],
              img: city.split(",")[1],
            });
          });
        }
        setCountryList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const toCityDetail = (id1, id2) => {
    history.push(`${process.env.PUBLIC_URL}/country/city-detail/${id2}/${id1}`);
  };

  const columns = [
    {
      name: "city",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "country",
      options: {
        display: "excluded",
        filter: false,
      },
    },

    {
      name: "img",
      label: "FOTO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
              className="avatar-rect"
              src={value && replaceImageUrl(value, 100, 80)}
              alt={value}
            />
          );
        },
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "country",
      label: "NEGARA",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <React.Fragment>{upperCaseFirst(value)}</React.Fragment>;
        },
        filterType: "multiselect",
      },
    },
    {
      name: "city",
      label: "KOTA",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <React.Fragment>{upperCaseFirst(value)}</React.Fragment>;
        },
        filter: false,
      },
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Negara &amp; Kota</h5>
            <p className="text-muted">(total {countryList.length})</p>
            <Button
              type="button"
              color="primary"
              className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
              onClick={() => toCityDetail("new", "new")}
            >
              <Plus /> Tambah Kota
            </Button>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={countryList}
            toItemDetail={toCityDetail}
            sortInitialColumn="country"
            searchableCols="Negara, Kota"
            defaultSearchInput={true}
            twoRowsId
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default CountryCityList;
