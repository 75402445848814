import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Input,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import firebase from "../../data/firebase";
import SelectCountryCity from "../common/SelectCountryCity";
import FileDownload from "../common/FileDownload";
import AvailabilityComponent from "./GetGuideAvailability/AvailabilityComponent";
import LinkButton from "../common/LinkButton";
import BookingsTable from "./GetGuideAvailability/BookingsTable";
import EntryComponent from "../cms/tnc-faq-components/EntryComponent";

const GetGuideDetail = () => {
  let { id } = useParams();

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [getGuideData, setGetGuideData] = useState({
    availability: [],
    tnc: [],
    faq: [],
  });
  const [userList, setUserList] = useState({});
  const [loading, setLoading] = useState(true);
  const [newService, setNewService] = useState(true);

  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: true,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotos([]);
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      Promise.all(
        acceptedFiles.map((file) => {
          const fileName = uuidv4();
          ref
            .child("getguideImage/" + fileName)
            .put(file)
            .then(() => {
              ref
                .child("getguideImage/" + fileName)
                .getDownloadURL()
                .then((url) => {
                  file["preview"] = url;
                  setPhotos((photos) => [...photos, file]);
                  return setPhotoUploadFinished(true);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
    },
  });

  const getUserOption = () => {
    var userOption = [];
    Object.keys(userList).forEach((keyName, i) => {
      userOption.push({
        value: keyName,
        label:
          userList[keyName]["displayName"] +
          " | " +
          userList[keyName]["city"] +
          " | " +
          keyName,
      });
    });
    return userOption;
  };

  const setGetGuideDataValue = (property, value) => {
    var newData = {};
    if (property === "city") {
      onCityChange(value, getGuideData.country).then((response) => {
        const cityData = response.data();
        newData.defaultGetGuidePrice = cityData.getGuidePrice;
        newData.minGetGuidePrice = cityData.minGetGuidePrice;
        newData.maxGetGuidePrice = cityData.maxGetGuidePrice;
        newData.defaultGetGuidePriceExtra = cityData.getGuidePriceExtra;
        newData.minGetGuidePriceExtra = cityData.minGetGuidePriceExtra;
        newData.maxGetGuidePriceExtra = cityData.maxGetGuidePriceExtra;
        setGetGuideData({ ...getGuideData, [property]: value, ...newData });
      });
    } else {
      setGetGuideData({ ...getGuideData, [property]: value, ...newData });
    }
  };

  const onSelectChange = (name, e) => {
    setGetGuideData({ ...getGuideData, [name]: e.value });
  };

  const onCityChange = async (city, country) => {
    const cityData = await firebase
      .firestore()
      .collection("countries")
      .doc(country)
      .collection("cities")
      .doc(city)
      .get();

    // setGetGuideData({ ...getGuideData, ...data });
    return cityData;
  };

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        var swiperPhotos = [];
        const response = await firebase
          .firestore()
          .collection("getGuide")
          .doc(id)
          .get();
        if (response.exists) {
          data = response.data();
          if (data.swiper) {
            data.swiper.forEach((swiper) => {
              swiperPhotos.push({ preview: swiper.img, name: swiper.title });
            });
          }
          data.availability = data.availability || [];
          data.tnc = data.tnc || [];
          data.faq = data.faq || [];

          firebase
            .firestore()
            .collection("countries")
            .doc(data.country)
            .collection("cities")
            .doc(data.city)
            .get()
            .then((response) => {
              const cityData = response.data();
              data.defaultGetGuidePrice = cityData.getGuidePrice;
              data.minGetGuidePrice = cityData.minGetGuidePrice;
              data.maxGetGuidePrice = cityData.maxGetGuidePrice;
              data.defaultGetGuidePriceExtra = cityData.getGuidePriceExtra;
              data.minGetGuidePriceExtra = cityData.minGetGuidePriceExtra;
              data.maxGetGuidePriceExtra = cityData.maxGetGuidePriceExtra;

              setGetGuideData(data);
              setPhotos(swiperPhotos);
              setLoading(false);
            });
        }
      } catch (err) {
        console.error(err);
      }
    };
    const fetchUserList = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("userList")
          .get();
        if (response.exists) {
          data = response.data();
        }
        setUserList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    if (id !== "new") {
      setNewService(false);
      fetchData();
    } else {
      fetchUserList();
    }
  }, []);

  const deleteService = () => {
    firebase
      .firestore()
      .collection("getGuide")
      .doc(id)
      .delete()
      .then(() => {
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/services/getguide-list`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { handleSubmit, register } = useForm();

  const getGetGuidePrice = async (country, city) => {
    return firebase
      .firestore()
      .collection("countries")
      .doc(country)
      .collection("cities")
      .doc(city)
      .get();
  };

  const getUserData = async (authorUid) => {
    return firebase.firestore().collection("users").doc(authorUid).get();
  };

  const saveGetGuideData = async (docId, docData) => {
    return firebase
      .firestore()
      .collection("getGuide")
      .doc(docId)
      .set(docData, { merge: true });
  };

  const updateAggregateList = async (docId, docData) => {
    return firebase
      .firestore()
      .collection("aggregateList")
      .doc("getGuideList")
      .update({
        [docId]: docData,
      });
  };

  const updateCityGetGuide = async (country, city, docId, docData) => {
    return firebase
      .firestore()
      .collection("countries")
      .doc(country)
      .collection("cities")
      .doc(city)
      .collection("getGuide")
      .doc(docId)
      .set(docData);
  };

  const updateUserGetGuide = async (authorUid, docId, docData) => {
    return firebase
      .firestore()
      .collection("users")
      .doc(authorUid)
      .collection("services")
      .doc(docId)
      .set(docData);
  };

  const updateWishtripGetGuide = (wishtripUserList, docId, docData) => {
    wishtripUserList &&
      wishtripUserList.forEach((uid) => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("wishtrip")
          .doc(docId)
          .set(docData, { merge: true });
      });
  };

  const onSubmit = (data) => {
    var docId = "";
    var city = getGuideData["city"];
    var country = getGuideData["country"];
    var swiper = [];
    var authorUid = "";
    var availability = getGuideData["availability"];

    if (newService) {
      docId = uuidv4();
      authorUid = getGuideData["author"];
    } else {
      docId = id;
      authorUid = getGuideData.author.uid;
    }

    photos.forEach((photo) => {
      swiper.push({
        img: photo["preview"],
        title: photo["name"],
      });
    });

    const getGuidePrice = data.getGuidePrice || 0;
    const getGuidePriceExtra = data.getGuidePriceExtra || 0;

    // get user data from users collection
    getUserData(authorUid)
      .then((user) => {
        var userId = user.id;
        var userData = user.data();
        var author = {
          avatar: userData.avatar,
          fullName: userData.fullName,
          displayName: userData.displayName,
          email: userData.email,
          uid: authorUid,
        };
        var slug = "getguide-" + city + "-" + author.displayName;
        var docData = {
          author,
          categories: userData.categories,
          description: userData.description,
          languages: userData.languages,
          city,
          country,
          slug,
          getGuidePrice,
          getGuidePriceExtra,
          swiper,
          availability,
          tnc: getGuideData.tnc,
          faq: getGuideData.faq,
        };

        // set getguide document
        saveGetGuideData(docId, docData)
          .then(() => {
            var docDataList = {
              author,
              country,
              city,
            };

            // update getguide data in list
            updateAggregateList(docId, docDataList);

            // update getguide data in city
            var docDataCity = {
              author,
              categories: userData.categories,
              city,
              languages: userData.languages,
              slug,
              getGuidePrice,
              availability,
            };
            updateCityGetGuide(country, city, docId, docDataCity);

            // update getguide data in user
            var docDataUser = {
              type: "getGuide",
              img: author.avatar,
              slug,
              city,
            };
            updateUserGetGuide(userId, docId, docDataUser)
              .then(() => {
                toast.success("Berhasil disimpan", {
                  position: toast.POSITION.TOP_CENTER,
                });
                history.push(
                  `${process.env.PUBLIC_URL}/services/getguide-list`
                );
              })
              .catch((error) => {
                console.error("Error: ", error);
              });

            // update getguideData in wishtrip
            const wishtripUserList = getGuideData.wishtripUser;
            var wishtripData = {
              availability,
            };
            updateWishtripGetGuide(wishtripUserList, docId, wishtripData);
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus GetGuide?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteService}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2 className="mb-5">GetGuide</h2>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  {newService ? (
                    <div>
                      <FormGroup>
                        <Label className="col-form-label pt-0">Partner</Label>
                        <Select
                          id="author"
                          instanceId="author"
                          name="author"
                          options={getUserOption()}
                          isSearchable
                          onChange={(e) => onSelectChange("author", e)}
                          input
                        />
                        <SelectCountryCity
                          userCountry={getGuideData.country}
                          userCity={getGuideData.city}
                          selectedCountry={(value) =>
                            setGetGuideDataValue("country", value)
                          }
                          selectedCity={(value) =>
                            setGetGuideDataValue("city", value)
                          }
                        />
                      </FormGroup>
                    </div>
                  ) : (
                    <div>
                      <Row>
                        <Col lg="3">
                          <div>
                            <img
                              src={
                                getGuideData.author &&
                                getGuideData.author.avatar
                              }
                              alt=""
                              className="avatar avatar-xxl avatar-square mb-4"
                            ></img>
                          </div>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <div className="col-form-label pt-0 pb-0">
                              <h2>
                                {" "}
                                {getGuideData.author &&
                                  getGuideData.author.fullName}{" "}
                                (
                                {getGuideData.author &&
                                  getGuideData.author.displayName}
                                )
                              </h2>
                            </div>
                          </FormGroup>
                          <Row>
                            <Col lg="6">
                              <LinkButton
                                title="Lihat Halaman Service"
                                url={`https://getgoing.co.id/getguide/${getGuideData.slug}?id=${id}`}
                              />
                            </Col>
                            <Col lg="6">
                              {getGuideData.author && (
                                <LinkButton
                                  title="Lihat Profil Member"
                                  url={`${process.env.PUBLIC_URL}/community/member-detail/${getGuideData.author.uid}`}
                                />
                              )}
                            </Col>
                          </Row>
                          <SelectCountryCity
                            userCountry={getGuideData.country}
                            userCity={getGuideData.city}
                            selectedCountry={(value) =>
                              setGetGuideDataValue("country", value)
                            }
                            selectedCity={(value) =>
                              setGetGuideDataValue("city", value)
                            }
                          />
                        </Col>
                        <Col lg="1"></Col>
                      </Row>
                    </div>
                  )}

                  <div>
                    <Row>
                      <Col>
                        Default Harga GetGuide di Kota {getGuideData.city}:{" "}
                      </Col>
                      <Col>{getGuideData.defaultGetGuidePrice}</Col>
                    </Row>
                    <Row>
                      <Col>
                        Minimal Harga GetGuide di Kota {getGuideData.city}:{" "}
                      </Col>
                      <Col>{getGuideData.minGetGuidePrice}</Col>
                    </Row>
                    <Row>
                      <Col>
                        Maksimal Harga GetGuide di Kota {getGuideData.city}:{" "}
                      </Col>
                      <Col>{getGuideData.maxGetGuidePrice}</Col>
                    </Row>
                  </div>

                  <FormGroup>
                    <Label className="col-form-label pt-3">
                      Harga GetGuide Spesifik per Grup per 5 Jam (dalam RUPIAH)
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="getGuidePrice"
                      innerRef={register({ required: false })}
                      defaultValue={getGuideData.getGuidePrice}
                    />
                  </FormGroup>

                  <div>
                    <Row>
                      <Col>
                        Default Harga GetGuide per Ekstra Jam di Kota{" "}
                        {getGuideData.city}:{" "}
                      </Col>
                      <Col>{getGuideData.defaultGetGuidePriceExtra}</Col>
                    </Row>
                    <Row>
                      <Col>
                        Minimal Harga GetGuide per Ekstra Jam di Kota{" "}
                        {getGuideData.city}:{" "}
                      </Col>
                      <Col>{getGuideData.minGetGuidePriceExtra}</Col>
                    </Row>
                    <Row>
                      <Col>
                        Maksimal Harga GetGuide per Ekstra Jam di Kota{" "}
                        {getGuideData.city}:{" "}
                      </Col>
                      <Col>{getGuideData.maxGetGuidePriceExtra}</Col>
                    </Row>
                  </div>

                  <FormGroup>
                    <Label className="col-form-label pt-3">
                      Harga GetGuide Spesifik per Ekstra Jam (dalam RUPIAH)
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="getGuidePriceExtra"
                      innerRef={register({ required: false })}
                      defaultValue={getGuideData.getGuidePriceExtra}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label pt-3">Availability</Label>
                    <AvailabilityComponent
                      availabilityList={getGuideData.availability}
                      setAvailabilityList={(value) =>
                        setGetGuideDataValue("availability", value)
                      }
                    />
                  </FormGroup>

                  <FormGroup className="mt-5">
                    <h4>TNC Spesifik</h4>
                    <EntryComponent
                      value={getGuideData.tnc}
                      setValue={(value) => setGetGuideDataValue("tnc", value)}
                    />
                  </FormGroup>

                  <FormGroup className="mt-5">
                    <h4>FAQ Spesifik</h4>
                    <EntryComponent
                      value={getGuideData.faq}
                      setValue={(value) => setGetGuideDataValue("faq", value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label pt-3">Foto Galeri</Label>
                    <Row className="ml-n1 mr-n1">
                      {photos &&
                        photos.map((file, index) => (
                          <Col key={index} lg="4" xs="6" className="px-1 mb-2">
                            <img
                              src={file.preview}
                              alt={file.preview}
                              className="img-fluid"
                            />

                            <FileDownload url={file.preview} />
                          </Col>
                        ))}
                    </Row>
                    <div
                      {...getRootProps({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps()} />

                      <div className="dz-message text-muted">
                        <p>Upload file-file baru (minimal 2)</p>
                        {!newService && (
                          <p>Foto-foto lama akan diganti dengan foto baru</p>
                        )}
                        {!photoUploadFinished && (
                          <div className="loader-box">
                            <div className="loader-3"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </FormGroup>

                  <BookingsTable bookings={getGuideData.bookings} />

                  <FormGroup className="form-row mt-3 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      disabled={!photoUploadFinished}
                    >
                      Simpan
                    </Button>
                    {!newService && (
                      <Button
                        type="button"
                        className="btn-secondary-gradien mr-2"
                        onClick={toggle}
                        color="danger mr-2"
                      >
                        Hapus Layanan
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default GetGuideDetail;
