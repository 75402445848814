import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../assets/images/Logo_lets_getgoing_merah_hitam.png";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import app from "../data/firebase";
import { useAuth } from "../services/auth-context";

const Login = (props) => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [sendingForm, setSendingForm] = useState(false);

  const history = useHistory();
  const auth = useAuth();

  const handleLogin = (e) => {
    setSendingForm(true);
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    app
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        auth.handleUser(response.user);
        history.push("/");
      })
      .catch(function (error) {
        setSendingForm(false);
        var errorCode = error.code;
        if (
          errorCode === "auth/user-not-found" ||
          errorCode === "auth/invalid-email"
        ) {
          setInvalidEmail(true);
        } else if (errorCode === "auth/wrong-password") {
          setInvalidPassword(true);
        }
      });
  };

  return (
    <Container fluid className="px-3">
      <Row className="min-vh-100">
        <Col md="8" lg="6" xl="5" className="d-flex align-items-center">
          <div className="w-100 py-2 px-md-5 px-xl-6 position-relative">
            <div className="mb-3">
              <img
                src={Logo}
                alt="Logo GetGoing Merah"
                className="img-fluid mb-3"
              />
            </div>

            <Form className="form-validate" onSubmit={handleLogin}>
              <FormGroup>
                <Label for="loginUsername" className="form-label">
                  Email
                </Label>
                <Input
                  name="email"
                  id="email"
                  type="email"
                  placeholder="alamat@email.com"
                  autoComplete="off"
                  required
                  invalid={invalidEmail}
                  className="form-signin-field"
                />
                {invalidEmail && (
                  <FormFeedback>
                    Akun admin dengan alamat email ini tidak dapat ditemukan
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup className="mb-4">
                <Row>
                  <Col>
                    <Label for="loginPassword" className="form-label">
                      Password
                    </Label>
                  </Col>
                </Row>
                <Input
                  name="password"
                  id="password"
                  type="password"
                  placeholder="password"
                  autoComplete="off"
                  required
                  invalid={invalidPassword}
                  className="form-signin-field"
                />
                {invalidPassword && (
                  <FormFeedback>Password yang kamu masukkan salah</FormFeedback>
                )}
                <Row>
                  <Col xs="auto">
                    <a href="/lupa-password" className="form-text small">
                      Lupa password?
                    </a>
                  </Col>
                </Row>
              </FormGroup>

              <Button
                type="submit"
                size="lg"
                block
                className={`px-3 ${
                  sendingForm ? "disabled button-signin" : "button-signin"
                }`}
              >
                {/* {sendingForm ? (
                  <BarLoader
                    width={"100%"}
                    color={"#ffffff"}
                  />
                ) : (
                    "Login"
                  )} */}
                LOGIN DASHBOARD
              </Button>
            </Form>
          </div>
        </Col>
        <Col md="4" lg="6" xl="7" className="dashboard-login">
          {/*<img src={Background} alt="Big Ben" className="background-login" />*/}
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
