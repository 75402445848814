import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Plus } from "react-feather";
import firebase from "../../data/firebase";
import { replaceImageUrl } from "../../data/helper";
import MaterialUiTable from "../common/MaterialUiTable";

const GetFlexList = () => {
  const history = useHistory();
  const [getFlexList, setGetFlexList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("getFlexList")
          .get();
        var responseData = response.data();
        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          const type = value.type;
          const title = value.title;
          const image = value.image;
          // ? replaceImageUrl(value.image, 120, 80)
          // : "";
          const durationDay = value.durationDay;
          const durationNight = value.durationNight;
          const countryCount = value.countryCount;
          const cityCount = value.cityCount;

          data.push({
            id: key,
            type,
            title,
            image,
            durationDay,
            durationNight,
            duration: durationDay + " / " + durationNight,
            countryCount,
            cityCount,
            destinationCount: countryCount + " / " + cityCount,
          });
        }
        setGetFlexList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const toGetFlexDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/services/getflex-detail/${id}`);
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "image",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img className="avatar-rect" src={value} alt={value} />;
        },
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "title",
      label: "JUDUL GETFLEX",
      options: {
        filter: false,
      },
    },
    {
      name: "type",
      label: "TIPE",
    },
    {
      name: "destinationCount",
      label: "JML DESTINASI (negara / kota)",
      options: {
        filter: false,
      },
    },
    {
      name: "duration",
      label: "DURASI (hari / malam)",
      options: {
        filter: false,
      },
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row className="ml-1">
                  <h5 className="line-height-inherit mr-3">GetFlex List</h5>
                  <p className="text-muted">(total {getFlexList.length})</p>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
                    onClick={() => toGetFlexDetail("new")}
                  >
                    <Plus /> Tambah GetFlex
                  </Button>
                </Row>
              </CardHeader>

              <div className="table-responsive">
                <MaterialUiTable
                  columns={columns}
                  data={getFlexList}
                  toItemDetail={toGetFlexDetail}
                  sortInitialColumn="title"
                  searchableCols="Judul, Tipe, .."
                  defaultSearchInput={true}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GetFlexList;
