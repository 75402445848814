import { createContext } from "react";

const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "$",
  isNavbarVerticalCollapsed: false,
  navbarStyle: "transparent",
};

const AppContext = createContext(settings);
export const ChatContext = createContext();
export default AppContext;
