import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import Dropzone from "../../common/Dropzone";
import FileDownload from "../../common/FileDownload";

const TicketShopInput = (props) => {
  const { idx, state, handleChange, disableDelete, deleteInput } = props;

  const nameId = `nameId-${idx}`;
  const imageId = `image-${idx}`;
  const linkId = `link-${idx}`;
  const iosLinkId = `iosLink-${idx}`;
  const androidLinkId = `androidLink-${idx}`;
  return (
    <div key={`activity-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <Col className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <h5>{idx + 1}</h5>
            <Col md="11">
              <FormGroup className="mb-3">
                <Label className="form-label" for={nameId}>
                  Nama
                </Label>
                <Input
                  type="text"
                  input={nameId}
                  name={nameId}
                  id={nameId}
                  data-id={idx}
                  className="name"
                  value={state[idx].name || ""}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label" for={linkId}>
                  Link ke Website
                </Label>
                <Input
                  type="text"
                  input={linkId}
                  name={linkId}
                  id={linkId}
                  data-id={idx}
                  className="link"
                  value={state[idx].link || ""}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label" for={iosLinkId}>
                  Link ke AppStore (iOS)
                </Label>
                <Input
                  type="text"
                  input={iosLinkId}
                  name={iosLinkId}
                  id={iosLinkId}
                  data-id={idx}
                  className="iosLink"
                  value={state[idx].iosLink || ""}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label" for={androidLinkId}>
                  Link ke PlayStore (Android)
                </Label>
                <Input
                  type="text"
                  input={androidLinkId}
                  name={androidLinkId}
                  id={androidLinkId}
                  data-id={idx}
                  className="androidLink"
                  value={state[idx].androidLink || ""}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label" for={imageId}>
                  Foto / Logo
                </Label>
                <Row>
                  <Col>
                    <Dropzone
                      path="webImages"
                      name={state[idx].title}
                      handlePhotoUploaded={(e) => handleChange(e, idx)}
                    />
                  </Col>
                  <Col>
                    <span className="media user-header">
                      <img
                        className="mr-2 img-35"
                        src={state[idx].image || ""}
                        alt={state[idx].title}
                        style={{ maxHeight: 100, maxWidth: 100 }}
                      />
                    </span>
                    {state[idx].image && (
                      <FileDownload url={state[idx].image} />
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default TicketShopInput;
