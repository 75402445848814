import React, { Fragment, useEffect, useState } from "react";
import { Card, Row, Col, CardHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import firebase from "../../../data/firebase";
import MaterialUiTable from "../../common/MaterialUiTable";
import { Plus } from "react-feather";
import { itineraryTableInfo } from "./util";

const ItineraryList = () => {
  const history = useHistory();
  const [itineraryGroupList, setItineraryGroupList] = useState([]);
  const [itinerarySingleList, setItinerarySingleList] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatDate = (value) => {
    return value ? moment(value.toDate()).format("DD MMM YYYY") : "";
  };

  const sortByTime = (list) => {
    return list.sort((a, b) => (a.time > b.time ? 1 : -1));
  };

  useEffect(() => {
    var groupData = [];
    const fetchGroupData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("itineraryGroupList")
          .get();

        var responseData = response.data();
        for (const key in responseData) {
          const value = responseData[key];
          groupData.push({
            id: key,
            displayName: value.displayName,
            itineraryCount: value.itineraryCount,
            getFlexName: value.getFlex?.title,
            departureDate: formatDate(value.departure?.startDate),
          });
        }
        setItineraryGroupList(groupData);
      } catch (err) {
        console.error(err);
      }
    };

    // const getGroupName = (groupId) => {
    //   return groupData.filter((group) => group.id === groupId)[0].displayName;
    // };

    const fetchItineraryData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("itineraryList")
          .get();

        var responseData = response.data();
        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          if (value.type === "transportation") {
            const firstDeparture =
              value.vehicles &&
              value.vehicles.length > 0 &&
              value.vehicles[0].departure;
            const lastArrival =
              value.vehicles &&
              value.vehicles.length > 0 &&
              value.vehicles[value.vehicles.length - 1].arrival;
            data.push({
              id: key,
              type: "departure",
              date: formatDate(firstDeparture.date),
              time: firstDeparture.time,
              // groupName: value.groupId
              //   ? getGroupName(value.groupId)
              //   : "no group",
              info: itineraryTableInfo(value),
            });
            data.push({
              id: key,
              type: "arrival",
              date: formatDate(lastArrival.date),
              time: lastArrival.time,
              // groupName: value.groupId
              //   ? getGroupName(value.groupId)
              //   : "no group",
              info: itineraryTableInfo(value),
            });
          } else if (value.type === "accommodation") {
            ["checkIn", "checkOut"].map((t) => {
              data.push({
                id: key,
                type: t,
                date: formatDate(value[t].date),
                time: value[t].time,
                // groupName: value.groupId
                //   ? getGroupName(value.groupId)
                //   : "no group",
                info: itineraryTableInfo(value),
              });
            });
          } else if (["getflex-basic"].includes(value.type)) {
            data.push({
              id: key,
              type: value.type,
              date: formatDate(value.date),
              time: value.time,
              // groupName: "getflex-basic",
              info: itineraryTableInfo(value),
            });
          } else {
            const date = formatDate(value.date);
            const time = value.time;
            data.push({
              id: key,
              type: value.type,
              date,
              time,
              // groupName: value.groupId
              //   ? getGroupName(value.groupId)
              //   : "no group",
              info: itineraryTableInfo(value),
            });
          }
        }
        const sortedList = sortByTime(data);
        setItinerarySingleList(sortedList);
      } catch (err) {
        console.error(err);
      }
    };

    fetchGroupData().then(() => {
      fetchItineraryData().then(() => {
        setLoading(false);
      });
    });
  }, []);

  const columnsGroup = [
    {
      name: "id",
      label: "ID",
      options: {
        // display: "excluded",
        filter: false,
      },
    },
    {
      name: "displayName",
      label: "NAMA GRUP",
    },
    {
      name: "itineraryCount",
      label: "JUMLAH ITINERARY",
    },
    {
      name: "getFlexName",
      label: "GETFLEX",
    },
    {
      name: "departureDate",
      label: "TANGGAL KEBERANGKATAN",
    },
  ];

  const columnsSingle = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "type",
      label: "TIPE",
    },
    // {
    //   name: "groupName",
    //   label: "NAMA GRUP",
    // },
    {
      name: "date",
      label: "TANGGAL",
    },
    {
      name: "time",
      label: "WAKTU",
      options: {
        filter: false,
      },
    },
    {
      name: "info",
      label: "INFO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ whiteSpace: "pre-line" }}>{value}</span>;
        },
        filter: false,
        sort: false,
      },
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const toItinerarySingleDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/itinerary/itinerary-detail/${id}`);
  };

  const toItineraryGroupDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/itinerary/itinerary-group/${id}`);
  };

  return (
    <Fragment>
      <Card className="mx-4">
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Itinerary Group List</h5>
            <p className="text-muted">(total {itineraryGroupList.length})</p>
            <Button
              type="button"
              className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
              onClick={() => toItineraryGroupDetail("new")}
            >
              <Plus /> Tambah Grup Itinerary
            </Button>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columnsGroup}
            data={itineraryGroupList}
            toItemDetail={toItineraryGroupDetail}
            sortInitialColumn="date"
            searchableCols="Nama"
            defaultSearchInput={true}
          />
        </div>
      </Card>

      <Card className="mx-4">
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Semua Itinerary</h5>
            <p className="text-muted">(total {itinerarySingleList.length})</p>
            <Button
              type="button"
              className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
              onClick={() => toItinerarySingleDetail("new")}
            >
              <Plus /> Tambah Itinerary
            </Button>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columnsSingle}
            data={itinerarySingleList}
            toItemDetail={toItinerarySingleDetail}
            sortInitialColumn="date"
            searchableCols="Tipe, Tanggal"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default ItineraryList;
