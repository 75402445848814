import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useAuth } from "../../services/auth-context";
import firebase from "../../data/firebase";
import { formatDate } from "../../components/common/TableUtils";

import { Bell, MessageCircle } from "react-feather";

const BellDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [tasks, setTasks] = useState([]);
  const auth = useAuth();
  const userUid = auth.user ? auth.user.uid : undefined;

  useEffect(() => {
    var taskList = [];
    const fetchData = async () => {
      try {
        await firebase
          .firestore()
          .collection("users")
          .doc(userUid)
          .collection("tasks")
          .where("status", "not-in", ["Done", "Postponed", "Cancelled"])
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              taskList.push({
                title: doc.id,
                channel: doc.data().channel,
                endDate: doc.data().endDate,
                status: doc.data().status,
              });
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
        // sort by deadline
        taskList.sort((a, b) =>
          a.endDate > b.endDate ? 1 : b.endDate > a.endDate ? -1 : 0
        );
        setTasks(taskList);
      } catch (err) {
        console.error(err);
      }
    };
    if (userUid !== undefined) {
      fetchData();
    }
  }, [userUid]);

  const getStatusColor = (status) => {
    if (status === "Todo") {
      return "bg-info";
    }
    if (status === "In Progress") {
      return "bg-warning";
    }
    if (status === "Review") {
      return "bg-danger";
    }
    if (status === "Postponed") {
      return "bg-danger";
    }
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle>
        <Bell />
        <span className="notification badge badge-pill badge-danger f-10">
          {tasks.length}
        </span>
      </DropdownToggle>
      <DropdownMenu className="p-0">
        <ul className="notification-dropdown">
          <li className="gradient-primary-1">
            <h6>Notifikasi</h6>
            <p>Kamu mempunyai {tasks.length} tugas aktif</p>
            {/* <p>Todo: {tasks.filter((t) => t.status === "Todo").length}</p>
            <p>
              In Progress:{" "}
              {tasks.filter((t) => t.status === "In Progress").length}
            </p>
            <p>Review: {tasks.filter((t) => t.status === "Review").length}</p>
            <p>
              Postponed: {tasks.filter((t) => t.status === "Postponed").length}
            </p> */}
          </li>
          {tasks.map((task) => (
            <li className="pt-0" key={task.title}>
              <a href={`/project-management/${task.channel}/${task.title}`}>
                <div className="media">
                  <div
                    className={`notification-icons mr-3  ${getStatusColor(
                      task.status
                    )}`}
                  >
                    <MessageCircle className="mt-0" />
                  </div>
                  <div className="media-body">
                    <h6>{task.title}</h6>
                    <p className="mb-0">Status: {task.status}</p>
                    <p className="mb-0">Deadline: {formatDate(task.endDate)}</p>
                  </div>
                </div>
              </a>
            </li>
          ))}

          <li className="bg-light txt-dark">
            <a href="/dashboard">Lihat semua tugas</a>
          </li>
        </ul>
      </DropdownMenu>
    </Dropdown>
  );
};

export default BellDropdown;
