import React, { useState, useContext, useEffect } from "react";
import { Form, FormGroup, Input, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import firebase from "../../../data/firebase";
import { ChatContext } from "../helper/Context";

const ChatContactsSearch = () => {
  const { users, searchOrCreateThread } = useContext(ChatContext);

  const getUserOptions = () => {
    var userOptions = [];
    users.forEach((user) => {
      userOptions.push({
        value: user.id + "," + user.fullName,
        label: user.fullName + ", " + user.role,
      });
    });
    return userOptions;
  };

  const userSelected = (e) => {
    const value = e.value;
    const uid = value.split(",")[0];
    searchOrCreateThread(uid);
  };

  return (
    // <Form
    //   className="contacts-search-wrapper"
    //   onSubmit={(e) => e.preventDefault()}
    // >
    //   <FormGroup className="mb-0 position-relative d-md-none d-lg-block w-100 h-100">
    //     <Input
    //       className="chat-contacts-search border-0 h-100"
    //       placeholder="Cari User"
    //       bsSize="sm"
    //       onChange={(e) => console.log(e.target.value)}
    //     />
    //     <FontAwesomeIcon icon="search" className="contacts-search-icon" />
    //   </FormGroup>
    //   <Button
    //     color="transparent"
    //     size="sm"
    //     className="d-none d-md-inline-block d-lg-none"
    //     type="submit"
    //   >
    //     <FontAwesomeIcon icon="search" className="fs--1" />
    //   </Button>
    // </Form>
    <Select
      id="user"
      instanceId="user"
      name="user"
      options={getUserOptions()}
      isSearchable
      onChange={userSelected}
      input
      menuPlacement="top"
      placeholder="Cari user"
    />
  );
};

export default ChatContactsSearch;
