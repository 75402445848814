import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";

const EntryInput = (props) => {
  const { idx, state, handleChange, disableDelete, deleteInput } = props;

  const titleId = `titleId-${idx}`;
  const descriptionId = `descriptionId-${idx}`;
  return (
    <div key={`entry-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <Col className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <h5>{idx + 1}</h5>
            <Col md="11">
              <FormGroup className="mb-3">
                <Label className="form-label" for={titleId}>
                  Title
                </Label>
                <Input
                  type="text"
                  input={titleId}
                  name={titleId}
                  id={titleId}
                  data-id={idx}
                  className="title"
                  value={state[idx].title || ""}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label" for={descriptionId}>
                  Content
                </Label>
                <Input
                  type="textarea"
                  input={descriptionId}
                  name={descriptionId}
                  id={descriptionId}
                  data-id={idx}
                  className="description"
                  value={state[idx].description || ""}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default EntryInput;
