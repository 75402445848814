import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Button, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Plus } from "react-feather";
import firebase from "../../data/firebase";
import { replaceImageUrl, upperCaseFirst } from "../../data/helper";
import MaterialUiTable from "../common/MaterialUiTable";

const CountryList = () => {
  const history = useHistory();
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("countryList")
          .get();
        var responseData = response.data();
        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          data.push({
            id: value.docName,
            code: key,
            docName: value.docName,
            displayName: value.displayName,
            iconUrl: value.iconUrl,
          });
        }
        setCountryList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const toCountryDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/country/country-detail/${id}`);
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "iconUrl",
      label: "FOTO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
              className="avatar-square"
              src={value && replaceImageUrl(value, 80, 80)}
              alt={value}
            />
          );
        },
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "code",
      label: "KODE NEGARA",
    },
    {
      name: "displayName",
      label: "DISPLAY NAME",
    },
    {
      name: "docName",
      label: "DOCUMENT NAME",
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Negara</h5>
            <p className="text-muted">(total {countryList.length})</p>
            <Button
              type="button"
              color="primary"
              className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
              onClick={() => toCountryDetail("new")}
            >
              <Plus /> Tambah Negara
            </Button>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={countryList}
            toItemDetail={toCountryDetail}
            sortInitialColumn="id"
            searchableCols="Display Name, Document Name"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default CountryList;
