import React, { useState } from "react";
import man from "../../assets/images/user/user.png";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { LogOut } from "react-feather";
import app from "../../data/firebase";
import { useAuth } from "../../services/auth-context";

const UserActivity = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const auth = useAuth();
  const currentUser = auth.user;

  const handleSignOut = () => {
    app
      .auth()
      .signOut()
      .then(() => {
        console.log("logged out");
      });
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle>
        <span className="media user-header">
          <img
            className="mr-2 rounded-circle img-35"
            src={
              currentUser
                ? currentUser.photoURL
                  ? currentUser.photoURL
                  : man
                : man
            }
            style={{ width: "35px", height: "35px" }}
            alt=""
          />
        </span>
      </DropdownToggle>
      <DropdownMenu className="p-0">
        <ul className="profile-dropdown">
          <li className="gradient-primary-1">
            <h6 className="mb-0">{currentUser && currentUser.displayName}</h6>
          </li>
          <li onClick={() => handleSignOut()} className="cursor-pointer">
            <LogOut />
            Logout
          </li>
        </ul>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserActivity;
