import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  CardHeader,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import * as firebaseApp from "firebase/app";
import firebase from "../../../data/firebase";
import MaterialUiTable from "../../common/MaterialUiTable";
import { Plus } from "react-feather";
import { itineraryTableInfo } from "./util";
import LinkButton from "../../common/LinkButton";
import useQuery from "../../../util/useQuery";

const ItineraryPersonalDetail = () => {
  let { id } = useParams();
  let query = useQuery();
  const bookingId = query.get("bookingId");
  const getFlexId = query.get("getFlexId");
  const departureId = query.get("departureId");
  const itineraryGroupId = query.get("itineraryGroupId");
  const history = useHistory();
  const [itineraryList, setItineraryList] = useState([]);

  const [formData, setFormData] = useState({
    getFlexData: {},
    departureData: {},
    travellerData: {},
    status: "new",
    uid: "",
    itineraryList: [],
  });
  const [newItineraryPersonal, setNewItineraryPersonal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState({});

  const formatDate = (value) => {
    return value ? moment(value.toDate()).format("DD MMM YYYY") : "";
  };

  const getUserOption = () => {
    var userOption = [];
    Object.keys(userList).forEach((keyName, i) => {
      userOption.push({
        value: keyName,
        label:
          userList[keyName]["fullName"] +
          " | " +
          userList[keyName]["email"] +
          " | " +
          keyName,
      });
    });
    return userOption;
  };

  const onSelectChange = (e) => {
    setFormData({ ...formData, uid: e.value });
  };

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("userList")
          .get();
        if (response.exists) {
          const data = response.data();
          setUserList(data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchDepartureAndBookingData = (
      getFlexId,
      departureId,
      bookingId,
      status,
      travellerData,
      uid
    ) => {
      firebase
        .firestore()
        .collection("getFlex")
        .doc(getFlexId)
        .get()
        .then((response) => {
          if (response.exists) {
            const getFlexData = { id: response.id, ...response.data() };
            firebase
              .firestore()
              .collection("getFlex")
              .doc(getFlexId)
              .collection("departures")
              .doc(departureId)
              .get()
              .then((response) => {
                if (response.exists) {
                  const departureData = { id: response.id, ...response.data() };

                  firebase
                    .firestore()
                    .collection("bookingForms")
                    .doc(bookingId)
                    .get()
                    .then((response) => {
                      if (response.exists) {
                        const bookingData = {
                          id: response.id,
                          ...response.data(),
                        };
                        setFormData({
                          status,
                          getFlexData,
                          departureData,
                          bookingData,
                          travellerData,
                          uid,
                        });
                      }
                    });
                }
              });
          }
        });
    };

    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("itinerary")
          .doc("itineraryGroup")
          .collection("itineraryGroup")
          .doc(itineraryGroupId)
          .collection("itineraryIndividual")
          .doc(id)
          .get();
        var responseData = response.data();

        if (responseData) {
          const itineraryListData = responseData.itineraryItemList;

          // get departure data
          const _getFlexId = responseData.getFlexId || getFlexId;
          const _departureId = responseData.departureId || departureId;
          const _bookingId = responseData.bookingId || bookingId;

          if (_getFlexId && _departureId && _bookingId) {
            fetchDepartureAndBookingData(
              _getFlexId,
              _departureId,
              _bookingId,
              responseData.status,
              responseData.travellerData,
              responseData.uid
            );
          } else {
            setFormData({
              ...formData,
              travellerData: responseData.travellerData,
              uid: responseData.uid,
            });
          }

          var itineraryList = [];

          if (itineraryListData && itineraryListData.length > 0) {
            itineraryListData.forEach((value) => {
              const templateId = value.templateId ? value.templateId : "";
              if (value.type === "transportation") {
                const firstDeparture =
                  value.vehicles &&
                  value.vehicles.length > 0 &&
                  value.vehicles[0].departure;
                const lastArrival =
                  value.vehicles &&
                  value.vehicles.length > 0 &&
                  value.vehicles[value.vehicles.length - 1].arrival;

                itineraryList.push({
                  id: value.id,
                  dateArr: [
                    formatDate(firstDeparture.date),
                    formatDate(lastArrival.date),
                  ],
                  timeArr: [firstDeparture.time, lastArrival.time],
                  info: itineraryTableInfo(value),
                  ...value,
                  templateId,
                });
              } else if (value.type === "accommodation") {
                itineraryList.push({
                  id: value.id,
                  dateArr: [
                    formatDate(value["checkIn"].date),
                    formatDate(value["checkOut"].date),
                  ],
                  timeArr: [value["checkIn"].time, value["checkOut"].time],
                  info: itineraryTableInfo(value),
                  ...value,
                  templateId,
                });
              } else {
                const date = [formatDate(value.date)];
                const time = [value.time];
                itineraryList.push({
                  id: value.id,
                  dateArr: date,
                  timeArr: time,
                  info: itineraryTableInfo(value),
                  ...value,
                  templateId,
                });
              }
            });
            setItineraryList(itineraryList);
          }
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchItineraryGroupData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("itinerary")
          .doc("itineraryGroup")
          .collection("itineraryGroup")
          .doc(itineraryGroupId)
          .get();
        var responseData = response.data();
        const itineraryListData = responseData.itineraryItemList;

        if (itineraryListData && itineraryListData.length > 0) {
          itineraryListData.forEach((value) => {
            const templateId = value.templateId
              ? value.templateId
              : id === "new"
              ? value.id
              : "";
            if (value.type === "transportation") {
              const firstDeparture =
                value.vehicles &&
                value.vehicles.length > 0 &&
                value.vehicles[0].departure;
              const lastArrival =
                value.vehicles &&
                value.vehicles.length > 0 &&
                value.vehicles[value.vehicles.length - 1].arrival;

              itineraryList.push({
                id: value.id,
                dateArr: [
                  formatDate(firstDeparture.date),
                  formatDate(lastArrival.date),
                ],
                timeArr: [firstDeparture.time, lastArrival.time],
                info: itineraryTableInfo(value),
                ...value,
                templateId,
              });
            } else if (value.type === "accommodation") {
              itineraryList.push({
                id: value.id,
                dateArr: [
                  formatDate(value["checkIn"].date),
                  formatDate(value["checkOut"].date),
                ],
                timeArr: [value["checkIn"].time, value["checkOut"].time],
                info: itineraryTableInfo(value),
                ...value,
                templateId,
              });
            } else {
              const date = [formatDate(value.date)];
              const time = [value.time];
              itineraryList.push({
                id: value.id,
                dateArr: date,
                timeArr: time,
                info: itineraryTableInfo(value),
                ...value,
                templateId,
              });
            }
          });
          setItineraryList(itineraryList);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (id !== "new") {
      fetchData().then(() => fetchUserList().then(() => setLoading(false)));
    } else {
      setNewItineraryPersonal(true);
      fetchItineraryGroupData().then(() =>
        fetchUserList().then(() => setLoading(false))
      );
    }
  }, []);

  const columnsItinerary = [
    {
      name: "id",
      options: {
        // display: "excluded",
        filter: false,
      },
    },
    {
      name: "templateId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "type",
      label: "TIPE",
    },
    {
      name: "dateArr",
      label: "TANGGAL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <p>{value[0]}</p>
              {value.length > 0 ? <p>{value[1]}</p> : null}
            </div>
          );
        },
      },
    },
    {
      name: "timeArr",
      label: "WAKTU",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <p>{value[0]}</p>
              {value.length > 0 ? <p>{value[1]}</p> : null}
            </div>
          );
        },
        filter: false,
      },
    },
    {
      name: "info",
      label: "INFO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ whiteSpace: "pre-line" }}>{value}</span>;
        },
        filter: false,
        sort: false,
      },
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const toItineraryDetail = (id1, id2) => {
    let itemId = id1;
    if (id1 === id2) {
      itemId = "new";
    }
    history.push(
      `${process.env.PUBLIC_URL}/itinerary/itinerary-detail/${itemId}?templateId=${id2}&itineraryLevel=individual&individualId=${id}&groupId=${itineraryGroupId}`
    );
  };

  const toNewItinerary = () => {
    history.push(
      `${process.env.PUBLIC_URL}/itinerary/itinerary-detail/new?individualId=${id}&groupId=${itineraryGroupId}&itineraryLevel=individual`
    );
  };

  const publishItineraryIndividual = () => {
    saveItineraryIndividual().then((data) => {
      data.status = "PUBLISHED";

      var userItineraryData = {
        itineraryGroupId: itineraryGroupId || "",
        itineraryIndividualId: data.id,
      };

      if (formData.bookingData && formData.departureData) {
        userItineraryData = {
          ...userItineraryData,
          currentStatus:
            formData.bookingData.status[
              formData.bookingData.status.length - 1
            ] || {},
          productId: data.getFlexId,
          title: formData.bookingData.title,
          type: "getflex-basic",
          date: formData.departureData.startDate,
        };
      }

      if (data.uid && data.itineraryItemList) {
        const userId = data.uid;
        firebase
          .firestore()
          .collection("itinerary")
          .doc("itineraryGroup")
          .collection("itineraryGroup")
          .doc(itineraryGroupId)
          .collection("itineraryIndividual")
          .doc(data.id)
          .set({ status: "PUBLISHED" }, { merge: true })
          .then(() => {
            history.goBack();

            if (userId) {
              firebase
                .firestore()
                .collection("users")
                .doc(userId)
                .collection("itinerary")
                .doc(itineraryGroupId)
                .set({ ...userItineraryData, ...data })
                .then(() => {
                  toast.success("Publish berhasil", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                });
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const saveItineraryIndividual = async () => {
    // TODO: handle uid change
    var docId = newItineraryPersonal ? uuidv4() : id;

    var currentItineraryList = [];
    itineraryList.forEach((itin) => {
      itin.groupId = docId;
      currentItineraryList.push(itin);
    });

    var data = {
      id: docId,
      status: formData.status || "",
      getFlexId:
        formData.getFlexData && formData.getFlexData.id
          ? formData.getFlexData.id
          : "",
      departureId:
        formData.departureData && formData.departureData.id
          ? formData.departureData.id
          : "",
      bookingId:
        formData.bookingData && formData.bookingData.id
          ? formData.bookingData.id
          : "",
      travellerData: formData.travellerData || {},
      uid: formData.uid || "",
      itineraryItemList: currentItineraryList || [],
    };

    await firebase
      .firestore()
      .collection("itinerary")
      .doc("itineraryGroup")
      .collection("itineraryGroup")
      .doc(itineraryGroupId)
      .collection("itineraryIndividual")
      .doc(docId)
      .set(data, { merge: true })
      .then(() => {
        if (newItineraryPersonal) {
          firebase
            .firestore()
            .collection("itinerary")
            .doc("itineraryGroup")
            .collection("itineraryGroup")
            .doc(itineraryGroupId)
            .set(
              {
                itineraryIndividualList:
                  firebaseApp.firestore.FieldValue.arrayUnion(docId),
              },
              { merge: true }
            );
        }
        // history.goBack();

        toast.success("Berhasil disimpan", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => console.log(err));

    return data;
  };

  const onTravellerDataChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    const travellerData = formData.travellerData;
    travellerData[name] = value;
    setFormData({ ...formData, travellerData });
  };

  return (
    <Fragment>
      <Form className="theme-form">
        {formData.getFlexData && Object.keys(formData.getFlexData).length > 0 && (
          <FormGroup className="m-5" style={{ marginLeft: 100 }}>
            <Row>
              <Col>
                <Label className="col-form-label">GetFlex</Label>
                <p>{formData.getFlexData.id}</p>
              </Col>
              <Col>
                <Label className="col-form-label">Judul</Label>
                <p>{formData.getFlexData.title}</p>
              </Col>
              <Col>
                <LinkButton
                  title="Lihat GetFlex"
                  url={`${process.env.PUBLIC_URL}/services/getflex-detail/${formData.getFlexData.id}`}
                />
              </Col>
            </Row>
          </FormGroup>
        )}

        {formData.departureData &&
          Object.keys(formData.departureData).length > 0 && (
            <FormGroup className="m-5" style={{ marginLeft: 100 }}>
              <Row>
                <Col>
                  <Label className="col-form-label">Departure</Label>
                  <p>{formData.departureData.id}</p>
                </Col>
                <Col>
                  <Label className="col-form-label">
                    Periode Keberangkatan
                  </Label>
                  <p>
                    {formatDate(formData.departureData.startDate)} -{" "}
                    {formatDate(formData.departureData.endDate)}
                  </p>
                </Col>
                <Col>
                  <LinkButton
                    title="Lihat GetFlex Keberangkatan"
                    url={`${process.env.PUBLIC_URL}/getflex/departure/${formData.getFlexData.id}/${formData.departureData.id}`}
                  />
                </Col>
              </Row>
            </FormGroup>
          )}

        {itineraryGroupId && (
          <FormGroup className="m-5" style={{ marginLeft: 100 }}>
            <Row>
              <Col>
                <Label className="col-form-label">Itinerary Group</Label>
                <p>{itineraryGroupId}</p>
              </Col>
              <Col>
                <LinkButton
                  title="Lihat Itinerary Group"
                  url={`${process.env.PUBLIC_URL}/itinerary/itinerary-group/${itineraryGroupId}`}
                />
              </Col>
            </Row>
          </FormGroup>
        )}

        <FormGroup className="m-5" style={{ marginLeft: 100 }}>
          <Row>
            <Col>
              <h3 className="col-form-label">Traveller Info</h3>
              <Label>Nama</Label>
              <Input
                className="form-control"
                type="text"
                name="name"
                defaultValue={formData.travellerData.name}
                onChange={onTravellerDataChange}
              />
              <Label>Email</Label>
              <Input
                className="form-control"
                type="text"
                name="email"
                defaultValue={formData.travellerData.email}
                onChange={onTravellerDataChange}
              />
              <Label>No. Telp</Label>
              <Input
                className="form-control"
                type="text"
                name="phone"
                defaultValue={formData.travellerData.phone}
                onChange={onTravellerDataChange}
              />
            </Col>

            <Col>
              <Label className="col-form-label">User</Label>
              <Select
                id="uid"
                instanceId="uid"
                name="uid"
                options={getUserOption()}
                defaultValue={{
                  value: formData.uid,
                  label: formData.uid
                    ? getUserOption().find((opt) => opt.value === formData.uid)
                        .label
                    : "",
                }}
                isSearchable
                onChange={onSelectChange}
                input
              />

              <span>
                TODO: Jangan ubah user setelah diassign satu kali, user yg
                sebelunya blm kehapus itinerary nya
              </span>
            </Col>
          </Row>
        </FormGroup>

        {formData.status && (
          <FormGroup className="m-5" style={{ marginLeft: 100 }}>
            <Row>
              <Col>
                <Label className="col-form-label">Status</Label>
                <p>{formData.status}</p>
              </Col>
            </Row>
          </FormGroup>
        )}
      </Form>

      <Card className="mx-4">
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Itinerary Individual</h5>
            <p className="text-muted">(total {itineraryList.length})</p>
            <Button
              type="button"
              className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
              onClick={toNewItinerary}
            >
              <Plus /> Tambah Itinerary
            </Button>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columnsItinerary}
            data={itineraryList}
            toItemDetail={toItineraryDetail}
            sortInitialColumn="date"
            searchableCols="Nama"
            defaultSearchInput={true}
            twoRowsId
          />
        </div>
      </Card>

      <FormGroup className="form-row my-4">
        <Button
          className="btn-success-gradien mr-2"
          type="submit"
          onClick={() => {
            saveItineraryIndividual().then(() => history.goBack());
          }}
        >
          Simpan
        </Button>

        <Button
          className="btn-success-gradien mr-2"
          type="button"
          onClick={publishItineraryIndividual}
        >
          Simpan &amp; Publish
        </Button>
      </FormGroup>
    </Fragment>
  );
};

export default ItineraryPersonalDetail;
