import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Media,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  CardTitle,
  CardText,
  Badge,
} from "reactstrap";
import {
  UserPlus,
  Users,
  Home,
  Camera,
  PlusCircle,
  AlertCircle,
} from "react-feather";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { Expo } from "expo-server-sdk";

import { useAuth } from "../services/auth-context";
import firebase from "../data/firebase";
import { formatDate } from "../components/common/TableUtils";
import MaterialUiTable from "../components/common/MaterialUiTable";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { replaceImageUrl, sendPushNotification } from "../data/helper";

const Index = () => {
  const history = useHistory();
  const auth = useAuth();
  const currentUser = auth.user;

  const [userData, setUserData] = useState({});
  const [tasks, setTasks] = useState([]);
  const [allAssigneesList, setAllAssigneesList] = useState([]);

  const [verifiedUserCount, setVerifiedUserCount] = useState(0);
  const [unverifiedUserCount, setUnverifiedUserCount] = useState(0);
  const [getGuideCount, setGetGuideCount] = useState(0);
  const [getStayCount, setGetStayCount] = useState(0);
  const [getExperienceCount, setGetExperienceCount] = useState(0);
  const [getInfoCount, setGetInfoCount] = useState(0);
  const [newListingCount, setNewListingCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const allStatusList = [
    "Todo",
    "In Progress",
    "Review",
    // "Done",
    "Cancelled",
    "Postponed",
  ];

  const statusMap = {
    "0_todo": "Todo",
    "1_inProgress": "In Progress",
    "2_review": "Review",
    "3_done": "Done",
    "4_cancelled": "Cancelled",
    "5_postponed": "Postponed",
  };

  const inverseStatusMap = {
    Todo: "0_todo",
    "In Progress": "1_inProgress",
    Review: "2_review",
    Done: "3_done",
    Cancelled: "4_cancelled",
    Postponed: "5_postponed",
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .get();
        var responseData = response.data();
        setUserData(responseData);
      } catch (err) {
        console.error(err);
      }
    };
    var taskList = [];
    const assigneesSet = new Set();
    const fetchUserTasks = async () => {
      try {
        await firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .collection("tasks")
          .where("status", "not-in", ["Done"])
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              var data = doc.data();
              var assigneesArr = [];
              if (data.assignees && data.assignees.length > 0) {
                assigneesArr = data.assignees.map((a) => [a.avatar, a.name]);
                assigneesArr.forEach((a) => assigneesSet.add(a.name));
              }

              taskList.push({
                id: doc.id,
                title: data.title ? data.title : doc.id,
                channel: data.channel,
                assignees: assigneesArr,
                deadline: data.endDate,
                status: inverseStatusMap[data.status],
                priority: data.priority,
              });
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
        // sort by deadline
        taskList.sort((a, b) =>
          a.endDate > b.endDate ? 1 : b.endDate > a.endDate ? -1 : 0
        );
        setTasks(taskList);
        setAllAssigneesList(Array.from(assigneesSet));
      } catch (err) {
        console.error(err);
      }
    };

    const fetchData1 = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("bergabungFormList")
          .get();
        var responseData = response.data();
        var unverifiedUser = 0;
        for (const key in responseData) {
          const value = responseData[key];
          if (value.status === "new") {
            unverifiedUser++;
          }
        }
        setUnverifiedUserCount(unverifiedUser);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchData2 = async (list) => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc(list)
          .get();
        var responseData = response.data();
        var count = Object.keys(responseData).length;
        return count;
        // setVerifiedUserCount(verifiedUser);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchData3 = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("listingFormList")
          .get();
        var responseData = response.data();
        var count = 0;
        for (const key in responseData) {
          const value = responseData[key];
          if (value.action !== "ok" && value.action !== "archived") {
            count++;
          }
        }
        setNewListingCount(count);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchUserData();
    fetchUserTasks();
    fetchData1();
    fetchData2("userList").then((count) => setVerifiedUserCount(count));
    fetchData2("getGuideList").then((count) => setGetGuideCount(count));
    fetchData2("getStayList").then((count) => setGetStayCount(count));
    fetchData2("getExperienceList").then((count) =>
      setGetExperienceCount(count)
    );
    fetchData2("getInfoList").then((count) => setGetInfoCount(count));
    fetchData3();
  }, []);

  const { register, handleSubmit, errors } = useForm();

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const onSubmit = (data) => {
    // if (data !== "") {
    //   firebase
    //     .firestore()
    //     .collection("users")
    //     .doc(currentUser.uid)
    //     .set(
    //       {
    //         notes: data.notes,
    //       },
    //       { merge: true }
    //     )
    //     .then(() => {
    //       toast.success("Berhasil disimpan", {
    //         position: toast.POSITION.TOP_CENTER,
    //       });
    //     })
    //     .catch(function (error) {
    //       // Handle any errors
    //       console.log("error " + error);
    //     });
    // } else {
    //   errors.showMessages();
    // }

    sendPushNotification(
      "ExponentPushToken[GJjOxPNp5hUg4TW74x2P5h]",
      "title",
      "body",
      {
        url: "https://getgoing.co.id/app/ELrxEVhqDDx1tFV76",
      }
    );
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "channel",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "channel",
      label: "CHANNEL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>#{value}</span>;
        },
      },
    },
    {
      name: "title",
      label: "JUDUL",
      options: {
        filter: false,
      },
    },
    {
      name: "assignees",
      label: "TEAM",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <div className="stacked_avatars">
                {value &&
                  value.map((person) => (
                    <a
                      href="#"
                      className="stacked_avatars__item"
                      key={person[1]}
                    >
                      <img
                        className="avatar"
                        src={replaceImageUrl(person[0], 80, 80)}
                        alt={person[1]}
                      />
                    </a>
                  ))}
              </div>
            </div>
          );
        },
        filterType: "custom",
        filterOptions: {
          logic: (location, filters, row) => {
            var nameArr = location.map((l) => l[1]);
            if (filters.length)
              return !filters.some((r) => nameArr.includes(r));
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const optionValues = allAssigneesList;
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">TEAM</InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={(selected) => selected.join(", ")}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        color="primary"
                        checked={filterList[index].indexOf(item) > -1}
                      />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ color: getStatusColor(value) }}>
              <i className="fa fa-circle"></i> {statusMap[value]}
            </div>
          );
        },
        filterType: "custom",
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(statusMap[location]);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const optionValues = allStatusList;
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">STATUS</InputLabel>
                <Select
                  value={filterList[index]}
                  renderValue={(selected) => selected}
                  onChange={(event) => {
                    var value = event.target.value;
                    if (value === "All") {
                      filterList = [value];
                      onChange([], index, column);
                    } else {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }
                  }}
                >
                  <MenuItem value="All">All</MenuItem>
                  {optionValues.map((item) => (
                    <MenuItem key={item} value={item}>
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: "priority",
      label: "PRIORITAS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Badge color={getPriorityColor(value)} pill>
                {value}
              </Badge>
            </div>
          );
        },
      },
    },
    {
      name: "deadline",
      label: "DEADLINE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Badge
                color="light"
                pill
                style={{ color: getDeadlineColor(value) }}
              >
                <i className="fa fa-circle"></i> {getDeadlineDiff(value)}
              </Badge>
              <p className="m-0">{formatDate(value)}</p>
            </div>
          );
        },
        filter: false,
        //   filterOptions: {
        //     names: [],
        //     logic(age, filters) {
        //       if (filters[0] && filters[1]) {
        //         return age < filters[0] || age > filters[1];
        //       } else if (filters[0]) {
        //         return age < filters[0];
        //       } else if (filters[1]) {
        //         return age > filters[1];
        //       }
        //       return false;
        //     },
        //     display: (filterList, onChange, index, column) => (
        //       <div>
        //         <FormLabel>DEADLINE</FormLabel>
        //         <FormGroup row>
        //           <TextField
        //             label='min'
        //             value={filterList[index][0] || ''}
        //             onChange={event => {
        //               filterList[index][0] = event.target.value;
        //               onChange(filterList[index], index, column);
        //             }}
        //             style={{ width: '45%', marginRight: '5%' }}
        //           />
        //           <TextField
        //             label='max'
        //             value={filterList[index][1] || ''}
        //             onChange={event => {
        //               filterList[index][1] = event.target.value;
        //               onChange(filterList[index], index, column);
        //             }}
        //             style={{ width: '45%' }}
        //           />
        //         </FormGroup>
        //       </div>
        //     ),
        //   },
      },
    },
  ];

  const getStatusColor = (color) => {
    switch (color) {
      case "0_todo":
        return "#449AEF";
      case "1_inProgress":
        return "#FF8831";
      case "2_review":
        return "#F2C94C";
      case "3_done":
        return "#00C950";
      case "4_cancelled":
        return "#DE350B";
      case "5_postponed":
        return "#25213B";
      default:
        return "#000000";
    }
  };

  const getPriorityColor = (value) => {
    switch (value) {
      case "Prioritas 1":
        return "danger";
      case "Prioritas 2":
        return "warning";
      case "Prioritas 3":
        return "info";
    }
  };

  const getDeadlineColor = (value) => {
    var diffDay = moment().diff(value.toDate(), "days");
    if (diffDay >= 0) {
      return "#DE350B";
    }
    return "#00C950";
  };

  const getDeadlineDiff = (value) => {
    var diffDay = moment().diff(value.toDate(), "days");
    if (diffDay > 0) {
      return diffDay + " hari yang lalu";
    } else if (diffDay < 0) {
      return diffDay * -1 + " hari lagi";
    }
    return "hari ini";
  };

  const toTaskDetail = (id1, id2) => {
    history.push(`${process.env.PUBLIC_URL}/project-management/${id2}/${id1}`);
  };

  return (
    <Fragment>
      <Container fluid={true} className="py-3">
        <Card className="custom-card">
          <Row>
            <Col lg="4">
              <Card className="card-poster-square gradient-overlay mb-4 mb-lg-0">
                <img
                  src={replaceImageUrl(currentUser.photoURL, 600, 600)}
                  alt={currentUser.displayName}
                  className="w-100"
                />
                <CardBody className="card-body overlay-content p-3">
                  <CardTitle
                    tag="h4"
                    className="card-title text-shadow font-weight-bold mb-1"
                  >
                    {userData.fullName && userData.fullName}
                  </CardTitle>
                  <CardText className="card-text text-sm text-uppercase">
                    {userData.metric && userData.metric}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col lg="8">
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Label
                    className="col-form-label font-weight-bold"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Catatan Gue
                  </Label>
                  <Input
                    className="form-control"
                    type="textarea"
                    name="notes"
                    innerRef={register({ required: false })}
                    defaultValue={userData.notes && userData.notes}
                  />
                </FormGroup>
                <Button
                  color="mr-2"
                  type="submit"
                  className="btn-success-gradien mr-2"
                >
                  Simpan
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>

        <div className="table-responsive mt-5">
          <Label
            className="col-form-label font-weight-bold"
            style={{ fontSize: "1.5rem" }}
          >
            Tugas Gue
          </Label>
          <MaterialUiTable
            columns={columns}
            data={tasks}
            toItemDetail={toTaskDetail}
            sortInitialColumn="status"
            searchableCols="Judul, Channel"
            defaultSearchInput={true}
            twoRowsId
          />
        </div>

        {/* <Row>
          <Col lg="6" md="6">
            <Link to="/users/user-list">
              <Card className="o-hidden">
                <CardBody className="bg-success b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Users />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Jumlah Partner</span>
                      <h4 className="mb-0 counter">{verifiedUserCount}</h4>
                      <Users className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col lg="6" md="6">
            <Link to="/forms/bergabung-forms">
              <Card className="o-hidden">
                <CardBody className="bg-warning b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <UserPlus />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Partner Belum Terverifikasi</span>
                      <h4 className="mb-0 counter">{unverifiedUserCount}</h4>
                      <UserPlus className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
        </Row> */}

        {/* <Row>
          <Col lg="6" md="6">
            <Link to="/forms/listing-form-list">
              <Card className="o-hidden">
                <CardBody className="bg-warning b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <PlusCircle />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Listing Baru</span>
                      <h4 className="mb-0 counter">{newListingCount}</h4>
                      <PlusCircle className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6">
            <Link to="/services/getguide-list">
              <Card className="o-hidden">
                <CardBody className="bg-light b-r-4 text-dark">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Users />
                    </div>
                    <div className="media-body">
                      <span className="m-0">GetGuide</span>
                      <h4 className="mb-0 counter">{getGuideCount}</h4>
                      <Users className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col lg="3" md="6">
            <Link to="/services/getstay-list">
              <Card className="o-hidden">
                <CardBody className="bg-light b-r-4 text-dark">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Home />
                    </div>
                    <div className="media-body">
                      <span className="m-0">GetStay</span>
                      <h4 className="mb-0 counter">{getStayCount}</h4>
                      <Home className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col lg="3" md="6">
            <Link to="/services/getexperience-list">
              <Card className="o-hidden">
                <CardBody className="bg-light b-r-4 text-dark">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Camera />
                    </div>
                    <div className="media-body">
                      <span className="m-0">GetExperience</span>
                      <h4 className="mb-0 counter">{getExperienceCount}</h4>
                      <Camera className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col lg="3" md="6">
            <Link to="/services/getinfo-list">
              <Card className="o-hidden">
                <CardBody className="bg-light b-r-4 text-dark">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <AlertCircle />
                    </div>
                    <div className="media-body">
                      <span className="m-0">GetInfo</span>
                      <h4 className="mb-0 counter">{getInfoCount}</h4>
                      <AlertCircle className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
        </Row> */}
      </Container>
    </Fragment>
  );
};

export default Index;
