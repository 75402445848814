import React, { useEffect } from "react";
import { Row, Col, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { MoreHorizontal } from "react-feather";
import Rightbar from "./header/Rightbar";
import Logo from "../assets/images/logo_merah_40x40.png";
import { MobileRightToggle, SwitchToggle } from "../redux/common/actions";
import { useAuth } from "../services/auth-context";
import useWindowSize from "../util/useWindowSize";

export const Header = () => {
  //const configDB = useSelector((content) => content.Customizer.customizer);
  //const sidebar_background_color = configDB.settings.sidebar_background_setting;

  const auth = useAuth();
  const currentUser = auth.user;

  const dispatch = useDispatch();
  const mobileRightTog = useSelector((state) => state.Common.mobileRightToggle);
  const switchToggle = useSelector((state) => state.Common.switchToggle);
  const width = useWindowSize();

  useEffect(() => {
    if (width <= 991) {
      dispatch(SwitchToggle(true));
      document.querySelector(".page-main-header").className =
        "page-main-header open";
      document.querySelector(".page-sidebar").className = "page-sidebar open";
    } else {
      document.querySelector(".page-main-header").className =
        "page-main-header";
      document.querySelector(".page-sidebar").className =
        "page-sidebar color3-sidebar";
    }
  }, [width]);

  return (
    <div
      className={`page-main-header ${
        width > 991 ? "open" : switchToggle ? "open" : ""
      }`}
    >
      <div className="main-header-right row">
        {width <= 991 ? (
          <div className="w-100">
            <Row>
              <Col xs="2">
                <div className="main-header-left d-lg-none">
                  <div className="logo-wrapper header-logo normal">
                    <a href="/">
                      <img className="normallogo" src={Logo} alt="" />
                    </a>
                  </div>
                </div>
              </Col>

              <Col>
                <span className="font-weight-light">
                  Hello,{" "}
                  <span className="font-weight-bold">
                    {currentUser && currentUser.displayName}
                  </span>
                </span>
                <h4 className="font-weight-bold">Gue Eksplorasi Terus</h4>
              </Col>
            </Row>
            <Row className="ml-1">
              <div className="mobile-sidebar d-block">
                <div className="media-body text-right switch-sm">
                  <Label className="switch">
                    <Input
                      type="checkbox"
                      onChange={() => dispatch(SwitchToggle(switchToggle))}
                      checked={!switchToggle}
                    />
                    <span className="switch-state"></span>
                  </Label>
                </div>
              </div>
              <div className="d-lg-none mobile-toggle pull-right ml-auto mr-3">
                <MoreHorizontal
                  onClick={() => dispatch(MobileRightToggle(mobileRightTog))}
                />
              </div>
            </Row>
          </div>
        ) : (
          <div>
            <Row className="ml-3">
              <span className="font-weight-light">
                Hello,{" "}
                <span className="font-weight-bold">
                  {currentUser && currentUser.displayName}
                </span>
                {". "}
                Selamat Datang Kembali.
              </span>
            </Row>
            <Row className="ml-3">
              <h1 className="font-weight-bold-Moto">Gue Eksplorasi Terus</h1>
            </Row>
          </div>
        )}
        <Rightbar />
      </div>
    </div>
  );
};

export default Header;
