import React, { useState } from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import { SingleDatePicker } from "react-dates";
import Select from "react-select";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import FileDownload from "../../../common/FileDownload";
import Dropzone from "../../../common/Dropzone";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const VehicleInput = (props) => {
  const { idx, state, handleChange, disableDelete, deleteInput } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumber2, setPageNumber2] = useState(1);
  const [focused, setFocused] = useState({ focused: false });
  const [focused1, setFocused1] = useState({ focused: false });

  const fromLocationId = `fromLocation-${idx}`;
  const toLocationId = `toLocation-${idx}`;
  const vehicleNumberId = `vehicleNumber-${idx}`;

  const departureCityId = `departureCity-${idx}`;
  const departureCountryId = `departureCountry-${idx}`;
  const departureTimeId = `departureTime-${idx}`;
  const departureAddressId = `departureAddress-${idx}`;
  const departureAssemblyPointId = `departureAssemblyPoint-${idx}`;
  const departureLatitudeId = `departureLatitude-${idx}`;
  const departureLongitudeId = `departureLongitude-${idx}`;

  const arrivalCityId = `arrivalCity-${idx}`;
  const arrivalCountryId = `arrivalCountry-${idx}`;
  const arrivalTimeId = `arrivalTime-${idx}`;
  const arrivalAddressId = `arrivalAddress-${idx}`;
  const arrivalAssemblyPointId = `arrivalAssemblyPoint-${idx}`;
  const arrivalLatitudeId = `arrivalLatitude-${idx}`;
  const arrivalLongitudeId = `arrivalLongitude-${idx}`;

  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(1);
  }

  function onDocumentLoadSuccess2({ numPages }) {
    setPageNumber2(1);
  }

  const getTransportationOptions = () => {
    const transporations = ["plane", "bus", "train", "car"];
    var transportOptions = [];
    transporations.forEach((t) => {
      transportOptions.push({ value: t, label: transportationMap[t] });
    });
    return transportOptions;
  };

  const transportationMap = {
    plane: "Pesawat",
    bus: "Bus",
    train: "Kereta",
    car: "Mobil",
  };

  const getDate = (date) => {
    var dateMoment = moment();
    if (moment.isMoment(date)) {
      dateMoment = date;
    } else if (date instanceof Date) {
      dateMoment = moment(date);
    } else if (date) {
      dateMoment = moment(date.toDate());
    }

    return dateMoment;
  };

  return (
    <div key={`vehicle-${idx}`}>
      {state[idx] && (
        <div>
          <Col className="px-0">
            <Button
              type="button"
              color="danger"
              data-id={idx}
              onClick={() => deleteInput(idx)}
            >
              Hapus Transportasi {idx + 1}
            </Button>
          </Col>
          <Row className="mb-5">
            <Col>
              <FormGroup>
                <Label className="col-form-label pt-3">
                  Jenis Transportasi <span className="text-danger">*</span>
                </Label>
                <Select
                  defaultValue={{
                    value: state[idx].transportation,
                    label: state[idx].transportation
                      ? transportationMap[state[idx].transportation]
                      : "",
                  }}
                  name="transportation"
                  options={getTransportationOptions()}
                  onChange={(e) => handleChange(e.value, idx, "transportation")}
                />
              </FormGroup>

              <Row>
                <Col>
                  <Label>Dari</Label>
                  <span> (Bandara xyz, Stasiun abc)</span>
                  <Input
                    type="text"
                    input={fromLocationId}
                    name={fromLocationId}
                    id={fromLocationId}
                    data-id={idx}
                    className="fromLocation"
                    value={state[idx].fromLocation || ""}
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <Label>Menuju</Label>
                  <span> (Bandara xyz, Stasiun abc)</span>
                  <Input
                    type="text"
                    input={toLocationId}
                    name={toLocationId}
                    id={toLocationId}
                    data-id={idx}
                    className="toLocation"
                    value={state[idx].toLocation || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <FormGroup>
                <Label className="col-form-label">Nomor Kendaraan</Label>
                <Input
                  type="text"
                  input={vehicleNumberId}
                  name={vehicleNumberId}
                  id={vehicleNumberId}
                  data-id={idx}
                  className="vehicleNumber"
                  value={state[idx].vehicleNumber || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label className="col-form-label">Tiket Grup</Label>
                <Row>
                  <Col lg="4">
                    <Dropzone
                      path="itineraryFiles"
                      name={state[idx].vehicleNumber}
                      handlePhotoUploaded={(e) => handleChange(e, idx, "group")}
                      itineraryFile
                    />
                  </Col>
                  <Col lg="8">
                    <Document
                      file={state[idx].groupTicketFile}
                      onLoadSuccess={onDocumentLoadSuccess2}
                      className="pdf-preview"
                    >
                      <Page pageNumber={pageNumber2} renderTextLayer={false} />
                    </Document>

                    {state[idx].groupTicketFile && (
                      <FileDownload url={state[idx].groupTicketFile} />
                    )}
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Label className="col-form-label">Tiket Individual</Label>
                <Row>
                  <Col lg="4">
                    <Dropzone
                      path="itineraryFiles"
                      name={state[idx].vehicleNumber}
                      handlePhotoUploaded={(e) =>
                        handleChange(e, idx, "individual")
                      }
                      itineraryFile
                    />
                  </Col>
                  <Col lg="8">
                    <Document
                      file={state[idx].ticketFile}
                      onLoadSuccess={onDocumentLoadSuccess}
                      className="pdf-preview"
                    >
                      <Page pageNumber={pageNumber} renderTextLayer={false} />
                    </Document>

                    {state[idx].ticketFile && (
                      <FileDownload url={state[idx].ticketFile} />
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Row>
              <Col>
                <h4>Keberangkatan</h4>

                <Row>
                  <Col>
                    <Label>Kota</Label>
                    <Input
                      type="text"
                      input={departureCityId}
                      name={departureCityId}
                      id={departureCityId}
                      data-id={idx}
                      className="departure-city"
                      value={state[idx].departure?.city || ""}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Label>Negara</Label>
                    <Input
                      type="text"
                      input={departureCountryId}
                      name={departureCountryId}
                      id={departureCountryId}
                      data-id={idx}
                      className="departure-country"
                      value={state[idx].departure?.country || ""}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <FormGroup>
                  <Label className="col-form-label">
                    Tanggal, Waktu
                    <span className="text-danger">*</span>
                  </Label>
                  <Row>
                    <Col>
                      <SingleDatePicker
                        date={getDate(state[idx].departure?.date)}
                        onDateChange={(e) => handleChange(e, idx, "departure")}
                        focused={focused.focused}
                        onFocusChange={(focused) => setFocused(focused)}
                        id="departureDate"
                        placeholder="Tanggal"
                        numberOfMonths={1}
                        orientation="horizontal"
                        isOutsideRange={() => false}
                        required
                      />
                    </Col>
                    <Col>
                      <Input
                        type="time"
                        input={departureTimeId}
                        name={departureTimeId}
                        id={departureTimeId}
                        data-id={idx}
                        className="departure-time"
                        value={state[idx].departure?.time || ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <h5 className="mt-5">Lokasi Kumpul</h5>
                  <Label className="col-form-label">Alamat Lengkap</Label>
                  <span>(Alamat, Kode Pos)</span>
                  <Input
                    type="text"
                    input={departureAddressId}
                    name={departureAddressId}
                    id={departureAddressId}
                    data-id={idx}
                    className="departure-address"
                    value={state[idx].departure?.address || ""}
                    onChange={handleChange}
                  />

                  <Label className="col-form-label">Informasi Tambahan</Label>
                  <span>(cth: Tempat Parkir, Gate Airport, ...)</span>
                  <Input
                    type="text"
                    input={departureAssemblyPointId}
                    name={departureAssemblyPointId}
                    id={departureAssemblyPointId}
                    data-id={idx}
                    className="departure-assemblyPoint"
                    value={state[idx].departure?.assemblyPoint || ""}
                    onChange={handleChange}
                  />

                  <Label className="col-form-label">
                    Koordinat
                    {"  "}
                    <span>
                      <a
                        href="https://www.latlong.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Cari Koordinat
                      </a>
                    </span>
                  </Label>
                  <Row>
                    <Col>
                      <Label>Latitude</Label>
                      <Input
                        type="text"
                        input={departureLatitudeId}
                        name={departureLatitudeId}
                        id={departureLatitudeId}
                        data-id={idx}
                        className="departure-latitude"
                        value={state[idx].departure?.latitude || ""}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col>
                      <Label>Longitude</Label>
                      <Input
                        type="text"
                        input={departureLongitudeId}
                        name={departureLongitudeId}
                        id={departureLongitudeId}
                        data-id={idx}
                        className="departure-longitude"
                        value={state[idx].departure?.longitude || ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col>
                <h4>Kedatangan</h4>

                <Row>
                  <Col>
                    <Label>Kota</Label>
                    <Input
                      type="text"
                      input={arrivalCityId}
                      name={arrivalCityId}
                      id={arrivalCityId}
                      data-id={idx}
                      className="arrival-city"
                      value={state[idx].arrival?.city || ""}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Label>Negara</Label>
                    <Input
                      type="text"
                      input={arrivalCountryId}
                      name={arrivalCountryId}
                      id={arrivalCountryId}
                      data-id={idx}
                      className="arrival-country"
                      value={state[idx].arrival?.country || ""}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <FormGroup>
                  <Label className="col-form-label">
                    Tanggal, Waktu
                    <span className="text-danger">*</span>
                  </Label>
                  <Row>
                    <Col>
                      <SingleDatePicker
                        date={getDate(state[idx].arrival?.date)}
                        onDateChange={(e) => handleChange(e, idx, "arrival")}
                        focused={focused1.focused}
                        onFocusChange={(focused) => setFocused1(focused)}
                        id="arrivalDate"
                        placeholder="Tanggal"
                        numberOfMonths={1}
                        orientation="horizontal"
                        isOutsideRange={() => false}
                        required
                      />
                    </Col>
                    <Col>
                      <Input
                        type="time"
                        input={arrivalTimeId}
                        name={arrivalTimeId}
                        id={arrivalTimeId}
                        data-id={idx}
                        className="arrival-time"
                        value={state[idx].arrival?.time || ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <h5 className="mt-5">Lokasi Kumpul</h5>
                  <Label className="col-form-label">Alamat Lengkap</Label>
                  <span>(Alamat, Kode Pos)</span>
                  <Input
                    type="text"
                    input={arrivalAddressId}
                    name={arrivalAddressId}
                    id={arrivalAddressId}
                    data-id={idx}
                    className="arrival-address"
                    value={state[idx].arrival?.address || ""}
                    onChange={handleChange}
                  />

                  <Label className="col-form-label">Informasi Tambahan</Label>
                  <span>(cth: Tempat Parkir, Gate Airport, ...)</span>
                  <Input
                    type="text"
                    input={arrivalAssemblyPointId}
                    name={arrivalAssemblyPointId}
                    id={arrivalAssemblyPointId}
                    data-id={idx}
                    className="arrival-assemblyPoint"
                    value={state[idx].arrival?.assemblyPoint || ""}
                    onChange={handleChange}
                  />

                  <Label className="col-form-label">
                    Koordinat
                    {"  "}
                    <span>
                      <a
                        href="https://www.latlong.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Cari Koordinat
                      </a>
                    </span>
                  </Label>
                  <Row>
                    <Col>
                      <Label>Latitude</Label>
                      <Input
                        type="text"
                        input={arrivalLatitudeId}
                        name={arrivalLatitudeId}
                        id={arrivalLatitudeId}
                        data-id={idx}
                        className="arrival-latitude"
                        value={state[idx].arrival?.latitude || ""}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col>
                      <Label>Longitude</Label>
                      <Input
                        type="text"
                        input={arrivalLongitudeId}
                        name={arrivalLongitudeId}
                        id={arrivalLongitudeId}
                        data-id={idx}
                        className="arrival-longitude"
                        value={state[idx].arrival?.longitude || ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </Row>
        </div>
      )}
    </div>
  );
};

export default VehicleInput;
