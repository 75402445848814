import React, { useContext } from "react";
import { Nav } from "reactstrap";
import Scrollbar from "react-scrollbars-custom";
import { useDocument } from "react-firebase-hooks/firestore";
import ChatThread from "./ChatThread";
import ChatContactsSearch from "./ChatContactsSearch";
import AppContext, { ChatContext } from "../helper/Context";
import { isIterableArray } from "../helper/utils";
import firebase from "../../../data/firebase";

const ChatSidebar = () => {
  const { isRTL } = useContext(AppContext);
  const { loading, threads } = useContext(ChatContext);

  // const threadsRef = firebase.firestore().collection("chats").doc("threads");

  // const [threadsData, loadingThreads, errorThreads] = useDocument(
  //   threadsRef,
  //   {}
  // );

  // if (loading || loadingThreads) {
  //   return <p>loading threads</p>;
  // }

  // const threads = threadsData.data().threads;

  // sort threads by last message time (desc)
  // threads.sort((a, b) => {
  //   if (!a.lastMessage.time) return 0;
  //   if (!b.lastMessage.time) return 0;
  //   var keyA = new Date(a.lastMessage.time.toDate());
  //   var keyB = new Date(b.lastMessage.time.toDate());
  //   // Compare the 2 dates
  //   if (keyA > keyB) return -1;
  //   if (keyA < keyB) return 1;
  //   return 0;
  // });

  return (
    <div className="chat-sidebar rounded-left">
      <div className="contacts-list bg-white">
        <Scrollbar
          style={{
            height: "100%",
            minWidth: "75px",
          }}
          rtl={isRTL}
          noScrollX
          trackYProps={{
            renderer(props) {
              const { elementRef, ...restProps } = props;
              return (
                <span {...restProps} ref={elementRef} className="TrackY" />
              );
            },
          }}
        >
          <Nav className="border-0">
            {isIterableArray(threads) &&
              threads.map((thread) => (
                <ChatThread thread={thread} key={thread.messagesId} />
              ))}
          </Nav>
        </Scrollbar>
      </div>
      <ChatContactsSearch />
    </div>
  );
};

export default ChatSidebar;
