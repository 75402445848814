import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import firebase from "../../data/firebase";
import SelectCountryCity from "../common/SelectCountryCity";
import FileDownload from "../common/FileDownload";
import OpeningHoursInput from "../common/OpeningHoursInput";
import LinkButton from "../common/LinkButton";
import Dropzone from "../common/Dropzone";

const GetInfoDetail = () => {
  let { id } = useParams();

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [getInfoData, setGetInfoData] = useState({});
  const [loading, setLoading] = useState(true);
  const [newService, setNewService] = useState(true);
  const [formData, setFormData] = useState({
    openingHours: ["", "", "", "", "", "", ""],
  });

  const [mainPhoto, setMainPhoto] = useState({});
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      accept: "image/*",
      minSize: 0,
      maxSize: 12582912, //12mb
      multiple: false,
      required: true,
      onDrop: (acceptedFiles) => {
        setPhotoUploadFinished(false);
        var ref = firebase.storage().ref();
        var file = acceptedFiles[0];
        const fileName = uuidv4();
        ref
          .child("getinfoImage/" + fileName)
          .put(file)
          .then(() => {
            ref
              .child("getinfoImage/" + fileName)
              .getDownloadURL()
              .then((url) => {
                var photo = Object.assign(file, {
                  preview: url,
                });
                setMainPhoto(photo);
                return setPhotoUploadFinished(true);
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });

  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 12582912, //12mb
    multiple: true,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotos([]);
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      Promise.all(
        acceptedFiles.map((file) => {
          const fileName = uuidv4();
          ref
            .child("getinfoImage/" + fileName)
            .put(file)
            .then(() => {
              ref
                .child("getinfoImage/" + fileName)
                .getDownloadURL()
                .then((url) => {
                  file["preview"] = url;
                  setPhotos((photos) => [...photos, file]);
                  return setPhotoUploadFinished(true);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
    },
  });

  const handleMenuChange = (file) => {
    setGetInfoData({ ...getInfoData, menu: file.preview });
  };

  const amenitiesMap = {
    vegan_friendly: "Masakan Vegan",
    wifi: "Wifi",
    no_smoking: "Tempat Tidak Merokok",
    parking: "Tempat Parkir",
    alcohol: "Minuman Beralkohol",
    halal: "Halal",
  };

  const getInfoTypes = ["restaurant", "supermarkt", "embassy"];

  const categoriesMap = {
    restaurant: "Restoran",
    supermarkt: "Supermarket",
    embassy: "Kedutaan",
  };

  const [checkboxInput, setCheckboxInput] = useState({
    amenities: {
      vegan_friendly: false,
      wifi: false,
      no_smoking: false,
      parking: false,
      alcohol: false,
      halal: false,
    },
    categories: {
      restaurant: false,
      supermarkt: false,
    },
  });

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        var galleryPhotos = [];
        const response = await firebase
          .firestore()
          .collection("getInfo")
          .doc(id)
          .get();
        if (response.exists) {
          data = response.data();
          data.gallery.forEach((photo) => {
            galleryPhotos.push({ preview: photo.img, name: photo.title });
          });

          setFormData({
            ...formData,
            category: data.category,
            openingHours: data.openingHours,
            city: data.city,
            country: data.country,
          });

          setCheckboxInput({
            amenities: data["amenities"]
              ? data["amenities"]
              : {
                  vegan_friendly: false,
                  wifi: false,
                  no_smoking: false,
                  parking: false,
                  alcohol: false,
                  halal: false,
                },
            categories: data["category"]
              ? data["category"]
              : {
                  restaurant: false,
                  supermarkt: false,
                },
          });
        }
        setGetInfoData(data);
        setPhotos(galleryPhotos);
        setMainPhoto({ preview: data.img });
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    if (id !== "new") {
      setNewService(false);
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  const onCategoryChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, category: value });
  };

  const onCheckboxAmenityChange = (e) => {
    const value = e.target.value;
    checkboxInput["amenities"][e.target.id] = !value;
    setCheckboxInput({ ...checkboxInput });
  };

  const onOpeningHoursChange = (e, id) => {
    const openingHoursTemp = formData.openingHours
      ? formData.openingHours
      : ["", "", "", "", "", "", ""];
    openingHoursTemp[id] = e;
    setFormData({ ...formData, openingHours: openingHoursTemp });
  };

  const setSelectedCountry = (country) => {
    setFormData({ ...formData, country: country });
  };

  const setSelectedCity = (city) => {
    setFormData({ ...formData, city: city });
  };

  const deleteService = () => {
    firebase
      .firestore()
      .collection("getInfo")
      .doc(id)
      .delete()
      .then(() => {
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/services/getinfo-list`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const updateWishtripGetInfo = (wishtripUserList, data) => {
    const wishtripData = {
      address: data.address,
      city: data.city,
      country: data.country,
      category: data.category,
      img: data.img,
      name: data.name,
      openingHours: data.openingHours,
    };
    wishtripUserList &&
      wishtripUserList.forEach((uid) => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("wishtrip")
          .doc(data.id)
          .set(wishtripData, { merge: true });
      });
  };

  const updateList = async (data) => {
    const listData = {
      name: data["name"],
      image: data["img"],
      country: data["country"],
      city: data["city"],
      category: data["category"],
      address: data["address"],
      openingHours: data["openingHours"],
    };
    return firebase
      .firestore()
      .collection("aggregateList")
      .doc("getInfoList")
      .set(
        {
          [data.id]: listData,
        },
        { merge: true }
      );
  };

  const updateCity = (data) => {
    const getInfoCityData = {
      category: data.category,
      image: data.img,
      name: data.name,
      coordinates: data.coordinates,
      slug: data.slug,
      address: data.address,
      openingHours: data.openingHours,
    };
    firebase
      .firestore()
      .collection("countries")
      .doc(formData.country)
      .collection("cities")
      .doc(formData.city)
      .collection("getInfo")
      .doc(data.id)
      .set(getInfoCityData, { merge: true });
  };

  const onSubmit = (data) => {
    var docId = "";
    var swiper = [];
    var coordinates = {
      latitude: "",
      longitude: "",
    };

    if (data !== "") {
      coordinates["latitude"] = data["coordinatesLat"] || "";
      coordinates["longitude"] = data["coordinatesLong"] || "";

      var city = formData["city"] ? formData["city"] : getInfoData["city"];
      var country = formData["country"]
        ? formData["country"]
        : getInfoData["country"];
      var slug = "getinfo-" + city + "-" + data["name"].replace(/ /g, "-");

      var contact = {
        phone: data["phone"] || "",
        email: data["email"] || "",
        website: data["website"] || "",
        instagram: data["instagram"] || "",
        whatsapp: data["whatsapp"] || "",
      };

      if (newService) {
        docId = uuidv4();
      } else {
        docId = id;
      }

      photos.forEach((photo) => {
        swiper.push({
          img: photo["preview"],
          title: photo["name"],
        });
      });

      const newGetInfoData = {
        id: docId,
        name: data["name"] || "",
        address: data["address"] || "",
        zip: data["zip"] || "",
        city: city || "",
        country: country || "",
        coordinates: coordinates || "",
        content: data["description"] || "",
        category: formData["category"] || "",
        contact,
        amenities: checkboxInput["amenities"] || "",
        openingHours: formData["openingHours"] || "",
        slug: slug || "",
        gallery: swiper || [],
        img: mainPhoto.preview || "",
        menu: getInfoData.menu || "",
      };

      firebase
        .firestore()
        .collection("getInfo")
        .doc(docId)
        .set(newGetInfoData, { merge: true })
        .then(() => {
          updateList(newGetInfoData)
            .then(function () {
              updateCity(newGetInfoData);

              // update getguideData in wishtrip
              const wishtripUserList = getInfoData.wishtripUser;
              updateWishtripGetInfo(wishtripUserList, newGetInfoData);

              toast.success("Berhasil disimpan", {
                position: toast.POSITION.TOP_CENTER,
              });
              history.push(`${process.env.PUBLIC_URL}/services/getinfo-list`);
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus GetInfo?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteService}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2 className="mb-5">GetInfo</h2>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  {!newService && (
                    <Row className="mb-3">
                      <LinkButton
                        title="Lihat Halaman Service"
                        url={`https://getgoing.co.id/getInfo/${getInfoData.slug}?id=${id}`}
                      />
                    </Row>
                  )}
                  <Label for="mainImage" className="d-block form-label">
                    Foto Utama <span className="text-danger">*</span>
                  </Label>

                  <div
                    {...getRootProps2({ className: "dropzone dz-clickable" })}
                  >
                    <input {...getInputProps2()} />
                    <div className="dz-message text-muted">
                      <p>Klik untuk ubah foto baru (max 1)</p>
                      <img
                        src={mainPhoto["preview"]}
                        alt={mainPhoto["preview"]}
                        className="avatar avatar-large mb-2"
                      />
                      {!photoUploadFinished && (
                        <div className="loader-box">
                          <div className="loader-3"></div>
                        </div>
                      )}
                    </div>
                  </div>

                  <FormGroup>
                    <Label className="col-form-label">
                      Judul <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="name"
                      innerRef={register({ required: true })}
                      defaultValue={getInfoData.name}
                    />
                    <span style={{ color: "red" }}>
                      {errors.name && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Deskripsi <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="description"
                      innerRef={register({ required: true })}
                      defaultValue={getInfoData.content}
                    />
                    <span style={{ color: "red" }}>
                      {errors.description && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Alamat<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="address"
                      innerRef={register({ required: true })}
                      defaultValue={getInfoData.address}
                    />
                    <span style={{ color: "red" }}>
                      {errors.address && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      ZIP<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="zip"
                      innerRef={register({ required: true })}
                      defaultValue={getInfoData.zip}
                    />
                    <span style={{ color: "red" }}>
                      {errors.zip && "Harus diisi"}
                    </span>
                  </FormGroup>

                  <SelectCountryCity
                    userCountry={formData.country}
                    userCity={formData.city}
                    selectedCountry={setSelectedCountry}
                    selectedCity={setSelectedCity}
                  />

                  <FormGroup>
                    <Label className="col-form-label">
                      Koordinat<span className="text-danger">*</span>
                      {"  "}
                      <span>
                        <a
                          href="https://www.latlong.net/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Cari Koordinat
                        </a>
                      </span>
                    </Label>
                    <Row>
                      <Col lg="3">
                        <Input
                          className="form-control"
                          type="text"
                          name="coordinatesLat"
                          innerRef={register({ required: true })}
                          defaultValue={getInfoData.coordinates?.latitude}
                        />
                        <span style={{ color: "red" }}>
                          {errors.coordinatesLat && "Harus diisi"}
                        </span>
                      </Col>
                      <Col lg="3">
                        <Input
                          className="form-control"
                          type="text"
                          name="coordinatesLong"
                          innerRef={register({ required: true })}
                          defaultValue={getInfoData.coordinates?.longitude}
                        />
                        <span style={{ color: "red" }}>
                          {errors.coordinatesLong && "Harus diisi"}
                        </span>
                      </Col>
                    </Row>
                  </FormGroup>

                  <Label className="col-form-label">
                    Kategori<span className="text-danger">*</span>
                  </Label>

                  {getInfoTypes.map((item) => (
                    <FormGroup key={item} style={{ marginLeft: 20 }}>
                      <Label>
                        <Input
                          type="radio"
                          id={item}
                          key={item}
                          name={item}
                          value={item}
                          checked={formData.category === item}
                          onChange={(e) => onCategoryChange(e)}
                        />
                        {categoriesMap[item]}
                      </Label>
                    </FormGroup>
                  ))}

                  <Label className="col-form-label">
                    Kelengkapan <span className="text-danger">*</span>
                  </Label>

                  {Object.keys(amenitiesMap).map((keyName, i) => (
                    <FormGroup check key={i}>
                      <Label check>
                        <Input
                          type="checkbox"
                          id={keyName}
                          key={keyName}
                          name={keyName}
                          value={
                            checkboxInput["amenities"] &&
                            (checkboxInput["amenities"][keyName] || "")
                          }
                          defaultChecked={
                            checkboxInput["amenities"]
                              ? checkboxInput["amenities"][keyName]
                              : false
                          }
                          onChange={(e) => onCheckboxAmenityChange(e)}
                        />{" "}
                        {amenitiesMap[keyName]}
                      </Label>
                    </FormGroup>
                  ))}
                  <FormGroup>
                    <Label className="col-form-label">Kontak</Label>
                    <Row>
                      <Col lg="2">
                        <p>Phone</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="phone"
                          name="phone"
                          innerRef={register({ required: false })}
                          defaultValue={
                            getInfoData.contact && getInfoData.contact.phone
                          }
                        />
                      </Col>
                      <Col lg="2">
                        <p>Email</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="email"
                          name="email"
                          innerRef={register({ required: false })}
                          defaultValue={
                            getInfoData.contact && getInfoData.contact.email
                          }
                        />
                      </Col>
                      <Col lg="2">
                        <p>Website</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="text"
                          name="website"
                          innerRef={register({ required: false })}
                          defaultValue={
                            getInfoData.contact && getInfoData.contact.website
                          }
                        />
                      </Col>
                      <Col lg="2">
                        <p>Instagram</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="text"
                          name="instagram"
                          innerRef={register({ required: false })}
                          defaultValue={
                            getInfoData.contact && getInfoData.contact.instagram
                          }
                        />
                      </Col>
                      <Col lg="2">
                        <p>No. Whatsapp</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="phone"
                          name="whatsapp"
                          placeholder="4912345678 (Format internasional, tanpa simbol +, (, ), -, spasi,
                          atau 0 di depan nomor)"
                          innerRef={register({ required: false })}
                          defaultValue={
                            getInfoData.contact && getInfoData.contact.whatsapp
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Jam Buka</Label>

                    {[
                      "Senin",
                      "Selasa",
                      "Rabu",
                      "Kamis",
                      "Jumat",
                      "Sabtu",
                      "Minggu",
                    ].map((el, id) => (
                      <Row key={id} className="mb-5">
                        <Col lg="2">
                          <p>{el}</p>
                        </Col>
                        <Col lg="10">
                          <OpeningHoursInput
                            value={formData.openingHours[id]}
                            setValue={(e) => onOpeningHoursChange(e, id)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Menu</Label>
                    <Col>
                      <Dropzone
                        path="getinfoImage"
                        name="menu"
                        handlePhotoUploaded={(e) => handleMenuChange(e)}
                        itineraryFile
                      />
                    </Col>
                    <Col>
                      <span className="media user-header">
                        <img
                          className="mr-2 img-35"
                          src={getInfoData.menu || ""}
                          alt={getInfoData.name}
                          style={{ maxHeight: 300, maxWidth: 300 }}
                        />
                      </span>
                      {getInfoData.menu && (
                        <FileDownload url={getInfoData.menu} />
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label pt-0">Foto Galeri</Label>
                    <Row className="ml-n1 mr-n1">
                      {photos &&
                        photos.map((file, index) => (
                          <Col key={index} lg="4" xs="6" className="px-1 mb-2">
                            <img
                              src={file.preview}
                              alt={file.preview}
                              className="img-fluid"
                            />

                            <FileDownload url={file.preview} />
                          </Col>
                        ))}
                    </Row>
                    <div
                      {...getRootProps({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps()} />

                      <div className="dz-message text-muted">
                        <p>Upload file-file baru (minimal 2)</p>
                        {!newService && (
                          <p>Foto-foto lama akan diganti dengan foto baru</p>
                        )}
                        {!photoUploadFinished && (
                          <div className="loader-box">
                            <div className="loader-3"></div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* {uploadError &&
                                        <p className="text-danger">Harap upload min. 2, maks. 10 fotomu.</p>
                                    } */}
                  </FormGroup>

                  <FormGroup className="form-row mt-3 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      disabled={!photoUploadFinished}
                    >
                      Simpan
                    </Button>
                    {!newService && (
                      <Button
                        type="button"
                        onClick={toggle}
                        color="mr-2"
                        className="btn-secondary-gradien mr-2"
                      >
                        Hapus Layanan
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default GetInfoDetail;
