import React, { useState } from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";

import Dropzone from "../common/Dropzone";
import FileDownload from "../common/FileDownload";

const TestimonialInputs = (props) => {
  const { idx, testimonialState, handleTestimonialChange } = props;
  const [photo, setPhoto] = useState({});

  const handlePhotoUploaded = (photo) => {
    var newData = tentangKamiData;
    newData[block][index][inputTitle] = photo.preview;

    setPhoto(photo);
  };

  const avatarId = `avatar-${idx}`;
  const titleId = `title-${idx}`;
  const contentId = `content-${idx}`;
  return (
    <div key={`testimonial-${idx}`}>
      {testimonialState[idx] && (
        <div>
          <Row className="my-5">
            {!props.disableDelete && (
              <Col md="1" className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => props.deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <Col xl="3" lg="3">
              <span className="media user-header">
                <img
                  className="mr-2 rounded-circle img-35"
                  src={testimonialState[idx].avatar || ""}
                  alt={testimonialState[idx].title}
                />
              </span>
              {testimonialState[idx].avatar && (
                <FileDownload url={testimonialState[idx].avatar} />
              )}
            </Col>
            <Col xl="3" lg="3">
              <Dropzone
                path="webImages"
                name={testimonialState[idx].title}
                handlePhotoUploaded={(e) =>
                  handleTestimonialChange(e, "avatar", idx)
                }
              />
            </Col>
            <Col xl="5" lg="5">
              <div>
                <Label className="col-form-label">
                  Nama <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  input={titleId}
                  name={titleId}
                  id={titleId}
                  data-id={idx}
                  className="title"
                  value={testimonialState[idx].title || ""}
                  onChange={(e) => handleTestimonialChange(e, "title", idx)}
                />

                <Label className="col-form-label">
                  Konten <span className="text-danger">*</span>
                </Label>
                <Input
                  type="textarea"
                  rows="10"
                  input={contentId}
                  name={contentId}
                  id={contentId}
                  data-id={idx}
                  className="content"
                  value={testimonialState[idx].content || ""}
                  onChange={(e) => handleTestimonialChange(e, "content", idx)}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default TestimonialInputs;
