import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  CardHeader,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import QRCodeSVG from "qrcode.react";
import firebase from "../../../data/firebase";
import MaterialUiTable from "../../common/MaterialUiTable";
import { Plus } from "react-feather";
import { itineraryTableInfo } from "./util";
import LinkButton from "../../common/LinkButton";
import useQuery from "../../../util/useQuery";
import Logo from "../../../assets/images/LogoMerah.png";
import * as firebaseApp from "firebase/app";

const ItineraryGroupDetail = () => {
  let { id } = useParams();
  let query = useQuery();
  const bookingId = query.get("bookingId");
  const getFlexId = query.get("getFlexId");
  const departureId = query.get("departureId");
  const history = useHistory();
  const [itineraryList, setItineraryList] = useState([]);
  const [itineraryPersonalList, setItineraryPersonalList] = useState([]);

  const [formData, setFormData] = useState({
    displayName: "",
    getFlexData: {},
    departureData: {},
    bookingData: {},
    travellerUidList: [],
  });

  const [newItineraryGroup, setNewItineraryGroup] = useState(false);
  const [loading, setLoading] = useState(true);

  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const formatDate = (value) => {
    return value ? moment(value.toDate()).format("DD MMM YYYY") : "";
  };

  useEffect(() => {
    const fetchDepartureAndBookingData = (
      getFlexId,
      departureId,
      bookingId,
      displayName,
      travellerUidList
    ) => {
      firebase
        .firestore()
        .collection("getFlex")
        .doc(getFlexId)
        .get()
        .then((response) => {
          if (response.exists) {
            const getFlexData = { id: response.id, ...response.data() };
            firebase
              .firestore()
              .collection("getFlex")
              .doc(getFlexId)
              .collection("departures")
              .doc(departureId)
              .get()
              .then((response) => {
                if (response.exists) {
                  const departureData = response.data();

                  firebase
                    .firestore()
                    .collection("bookingForms")
                    .doc(bookingId)
                    .get()
                    .then((response) => {
                      if (response.exists) {
                        const bookingData = response.data();

                        setFormData({
                          displayName: displayName,
                          getFlexData,
                          departureData,
                          bookingData,
                          travellerUidList,
                        });
                      } else {
                        setFormData({
                          displayName,
                          getFlexData,
                          departureData,
                          bookingData: {},
                          travellerUidList,
                        });
                      }
                    });
                }
              });
          }
        });
    };

    const fetchItineraryIndividualData = async (itineraryIndividualList) => {
      const itineraryIndividualDataList = [];

      for (const individualId of itineraryIndividualList) {
        await firebase
          .firestore()
          .collection("itinerary")
          .doc("itineraryGroup")
          .collection("itineraryGroup")
          .doc(id)
          .collection("itineraryIndividual")
          .doc(individualId)
          .get()
          .then((response) => {
            if (response.exists) {
              const data = response.data();
              const itineraryIndividualData = {
                id: response.id,
                groupItineraryId: id,
                name: data.travellerData.name,
                uid: data.uid,
              };
              itineraryIndividualDataList.push(itineraryIndividualData);
            }
          });
      }
      setItineraryPersonalList(itineraryIndividualDataList);
    };

    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("itinerary")
          .doc("itineraryGroup")
          .collection("itineraryGroup")
          .doc(id)
          .get();
        var responseData = response.data();

        if (responseData) {
          const itineraryListData = responseData.itineraryItemList;

          // get departure data
          const _getFlexId = responseData.getFlexId || getFlexId;
          const _departureId = responseData.departureId || departureId;
          const _bookingId = responseData.bookingId || bookingId;
          const _displayName = responseData.displayName || "";
          const _travellerUidList = responseData.travellerUidList || [];

          if (_getFlexId && _departureId && _bookingId) {
            fetchDepartureAndBookingData(
              _getFlexId,
              _departureId,
              _bookingId,
              _displayName,
              _travellerUidList
            );
          } else {
            setFormData({
              displayName: _displayName,
              travellerUidList: _travellerUidList,
            });
          }

          var itineraryList = [];
          if (itineraryListData && itineraryListData.length > 0) {
            itineraryListData.forEach((value) => {
              const templateId = value.templateId ? value.templateId : "";
              if (value.type === "transportation") {
                const firstDeparture =
                  value.vehicles &&
                  value.vehicles.length > 0 &&
                  value.vehicles[0].departure;
                const lastArrival =
                  value.vehicles &&
                  value.vehicles.length > 0 &&
                  value.vehicles[value.vehicles.length - 1].arrival;

                itineraryList.push({
                  id: value.id,
                  dateArr: [
                    formatDate(firstDeparture.date),
                    formatDate(lastArrival.date),
                  ],
                  timeArr: [firstDeparture.time, lastArrival.time],
                  info: itineraryTableInfo(value),
                  ...value,
                  templateId,
                });
              } else if (value.type === "accommodation") {
                itineraryList.push({
                  id: value.id,
                  dateArr: [
                    formatDate(value["checkIn"].date),
                    formatDate(value["checkOut"].date),
                  ],
                  timeArr: [value["checkIn"].time, value["checkOut"].time],
                  info: itineraryTableInfo(value),
                  ...value,
                  templateId,
                });
              } else {
                const date = [formatDate(value.date)];
                const time = [value.time];
                itineraryList.push({
                  id: value.id,
                  dateArr: date,
                  timeArr: time,
                  info: itineraryTableInfo(value),
                  ...value,
                  templateId,
                });
              }
            });
            setItineraryList(itineraryList);
          }

          const itineraryIndividualList = responseData.itineraryIndividualList;
          if (itineraryIndividualList && itineraryIndividualList.length > 0) {
            await fetchItineraryIndividualData(itineraryIndividualList);
          }
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (id === "new") {
      setNewItineraryGroup(true);
      setLoading(false);
    } else {
      fetchData().then(() => setLoading(false));
    }
  }, []);

  const columnsItinerary = [
    {
      name: "id",
      options: {
        // display: "excluded",
        filter: false,
      },
    },
    {
      name: "templateId",
      options: {
        // display: "excluded",
        filter: false,
      },
    },
    {
      name: "type",
      label: "TIPE",
    },
    {
      name: "dateArr",
      label: "TANGGAL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <p>{value[0]}</p>
              {value.length > 0 ? <p>{value[1]}</p> : null}
            </div>
          );
        },
      },
    },
    {
      name: "timeArr",
      label: "WAKTU",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <p>{value[0]}</p>
              {value.length > 0 ? <p>{value[1]}</p> : null}
            </div>
          );
        },
        filter: false,
      },
    },
    {
      name: "info",
      label: "INFO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ whiteSpace: "pre-line" }}>{value}</span>;
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const columnsItineraryPersonal = [
    {
      name: "id",
      options: {
        // display: "excluded",
        filter: false,
      },
    },
    {
      name: "groupItineraryId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "name",
      label: "NAMA TRAVELLER",
    },
    {
      name: "uid",
      label: "ID USER",
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const toItineraryDetail = (id1, id2) => {
    let itemId = id1;
    if (id1 === id2) itemId = "new";
    history.push(
      `${process.env.PUBLIC_URL}/itinerary/itinerary-detail/${itemId}?templateId=${id2}&itineraryLevel=group&groupId=${id}`
    );
  };

  const toItineraryPersonalDetail = (id1, id2) => {
    // id1: itineraryPersonalId
    // id2: itineraryGroupId
    history.push(
      `${process.env.PUBLIC_URL}/itinerary/itinerary-personal/${id1}?itineraryGroupId=${id2}&itineraryLevel=personal`
    );
  };

  const toNewItinerary = () => {
    history.push(
      `${process.env.PUBLIC_URL}/itinerary/itinerary-detail/new?groupId=${id}&itineraryLevel=group`
    );
  };

  const toNewTraveller = () => {
    history.push(
      `${process.env.PUBLIC_URL}/itinerary/itinerary-personal/new?itineraryGroupId=${id}&itineraryLevel=personal`
    );
  };

  const saveItineraryGroup = () => {
    var docId = newItineraryGroup ? uuidv4() : id;

    var currentItineraryList = [];
    itineraryList.forEach((itin) => {
      itin.groupId = docId;
      currentItineraryList.push(itin);
    });
    var data = {
      displayName: formData.displayName || "",
      itineraryItemList: currentItineraryList,
      getFlexId: formData.getFlexData?.id || "",
      departureId: formData.departureData?.id || "",
      bookingId: formData.bookingData?.id || "",
    };

    firebase
      .firestore()
      .collection("itinerary")
      .doc("itineraryGroup")
      .collection("itineraryGroup")
      .doc(docId)
      .set(data, { merge: true })
      .then(() => {
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("itineraryGroupList")
          .update({
            [docId]: {
              displayName: data.displayName,
              itineraryCount: itineraryList.length,
              getFlexId: data.getFlexId,
              departureId: data.departureId,
              bookingId: data.bookingId,
            },
          })
          .then(function () {
            history.goBack();
            toast.success("Berhasil disimpan", {
              position: toast.POSITION.TOP_CENTER,
            });
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      });
  };

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `itinerary-${id}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const deleteItineraries = () => {
    if (itineraryList && itineraryList.length > 0) {
      itineraryList.forEach((itin) => {
        const itinId = itin.id;
        firebase
          .firestore()
          .collection("itinerary")
          .doc("itineraryItem")
          .collection("itineraryItem")
          .doc(itinId)
          .delete()
          .then(() => {
            firebase
              .firestore()
              .collection("aggregateList")
              .doc("itineraryList")
              .update({
                [itinId]: firebaseApp.firestore.FieldValue.delete(),
              });
          });
      });
    }
  };

  const deleteFromUser = () => {
    const travellerUidList = formData.travellerUidList;
    if (travellerUidList && travellerUidList.length > 0) {
      travellerUidList.forEach((uid) => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("itinerary")
          .doc(id)
          .delete();
      });
    }
  };

  const deleteItineraryGroup = () => {
    firebase
      .firestore()
      .collection("itinerary")
      .doc("itineraryGroup")
      .collection("itineraryGroup")
      .doc(id)
      .delete()
      .then(() => {
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("itineraryGroupList")
          .update({
            [id]: firebaseApp.firestore.FieldValue.delete(),
          })
          .then(() => {
            deleteItineraries();
            deleteFromUser();

            history.goBack();

            toast.success("Berhasil dihapus", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      });
  };

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>
        <ModalFooter>
          <ModalBody>Hapus Itinerary Group?</ModalBody>
          <Button
            className="btn-success-gradien mr-2"
            onClick={deleteItineraryGroup}
          >
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Form className="theme-form">
        <Row className="m-5" style={{ marginLeft: 100 }}>
          <FormGroup className="m-5">
            <Label className="col-form-label">Nama Grup</Label>
            <Input
              className="form-control"
              type="text"
              name="groupName"
              defaultValue={formData.displayName}
              onChange={(e) =>
                setFormData({ ...formData, displayName: e.target.value })
              }
            />
          </FormGroup>
        </Row>
        {formData.getFlexData && Object.keys(formData.getFlexData).length > 0 && (
          <FormGroup className="m-5" style={{ marginLeft: 100 }}>
            <Row>
              <Col>
                <Label className="col-form-label">GetFlex</Label>
                <p>{formData.getFlexData.id}</p>
              </Col>
              <Col>
                <Label className="col-form-label">Judul</Label>
                <p>{formData.getFlexData.title}</p>
              </Col>
              <Col>
                <LinkButton
                  title="Lihat GetFlex"
                  url={`${process.env.PUBLIC_URL}/services/getflex-detail/${formData.getFlexData.id}`}
                />
              </Col>
            </Row>
          </FormGroup>
        )}

        {formData.departureData &&
          Object.keys(formData.departureData).length > 0 && (
            <FormGroup className="m-5" style={{ marginLeft: 100 }}>
              <Row>
                <Col>
                  <Label className="col-form-label">Departure</Label>
                  <p>{formData.departureData.id}</p>
                </Col>
                <Col>
                  <Label className="col-form-label">
                    Periode Keberangkatan
                  </Label>
                  <p>
                    {formatDate(formData.departureData.startDate)} -{" "}
                    {formatDate(formData.departureData.endDate)}
                  </p>
                </Col>
                <Col>
                  <LinkButton
                    title="Lihat GetFlex Keberangkatan"
                    url={`${process.env.PUBLIC_URL}/getflex/departure/${formData.getFlexData.id}/${formData.departureData.id}`}
                  />
                </Col>
              </Row>
            </FormGroup>
          )}

        {formData.bookingData &&
          Object.keys(formData.departureData).length > 0 && (
            <FormGroup className="m-5" style={{ marginLeft: 100 }}>
              <Row>
                <Col>
                  <Label className="col-form-label">Booking</Label>
                  <p>{formData.bookingData.id}</p>
                </Col>
                <Col>
                  <LinkButton
                    title="Lihat Booking"
                    url={`${process.env.PUBLIC_URL}/bookings/booking-form-detail/${formData.bookingData.id}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="col-form-label">Pax Info</Label>
                  {formData.bookingData.bookingDetail &&
                    formData.bookingData.bookingDetail.travellerList &&
                    formData.bookingData.bookingDetail.travellerList.map(
                      (traveller, idx) => (
                        <Row key={idx}>
                          <Col lg={1}>{idx + 1}</Col>
                          <Col>
                            <p>{traveller.name}</p>
                            <p>{traveller.email}</p>
                            <p>{traveller.phone}</p>
                          </Col>
                        </Row>
                      )
                    )}
                </Col>
              </Row>
            </FormGroup>
          )}
      </Form>
      {!newItineraryGroup && (
        <div>
          <Label>Link itinerary</Label>
          <Input
            className="form-control"
            type="text"
            name="itineraryCode"
            disabled
            defaultValue={`https://getgoing.co.id/itinerary/${id}`}
          />
          <Label>QR Code</Label>
          <div>
            <QRCodeSVG
              id="qr-gen"
              value={"https://getgoing.co.id/itinerary/" + id}
              size={128}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              imageSettings={{
                src: Logo,
                x: null,
                y: null,
                height: 24,
                width: 24,
                excavate: true,
              }}
            />
          </div>
          <a
            className="anchor-button w-100"
            target="_blank"
            rel="noopener noreferrer"
            download
            onClick={downloadQRCode}
          >
            Download QR Code
          </a>
        </div>
      )}

      {!newItineraryGroup && (
        <div>
          <Card className="mx-4">
            <CardHeader>
              <Row className="ml-1">
                <h5 className="line-height-inherit mr-3">Itinerary</h5>
                <p className="text-muted">(total {itineraryList.length})</p>
                <Button
                  type="button"
                  className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
                  onClick={toNewItinerary}
                >
                  <Plus /> Tambah Itinerary
                </Button>
              </Row>
            </CardHeader>
            <div className="table-responsive">
              <MaterialUiTable
                columns={columnsItinerary}
                data={itineraryList}
                toItemDetail={toItineraryDetail}
                sortInitialColumn="date"
                searchableCols="Nama"
                defaultSearchInput={true}
                twoRowsId
              />
            </div>
          </Card>

          <Card className="mx-4">
            <CardHeader>
              <Row className="ml-1">
                <h5 className="line-height-inherit mr-3">
                  Travellers' Itinerary
                </h5>
                <p className="text-muted">
                  (total {itineraryPersonalList.length})
                </p>
                <Button
                  type="button"
                  className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
                  onClick={toNewTraveller}
                >
                  <Plus /> Tambah Travaller
                </Button>
              </Row>
            </CardHeader>
            <div className="table-responsive">
              <MaterialUiTable
                columns={columnsItineraryPersonal}
                data={itineraryPersonalList}
                toItemDetail={toItineraryPersonalDetail}
                sortInitialColumn="date"
                searchableCols="Nama"
                defaultSearchInput={true}
                twoRowsId
              />
            </div>
          </Card>
        </div>
      )}

      {formData.travellerUidList && formData.travellerUidList.length > 0 && (
        <div>
          <h4>Itinerary telah di-assign atau discan oleh User:</h4>
          {formData.travellerUidList.map((uid) => (
            <p key={uid}>{uid}</p>
          ))}
        </div>
      )}

      <FormGroup className="form-row my-4">
        <Button
          className="btn-success-gradien mr-2"
          type="submit"
          onClick={saveItineraryGroup}
        >
          Simpan
        </Button>

        <Button
          className="btn-secondary-gradien mr-2"
          type="button"
          onClick={toggle}
        >
          Hapus
        </Button>
      </FormGroup>
    </Fragment>
  );
};

export default ItineraryGroupDetail;
