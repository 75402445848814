import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import idLocale from "moment/locale/id";
import firebase from "../../data/firebase";
import LinkButton from "../common/LinkButton";

const BlogDetail = () => {
  let location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  let { id } = useParams();

  const [blogData, setBlogData] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [newBlog, setNewBlog] = useState(true);
  const [loading, setLoading] = useState(true);

  moment.locale("id", idLocale);
  const [focused, setFocused] = useState({ focused: false });
  const [date, setDate] = useState(moment());

  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [foto, setFoto] = useState({});
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: false,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      var file = acceptedFiles[0];
      const fileName = uuidv4();
      ref
        .child("blogImage/" + fileName)
        .put(file)
        .then(() => {
          ref
            .child("blogImage/" + fileName)
            .getDownloadURL()
            .then((url) => {
              var photo = Object.assign(file, {
                preview: url,
              });
              setFoto(photo);
              setPhotoUploadFinished(true);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("blogs")
          .doc(id)
          .get();
        if (response.exists) {
          data = response.data();
          if (data["content"]) {
            var content = htmlToDraft(data["content"]);
            const contentState = ContentState.createFromBlockArray(
              content.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);

            setDate(moment(data["date"].toDate()));
          }
        }
        setBlogData(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    if (id !== "new") {
      fetchData();
      setNewBlog(false);
    } else {
      setBlogData("");
      setNewBlog(true);
      setLoading(false);
    }
  }, [location]);

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      var ref = firebase.storage().ref();
      var photoObject = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      const fileName = uuidv4();
      ref
        .child("blogImage/" + fileName)
        .put(file)
        .then(() => {
          ref
            .child("blogImage/" + fileName)
            .getDownloadURL()
            .then((url) => {
              resolve({ data: { link: url } });
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const deleteBlog = () => {
    firebase
      .firestore()
      .collection("blogs")
      .doc(id)
      .delete()
      .then(() => {
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/blogs/blog-list`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSubmit = (data) => {
    // replace everything but numbers and text
    var blogId =
      id === "new" ? data["title"].replace(/[^0-9|a-z|A-Z]/g, "") : id;
    firebase
      .firestore()
      .collection("blogs")
      .doc(blogId)
      .set(
        {
          title: data["title"],
          author: data["author"],
          date: new Date(date),
          img: foto["preview"] ? foto["preview"] : blogData["img"],
          excerpt: data["excerpt"],
          content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        },
        { merge: true }
      )
      .then(() => {
        toast.success("Berhasil disimpan", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/blogs/blog-list`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>
        <ModalBody>Hapus Blog?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteBlog}>
            Ya
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Card className="mt-4">
          <CardHeader>
            <h2>Blog Detail</h2>
          </CardHeader>
          <CardBody>
            <div>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                {!newBlog && (
                  <Row className="mb-5">
                    <Col>
                      <LinkButton
                        title="Lihat Halaman Blog"
                        url={`https://getgoing.co.id/blog/${id}`}
                      />
                    </Col>
                  </Row>
                )}
                <FormGroup>
                  <Label className="col-form-label pt-0">
                    Judul <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="title"
                    innerRef={register({ required: true })}
                    defaultValue={blogData.title}
                  />
                  <span style={{ color: "red" }}>
                    {errors.title && "Harus diisi"}
                  </span>
                </FormGroup>
                <Label className="col-form-label pt-0">
                  Foto Untuk Halaman Utama Blog
                  <span className="text-danger">*</span>
                </Label>

                <div {...getRootProps({ className: "dropzone dz-clickable" })}>
                  <input {...getInputProps()} />
                  {newBlog ? (
                    <div className="dz-message text-muted">
                      <p>Klik untuk upload foto (max 1)</p>
                      <img
                        src={foto["preview"] && foto["preview"]}
                        alt={blogData.title}
                        className="avatar avatar-large mb-2"
                      />
                      {!photoUploadFinished && (
                        <div className="loader-box">
                          <div className="loader-3"></div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Input
                        type="text"
                        name="photo"
                        defaultValue={blogData.img}
                        disabled
                      />
                      <div className="dz-message text-muted">
                        <p>Klik untuk ubah foto dan upload foto baru (max 1)</p>
                        <img
                          src={foto["preview"] ? foto["preview"] : blogData.img}
                          alt={blogData.title}
                          className="avatar avatar-large mb-2"
                        />
                        {!photoUploadFinished && (
                          <div className="loader-box">
                            <div className="loader-3"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <FormGroup>
                  <Label className="col-form-label pt-3">
                    Ringkasan/Excerpt <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className="form-control"
                    type="textarea"
                    rows="5"
                    name="excerpt"
                    innerRef={register({ required: true })}
                    defaultValue={blogData.excerpt}
                  />
                  <span style={{ color: "red" }}>
                    {errors.excerpt && "Harus diisi"}
                  </span>
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    Penulis <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="author"
                    innerRef={register({ required: true })}
                    defaultValue={blogData.author}
                  />
                  <span style={{ color: "red" }}>
                    {errors.author && "Harus diisi"}
                  </span>
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    Tanggal Penulisan <span className="text-danger">*</span>
                  </Label>
                  <div className="d-block">
                    <SingleDatePicker
                      date={date}
                      onDateChange={(date) => setDate(date)}
                      focused={focused.focused}
                      onFocusChange={(focused) => setFocused(focused)}
                      id="date"
                      placeholder="Tanggal"
                      numberOfMonths={1}
                      orientation="horizontal"
                      isOutsideRange={() => false}
                      required
                    />
                  </div>
                </FormGroup>

                <Label className="col-form-label">
                  Konten <span className="text-danger">*</span>
                </Label>

                <Editor
                  toolbar={{
                    image: {
                      uploadCallback: uploadCallback,
                      previewImage: true,
                      alt: { present: true, mandatory: false },
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    },
                  }}
                  editorState={editorState}
                  wrapperClassName="blog-editor-wrapper"
                  editorClassName="blog-editor"
                  onEditorStateChange={onEditorStateChange}
                />

                <FormGroup className="form-row mt-5 mb-0">
                  <Button
                    color="mr-2"
                    type="submit"
                    className="btn-success-gradien mr-2"
                    disabled={!photoUploadFinished}
                  >
                    Simpan Blog
                  </Button>
                  {!newBlog && (
                    <Button
                      color="mr-2"
                      type="button"
                      className="btn-secondary-gradien mr-2"
                      onClick={toggle}
                    >
                      Hapus Blog
                    </Button>
                  )}
                </FormGroup>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default BlogDetail;
