import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import firebase from "../../data/firebase";
import MaterialUiTable from "../common/MaterialUiTable";
import { formatDate, formatDateTime } from "../common/TableUtils";

const BookingFormList = () => {
  const history = useHistory();
  const [bookingList, setBookingList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("bookingFormList")
          .get();
        var responseData = response.data();
        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          const clientDetail = value.clientDetail;
          const status =
            value.status && value.status.length > 0
              ? value.status[value.status.length - 1].status
              : "";
          const date =
            typeof value.date === "string" || value.date instanceof Date
              ? moment(value.date).format("DD MMM YYYY")
              : moment(value.date.toDate()).format("DD MMM YYYY");
          data.push({
            id: key,
            nameEmail: clientDetail.name + "," + clientDetail.email,
            phone: clientDetail.phoneNumber,
            productType: value.productType,
            date,
            totalGuests: value.totalGuests,
            customerService: value.customerService ? value.customerService : "",
            status,
            createdAt: value.createdAt ? value.createdAt : "",
          });
        }
        setBookingList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "nameEmail",
      label: "NAMA, EMAIL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var name = value.split(",")[0];
          var email = value.split(",")[1];
          return (
            <div>
              <p className="my-0 font-weight-bold">{name}</p>
              <p className="my-0">{email}</p>
            </div>
          );
        },
        filter: false,
      },
    },
    {
      name: "productType",
      label: "SERVICE",
    },
    {
      name: "date",
      label: "TANGGAL DATANG",
    },
    {
      name: "totalGuests",
      label: "PAX",
    },
    {
      name: "customerService",
      label: "CS",
    },
    {
      name: "status",
      label: "STATUS",
    },

    {
      name: "createdAt",
      label: "WAKTU PESAN",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <React.Fragment>{formatDateTime(value)}</React.Fragment>;
        },
        filter: false,
      },
    },
  ];

  const toBookingDetail = (id) => {
    history.push(
      `${process.env.PUBLIC_URL}/bookings/booking-form-detail/${id}`
    );
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Booking</h5>
            <p className="text-muted">(total {bookingList.length})</p>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={bookingList}
            toItemDetail={toBookingDetail}
            sortInitialColumn="createdAt"
            initialColDirection="desc"
            searchableCols="Nama, Service, Status"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default BookingFormList;
