import React, { useState, useEffect } from "react";
import { Row, Col, Input, Label } from "reactstrap";
const OpeningHoursInput = (props) => {
  const { value, setValue } = props;
  const [addInput, setAddInput] = useState(false);

  const [unknown, setUnknown] = useState(value === "unknown");
  const [closed, setClosed] = useState(value === "closed");
  const [allDay, setAllDay] = useState(value === "allDay");
  const [valueArr, setValueArr] = useState([
    ["", ""],
    ["", ""],
  ]);

  const onChange = (e, idx1, idx2) => {
    const v = e.target.value;
    const tempValue = valueArr;
    tempValue[idx1][idx2] = v;
    setValue(formatValueArr(tempValue));
  };

  const toggleClosed = () => {
    setClosed(!closed);

    if (!closed) {
      setValueArr([
        ["", ""],
        ["", ""],
      ]);
    }

    setValue("closed");
  };

  const toggleAddInput = () => {
    setAddInput(!addInput);

    const firstPart = value ? value.split(",")[0] : "";

    if (!addInput) {
      const updateValueArr = [valueArr[0], []];
      setValueArr(updateValueArr);
    }

    setValue(firstPart);
  };

  const toggleUnknown = () => {
    setUnknown(!unknown);

    if (!unknown) {
      setValueArr([
        ["", ""],
        ["", ""],
      ]);
    }

    setValue("unknown");
  };

  const toggleAllDay = () => {
    setAllDay(!allDay);

    if (!allDay) {
      setValueArr([
        ["", ""],
        ["", ""],
      ]);
    }

    setValue("allDay");
  };

  const formatValueArr = (arr) => {
    var stringValue = arr[0][0] + "-" + arr[0][1];
    if (arr[1][0]) {
      stringValue += "," + arr[1][0] + "-" + arr[1][1];
    }
    return stringValue;
  };

  const formatValue = (value) => {
    const arr = [
      ["", ""],
      ["", ""],
    ];
    if (value && (value !== "closed" || value !== "unknown")) {
      const valueSplit = value.split(",");

      valueSplit.forEach((el, idx) => {
        const timeSplit = el.split("-");
        const open = timeSplit[0];
        const closed = timeSplit[1];
        arr[idx][0] = open;
        arr[idx][1] = closed;
      });

      if (arr[1][0] && arr[1][1]) {
        setAddInput(true);
      }
    }
    setValueArr(arr);
  };

  useEffect(() => {
    formatValue(value);
  }, [value]);

  return (
    <Row>
      {!closed && !unknown && !allDay && (
        <Col lg="10">
          <Row>
            <Col lg="3">
              <Input
                className="form-control"
                type="time"
                name="openingHours0Open"
                defaultValue={valueArr[0][0]}
                onChange={(e) => onChange(e, 0, 0)}
              />
            </Col>
            <h3>-</h3>
            <Col lg="3">
              <Input
                className="form-control"
                type="time"
                name="openingHours0Close"
                defaultValue={valueArr[0][1]}
                onChange={(e) => onChange(e, 0, 1)}
              />
            </Col>
            <Col lg="2">
              <Label>
                <Input
                  type="checkbox"
                  id="addInput"
                  name="addInput"
                  value={addInput}
                  checked={addInput}
                  onChange={toggleAddInput}
                />
                Tambah Input
              </Label>
            </Col>
          </Row>
        </Col>
      )}
      {addInput && !closed && !unknown && !allDay && (
        <Col lg="10">
          <Row>
            <Col lg="3">
              <Input
                className="form-control"
                type="time"
                name="openingHours1Open"
                defaultValue={valueArr[1][0]}
                onChange={(e) => onChange(e, 1, 0)}
              />
            </Col>
            <h3>-</h3>
            <Col lg="3">
              <Input
                className="form-control"
                type="time"
                name="openingHours2Close"
                defaultValue={valueArr[1][1]}
                onChange={(e) => onChange(e, 1, 1)}
              />
            </Col>
          </Row>
        </Col>
      )}
      <Col>
        {!closed && !unknown && (
          <Label>
            <Input
              type="checkbox"
              id="allDay"
              name="allDay"
              value={allDay}
              checked={allDay}
              onChange={toggleAllDay}
            />
            Buka 24 Jam
          </Label>
        )}
        {!unknown && !allDay && (
          <Label>
            <Input
              type="checkbox"
              id="closed"
              name="closed"
              value={closed}
              checked={closed}
              onChange={toggleClosed}
            />
            Tutup
          </Label>
        )}
        {!closed && !allDay && (
          <Label>
            <Input
              type="checkbox"
              id="unknown"
              name="unknown"
              value={unknown}
              checked={unknown}
              onChange={toggleUnknown}
            />
            Info tidak tersedia
          </Label>
        )}
      </Col>
    </Row>
  );
};

export default OpeningHoursInput;
