import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Media, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Avatar from "../helper/Avatar";
import FalconLightBox from "../helper/FalconLightBox";
import LightBoxGallery from "../helper/LightBoxGallery";
import Flex from "../helper/Flex";
import ChatMessageOptions from "./ChatMessageOptions";
import { isIterableArray } from "../helper/utils";
import { ChatContext } from "../helper/Context";

import { useAuth } from "../../../services/auth-context";

const formatDate = (date) => {
  const options = {
    weekday: "short",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const now = date
    .toLocaleString("en-US", options)
    .split(",")
    .map((item) => item.trim());

  return {
    day: now[0],
    hour: now[3],
    date: now[1] + ", " + now[2],
  };
};

const Message = ({ message, senderUserId, status, time, isGroup }) => {
  const auth = useAuth();
  const currentUserId = auth.user.uid;
  const { users, loading } = useContext(ChatContext);
  const user = users.find(
    ({ id }) => id.toString() === senderUserId || id === senderUserId
  );
  const name = user?.fullName.split(" ")[0];
  const isLeft =
    senderUserId.toString() !== currentUserId && senderUserId !== currentUserId;

  return (
    <Media className={classNames("p-3", { "d-block": !isLeft })}>
      {isLeft && user && <Avatar size="l" className="mr-2" src={user.avatar} />}
      <Media
        body
        className={classNames({ "d-flex  justify-content-end": !isLeft })}
      >
        <div
          className={classNames("w-xxl-75", {
            "w-100": !isLeft,
          })}
        >
          <Flex
            align="center"
            className={classNames("hover-actions-trigger", {
              "justify-content-end": !isLeft,
            })}
          >
            {/* {!isLeft && <ChatMessageOptions />} */}
            {isIterableArray(message?.attachment) ? (
              <div className="chat-message chat-gallery">
                {message.text && (
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: message.text ? message.text : message,
                    }}
                  />
                )}
                <LightBoxGallery images={message.attachment}>
                  {(openImgIndex) => (
                    <Row noGutters className="mx-n1">
                      {message.type === "image" &&
                        message.attachment.map((img, index) => {
                          return (
                            <Col
                              xs={6}
                              md={4}
                              className="px-1"
                              style={{ minWidth: 50 }}
                              key={index}
                            >
                              <img
                                className="img-fluid rounded mb-2 cursor-pointer"
                                src={img}
                                alt=""
                                onClick={() => openImgIndex(index)}
                              />
                            </Col>
                          );
                        })}

                      {message.type === "file" &&
                        message.attachment.map((uri, index) => {
                          return (
                            <Col
                              xs={6}
                              md={4}
                              className="px-1"
                              style={{ minWidth: 50 }}
                              key={index}
                            >
                              <a href={uri}>{uri}</a>
                            </Col>
                          );
                        })}
                    </Row>
                  )}
                </LightBoxGallery>
              </div>
            ) : (
              <>
                <div
                  className={classNames("ml-3 p-2 rounded-soft chat-message", {
                    "bg-light text-dark": isLeft,
                    "bg-primary text-white": !isLeft,
                  })}
                >
                  {message && (
                    <p
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: message.text ? message.text : message,
                      }}
                    />
                  )}
                  {message && message.attachment && (
                    <FalconLightBox imgSrc={message.attachment}>
                      <img
                        src={message.attachment}
                        className="img-fluid rounded"
                        width={150}
                        alt=""
                      />
                    </FalconLightBox>
                  )}
                </div>
              </>
            )}
            {/* {isLeft && <ChatMessageOptions />} */}
          </Flex>
          <div
            className={classNames("message-time-right", {
              "text-right": !isLeft,
            })}
          >
            {isLeft && isGroup && (
              <span className="font-weight-semi-bold mr-2">{name}</span>
            )}
            {time.hour}
            {!isLeft && !!message && !!status && (
              <FontAwesomeIcon
                icon={classNames({
                  check: status === "seen" || status === "sent",
                  "check-double": status === "delivered",
                })}
                transform="shrink-5 down-4"
                className={classNames("ml-2", {
                  "text-success": status === "seen",
                })}
              />
            )}
          </div>
        </div>
      </Media>
    </Media>
  );
};
Message.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  senderUserId: PropTypes.string.isRequired,
  status: PropTypes.string,
  time: PropTypes.object.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

Message.defaultProps = { status: "" };

export default Message;
