import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Row, Button, Col, CardBody } from "reactstrap";
import { Plus, Users } from "react-feather";
import { useHistory } from "react-router-dom";
import moment from "moment";
import firebase from "../../data/firebase";
import { upperCaseFirst } from "../../data/helper";
import MaterialUiTable from "../common/MaterialUiTable";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Todo from "../../assets/images/svg/task-list-square-add-24.svg";
import Review from "../../assets/images/svg/baseline-rate-review.svg";
import Done from "../../assets/images/svg/checkmark-done-circle.svg";
import InProgress from "../../assets/images/svg/in-progress.svg";

const ProjectManagementOverview = () => {
  const history = useHistory();
  const [channelList, setChannelList] = useState([]);
  const [loading, setLoading] = useState(true);

  const countTasks = (tasks, type) => {
    if (type === "all") {
      return tasks.length;
    }
    return tasks.filter((t) => t.status === type).length;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        var channels = [];
        const response = await firebase
          .firestore()
          .collection("projectManagement")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.id !== "channels") {
                var taskList = [];
                const tasks = doc.data();

                Object.keys(tasks).forEach((key) => {
                  taskList.push({
                    title: key,
                    status: tasks[key].status,
                  });
                });
                var channel = doc.id;
                channels.push({
                  id: channel,
                  channel: "#" + upperCaseFirst(channel),
                  all: countTasks(taskList, "all"),
                  todo: countTasks(taskList, "Todo"),
                  inProgress: countTasks(taskList, "In Progress"),
                  review: countTasks(taskList, "Review"),
                  done: countTasks(taskList, "Done"),
                  cancelled: countTasks(taskList, "Cancelled"),
                  postponed: countTasks(taskList, "Postponed"),
                });
              }
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });

        setChannelList(channels);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "channel",
      label: "CHANNEL",
      options: {
        filter: false,
      },
    },
    {
      name: "todo",
      label: "TODO",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
          <th
            key={2}
            onClick={() => handleToggleColumn(2)}
            style={{ cursor: "pointer", backgroundColor: "#f4f2ff" }}
          >
            <CustomHeader columnMeta={columnMeta} sortOrder={sortOrder} />
          </th>
        ),
      },
    },
    {
      name: "inProgress",
      label: "IN PROGRESS",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
          <th
            key={3}
            onClick={() => handleToggleColumn(3)}
            style={{ cursor: "pointer", backgroundColor: "#f4f2ff" }}
          >
            <CustomHeader columnMeta={columnMeta} sortOrder={sortOrder} />
          </th>
        ),
      },
    },
    {
      name: "review",
      label: "REVIEW",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
          <th
            key={4}
            onClick={() => handleToggleColumn(4)}
            style={{ cursor: "pointer", backgroundColor: "#f4f2ff" }}
          >
            <CustomHeader columnMeta={columnMeta} sortOrder={sortOrder} />
          </th>
        ),
      },
    },
    {
      name: "done",
      label: "DONE",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
          <th
            key={5}
            onClick={() => handleToggleColumn(5)}
            style={{ cursor: "pointer", backgroundColor: "#f4f2ff" }}
          >
            <CustomHeader columnMeta={columnMeta} sortOrder={sortOrder} />
          </th>
        ),
      },
    },
    {
      name: "postponed",
      label: "POSTPONED",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
          <th
            key={6}
            onClick={() => handleToggleColumn(6)}
            style={{ cursor: "pointer", backgroundColor: "#f4f2ff" }}
          >
            <CustomHeader columnMeta={columnMeta} sortOrder={sortOrder} />
          </th>
        ),
      },
    },
    {
      name: "cancelled",
      label: "CANCELLED",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
          <th
            key={7}
            onClick={() => handleToggleColumn(7)}
            style={{ cursor: "pointer", backgroundColor: "#f4f2ff" }}
          >
            <CustomHeader columnMeta={columnMeta} sortOrder={sortOrder} />
          </th>
        ),
      },
    },
    {
      name: "all",
      label: "TOTAL",
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }
  const toPmDetail = (channel) => {
    history.push(`${process.env.PUBLIC_URL}/project-management/${channel}/`);
  };

  var taskCount = [0, 0, 0, 0, 0, 0];
  channelList.forEach((c) => {
    taskCount[0] += c.todo;
    taskCount[1] += c.inProgress;
    taskCount[2] += c.review;
    taskCount[3] += c.done;
    taskCount[4] += c.postponed;
    taskCount[5] += c.cancelled;
  });

  const taskCards = [
    {
      name: "todo",
      label: "Todo",
      icon: Todo,
      taskCount: taskCount[0],
    },
    {
      name: "inProgress",
      label: "In Progress",
      icon: InProgress,
      taskCount: taskCount[1],
    },
    {
      name: "review",
      label: "Review",
      icon: Review,
      taskCount: taskCount[2],
    },
    {
      name: "done",
      label: "Done",
      icon: Done,
      taskCount: taskCount[3],
    },
    {
      name: "postponed",
      label: "Postponed",
      icon: Todo,
      taskCount: taskCount[4],
    },
    {
      name: "cancelled",
      label: "Cancelled",
      icon: Todo,
      taskCount: taskCount[5],
    },
  ];

  return (
    <Fragment>
      <Row className="mt-5">
        {taskCards.map((card) => (
          <Col lg="4" md="6">
            <Card className="o-hidden">
              <CardBody
                className="b-r-4"
                style={{ backgroundColor: getStatusColor(card.name) }}
              >
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"></div>
                  <div className="media-body">
                    <h4 className="mb-3 counter text-white">{card.label}</h4>
                    <h6 className="m-0 text-white">{card.taskCount} tugas</h6>

                    <img src={card.icon} alt={card.name} className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Card>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Overview</h5>
            <p className="text-muted">(Total channel: {channelList.length})</p>
            <Button
              type="button"
              color="primary"
              className="px-3 button-vertical-center ml-auto mr-3"
              onClick={() => toPmDetail("new")}
            >
              <Plus /> Tambah Channel
            </Button>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={channelList}
            toItemDetail={toPmDetail}
            sortInitialColumn="channel"
            searchableCols="Channel"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default ProjectManagementOverview;

const getStatusColor = (status) => {
  switch (status) {
    case "todo":
      return "#449AEF";
    case "inProgress":
      return "#FF8831";
    case "review":
      return "#F2C94C";
    case "done":
      return "#00C950";
    case "cancelled":
      return "#DE350B";
    case "postponed":
      return "#25213B";
    default:
      return "#000000";
  }
};

const CustomHeader = (props) => {
  const { columnMeta, sortOrder } = props;
  return (
    <React.Fragment>
      <span style={{ color: getStatusColor(columnMeta.name) }}>
        <i className="fa fa-circle"></i> {columnMeta.label}{" "}
      </span>
      {sortOrder.name === columnMeta.name ? (
        sortOrder.direction === "asc" ? (
          <ArrowUpwardIcon
            style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "18px" }}
          />
        ) : (
          <ArrowDownwardIcon
            style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "18px" }}
          />
        )
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
