import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { Plus } from "react-feather";
import moment from "moment";

import AvailabilityInput from "./AvailabilityInput";

const AvailabilityComponent = (props) => {
  const { availabilityList, setAvailabilityList } = props;
  const firstInput = {};
  const [state, setState] = useState(availabilityList || []);

  const addAvailability = () => {
    setState([...state, { ...firstInput }]);
  };

  const handleChange = (e, id) => {
    const isMoment = moment.isMoment(e);
    const updatedState = [...state];
    var fieldName = "";
    var value = "";

    if (!isMoment) {
      var className = e.target.className;
      if (className === "fromTime form-control") {
        fieldName = "fromTime";
      } else if (className === "toTime form-control") {
        fieldName = "toTime";
      }
      value = e.target.value;
      id = e.target.dataset.id;
    } else {
      fieldName = "date";
      value = e.toDate();
      id = id;
    }

    updatedState[id][fieldName] = value;
    setState(updatedState);
    setAvailabilityList(updatedState);
  };

  const deleteInput = (id) => {
    const updatedState = [...state];
    updatedState.splice(id, 1);
    setState(updatedState);
    setAvailabilityList(updatedState);
  };

  return (
    <Col className="ml-auto">
      {state &&
        state.map((val, idx) => (
          <AvailabilityInput
            key={`availability-${idx}`}
            idx={idx}
            state={state}
            handleChange={handleChange}
            deleteInput={deleteInput}
          />
        ))}

      <Button
        type="button"
        color="primary"
        className="btn-info-gradien mr-2 px-3 button-vertical-center"
        onClick={addAvailability}
      >
        <Plus /> Tambah Availability
      </Button>
    </Col>
  );
};

export default AvailabilityComponent;
