import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import firebase from "../../data/firebase";
import SelectCountryCity from "../common/SelectCountryCity";
import FileDownload from "../common/FileDownload";
import LinkButton from "../common/LinkButton";

const GetStayDetail = () => {
  let { id } = useParams();

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const amenitiesMap = {
    bathroom: "Perlangkapan Mandi",
    wifi: "Wifi",
    kitchen: "Perlangkapan Dapur",
    heating: "Pemanas Ruangan",
    washingMachine: "Mesin cuci",
    ac: "AC",
    tv: "TV",
  };

  const [getStayData, setGetStayData] = useState({});
  const [userList, setUserList] = useState({});
  const [loading, setLoading] = useState(true);
  const [newService, setNewService] = useState(true);
  const [formData, setFormData] = useState({});

  const [checkboxInput, setCheckboxInput] = useState({
    amenities: {
      bathroom: false,
      wifi: false,
      kitchen: false,
      heating: false,
      washingMachine: false,
      ac: false,
      tv: false,
    },
  });

  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: true,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotos([]);
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      Promise.all(
        acceptedFiles.map((file) => {
          const fileName = uuidv4();
          ref
            .child("getStayImage/" + fileName)
            .put(file)
            .then(() => {
              ref
                .child("getStayImage/" + fileName)
                .getDownloadURL()
                .then((url) => {
                  file["preview"] = url;
                  setPhotos((photos) => [...photos, file]);
                  return setPhotoUploadFinished(true);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
    },
  });

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        var swiperPhotos = [];
        const response = await firebase
          .firestore()
          .collection("getStay")
          .doc(id)
          .get();
        if (response.exists) {
          data = response.data();
          setCheckboxInput({
            amenities: data.amenities,
          });
          data.swiper.forEach((swiper) => {
            swiperPhotos.push({ preview: swiper.img, name: swiper.title });
          });
        }
        setGetStayData(data);
        setPhotos(swiperPhotos);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchUserList = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("userList")
          .get();
        if (response.exists) {
          data = response.data();
        }
        setUserList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    if (id !== "new") {
      setNewService(false);
      fetchData();
    } else {
      fetchUserList();
    }
  }, []);

  const getUserOption = () => {
    var userOption = [];
    Object.keys(userList).forEach((keyName, i) => {
      userOption.push({
        value: keyName,
        label:
          userList[keyName]["displayName"] +
          " | " +
          userList[keyName]["city"] +
          " | " +
          keyName,
      });
    });
    return userOption;
  };

  const setSelectedCountry = (country) => {
    setFormData({ ...formData, country: country });
  };

  const setSelectedCity = (city) => {
    setFormData({ ...formData, city: city });
  };

  const onSelectChange = (name, e) => {
    setFormData({ ...formData, [name]: e.value });
  };

  const onCheckboxChange = (e) => {
    const value = e.target.value;
    checkboxInput["amenities"][e.target.id] = !value;
    setCheckboxInput({ ...checkboxInput });
  };

  const deleteService = () => {
    firebase
      .firestore()
      .collection("getStay")
      .doc(id)
      .delete()
      .then(() => {
        history.push(`${process.env.PUBLIC_URL}/services/getstay-list`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    var swiper = [];
    var tags = {};
    var coordinates = [];
    var docId = "";

    if (data !== "") {
      tags = {
        bathrooms: data["bathrooms"],
        bedrooms: data["bedrooms"],
        beds: data["beds"],
        guests: data["guests"],
      };
      coordinates[0] = data["coordinatesLat"];
      coordinates[1] = data["coordinatesLong"];

      var city = formData["city"] ? formData["city"] : getStayData["city"];
      var country = formData["country"]
        ? formData["country"]
        : getStayData["country"];
      var slug = "getstay-" + city + "-" + data["title"].replace(/ /g, "-");

      var description = data["description"].replace(/\r\n|\r|\n/g, "<br />");

      if (newService) {
        docId = uuidv4();
        var author = userList[formData["author"]];
        author = {
          avatar: author["avatar"],
          fullName: author["fullName"],
          displayName: author["displayName"],
          uid: formData["author"],
        };
      } else {
        docId = id;
        author = {
          avatar: getStayData.author.avatar,
          fullName: getStayData.author.fullName,
          displayName: getStayData.author.displayName,
          uid: getStayData.author.uid,
        };
      }
      photos.forEach((photo) => {
        swiper.push({
          img: photo["preview"],
          title: photo["name"],
        });
      });

      firebase
        .firestore()
        .collection("getStay")
        .doc(docId)
        .set(
          {
            author,
            address: data["address"],
            additionalAddress: data["additionalAddress"],
            zip: data["zip"],
            city,
            country,
            coordinates,
            category: data["category"],
            amenities: checkboxInput["amenities"],
            tags: tags,
            title: data["title"],
            description,
            price: data["price"],
            slug,
            swiper,
          },
          { merge: true }
        )
        .then(() => {
          firebase
            .firestore()
            .collection("aggregateList")
            .doc("getStayList")
            .update({
              [docId]: {
                authorName: author.fullName,
                authorAvatar: author.avatar,
                country,
                city,
                title: data["title"],
              },
            })
            .then(function () {
              toast.success("Berhasil disimpan", {
                position: toast.POSITION.TOP_CENTER,
              });
              history.push(`${process.env.PUBLIC_URL}/services/getstay-list`);
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Modal isOpen={deleteModal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

          <ModalFooter>
            <ModalBody>Hapus GetStay? </ModalBody>
            <Button
              className="btn-success-gradien mr-2"
              onClick={deleteService}
            >
              Ya
            </Button>{" "}
            <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
              Batal
            </Button>
          </ModalFooter>
        </Modal>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2 className="mb-5">GetStay</h2>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  {newService ? (
                    <div>
                      <FormGroup>
                        <Label className="col-form-label pt-0">
                          Partner<span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="author"
                          instanceId="author"
                          name="author"
                          options={getUserOption()}
                          isSearchable
                          onChange={(e) => onSelectChange("author", e)}
                          input
                        />
                      </FormGroup>
                    </div>
                  ) : (
                    <div>
                      <Row>
                        <Col lg="3">
                          <div>
                            <img
                              src={
                                getStayData.author && getStayData.author.avatar
                              }
                              alt=""
                              className="avatar avatar-xxl avatar-square mb-4"
                            ></img>
                          </div>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <div className="col-form-label pt-0 pb-0">
                              <h2>
                                {" "}
                                {getStayData.author &&
                                  getStayData.author.fullName}{" "}
                                (
                                {getStayData.author &&
                                  getStayData.author.displayName}
                                )
                              </h2>
                            </div>
                          </FormGroup>
                          <Row>
                            <Col lg="6">
                              <LinkButton
                                title="Lihat Halaman Service"
                                url={`https://getgoing.co.id/getstay/${getStayData.slug}?id=${id}`}
                              />
                            </Col>
                            <Col lg="6">
                              <LinkButton
                                title="Lihat Profil Partner"
                                url={`https://getgoing.co.id/user-profile/${getStayData.author.uid}`}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg="1"></Col>
                      </Row>
                    </div>
                  )}
                  <FormGroup>
                    <Label className="col-form-label">
                      Alamat<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="address"
                      innerRef={register({ required: true })}
                      defaultValue={getStayData.address}
                    />
                    <span style={{ color: "red" }}>
                      {errors.address && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Alamat Tambahan </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="additionalAddress"
                      innerRef={register({ required: false })}
                      defaultValue={getStayData.additionalAddress}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      ZIP<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="zip"
                      innerRef={register({ required: true })}
                      defaultValue={getStayData.zip}
                    />
                    <span style={{ color: "red" }}>
                      {errors.zip && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <SelectCountryCity
                    userCountry={getStayData.country}
                    userCity={getStayData.city}
                    selectedCountry={setSelectedCountry}
                    selectedCity={setSelectedCity}
                  />
                  <FormGroup>
                    <Label className="col-form-label">
                      Koordinat<span className="text-danger">*</span>
                      {"  "}
                      <span>
                        <a
                          href="https://www.latlong.net/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Cari Koordinat
                        </a>
                      </span>
                    </Label>
                    <Row>
                      <Col lg="3">
                        <Input
                          className="form-control"
                          type="text"
                          name="coordinatesLat"
                          innerRef={register({ required: true })}
                          defaultValue={
                            getStayData.coordinates &&
                            getStayData.coordinates[1]
                          }
                        />

                        <span style={{ color: "red" }}>
                          {errors.coordinatesLat && "Harus diisi"}
                        </span>
                      </Col>
                      <Col lg="3">
                        <Input
                          className="form-control"
                          type="text"
                          name="coordinatesLong"
                          innerRef={register({ required: true })}
                          defaultValue={
                            getStayData.coordinates &&
                            getStayData.coordinates[0]
                          }
                        />

                        <span style={{ color: "red" }}>
                          {errors.coordinatesLong && "Harus diisi"}
                        </span>
                      </Col>
                    </Row>
                    <span style={{ color: "red" }}>
                      {errors.coordinates && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Judul <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="title"
                      innerRef={register({ required: true })}
                      defaultValue={getStayData.title}
                    />
                    <span style={{ color: "red" }}>
                      {errors.title && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Kategori<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="category"
                      innerRef={register({ required: true })}
                      defaultValue={getStayData.category}
                    />
                    <span style={{ color: "red" }}>
                      {errors.category && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Deskripsi <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="description"
                      innerRef={register({ required: true })}
                      defaultValue={
                        getStayData.description &&
                        getStayData.description.replace("</br>", "\n")
                      }
                    />
                    <span style={{ color: "red" }}>
                      {errors.description && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Harga dalam RUPIAH<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="price"
                      innerRef={register({ required: true })}
                      defaultValue={getStayData.price}
                    />
                    <span style={{ color: "red" }}>
                      {errors.price && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-4">
                        <Label className="col-form-label">
                          Jumlah tamu <span className="text-danger">*</span>
                        </Label>

                        <Input
                          type="number"
                          name="guests"
                          id="guests"
                          min="1"
                          max="20"
                          defaultValue={
                            getStayData.tags ? getStayData.tags.guests : "1"
                          }
                          innerRef={register({ required: true })}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup className="mb-4">
                        <Label className="col-form-label">
                          Jumlah kamar tidur{" "}
                          <span className="text-danger">*</span>
                        </Label>

                        <Input
                          type="number"
                          name="bedrooms"
                          id="bedrooms"
                          min="1"
                          max="20"
                          defaultValue={
                            getStayData.tags ? getStayData.tags.bedrooms : "1"
                          }
                          innerRef={register({ required: true })}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup className="mb-4">
                        <Label className="col-form-label">
                          Jumlah tempat tidur{" "}
                          <span className="text-danger">*</span>
                        </Label>

                        <Input
                          type="number"
                          name="beds"
                          id="beds"
                          min="1"
                          max="20"
                          defaultValue={
                            getStayData.tags ? getStayData.tags.beds : "1"
                          }
                          innerRef={register({ required: true })}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup className="mb-4">
                        <Label className="col-form-label">
                          Jumlah kamar mandi{" "}
                          <span className="text-danger">*</span>
                        </Label>

                        <Input
                          type="number"
                          name="bathrooms"
                          id="bathrooms"
                          min="1"
                          max="20"
                          defaultValue={
                            getStayData.tags ? getStayData.tags.bathrooms : "1"
                          }
                          innerRef={register({ required: true })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label className="form-label">
                      Kelengkapan / Amenities{" "}
                      <span className="text-danger">*</span>
                    </Label>
                    {Object.keys(amenitiesMap).map((keyName, i) => (
                      <FormGroup check key={i}>
                        <Label check>
                          <Input
                            type="checkbox"
                            id={keyName}
                            key={keyName}
                            name={keyName}
                            value={checkboxInput["amenities"][keyName] || ""}
                            defaultChecked={
                              getStayData.amenities
                                ? getStayData.amenities[keyName]
                                : false
                            }
                            onChange={(e) => onCheckboxChange(e)}
                          />{" "}
                          {amenitiesMap[keyName]}
                        </Label>
                      </FormGroup>
                    ))}
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label pt-0">Foto Galeri</Label>
                    <Row className="ml-n1 mr-n1">
                      {photos &&
                        photos.map((file, index) => (
                          <Col key={index} lg="4" xs="6" className="px-1 mb-2">
                            <img
                              src={file.preview}
                              alt={file.preview}
                              className="img-fluid"
                            />

                            <FileDownload url={file.preview} />
                          </Col>
                        ))}
                    </Row>
                    <div
                      {...getRootProps({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps()} />

                      <div className="dz-message text-muted">
                        <p>Upload file-file baru (minimal 2)</p>
                        {!newService && (
                          <p>Foto-foto lama akan diganti dengan foto baru</p>
                        )}
                        {!photoUploadFinished && (
                          <div className="loader-box">
                            <div className="loader-3"></div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* {uploadError &&
                                        <p className="text-danger">Harap upload min. 2, maks. 10 fotomu.</p>
                                    } */}
                  </FormGroup>

                  <FormGroup className="form-row mt-3 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      disabled={!photoUploadFinished}
                    >
                      Simpan
                    </Button>
                    {!newService && (
                      <Button
                        type="button"
                        onClick={toggle}
                        color="mr-2"
                        className="btn-secondary-gradien mr-2"
                      >
                        Hapus Layanan
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default GetStayDetail;
