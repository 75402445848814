import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Collapse,
  Card,
  Button,
  CardBody,
  CardFooter,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import firebase from "../../data/firebase";
import { upperCaseFirst } from "../../data/helper";

const SelectCountryCity = (props) => {
  const { userCity, freeCountryCityInput, selectedCountry, selectedCity } =
    props;
  const [formData, setFormData] = useState({});
  const [countryCityOption, setCountryCityOption] = useState([]);
  const [otherCountryOpen, setOtherCountryOpen] = useState(false);
  const [otherCityOpen, setOtherCityOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cityOptions, setCityOptions] = useState([]);
  const [userCountry, setUserCountry] = useState(props.userCountry);
  const [countryOrCityExist, setCountryOrCityExist] = useState(true);

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("countryCityList")
          .get();

        var cityOptions = [];
        if (response.exists) {
          data = response.data();
          // if (props.newCity) {
          //   data["otherCountry"] = ["otherCity"];
          // }

          if (props.userCountry && data[props.userCountry]) {
            data[props.userCountry].forEach((cityStr) => {
              var city = cityStr.split(",")[0];
              cityOptions.push({ value: city, label: upperCaseFirst(city) });
            });
            if (props.newCity) {
              cityOptions.push({ value: "otherCity", label: "otherCity" });
            }
          }

          if (props.listingForm) {
            // if city / country doesn't exist -> create new city first
            if (!data[props.userCountry]) {
              setCountryOrCityExist(false);
            } else if (!data[props.userCountry].includes(userCity)) {
              setCountryOrCityExist(false);
            } else {
              setCountryOrCityExist(true);
            }
          }
        }
        setCountryCityOption(data);

        if (freeCountryCityInput) {
          if (userCity) {
            cityOptions.push({ value: userCity, label: userCity });
          }
          cityOptions.push({ value: "otherCity", label: "Kota Lain" });
        }

        setCityOptions(cityOptions);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const history = useHistory();
  const createCity = () => {
    history.push(`${process.env.PUBLIC_URL}/country/city-detail/new/new`);
  };

  if (loading) {
    return "loading...";
  }

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData({ ...formData, [name]: value });
    if (name === "otherCountry") {
      selectedCountry(value);
    }
    if (name === "otherCity") {
      selectedCity(value);
    }
  };

  const sortKeys = (data) => {
    const sorted = Object.keys(data)
      .sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      })
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    return sorted;
  };

  const getCountryOption = () => {
    var countryCityObj = countryCityOption;
    var countries = [];
    const sortedObject = sortKeys(countryCityObj);

    if (freeCountryCityInput) {
      if (userCountry) {
        countries.push({
          value: userCountry,
          label: upperCaseFirst(userCountry),
        });
      }

      for (const [key, value] of Object.entries(sortedObject)) {
        countries.push({ value: key, label: upperCaseFirst(key) });
      }

      countries.push({ value: "otherCountry", label: "Negara Lain" });
    } else {
      for (const [key, value] of Object.entries(sortedObject)) {
        countries.push({ value: key, label: upperCaseFirst(key) });
      }
    }

    return countries;
  };

  const handleCountryOptionChange = (e) => {
    var value = e.value;

    if (value === "otherCountry") {
      setOtherCountryOpen(true);
      setCityOptions([{ value: "otherCity", label: "Kota Lain" }]);
    } else {
      setOtherCountryOpen(false);

      var cities = countryCityOption[value];
      var cityOptions = [];
      if (cities.length > 0) {
        cities.sort();
        cities.forEach((item, index) => {
          var city = item.split(",")[0];
          cityOptions.push({ value: city, label: upperCaseFirst(city) });
        });
        if (freeCountryCityInput) {
          cityOptions.push({ value: "otherCity", label: "Kota Lain" });
        }
      }

      setCityOptions(cityOptions);
    }

    setUserCountry(value);
    selectedCountry(value);
  };

  const onSelectChange = (e) => {
    selectedCity(e.value);
    if (e.value === "otherCity") {
      setOtherCityOpen(true);
    } else {
      setOtherCityOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Col>
        <FormGroup>
          <Label className="col-form-label">
            Negara<span className="text-danger">*</span>
          </Label>
          <Row>
            <Col lg="6">
              <Select
                id="country"
                instanceId="country"
                name="country"
                options={getCountryOption()}
                onChange={handleCountryOptionChange}
                className="selectpicker"
                classNamePrefix="selectpicker"
                isSearchable
                value={getCountryOption().filter(
                  (option) => option.value === userCountry
                )}
              />
            </Col>

            {props.userOtherCountryUpdate && (
              <Col lg="6">
                <Input
                  type="text"
                  name="country"
                  defaultValue={`Negara: ${props.userOtherCountryUpdate}`}
                  disabled
                />
              </Col>
            )}
            {props.listingForm && (
              <Col lg="6">
                <Input
                  type="text"
                  name="country"
                  defaultValue={props.userCountry}
                  disabled
                />
              </Col>
            )}
          </Row>
          <Collapse isOpen={otherCountryOpen}>
            <div>
              <Label for="country" className="form-label">
                Nama Negara (Bhs. Indonesia):{" "}
                <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="otherCountry"
                id="otherCountry"
                value={formData["otherCountry"]}
                onChange={handleInputChange}
              />
            </div>
          </Collapse>
        </FormGroup>
        {!props.newCity && (
          <FormGroup>
            <Label className="col-form-label">
              Kota<span className="text-danger">*</span>
            </Label>
            <Row>
              <Col lg="6">
                <Select
                  id="city"
                  instanceId="city"
                  name="city"
                  options={cityOptions}
                  className="selectpicker"
                  classNamePrefix="selectpicker"
                  isSearchable
                  onChange={onSelectChange}
                  value={cityOptions.filter(
                    (option) => option.value === userCity
                  )}
                />
              </Col>

              {props.userOtherCityUpdate && (
                <Col lg="6">
                  <Input
                    type="text"
                    name="city"
                    defaultValue={`Kota: ${props.userOtherCityUpdate}`}
                    disabled
                  />
                </Col>
              )}

              {props.listingForm && (
                <Col lg="6">
                  <Input
                    type="text"
                    name="city"
                    defaultValue={userCity}
                    disabled
                  />
                </Col>
              )}
            </Row>
          </FormGroup>
        )}
        <Collapse isOpen={otherCityOpen || props.newCity}>
          <div>
            <Label for="country" className="form-label">
              Nama Kota (Bhs. Indonesia): <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              name="otherCity"
              id="otherCity"
              value={formData["otherCity"]}
              onChange={handleInputChange}
            />
          </div>
        </Collapse>
        {!countryOrCityExist && (
          <Card>
            <CardBody>
              Negara atau kota tidak ada di list. Harap pilih kota atau negara
              lain atau buat kota terlebih dahulu.
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                onClick={() => setCountryOrCityExist(true)}
              >
                Pilih dari list
              </Button>{" "}
              <Button color="primary" onClick={createCity}>
                Ke halaman membuat kota
              </Button>
            </CardFooter>
          </Card>
        )}
      </Col>
    </React.Fragment>
  );
};

export default SelectCountryCity;
