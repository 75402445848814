import React, { useEffect } from "react";
import Grow from "@material-ui/core/Grow";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const defaultStyles = (theme) => ({
  main: {
    display: "flex",
    flex: "1 0 auto",
  },
  searchText: {
    flex: "0.8 0",
  },
});

class _DebounceTableSearch extends React.Component {
  handleTextChangeWrapper = (debouncedSearch) => {
    return function (event) {
      debouncedSearch(event.target.value);
    };
  };

  render() {
    const { classes, options, onHide, searchText, debounceWait } = this.props;

    const debouncedSearch = debounce((value) => {
      this.props.onSearch(value);
    }, debounceWait);

    return (
      <Grow appear in={true} timeout={300}>
        <div className={classes.main}>
          <OutlinedInput
            className={classes.searchText}
            autoFocus={false}
            defaultValue={searchText}
            onChange={this.handleTextChangeWrapper(debouncedSearch)}
            fullWidth={true}
            inputRef={(el) => (this.searchField = el)}
            placeholder={options.searchPlaceholder}
            {...(options.searchProps ? options.searchProps : {})}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </div>
      </Grow>
    );
  }
}

var DebounceTableSearch = withStyles(defaultStyles, {
  name: "MUIDataTableSearch",
})(_DebounceTableSearch);
export { DebounceTableSearch };

export function debounceSearchRender(debounceWait = 200) {
  return (searchText, handleSearch, hideSearch, options) => {
    return (
      <DebounceTableSearch
        searchText={searchText}
        onSearch={handleSearch}
        onHide={hideSearch}
        options={options}
        debounceWait={debounceWait}
      />
    );
  };
}
