const formatDate = (dateString) => {
  return new Date(dateString.toDate()).toLocaleDateString("id-ID", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const formatDateTime = (dateString) => {
  if (dateString) {
    var date = dateString;
    if (!(dateString instanceof Date)) {
      date = new Date(dateString.toDate());
    }

    return date.toLocaleDateString("id-ID", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  }
  return "";
};

export { formatDate, formatDateTime };
