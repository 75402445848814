import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import * as firebaseApp from "firebase/app";
import firebase from "../../data/firebase";
import SelectCountryCity from "../common/SelectCountryCity";
import FileDownload from "../common/FileDownload";
import LinkButton from "../common/LinkButton";

const GetStayDetail = () => {
  let { id } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [deleteFormModal, setDeleteFormModal] = useState(false);
  const toggleDeleteForm = () => setDeleteFormModal(!deleteFormModal);

  const [data, setData] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);

  const amenitiesMap = {
    bathroom: "Perlangkapan Mandi",
    wifi: "Wifi",
    kitchen: "Perlangkapan Dapur",
    heating: "Pemanas Ruangan",
    washingMachine: "Mesin cuci",
    ac: "AC",
    tv: "TV",
  };

  const [checkboxInput, setCheckboxInput] = useState({
    amenities: {
      bathroom: false,
      wifi: false,
      kitchen: false,
      heating: false,
      washingMachine: false,
      ac: false,
      tv: false,
    },
  });

  const onCheckboxChange = (e) => {
    const value = e.target.value;
    checkboxInput["amenities"][e.target.id] = !value;
    setCheckboxInput({ ...checkboxInput });
  };

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: true,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotos([]);
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      Promise.all(
        acceptedFiles.map((file) => {
          const fileName = uuidv4();
          ref
            .child("getstayImage/" + fileName)
            .put(file)
            .then(() => {
              ref
                .child("getstayImage/" + fileName)
                .getDownloadURL()
                .then((url) => {
                  file["preview"] = url;
                  setPhotoUploadFinished(true);
                  return setPhotos((photos) => [...photos, file]);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
    },
  });

  useEffect(() => {
    let formData = {};
    let serviceData = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("getStayForms")
          .doc(id)
          .get();
        if (response.exists) {
          formData = response.data();
          setCheckboxInput({
            amenities: formData["amenities"],
          });
          var swiperPhotos = [];

          if (formData.action !== "delete") {
            formData.photoUrls.forEach((url) => {
              swiperPhotos.push({ preview: url, name: url });
            });
            setPhotos(swiperPhotos);
          }
          if (formData.action === "update") {
            try {
              const response2 = await firebase
                .firestore()
                .collection("getStay")
                .doc(formData.id)
                .get();
              serviceData = response2.data();
            } catch (err) {
              console.error(err);
            }
          }
        }
        setFormData(formData);
        setData(serviceData);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const setStatus = (status) => {
    var formUpdate = {};
    formUpdate[`${id}.action`] = status;

    firebase.firestore().collection("getStayForms").doc(id).update({
      action: status,
    });
    firebase
      .firestore()
      .collection("aggregateList")
      .doc("listingFormList")
      .update(formUpdate)
      .then(() => {
        console.log("success");
      });
  };

  const setSelectedCountry = (country) => {
    setFormData({ ...formData, country: country });
  };

  const setSelectedCity = (city) => {
    setFormData({ ...formData, city: city });
  };

  const deleteService = () => {
    firebase
      .firestore()
      .collection("getStay")
      .doc(formData.id)
      .delete()
      .then(() => {
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/forms/listing-form-list`);
        setStatus("archived");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteForm = () => {
    firebase
      .firestore()
      .collection("getStayForms")
      .doc(id)
      .delete()
      .then(() => {
        firebase
          .firestore()
          .collection("aggregateList")
          .doc("listingFormList")
          .update({
            [id]: firebaseApp.firestore.FieldValue.delete(),
          })
          .then(() => {
            toast.success("Berhasil dihapus", {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/forms/listing-form-list`);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const sendConfirmationEmail = (email, link) => {
    firebase
      .firestore()
      .collection("mails")
      .add({
        to: email,
        replyTo: "we@getgoing.co.id",
        template: {
          name: "listingConfirmation",
          data: {
            servicename: "GetStay",
            link: link,
          },
        },
      });
  };

  const onSubmit = (data) => {
    var swiper = [];
    var tags = {};
    var coordinates = [];
    var docId = "";
    if (data !== "") {
      tags = {
        bathrooms: data["bathrooms"],
        bedrooms: data["bedrooms"],
        beds: data["beds"],
        guests: data["guests"],
      };

      coordinates[0] = data["coordinatesLat"];
      coordinates[1] = data["coordinatesLong"];

      var city = formData["city"] ? formData["city"] : data["city"];
      var country = formData["country"] ? formData["country"] : data["country"];
      var slug = "getstay-" + city + "-" + data["title"].replace(/ /g, "-");

      if (formData.action === "create") {
        docId = uuidv4();
      } else {
        docId = formData.id;
      }

      if (formData.action === "create" || formData.action === "update") {
        if (photos && photos.length > 0) {
          photos.forEach((photo) => {
            swiper.push({
              img: photo["preview"],
              title: photo["name"],
            });
          });
        } else {
          formData.photoUrls.forEach((photoUrl) => {
            swiper.push({
              img: photoUrl,
              title: photoUrl,
            });
          });
        }

        // get user data
        firebase
          .firestore()
          .collection("users")
          .doc(formData.author.uid)
          .get()
          .then((user) => {
            var userData = user.data();
            var author = {
              avatar: userData.avatar,
              fullName: userData.fullName,
              displayName: userData.displayName,
              uid: user.id,
            };
            firebase
              .firestore()
              .collection("getStay")
              .doc(docId)
              .set(
                {
                  author,
                  address: data["address"],
                  additionalAddress: data["additionalAddress"],
                  zip: data["zip"],
                  city,
                  country,
                  coordinates,
                  category: data["category"],
                  amenities: checkboxInput["amenities"],
                  tags,
                  title: data["title"],
                  description: data["description"],
                  price: data["price"],
                  slug,
                  swiper,
                },
                { merge: true }
              )
              .then(() => {
                history.push(
                  `${process.env.PUBLIC_URL}/forms/listing-form-list`
                );
                setStatus("ok");
                toast.success("Berhasil disimpan", {
                  position: toast.POSITION.TOP_CENTER,
                });

                var link =
                  "https://getgoing.co.id/getstay/" + slug + "?id=" + docId;
                sendConfirmationEmail(userData.email, link);
              })
              .catch(function (error) {
                console.error("Error writing document: ", error);
              });
          });
      }
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>
        <ModalBody>Hapus Layanan?</ModalBody>
        <ModalFooter>
          <Button className="btn-success-gradien mr-2" onClick={deleteService}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={deleteFormModal} toggle={toggleDeleteForm}>
        <ModalHeader toggle={toggleDeleteForm}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus Formulir?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteForm}>
            Ya
          </Button>{" "}
          <Button
            className="btn-secondary-gradien mr-2"
            onClick={toggleDeleteForm}
          >
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <Label className="col-form-label pt-0">Action</Label>
                    <span>: {formData.action}</span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label pt-0">ID Formulir</Label>
                    <span>: {id}</span>
                  </FormGroup>
                  {formData.action !== "create" &&
                    formData.action !== "archived" && (
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="col-form-label pt-0">
                              ID GetStay
                            </Label>
                            <span>: {formData.id}</span>
                          </FormGroup>
                        </Col>
                        <Col>
                          <LinkButton
                            title="Lihat Halaman Service"
                            url={`https://getgoing.co.id/getstay/${formData.slug}?id=${formData.id}`}
                          />
                        </Col>
                      </Row>
                    )}

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label pt-0">
                          ID Partner
                        </Label>
                        <span>: {formData.author && formData.author.uid}</span>
                      </FormGroup>
                    </Col>
                    <Col>
                      <LinkButton
                        title="Lihat Profil Partner"
                        url={`https://getgoing.co.id/user-profile/${formData.author.uid}`}
                      />
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label className="col-form-label pt-0">Nama Partner</Label>
                    <span>: {formData.author.fullName}</span>
                  </FormGroup>
                  {(formData.action === "create" ||
                    formData.action === "update") && (
                    <Row>
                      {formData.action === "update" && (
                        <Col lg="6">
                          <h3>Data Sekarang</h3>

                          <FormGroup>
                            <Label className="col-form-label">Alamat</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={data.address}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">
                              Alamat Tambahan
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={data.additionalAddress}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">ZIP</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={data.zip}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Negara</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={data.country}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Kota</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={data.city}
                              disabled
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Koordinat</Label>
                            <Row>
                              <Col lg="3">
                                <Input
                                  className="form-control"
                                  type="text"
                                  defaultValue={
                                    data.coordinates && data.coordinates[0]
                                  }
                                  disabled
                                />
                              </Col>
                              <Col lg="3">
                                <Input
                                  className="form-control"
                                  type="text"
                                  defaultValue={
                                    data.coordinates && data.coordinates[1]
                                  }
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Judul</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={data.title}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Kategori</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={data.category}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Deskripsi</Label>
                            <Input
                              className="form-control"
                              type="textarea"
                              defaultValue={data.description}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Harga</Label>
                            <Input
                              className="form-control"
                              type="number"
                              step="0.01"
                              defaultValue={data.price}
                              disabled
                            />
                          </FormGroup>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-4">
                                <Label className="col-form-label">
                                  Jumlah tamu
                                </Label>
                                <Input
                                  type="number"
                                  name="guestsOld"
                                  id="guestsOld"
                                  min="1"
                                  max="20"
                                  defaultValue={
                                    data.tags ? data.tags.guests : "1"
                                  }
                                  disabled
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup className="mb-4">
                                <Label className="col-form-label">
                                  Jumlah kamar tidur
                                </Label>
                                <Input
                                  type="number"
                                  name="bedroomsOld"
                                  id="bedroomsOld"
                                  min="1"
                                  max="20"
                                  defaultValue={
                                    data.tags ? data.tags.bedrooms : "1"
                                  }
                                  disabled
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup className="mb-4">
                                <Label className="col-form-label">
                                  Jumlah tempat tidur{" "}
                                </Label>
                                <Input
                                  type="number"
                                  name="bedsOld"
                                  id="bedsOld"
                                  min="1"
                                  max="20"
                                  defaultValue={
                                    data.tags ? data.tags.beds : "1"
                                  }
                                  disabled
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup className="mb-4">
                                <Label className="col-form-label">
                                  Jumlah kamar mandi
                                </Label>
                                <Input
                                  type="number"
                                  name="bathroomsOld"
                                  id="bathroomsOld"
                                  min="1"
                                  max="20"
                                  defaultValue={
                                    data.tags ? data.tags.bathrooms : "1"
                                  }
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
                            <Label className="form-label">
                              Kelengkapan / Amenities
                            </Label>
                            {Object.keys(amenitiesMap).map((keyName, i) => (
                              <FormGroup check key={i}>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    id={keyName + "old"}
                                    key={keyName + "old"}
                                    name={keyName + "old"}
                                    value={
                                      checkboxInput["amenities"][keyName] || ""
                                    }
                                    defaultChecked={
                                      data.amenities
                                        ? data.amenities[keyName]
                                        : false
                                    }
                                    disabled
                                  />
                                  {amenitiesMap[keyName]}
                                </Label>
                              </FormGroup>
                            ))}
                          </FormGroup>
                          <div>
                            <p>Foto Sebelum: </p>
                          </div>
                          <Row className="ml-n1 mr-n1">
                            {data &&
                              data.swiper &&
                              data.swiper.map((item, index) => (
                                <Col
                                  key={index}
                                  lg="4"
                                  xs="6"
                                  className="px-1 mb-2"
                                >
                                  <img
                                    src={item.img}
                                    alt={item.img}
                                    className="img-fluid"
                                  />

                                  <FileDownload url={item.img} />
                                </Col>
                              ))}
                          </Row>
                        </Col>
                      )}
                      <Col lg="6">
                        <h3>Data Dari Formulir</h3>
                        <FormGroup>
                          <Label className="col-form-label">
                            Alamat<span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="address"
                            innerRef={register({ required: true })}
                            defaultValue={formData.address}
                          />
                          <span style={{ color: "red" }}>
                            {errors.address && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            Alamat Tambahan
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="additionalAddress"
                            innerRef={register({ required: false })}
                            defaultValue={formData.additionalAddress}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            ZIP<span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="zip"
                            innerRef={register({ required: true })}
                            defaultValue={formData.zip}
                          />
                          <span style={{ color: "red" }}>
                            {errors.zip && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <SelectCountryCity
                          userCountry={formData.country}
                          userCity={formData.city}
                          selectedCountry={setSelectedCountry}
                          selectedCity={setSelectedCity}
                          listingForm
                        />

                        <FormGroup>
                          <Label className="col-form-label">
                            Koordinat<span className="text-danger">*</span>
                            {"  "}
                            <span>
                              <a
                                href="https://www.latlong.net/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Cari Koordinat
                              </a>
                            </span>
                          </Label>
                          <Row>
                            <Col lg="3">
                              <Input
                                className="form-control"
                                type="text"
                                name="coordinatesLat"
                                innerRef={register({ required: true })}
                                defaultValue={
                                  data.coordinates && data.coordinates[1]
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors.coordinatesLat && "Harus diisi"}
                              </span>
                            </Col>
                            <Col lg="3">
                              <Input
                                className="form-control"
                                type="text"
                                name="coordinatesLong"
                                innerRef={register({ required: true })}
                                defaultValue={
                                  data.coordinates && data.coordinates[0]
                                }
                              />
                              <span style={{ color: "red" }}>
                                {errors.coordinatesLong && "Harus diisi"}
                              </span>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            Judul <span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="title"
                            innerRef={register({ required: true })}
                            defaultValue={formData.title}
                          />
                          <span style={{ color: "red" }}>
                            {errors.title && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            Kategori<span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="category"
                            innerRef={register({ required: true })}
                            defaultValue={formData.category}
                          />
                          <span style={{ color: "red" }}>
                            {errors.category && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            Deskripsi <span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="textarea"
                            name="description"
                            innerRef={register({ required: true })}
                            defaultValue={formData.description}
                          />
                          <span style={{ color: "red" }}>
                            {errors.description && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">
                            Harga <span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="price"
                            innerRef={register({ required: true })}
                            defaultValue={formData.price}
                          />
                          <span style={{ color: "red" }}>
                            {errors.price && "Harus diisi"}
                          </span>
                        </FormGroup>
                        <Row>
                          <Col md="6">
                            <FormGroup className="mb-4">
                              <Label className="col-form-label">
                                Jumlah tamu{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                name="guests"
                                id="guests"
                                min="1"
                                max="20"
                                defaultValue={formData ? formData.guests : "1"}
                                innerRef={register({ required: true })}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup className="mb-4">
                              <Label className="col-form-label">
                                Jumlah kamar tidur{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                name="bedrooms"
                                id="bedrooms"
                                min="1"
                                max="20"
                                defaultValue={
                                  formData ? formData.bedrooms : "1"
                                }
                                innerRef={register({ required: true })}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup className="mb-4">
                              <Label className="col-form-label">
                                Jumlah tempat tidur{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                name="beds"
                                id="beds"
                                min="1"
                                max="20"
                                defaultValue={formData ? formData.beds : "1"}
                                innerRef={register({ required: true })}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup className="mb-4">
                              <Label className="col-form-label">
                                Jumlah kamar mandi{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                name="bathrooms"
                                id="bathrooms"
                                min="1"
                                max="20"
                                defaultValue={
                                  formData ? formData.bathrooms : "1"
                                }
                                innerRef={register({ required: true })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label className="form-label">
                            Kelengkapan / Amenities{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          {Object.keys(amenitiesMap).map((keyName, i) => (
                            <FormGroup check key={i}>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  id={keyName}
                                  key={keyName}
                                  name={keyName}
                                  value={
                                    checkboxInput["amenities"][keyName] || ""
                                  }
                                  defaultChecked={
                                    checkboxInput["amenities"]
                                      ? checkboxInput["amenities"][keyName]
                                      : false
                                  }
                                  onChange={(e) => onCheckboxChange(e)}
                                />{" "}
                                {amenitiesMap[keyName]}
                              </Label>
                            </FormGroup>
                          ))}
                        </FormGroup>
                        <div>
                          <p>Foto dari formulir: </p>
                        </div>
                        <Row className="ml-n1 mr-n1">
                          {photos &&
                            photos.map((file, index) => (
                              <Col
                                key={index}
                                lg="4"
                                xs="6"
                                className="px-1 mb-2"
                              >
                                <img
                                  src={file.preview}
                                  alt={file.preview}
                                  className="img-fluid"
                                />

                                <FileDownload url={file.preview} />
                              </Col>
                            ))}
                        </Row>
                        <div
                          {...getRootProps({
                            className: "dropzone dz-clickable",
                          })}
                        >
                          <input {...getInputProps()} />

                          <div className="dz-message text-muted">
                            <p>Ubah foto dan upload foto baru</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {formData.action === "delete" && (
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">Negara</Label>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue={formData.country}
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">Kota</Label>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue={formData.city}
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">Deskripsi</Label>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue={formData.description}
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">Alasan Dihapus</Label>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue={formData.reason}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <FormGroup className="form-row mt-3 mb-0">
                    {(formData.action === "create" ||
                      formData.action === "update") && (
                      <Button
                        color="mr-2"
                        type="submit"
                        className="btn-success-gradien mr-2"
                        disabled={!photoUploadFinished}
                      >
                        Simpan
                      </Button>
                    )}
                    {formData.action === "delete" && (
                      <Button
                        color="mr-2"
                        type="button"
                        className="btn-success-gradien mr-2"
                        onClick={toggle}
                      >
                        Hapus Layanan
                      </Button>
                    )}
                    <Button
                      color="mr-2 ml-auto float-right"
                      type="button"
                      className="btn-secondary-gradien mr-2"
                      onClick={toggleDeleteForm}
                    >
                      Hapus Formulir
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default GetStayDetail;
