import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import MaterialUiTable from "../../common/MaterialUiTable";
import { formatDateTime } from "../../common/TableUtils";

const BookingsTable = (props) => {
  const { bookings, getFlexId, departureId } = props;

  const [bookingList, setBookingList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const data = [];
    if (bookings && Object.keys(bookings).length > 0) {
      Object.keys(bookings).forEach((bookingId) => {
        const value = bookings[bookingId];
        const clientDetail = value.clientDetail;
        const status =
          value.status && value.status.length > 0
            ? value.status[value.status.length - 1].status
            : "";
        const urlParam = `?bookingId=${bookingId}&getFlexId=${getFlexId}&departureId=${departureId}`;
        data.push({
          id: value.itineraryGroupId
            ? value.itineraryGroupId + urlParam
            : `new${urlParam}`,
          nameEmail: clientDetail?.name + "," + clientDetail?.email,
          phone: clientDetail?.phoneNumber,
          totalGuests: value.totalGuests,
          customerService: value.customerService ? value.customerService : "",
          status,
          createdAt: value.createdAt ? value.createdAt : "",
          itineraryGroupId: value.itineraryGroupId,
        });
      });
      setBookingList(data);
    }
  }, []);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },

    {
      name: "itineraryGroupId",
      label: "ID Itinerary Group",
    },
    {
      name: "nameEmail",
      label: "NAMA, EMAIL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var name = value.split(",")[0];
          var email = value.split(",")[1];
          return (
            <div>
              <p className="my-0 font-weight-bold">{name}</p>
              <p className="my-0">{email}</p>
            </div>
          );
        },
        filter: false,
      },
    },
    {
      name: "totalGuests",
      label: "PAX",
    },
    {
      name: "customerService",
      label: "CS",
    },
    {
      name: "status",
      label: "STATUS",
    },
    {
      name: "createdAt",
      label: "WAKTU PESAN",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <React.Fragment>{formatDateTime(value)}</React.Fragment>;
        },
        filter: false,
      },
    },
  ];

  const toBookingDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/itinerary/itinerary-group/${id}`);
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Groups</h5>
            <p className="text-muted">(total {bookingList.length})</p>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={bookingList}
            toItemDetail={toBookingDetail}
            sortInitialColumn="status"
            searchableCols="Nama, Service, Status"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default BookingsTable;
