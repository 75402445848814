import React, { Fragment, useEffect, useState } from "react";
import { Card, Row, Col, CardHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import firebase from "../../data/firebase";
import {
  fetchCityData,
  getCityImage,
  getPhoneNumber,
  replaceImageUrl,
} from "../../data/helper";
import { upperCaseFirst } from "../../data/helper";
import MaterialUiTable from "../common/MaterialUiTable";
import { Plus } from "react-feather";

const MemberList = () => {
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (cityData) => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("userList")
          .get();
        var responseData = response.data();

        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          const image = getCityImage(value.city, cityData);

          const avatar = replaceImageUrl(value.avatar, 80, 80);

          if (["member", "admin", "superadmin"].includes(value.role))
            data.push({
              id: key,
              avatar,
              nameEmail: value.fullName + "," + value.email,
              country: value.country,
              city: value.city,
              cityCountry:
                image +
                "," +
                upperCaseFirst(value.city) +
                "," +
                upperCaseFirst(value.country),
              phone: getPhoneNumber(value.phone),

              // TODO
              //lastSignin: moment(),
            });
        }
        setUserList(data);

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchCityData().then((cityData) => {
      fetchData(cityData);
    });
  }, []);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "avatar",
      label: " ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img className="avatar-sm" src={value} alt={value} />;
        },
        searchable: false,
      },
    },
    {
      name: "nameEmail",
      label: "NAMA, EMAIL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var name = value.split(",")[0];
          var email = value.split(",")[1];
          return (
            <div>
              <p className="my-0 font-weight-bold">{name}</p>
              <p className="my-0">{email}</p>
            </div>
          );
        },
        filter: false,
      },
    },
    {
      name: "phone",
      label: "NO. TELEPON",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "country",
      label: "NEGARA",
      options: {
        display: "excluded",
        filterType: "multiselect",
      },
    },
    {
      name: "city",
      label: "KOTA",
      options: {
        display: "excluded",
        filterType: "multiselect",
      },
    },
    {
      name: "cityCountry",
      label: "DOMISILI",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var img = value.split(",")[0];
          var city = value.split(",")[1];
          var country = value.split(",")[2];
          return (
            <Row>
              <Col lg="4">
                <img
                  className="avatar-rect w-100"
                  src={
                    img
                      ? replaceImageUrl(img, 100, 80)
                      : "https://via.placeholder.com/40/FFFFFF/000000/?text=" +
                        city
                  }
                  alt={city}
                />
              </Col>
              <Col lg="8">
                <p className="my-0 font-weight-bold">{city}</p>
                <p className="my-0">{country}</p>
              </Col>
            </Row>
          );
        },
      },
    },
    {
      name: "lastSignin",
      label: "AKTIVITAS TERAKHIR",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {/* <Badge color="light" pill style={{ color: "green" }}>
                <i className="fa fa-circle"></i> 4 hari yang lalu
              </Badge>
              <p>({formatDateTime(value)})</p> */}
            </div>
          );
        },
        filter: false,
      },
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const toUserDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/community/member-detail/${id}`);
  };

  return (
    <Fragment>
      <Card className="mx-4">
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Member List</h5>
            <p className="text-muted">(total {userList.length})</p>
            <Button
              type="button"
              className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
              onClick={() => toUserDetail("new")}
            >
              <Plus /> Tambah Member
            </Button>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={userList}
            toItemDetail={toUserDetail}
            sortInitialColumn="nameEmail"
            searchableCols="Nama, Email, No. Telepon, Negara, Kota"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default MemberList;
