import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { Plus } from "react-feather";

import AddOnsInput from "./AddOnsInput";

const AddOnsComponent = (props) => {
  const { value, setValue } = props;
  const firstInput = {
    priceUnit: "orang",
  };
  const [state, setState] = useState(value || []);

  const addInput = () => {
    setState([...state, { ...firstInput }]);
  };

  const resetAllPhotos = (id) => {
    const updatedState = [...state];
    updatedState[id]["gallery"] = [];
    setState(updatedState);
    setValue(updatedState);
  };

  const handleChange = (e, idx) => {
    const updatedState = [...state];
    var fieldName = "";
    var value = "";
    var id = 0;

    if (e instanceof File) {
      fieldName = "gallery";
      value = e.preview;
      id = idx;
      const prevValue = updatedState[id][fieldName] || [];
      updatedState[id][fieldName] = [...prevValue, value];
    } else if (e.target && e.target.className === "form-check-input") {
      fieldName = "priceUnit";
      value = e.target.value;
      id = idx;
      updatedState[id][fieldName] = value;
    } else {
      var className = e.target.className;
      fieldName = className.split(" form-control")[0];
      value = e.target.value;
      id = e.target.dataset.id;
      updatedState[id][fieldName] = value;
    }

    setState(updatedState);
    setValue(updatedState);
  };

  const deleteInput = (id) => {
    const updatedState = [...state];
    updatedState.splice(id, 1);
    setState(updatedState);
    setValue(updatedState);
  };

  return (
    <Col className="ml-auto">
      {state &&
        state.map((val, idx) => (
          <AddOnsInput
            key={`addons-${idx}`}
            idx={idx}
            state={state}
            handleChange={handleChange}
            deleteInput={deleteInput}
            resetAllPhotos={resetAllPhotos}
          />
        ))}

      <Button
        type="button"
        color="primary"
        className="btn-info-gradien mr-2 px-3 button-vertical-center"
        onClick={addInput}
      >
        <Plus /> Tambah Input
      </Button>
    </Col>
  );
};

export default AddOnsComponent;
