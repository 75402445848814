import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { Plus } from "react-feather";
import moment from "moment";

import VehicleInput from "./VehicleInput";

const VehicleComponent = (props) => {
  const { vehicles, setVehicles } = props;
  const firstVehicle = { arrival: {}, departure: {} };
  const [state, setState] = useState(vehicles || []);

  const addVehicle = () => {
    setState([...state, { ...firstVehicle }]);
  };

  const handleChange = (e, idx, direction) => {
    const updatedState = [...state];
    var fieldName = "";
    var value = "";
    var id = 0;

    if (direction === "transportation") {
      updatedState[idx][direction] = e;
    } else if (moment.isMoment(e)) {
      updatedState[idx][direction]["date"] = e.toDate();
    } else if (e instanceof File) {
      if (direction === "group") {
        fieldName = "groupTicketFile";
      } else {
        fieldName = "ticketFile";
      }
      value = e.preview;
      id = idx;
      updatedState[id][fieldName] = value;
    } else {
      var className = e.target.className;
      fieldName = className.split(" ")[0];

      if (fieldName.includes("departure") || fieldName.includes("arrival")) {
        var fieldArr = fieldName.split("-");
        var direction = fieldArr[0];
        var name = fieldArr[1];
        value = e.target.value;
        id = e.target.dataset.id;

        updatedState[id][direction][name] = value;
      } else {
        value = e.target.value;
        id = e.target.dataset.id;
        updatedState[id][fieldName] = value;
      }
    }

    setState(updatedState);
    setVehicles(updatedState);
  };

  const deleteInput = (id) => {
    const updatedState = [...state];
    updatedState.splice(id, 1);
    setState(updatedState);
    setVehicles(updatedState);
  };

  return (
    <Col className="ml-auto">
      {state &&
        state.map((val, idx) => (
          <VehicleInput
            key={`vehicle-${idx}`}
            idx={idx}
            state={state}
            handleChange={handleChange}
            deleteInput={deleteInput}
          />
        ))}

      <Button
        type="button"
        color="primary"
        className="btn-info-gradien mr-2 px-3 button-vertical-center my-3"
        onClick={addVehicle}
      >
        <Plus /> Tambah Kendaraan
      </Button>
    </Col>
  );
};

export default VehicleComponent;
