import React, { Fragment, useState, useEffect } from "react";
import { Card, Row, Col, CardHeader, Button } from "reactstrap";
import { Plus } from "react-feather";
import { useHistory } from "react-router-dom";
import MaterialUiTable from "../../common/MaterialUiTable";
import { itineraryTableInfo } from "../../crm/itinerary/util";
import moment from "moment";

const ItineraryTable = (props) => {
  const { itineraries, getFlexId, departureId, itineraryLevel } = props;

  console.log(itineraries);

  const history = useHistory();
  const [itineraryList, setItineraryList] = useState([]);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "type",
      label: "TIPE",
    },
    {
      name: "date",
      label: "TANGGAL",
    },
    {
      name: "time",
      label: "WAKTU",
      options: {
        filter: false,
      },
    },
    {
      name: "info",
      label: "INFO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ whiteSpace: "pre-line" }}>{value}</span>;
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "itineraryLevel",
      label: "LEVEL",
    },
  ];

  const toItineraryDetail = (id) => {
    history.push(
      `${process.env.PUBLIC_URL}/itinerary/itinerary-detail/${id}?getFlexId=${getFlexId}&departureId=${departureId}&itineraryLevel=${itineraryLevel}`
    );
  };

  const formatDate = (value) => {
    return value ? moment(value.toDate()).format("DD MMM YYYY") : "";
  };

  useEffect(() => {
    const data = [];
    if (itineraries && itineraries.length > 0) {
      itineraries.forEach((itinerary) => {
        const value = itinerary;
        const date = formatDate(value.date);
        data.push({
          id: itinerary.id,
          type: value.type,
          date,
          time: value.time,
          info: itineraryTableInfo(value),
          itineraryLevel: value.itineraryLevel,
        });
      });
      setItineraryList(data);
    }
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">
              Itinerary {itineraryLevel}
            </h5>
            <p className="text-muted">(total {itineraryList.length})</p>
            <Button
              type="button"
              className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
              onClick={() => toItineraryDetail("new")}
            >
              <Plus /> Tambah Itinerary
            </Button>
          </Row>
        </CardHeader>

        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={itineraryList}
            toItemDetail={toItineraryDetail}
            sortInitialColumn="date"
            searchableCols="Tipe, Tanggal"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default ItineraryTable;
