import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  Label,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormText,
  Badge,
} from "reactstrap";
import { useParams, useLocation } from "react-router-dom";
import { Plus } from "react-feather";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Board, { moveCard } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import idLocale from "moment/locale/id";
import * as firebaseApp from "firebase/app";
import firebase from "../../data/firebase";
import { formatDate } from "../common/TableUtils";
import KanbanCard from "./KanbanCard";
import { ProjectManagementToggle } from "../../redux/common/actions";
import MaterialUiTable from "../common/MaterialUiTable";
import { InputAdornment, OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { replaceImageUrl } from "../../data/helper";

const ProjectManagementList = () => {
  let location = useLocation();
  const history = useHistory();
  let { channel } = useParams();

  const dispatch = useDispatch();
  const projectManagementToggle = useSelector(
    (state) => state.Common.projectManagementToggle
  );

  const [newChannel, setNewChannel] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit, errors } = useForm();
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [titleFilter, setTitleFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("All");
  const [priorityFilter, setPriorityFilter] = useState("All");

  moment.locale("id", idLocale);
  const [range, setRange] = useState({
    startDate: moment(),
    endDate: moment(),
  });

  const [initialRange, setInitialRange] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [dateFocused, setDateFocused] = useState(null);

  const [allAssigneesOption, setAllAssigneesOption] = useState([]);
  const [allPrioritiesOption, setAllPrioritiesOption] = useState([
    { value: "All", label: "Semua Prioritas" },
    { value: "Prioritas 1", label: "Prioritas 1" },
    { value: "Prioritas 2", label: "Prioritas 2" },
    { value: "Prioritas 3", label: "Prioritas 3" },
  ]);

  const statusMap = {
    "0_todo": "Todo",
    "1_inProgress": "In Progress",
    "2_review": "Review",
    "3_done": "Done",
    "4_cancelled": "Cancelled",
    "5_postponed": "Postponed",
  };

  const inverseStatusMap = {
    Todo: "0_todo",
    "In Progress": "1_inProgress",
    Review: "2_review",
    Done: "3_done",
    Cancelled: "4_cancelled",
    Postponed: "5_postponed",
  };

  const formatStatusValue = (action) => {
    return inverseStatusMap[action];
  };

  const renderCard = (card) => {
    return <KanbanCard channel={channel} card={card} />;
  };

  const renderColumnHeader = (header) => {
    return (
      <div
        className="p-3"
        style={{
          width: "260px",
          margin: "-15px",
          borderTop: `solid 10px ${setColor(header.title)}`,
          borderRadius: "10px",
        }}
      >
        <h4
          style={{
            color: setColor(header.title),
            borderBottom: `1px solid ${setColor(header.title)}`,
          }}
          className="pb-3"
        >
          {header.title}
        </h4>
      </div>
    );
  };

  const setColor = (title) => {
    switch (title) {
      case "Todo":
        return "#449AEF";
      case "In Progress":
        return "#FF8831";
      case "Review":
        return "#F2C94C";
      case "Done":
        return "#00C950";
      case "Postponed":
        return "#25213B";
      case "Cancelled":
        return "#DE350B";
      default:
        return "black";
    }
  };

  const board = {
    columns: [
      {
        id: "0_todo",
        title: "Todo",
        cards: [],
      },
      {
        id: "1_inProgress",
        title: "In Progress",
        cards: [],
      },
      {
        id: "2_review",
        title: "Review",
        cards: [],
      },
      {
        id: "3_done",
        title: "Done",
        cards: [],
      },
      {
        id: "4_cancelled",
        title: "Cancelled",
        cards: [],
      },
      {
        id: "5_postponed",
        title: "Postponed",
        cards: [],
      },
    ],
  };

  const [kanbanBoard, setKanbanBoard] = useState(board);
  var assigneesList = [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("projectManagement")
          .doc(channel)
          .get();
        var responseData = response.data();
        var data = [];

        var earliestDate = moment();
        var latestDate = moment();

        for (const key in responseData) {
          const value = responseData[key];
          const task = {
            id: key,
            title: value.title,
            assignees: value.assignees,
            status: formatStatusValue(value.status),
            deadline: value.endDate,
            priority: value.priority,
          };
          data.push(task);

          board.columns.forEach((column) => {
            if (formatStatusValue(value.status) === column.id) {
              column.cards.push(task);
            }
          });

          // set of all assignees for filtering
          value.assignees.forEach((a) => {
            if (!assigneesList.some((e) => e.uid === a.uid)) {
              assigneesList.push(a);
            }
          });

          // get earliest and latest deadline for filtering (todo, inprogess, review column)
          if (
            task.status === "0_todo" ||
            task.status === "1_inProgress" ||
            task.status === "2_review"
          ) {
            if (task.deadline.toDate() < earliestDate) {
              earliestDate = task.deadline.toDate();
            }

            if (task.deadline.toDate() > latestDate) {
              latestDate = task.deadline.toDate();
            }
          }
        }

        setRange({
          startDate: moment(earliestDate),
          endDate: moment(latestDate),
        });

        setInitialRange({
          startDate: moment(earliestDate),
          endDate: moment(latestDate),
        });

        setTaskList(data);
        setKanbanBoard(board);

        var allAssigneesOptionList = [{ value: "All", label: "Semua Nama" }];
        assigneesList.forEach((a) => {
          allAssigneesOptionList.push({ value: a.name, label: a.name });
        });
        setAllAssigneesOption(allAssigneesOptionList);

        console.log(data);

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    if (channel === "new") {
      setNewChannel(true);
      setLoading(false);
    } else {
      setLoading(true);
      fetchData();
    }
  }, [location]);

  const getPriorityColor = (value) => {
    switch (value) {
      case "Prioritas 1":
        return "#DE350B";
      case "Prioritas 2":
        return "#FF8831";
      case "Prioritas 3":
        return "#449AEF";
    }
  };

  const getStatusColor = (color) => {
    switch (color) {
      case "0_todo":
        return "#449AEF";
      case "1_inProgress":
        return "#FF8831";
      case "2_review":
        return "#F2C94C";
      case "3_done":
        return "#00C950";
      case "4_cancelled":
        return "#DE350B";
      case "5_postponed":
        return "#25213B";
      default:
        return "#000000";
    }
  };

  const getDeadlineColor = (value) => {
    var diffDay = moment().diff(value.toDate(), "days");
    if (diffDay >= 0) {
      return "#DE350B";
    }
    return "#00C950";
  };

  const getDeadlineDiff = (value) => {
    var diffDay = moment().diff(value.toDate(), "days");
    if (diffDay > 0) {
      return diffDay + " hari yang lalu";
    } else if (diffDay < 0) {
      return diffDay * -1 + " hari lagi";
    }
    return "hari ini";
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "title",
      label: "JUDUL",
    },
    {
      name: "assignees",
      label: "TEAM",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <div className="stacked_avatars">
                {value &&
                  (value.length > 0 ? (
                    value.map((person) => (
                      <a
                        href="#"
                        className="stacked_avatars__item"
                        key={person.name}
                      >
                        <img
                          className="avatar"
                          src={replaceImageUrl(person.avatar, 80, 80)}
                          alt={person.name}
                        />
                      </a>
                    ))
                  ) : (
                    <span className="text-primary">OPEN TICKET</span>
                  ))}
              </div>
            </div>
          );
        },
        sort: false,
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ color: getStatusColor(value) }}>
              <i className="fa fa-circle"></i> {statusMap[value]}
            </div>
          );
        },
      },
    },
    {
      name: "priority",
      label: "PRIORITAS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Badge
                pill
                style={{
                  borderRadius: "5px",
                  backgroundColor: getPriorityColor(value),
                }}
              >
                {value}
              </Badge>
            </div>
          );
        },
      },
    },
    {
      name: "deadline",
      label: "DEADLINE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Badge
                color="light"
                pill
                style={{ color: getDeadlineColor(value) }}
              >
                <i className="fa fa-circle"></i> {getDeadlineDiff(value)}
              </Badge>
              <p className="m-0">{formatDate(value)}</p>
            </div>
          );
        },
      },
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  const toTaskDetail = (id) => {
    history.push(
      `${process.env.PUBLIC_URL}/project-management/${channel}/${id}`
    );
  };

  const deleteChannel = () => {
    firebase
      .firestore()
      .collection("projectManagement")
      .doc(channel)
      .delete()
      .then(() => {
        firebase
          .firestore()
          .collection("projectManagement")
          .doc("channels")
          .update({
            channelList: firebaseApp.firestore.FieldValue.arrayRemove(channel),
          })
          .then(() => {
            toast.success("Berhasil dihapus", {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(
              `${process.env.PUBLIC_URL}/project-management-overview`
            );
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const resetFilter = () => {
    setTitleFilter("");
    setNameFilter("All");
    setPriorityFilter("All");
    setRange(initialRange);
  };

  const filterTitle = (title, titleFilter) => {
    return title.toLowerCase().includes(titleFilter.toLowerCase());
  };

  const filterName = (taskAssignees, nameFilter) => {
    var result = false;
    if (nameFilter === "All") {
      return true;
    }
    taskAssignees.forEach((assignee) => {
      if (assignee.name === nameFilter) {
        result = true;
      }
    });
    return result;
  };

  const filterPriority = (taskPriority, priorityFilter) => {
    var result = false;
    if (priorityFilter === "All") {
      return true;
    }
    if (taskPriority === priorityFilter) {
      result = true;
    }
    return result;
  };

  const filterDate = (task) => {
    const taskStatus = task.status;
    const taskDeadline = task.deadline;

    // filter only tasks in todo, inprogress, review
    if (
      taskStatus === "0_todo" ||
      taskStatus === "1_inProgress" ||
      taskStatus === "2_review"
    ) {
      return (
        taskDeadline.toDate() >= range.startDate.toDate() &&
        taskDeadline.toDate() <= range.endDate.toDate()
      );
    } else {
      return true;
    }
  };

  const getTaskData = () => {
    var filteredTasks = [];
    taskList.forEach((task) => {
      if (
        filterTitle(task.title, titleFilter) &&
        filterName(task.assignees, nameFilter) &&
        filterPriority(task.priority, priorityFilter) &&
        filterDate(task)
      ) {
        filteredTasks.push(task);
      }
    });
    return filteredTasks;
  };

  const sendEmailNotification = (uid, task) => {
    var userToNotify = assigneesList.filter((a) => {
      return a.uid === uid;
    });

    var email = userToNotify[0].email;
    var link = `${process.env.PUBLIC_URL}/project-management/${channel}/${task.id}`;

    var formattedEndDate = task.endDate.toLocaleDateString("id-ID", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    firebase
      .firestore()
      .collection("mails")
      .add({
        to: email,
        replyTo: "we@getgoing.co.id",
        template: {
          name: "projectManagementTask",
          data: {
            taskTitle: task.title,
            message: "Tugas telah diupdate!",
            deadline: formattedEndDate,
            status: task.status,
            description: task.description,
            link: link,
          },
        },
      })
      .catch((e) => console.log(e));
  };

  const updateUserTask = (task, prevStatus, newStatus) => {
    var uidList = task["assignees"].map((a) => a.uid);
    uidList.forEach((uid) => {
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("tasks")
        .doc(task.id)
        .set(
          {
            status: newStatus,
          },
          { merge: true }
        )
        .then(() => {
          // update taskStats
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .get()
            .then((doc) => {
              var taskStats = doc.data().taskStats;
              taskStats[prevStatus] = parseInt(taskStats[prevStatus]) - 1;
              taskStats[newStatus] = parseInt(taskStats[newStatus]) + 1;

              firebase.firestore().collection("users").doc(uid).set(
                {
                  taskStats: taskStats,
                },
                { merge: true }
              );
            })
            .catch((e) => console.log(e));

          // sendEmailNotification(uid, task);
        });
    });
  };

  function handleCardMove(_card, source, destination) {
    var updatedTask = {};
    var prevStatus = statusMap[source.fromColumnId];
    // set tasklist
    taskList.forEach((task) => {
      if (task.id === _card.id) {
        task.status = destination.toColumnId;
        updatedTask = task;
      }
    });
    setTaskList(taskList);

    const updatedBoard = moveCard(getKanbanData(), source, destination);
    setKanbanBoard(updatedBoard);

    const docId = _card.id;
    const newStatus = statusMap[destination.toColumnId];

    firebase
      .firestore()
      .collection("projectManagement")
      .doc(channel)
      .set(
        {
          [docId]: {
            status: newStatus,
          },
        },
        { merge: true }
      )
      .then(() => updateUserTask(updatedTask, prevStatus, newStatus))
      .catch(function (error) {
        // Handle any errors
        console.log("error " + error);
      });
  }

  const getKanbanData = () => {
    var filteredTasks = [];
    kanbanBoard.columns.forEach((column) => {
      column.cards = [];
    });
    taskList.forEach((task) => {
      if (
        filterTitle(task.title, titleFilter) &&
        filterName(task.assignees, nameFilter) &&
        filterPriority(task.priority, priorityFilter) &&
        filterDate(task)
      ) {
        filteredTasks.push(task);
        kanbanBoard.columns.forEach((column) => {
          if (task.status === column.id) {
            column.cards.push(task);
          }
        });
      }
    });
    return kanbanBoard;
  };

  if (newChannel) {
    const onSubmit = (data) => {
      if (data !== "") {
        firebase
          .firestore()
          .collection("projectManagement")
          .doc(data.name)
          .set({})
          .then(() => {
            firebase
              .firestore()
              .collection("projectManagement")
              .doc("channels")
              .update({
                channelList: firebaseApp.firestore.FieldValue.arrayUnion(
                  data.name
                ),
              })
              .then(() => {
                toast.success("Berhasil disimpan", {
                  position: toast.POSITION.TOP_CENTER,
                });
                history.push(
                  `${process.env.PUBLIC_URL}/project-management-overview`
                );
              });
          })
          .catch(function (error) {
            // Handle any errors
            console.log("error " + error);
          });
      } else {
        errors.showMessages();
      }
    };

    return (
      <Fragment>
        <Card>
          <CardBody>
            <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label className="col-form-label pt-2">
                  Nama Channel <span className="text-danger">*</span>
                </Label>
                <Input
                  className="form-control"
                  type="text"
                  name="name"
                  innerRef={register({
                    required: true,
                    pattern: /^[A-Z0-9_&-]+$/i,
                  })}
                  defaultValue={""}
                />
                <FormText>
                  Hanya boleh terdiri dari simbol{" "}
                  <span className="font-weight-bold text-danger">A-Z</span>{" "}
                  <span className="font-weight-bold text-danger">a-z</span>{" "}
                  <span className="font-weight-bold text-danger">0-9</span>{" "}
                  <span className="font-weight-bold text-danger">&amp;</span>{" "}
                  <span className="font-weight-bold text-danger">-</span>{" "}
                  <span className="font-weight-bold text-danger">_</span>
                </FormText>
                <span style={{ color: "red" }}>
                  {errors.name && "Nama channel tidak sesuai kriteria."}
                </span>
              </FormGroup>
              <Button
                color="mr-2"
                type="submit"
                className="btn-success-gradien mr-2"
              >
                Simpan
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>
        <ModalBody>
          Hapus Channel {channel}? Semua tugas di channel ini akan otomatis
          dihapus!
        </ModalBody>
        <ModalFooter>
          <Button className="btn-success-gradien mr-2" onClick={deleteChannel}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Card key={channel}>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">#{channel}</h5>
            <p className="text-muted">(Total tugas: {taskList.length})</p>
          </Row>
        </CardHeader>

        <Row>
          <div className="switch-md button-vertical-center  ml-5 my-3">
            <Button
              type="button"
              outline
              color="secondary"
              onClick={() => dispatch(ProjectManagementToggle(false))}
              style={{
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
              disabled={projectManagementToggle}
              active={projectManagementToggle}
            >
              Tampilan List
            </Button>
            <Button
              type="button"
              outline
              color="secondary"
              onClick={() => dispatch(ProjectManagementToggle(true))}
              style={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
              }}
              disabled={!projectManagementToggle}
              active={!projectManagementToggle}
            >
              Tampilan Kanban
            </Button>
          </div>

          <Button
            type="button"
            color="primary"
            className="px-3 button-vertical-center ml-auto mr-3 my-3"
            onClick={() => toTaskDetail("new")}
          >
            <Plus /> Tambah Task
          </Button>
        </Row>

        <Row className="my-3 ml-3">
          <Col lg="3">
            <OutlinedInput
              autoFocus={true}
              defaultValue={titleFilter}
              onChange={(e) => {
                setTitleFilter(e.target.value);
              }}
              fullWidth={true}
              placeholder="Cari judul"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              style={{ height: "38px" }}
            />
          </Col>
          <Col lg="2">
            <Select
              id="reactselect-assignee"
              className="search-form-banner-text border-0 shadow-0"
              isSearchable={true}
              name="assignee"
              options={allAssigneesOption}
              menuPlacement="bottom"
              onChange={(e) => setNameFilter(e.value)}
              defaultValue={{ value: "All", label: "Semua Nama" }}
              value={{ value: nameFilter, label: nameFilter }}
            />
          </Col>
          <Col lg="2">
            <Select
              id="reactselect-assignee"
              className="search-form-banner-text border-0 shadow-0"
              isSearchable={true}
              name="priority"
              options={allPrioritiesOption}
              menuPlacement="bottom"
              onChange={(e) => setPriorityFilter(e.value)}
              defaultValue={{ value: "All", label: "Semua Prioritas" }}
              value={{ value: priorityFilter, label: priorityFilter }}
            />
          </Col>
          <Col lg="3">
            <div className="date-range-picker-filter">
              <DateRangePicker
                startDate={range.startDate}
                startDateId="startDate"
                startDatePlaceholderText="Dari"
                endDate={range.endDate}
                endDateId="endDate"
                endDatePlaceholderText="Sampai"
                block={true}
                onDatesChange={({ startDate, endDate }) =>
                  setRange({ startDate, endDate })
                }
                focusedInput={dateFocused}
                onFocusChange={(dateFocused) => setDateFocused(dateFocused)}
                orientation={"horizontal"}
                required
                isOutsideRange={() => false}
              />
            </div>
          </Col>
          <Col lg="2">
            <Button type="button" onClick={resetFilter}>
              Reset filter
            </Button>
          </Col>
        </Row>

        {projectManagementToggle ? (
          <div className="table-responsive">
            <MaterialUiTable
              columns={columns}
              data={getTaskData()}
              toItemDetail={toTaskDetail}
              sortInitialColumn="status"
              searchableCols="Judul"
              defaultToolbar={false}
            />
          </div>
        ) : (
          <CardBody className="draggable-card">
            <Board
              renderCard={renderCard}
              renderColumnHeader={renderColumnHeader}
              onCardDragEnd={handleCardMove}
              disableColumnDrag={true}
            >
              {getKanbanData()}
            </Board>
          </CardBody>
        )}

        <Button
          type="button"
          color="danger"
          className="px-3 button-vertical-center ml-auto mr-3 my-5"
          onClick={toggle}
        >
          Hapus Channel
        </Button>
      </Card>
    </Fragment>
  );
};

export default ProjectManagementList;
