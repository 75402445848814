import { MOBILE_RIGHT_TOGGLE, SWITCH_TOGGLE, PM_TOGGLE } from "../actionTypes";

const Initial_state = {
  mobileRightToggle: false,
  switchToggle: false,
  projectManagementToggle: false,
};

const Common = (state = Initial_state, action) => {
  switch (action.type) {
    case MOBILE_RIGHT_TOGGLE:
      state.mobileRightToggle = !state.mobileRightToggle;
      return state;
    case SWITCH_TOGGLE:
      state.switchToggle = !state.switchToggle;
      return state;
    case PM_TOGGLE:
      state.projectManagementToggle = !state.projectManagementToggle;
      return state;
    default:
      return state;
  }
};

export default Common;
