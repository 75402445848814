import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import TicketAgeRangeComponent from "./TicketAgeRangeComponent";

const TicketProviderInput = (props) => {
  const {
    idx,
    state,
    handleChange,
    handleTicketAgeRangeChange,
    disableDelete,
    deleteInput,
  } = props;

  const ticketTypeId = `ticketType-${idx}`;
  const durationId = `duration-${idx}`;
  const zoneId = `zone-${idx}`;
  const descriptionId = `description-${idx}`;
  const groupSizeId = `groupSize-${idx}`;
  return (
    <div key={`ticketProvider-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <Col className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <h5>{idx + 1}</h5>
            <Col md="11">
              <FormGroup className="mb-3">
                <Label className="form-label" for={ticketTypeId}>
                  Jenis Tiket
                </Label>
                <span> (Tiket sekali jalan, Tiket Grup, ...)</span>
                <Input
                  type="text"
                  input={ticketTypeId}
                  name={ticketTypeId}
                  id={ticketTypeId}
                  data-id={idx}
                  className="ticketType"
                  value={state[idx].ticketType || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label" for={durationId}>
                  Durasi
                </Label>
                <span> (3 Jam, 24 Jam, ...)</span>
                <Input
                  type="text"
                  input={durationId}
                  name={durationId}
                  id={durationId}
                  data-id={idx}
                  className="duration"
                  value={state[idx].duration || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label" for={zoneId}>
                  Zona
                </Label>
                <span> (A-B, ...)</span>
                <Input
                  type="text"
                  input={zoneId}
                  name={zoneId}
                  id={zoneId}
                  data-id={idx}
                  className="zone"
                  value={state[idx].zone || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label" for={groupSizeId}>
                  Jumlah Orang
                </Label>
                <Input
                  type="number"
                  input={groupSizeId}
                  name={groupSizeId}
                  id={groupSizeId}
                  data-id={idx}
                  className="groupSize"
                  value={state[idx].groupSize || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label">Deskripsi</Label>
                <Input
                  type="textarea"
                  input={descriptionId}
                  name={descriptionId}
                  id={descriptionId}
                  data-id={idx}
                  rows="2"
                  className="description"
                  value={state[idx].description || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <TicketAgeRangeComponent
                value={state[idx].ticketAgeRange}
                setValue={handleTicketAgeRangeChange}
                providerId={idx}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default TicketProviderInput;
