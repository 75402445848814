import React, { useState } from "react";
import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import "react-dates/initialize";
import VehicleComponent from "./VehicleComponent";

const Transportation = (props) => {
  const { itineraryData, handleTransportationChange, register } = props;

  const [vehicles, setVehicles] = useState(itineraryData.vehicles || []);

  const onVehiclesChanged = (value) => {
    setVehicles(value);
    handleTransportationChange(value);
  };

  return (
    <Col>
      <Label className="col-form-label pt-3">
        Tipe: <span>{itineraryData.type}</span>
      </Label>

      <FormGroup>
        <Label>Keberangkatan Awal</Label>
        <Input
          className="form-control"
          type="text"
          name="startLocation"
          innerRef={register({ required: true })}
          defaultValue={itineraryData.startLocation}
        />
      </FormGroup>

      <Row>
        <Col>
          <Label>Kota</Label>
          <Input
            className="form-control"
            type="text"
            name="firstDepartureCity"
            innerRef={register({ required: true })}
            defaultValue={itineraryData.firstDepartureCity}
          />
        </Col>
        <Col>
          <Label>Negara</Label>
          <Input
            className="form-control"
            type="text"
            name="firstDepartureCountry"
            innerRef={register({ required: true })}
            defaultValue={itineraryData.firstDepartureCountry}
          />
        </Col>
      </Row>

      <FormGroup className="mt-5">
        <Label>Kedatangan Akhir</Label>
        <Input
          className="form-control"
          type="text"
          name="endLocation"
          innerRef={register({ required: true })}
          defaultValue={itineraryData.endLocation}
        />
      </FormGroup>

      <Row>
        <Col>
          <Label>Kota</Label>
          <Input
            className="form-control"
            type="text"
            name="lastArrivalCity"
            innerRef={register({ required: true })}
            defaultValue={itineraryData.lastArrivalCity}
          />
        </Col>
        <Col>
          <Label>Negara</Label>
          <Input
            className="form-control"
            type="text"
            name="lastArrivalCountry"
            innerRef={register({ required: true })}
            defaultValue={itineraryData.lastArrivalCountry}
          />
        </Col>
      </Row>
      <h3 className="mt-5">Kendaraan</h3>
      <VehicleComponent vehicles={vehicles} setVehicles={onVehiclesChanged} />
    </Col>
  );
};

export default Transportation;
