import React, { useState, useEffect } from "react";
import InfiniteLoader from "./Infinite-loading.svg";

const Loader = (props) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);
  return (
    <div className={`loader-wrapper ${show ? "" : "loderhide"}`}>
      <img src={InfiniteLoader} alt="Infinite Loader" />
    </div>
  );
};

export default Loader;
