import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import firebase from "../../data/firebase";
import {
  fetchCityData,
  getCityImage,
  replaceImageUrl,
} from "../../data/helper";
import { formatDateTime } from "../common/TableUtils";
import { upperCaseFirst } from "../../data/helper";
import MaterialUiTable from "../common/MaterialUiTable";

const ListingFormList = () => {
  const history = useHistory();
  const [listingFormList, setListingFormList] = useState([]);
  const [loading, setLoading] = useState(true);

  const actionMap = {
    "0_create": "create",
    "1_update": "update",
    "2_delete": "delete",
    "3_ok": "ok",
    "4_archived": "archived",
  };

  const inverseActionMap = {
    create: "0_create",
    update: "1_update",
    delete: "2_delete",
    ok: "3_ok",
    archived: "4_archived",
  };

  const formatActionValue = (action) => {
    return inverseActionMap[action];
  };

  useEffect(() => {
    const fetchData = async (cityData) => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("listingFormList")
          .get();
        var responseData = response.data();
        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          const country = value.country ? upperCaseFirst(value.country) : "";
          const city = value.city ? upperCaseFirst(value.city) : "";
          const image = getCityImage(value.city, cityData);
          const name = value.author && value.author.fullName;
          const email = value.author && value.author.email;
          const avatar = value.author && value.author.avatar;
          data.push({
            id: key,
            action: value.action ? formatActionValue(value.action) : "",
            serviceType: value.serviceType ? value.serviceType : "",
            nameEmail: name + "," + email,
            avatar: replaceImageUrl(avatar, 80, 80),
            country,
            city,
            countryCity: image + "," + city + "," + country,
            createdAt: value.createdAt ? value.createdAt : "",
          });
        }
        setListingFormList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchCityData().then((cityData) => {
      fetchData(cityData);
    });
  }, []);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "serviceType",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "avatar",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img className="avatar-sm" src={value} alt={value} />;
        },
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "nameEmail",
      label: "NAMA, EMAIL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var name = value.split(",")[0];
          var email = value.split(",")[1];
          return (
            <div>
              <p className="my-0 font-weight-bold">{name}</p>
              <p className="my-0">{email}</p>
            </div>
          );
        },
        filter: false,
      },
    },
    {
      name: "country",
      label: "NEGARA",
      options: {
        display: "excluded",
        filterType: "multiselect",
      },
    },
    {
      name: "city",
      label: "KOTA",
      options: {
        display: "excluded",
        filterType: "multiselect",
      },
    },
    {
      name: "countryCity",
      label: "NEGARA, KOTA",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var img = value.split(",")[0];
          var city = value.split(",")[1];
          var country = value.split(",")[2];
          return (
            <Row>
              <Col lg="3">
                <img
                  className="avatar-rect"
                  src={
                    img
                      ? img
                      : "https://via.placeholder.com/40/FFFFFF/000000/?text=" +
                        city
                  }
                  alt={city}
                />
              </Col>
              <Col lg="9">
                <p className="my-0 font-weight-bold">{city}</p>
                <p className="my-0">{country}</p>
              </Col>
            </Row>
          );
        },
      },
    },
    {
      name: "serviceType",
      label: "TIPE SERVICE",
    },
    {
      name: "action",
      label: "ACTION",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <React.Fragment>{actionMap[value]}</React.Fragment>;
        },
        filterOptions: {
          renderValue: (v) => actionMap[v],
        },
        customFilterListOptions: {
          render: (v) => actionMap[v],
        },
      },
    },
    {
      name: "createdAt",
      label: "WAKTU KIRIM",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <React.Fragment>{formatDateTime(value)}</React.Fragment>;
        },
        filter: false,
      },
    },
  ];

  const toListingFormDetail = (id1, id2) => {
    history.push(
      `${process.env.PUBLIC_URL}/forms/listing-form-detail/${id2}/${id1}`
    );
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Formulir Listing</h5>
            <p className="text-muted">(total {listingFormList.length})</p>
          </Row>
        </CardHeader>

        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={listingFormList}
            toItemDetail={toListingFormDetail}
            sortInitialColumn="action"
            searchableCols="Nama, Negara, Kota, Tipe, Action"
            defaultSearchInput={true}
            twoRowsId
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default ListingFormList;
