export const itineraryTableInfo = (value) => {
  var info = "";
  switch (value.type) {
    case "transportation":
      info =
        "Dari: " + value.startLocation + "\n" + "Menuju: " + value.endLocation;
      break;
    case "accommodation":
      info =
        "Nama: " +
        value.title +
        "\n" +
        "Kota, Negara: " +
        value.city +
        ", " +
        value.country;
      break;

    case "event":
      info = "Nama: " + value.title + "\n" + "Tipe: " + value.eventType;
      break;
    case "getguide":
      info =
        "Guide: " +
        value.getGuide.author.fullName +
        "\n" +
        "Kota, Negara: " +
        value.getGuide.city +
        ", " +
        value.getGuide.country +
        "\n" +
        "Durasi: " +
        value.duration;
      break;
    case "freetime":
      info = "Kota: " + value.city + "\n" + "Negara: " + value.country;
      break;
    case "getflex-basic":
      info =
        "Judul: " +
        value.title +
        "\n" +
        "Booking Id: " +
        value.bookingId +
        "\n" +
        "Status: " +
        value.currentStatus?.status;
      break;
    default:
      info = "";
  }
  return info;
};
