import React, { useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { SingleDatePicker } from "react-dates";
import Select from "react-select";
import "react-dates/initialize";

const Event = (props) => {
  const { itineraryData, register, setDate, setTime, handleTypeChange } = props;

  const [focused, setFocused] = useState({ focused: false });

  const getEventTypeOptions = () => {
    var options = [
      {
        value: "online",
        label: "online",
      },
      {
        value: "offline",
        label: "offline",
      },
    ];

    return options;
  };

  return (
    <Col>
      <Label className="col-form-label pt-3">
        Tipe: <span>{itineraryData.type}</span>
      </Label>

      <FormGroup>
        <Label className="col-form-label">Tipe Event</Label>
        {itineraryData.eventType ? (
          <p>{itineraryData.eventType}</p>
        ) : (
          <Select
            defaultValue={{
              value: itineraryData.eventType,
              label: itineraryData.eventType ? itineraryData.eventType : "",
            }}
            name="eventType"
            options={getEventTypeOptions()}
            onChange={(e) => handleTypeChange(e.value)}
          />
        )}
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label">Judul</Label>
        <Input
          className="form-control"
          type="text"
          name="title"
          innerRef={register({ required: false })}
          defaultValue={itineraryData.title}
        />
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label">
          Tanggal, Waktu Mulai, Waktu Selesai
          <span className="text-danger">*</span>
        </Label>
        <Row>
          <Col lg="3">
            <SingleDatePicker
              date={itineraryData.date}
              onDateChange={(date) => setDate(date)}
              focused={focused.focused}
              onFocusChange={(focused) => setFocused(focused)}
              id="date"
              placeholder="Tanggal"
              numberOfMonths={1}
              orientation="horizontal"
              isOutsideRange={() => false}
              required
            />
          </Col>
          <Col lg="3">
            <Input
              type="time"
              name="time"
              id="time"
              innerRef={register({ required: false })}
              defaultValue={itineraryData.time}
              onChange={(value) => setTime(value.target.value)}
            />
          </Col>
          <Col lg="3">
            <Input
              className="form-control"
              type="time"
              name="endTime"
              innerRef={register({ required: false })}
              defaultValue={itineraryData.endTime}
            />
          </Col>
        </Row>
      </FormGroup>

      {itineraryData.eventType === "offline" && (
        <div>
          <FormGroup>
            <Label className="col-form-label">Alamat Lengkap</Label>
            <span> (Alamat, Kode Pos, Kota, Negara)</span>
            <Input
              className="form-control"
              type="text"
              name="address"
              innerRef={register({ required: false })}
              defaultValue={itineraryData?.address}
            />

            <Label className="col-form-label">
              Koordinat
              {"  "}
              <span>
                <a
                  href="https://www.latlong.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cari Koordinat
                </a>
              </span>
            </Label>
            <Row>
              <Col>
                <Label>Latitude</Label>
                <Input
                  className="form-control"
                  type="text"
                  name="latitude"
                  innerRef={register({ required: false })}
                  defaultValue={itineraryData?.coordinates?.latitude}
                />
              </Col>
              <Col>
                <Label>Longitude</Label>
                <Input
                  className="form-control"
                  type="text"
                  name="longitude"
                  innerRef={register({ required: false })}
                  defaultValue={itineraryData?.coordinates?.longitude}
                />
              </Col>
            </Row>
          </FormGroup>
        </div>
      )}

      {itineraryData.eventType === "online" && (
        <FormGroup>
          <Label className="col-form-label">Link Meeting</Label>
          <Input
            className="form-control"
            type="text"
            name="meetingLink"
            innerRef={register({ required: false })}
            defaultValue={itineraryData.meetingLink}
          />
        </FormGroup>
      )}
    </Col>
  );
};

export default Event;
