import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardImgOverlay,
  Label,
  Input,
} from "reactstrap";

import { useAuth } from "../../services/auth-context";
import firebase from "../../data/firebase";
import { InputAdornment, OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Overdue from "../../assets/images/svg/exclamation-octagon-fill.svg";
import Done from "../../assets/images/svg/checkmark-done-circle.svg";
import InProgress from "../../assets/images/svg/in-progress.svg";
import { replaceImageUrl } from "../../data/helper";

const TeamGetGoing = () => {
  const auth = useAuth();
  const [allAdmins, setAllAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");
  const [taskCountAbsoluteView, setTaskCountAbsoluteView] = useState(true);

  const sortTaskStats = (taskStats) => {
    var total =
      taskStats["Todo"] +
      taskStats["In Progress"] +
      taskStats["Review"] +
      taskStats["Done"] +
      taskStats["Postponed"] +
      taskStats["Cancelled"];

    return {
      Todo: taskStats["Todo"],
      "In Progress": taskStats["In Progress"],
      Review: taskStats["Review"],
      Done: taskStats["Done"],
      Postponed: taskStats["Postponed"],
      Cancelled: taskStats["Cancelled"],
      Total: total,
    };
  };

  const getStatusColor = (color) => {
    switch (color) {
      // card body
      case "Todo":
        return "#449AEF";
      case "In Progress":
        return "#FF8831";
      case "Review":
        return "#F2C94C";
      case "Done":
        return "#00C950";
      case "Cancelled":
        return "#DE350B";
      case "Postponed":
        return "#25213B";

      // cards
      case "done":
        return "#00C950";
      case "notDone":
        return "#FF8831";
      case "overdue":
        return "#F2C94C";
      default:
        return "#000000";
    }
  };

  useEffect(() => {
    var snapSize = -1;
    const fetchAdminData = async () => {
      try {
        var allAdmins = [];
        await firebase
          .firestore()
          .collection("users")
          .where("role", "in", ["superadmin", "admin"])
          .get()
          .then((querySnapshot) => {
            snapSize = querySnapshot.size;
            querySnapshot.forEach((doc) => {
              var id = doc.id;
              var userid = id;
              var userData = doc.data();
              var overdueTaskCount = 0;

              const taskCards = [
                {
                  name: "done",
                  label: "Selesai",
                  icon: Done,
                  taskCount: 0,
                },
                {
                  name: "notDone",
                  label: "Belum Selesai",
                  icon: InProgress,
                  taskCount: 0,
                },
                {
                  name: "overdue",
                  label: "Overdue",
                  icon: Overdue,
                  taskCount: 0,
                },
              ];

              var todoCount = 0;
              var inProgressCount = 0;
              var reviewCount = 0;
              var doneCount = 0;
              var postponedCount = 0;
              var cancelledCount = 0;

              // get deadlines of unfinished tasks
              firebase
                .firestore()
                .collection("users")
                .doc(id)
                .collection("tasks")
                .where("status", "in", ["Todo", "In Progress", "Review"])
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    var data = doc.data();

                    if (data.status === "Todo") {
                      todoCount++;
                      if (data.endDate.toDate() < new Date()) {
                        overdueTaskCount += 1;
                      }
                    }
                    if (data.status === "In Progress") {
                      inProgressCount++;
                      if (data.endDate.toDate() < new Date()) {
                        overdueTaskCount += 1;
                      }
                    }
                    if (data.status === "Review") {
                      reviewCount++;
                      if (data.endDate.toDate() < new Date()) {
                        overdueTaskCount += 1;
                      }
                    }
                  });

                  // var taskStats = {
                  //   Todo: todoCount,
                  //   "In Progress": inProgressCount,
                  //   Review: reviewCount,
                  //   Done: doneCount,
                  //   Cancelled: cancelledCount,
                  //   Postponed: postponedCount,
                  // };

                  var taskStats = userData["taskStats"];
                  taskCards[0].taskCount = taskStats["Done"];
                  taskCards[1].taskCount =
                    todoCount + inProgressCount + reviewCount;
                  taskCards[2].taskCount = overdueTaskCount;

                  allAdmins.push({
                    uid: id,
                    avatar: replaceImageUrl(userData["avatar"], 500, 500),
                    name: userData["fullName"],
                    email: userData["email"],
                    metric: userData["metric"],
                    taskStats: sortTaskStats(taskStats),
                    taskCards,
                  });

                  if (allAdmins.length == snapSize) {
                    setLoading(false);
                  }
                });
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
        setAllAdmins(allAdmins);
      } catch (err) {
        console.error(err);
      }
    };
    fetchAdminData();
  }, []);

  const getTaskCountView = (item, total) => {
    if (taskCountAbsoluteView) {
      return item;
    }
    return Math.round((item / total) * 100) + "%";
  };

  const filterSearch = (admin, searchFilter) => {
    return (
      admin.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
      (admin.metric &&
        admin.metric.toLowerCase().includes(searchFilter.toLowerCase()))
    );
  };

  const getTeamData = () => {
    var filteredAdmins = [];
    allAdmins.forEach((admin) => {
      if (filterSearch(admin, searchFilter)) {
        filteredAdmins.push(admin);
      }
    });
    return filteredAdmins;
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container fluid={true} className="py-3">
        <Card className="custom-card">
          <Row className="mb-5">
            <Col lg="4">
              <OutlinedInput
                autoFocus={true}
                defaultValue={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
                fullWidth={true}
                placeholder="Cari nama, posisi, department"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                style={{ height: "38px" }}
              />
            </Col>
          </Row>

          <div className="text-center">
            <Row>
              {getTeamData().map((item, index) => (
                <Col key={index} lg="3" className="mb-3 mb-lg-4">
                  <Card className="mb-4 mb-lg-0 rounded">
                    <div className="w-100 gradient-overlay text-white">
                      <CardImg
                        src={item.avatar}
                        alt={item.name}
                        className="w-100"
                      />
                      <div
                        className="card-body overlay-content p-3 w-100"
                        style={{ bottom: "0" }}
                      >
                        <h4 className="card-title text-shadow font-weight-bold mb-1">
                          {item.name && item.name}
                        </h4>
                        <p className="card-text text-sm text-uppercase">
                          {item.metric && item.metric}
                        </p>
                      </div>
                    </div>
                    <CardBody>
                      <CardTitle>
                        <Row>
                          {item.taskCards.map((card) => (
                            <Col lg="4" className="p-0" key={card.name + index}>
                              <Card
                                className="o-hidden"
                                style={{
                                  height: "100%",
                                  width: "90%",
                                }}
                              >
                                <CardBody
                                  className="b-r-4 p-1 m-0"
                                  style={{
                                    backgroundColor: getStatusColor(card.name),
                                  }}
                                >
                                  <div className="media static-top-widget">
                                    <div className="media-body pl-1">
                                      <p
                                        className="m-0 counter text-white text-left"
                                        style={{ fontSize: "10px" }}
                                      >
                                        {card.label}
                                      </p>
                                      <p
                                        className="m-0 text-white font-weight-bold text-left"
                                        style={{
                                          fontSize: "20px",
                                          position: "absolute",
                                          bottom: "0",
                                        }}
                                      >
                                        {card.taskCount}
                                      </p>

                                      <img
                                        src={card.icon}
                                        alt={card.name}
                                        className="icon-bg"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </CardTitle>
                      <CardText tag="span">
                        {Object.keys(item.taskStats)
                          .filter((key) => key !== "Total")
                          .map((keyName, i) => (
                            <Row
                              style={{ color: getStatusColor(keyName) }}
                              key={keyName + index}
                            >
                              <Col lg="8" className="text-left">
                                <i className="fa fa-circle"></i> {keyName}
                              </Col>
                              <Col lg="4">
                                {getTaskCountView(
                                  item.taskStats[keyName],
                                  item.taskStats["Total"]
                                )}
                              </Col>
                            </Row>
                          ))}
                        <Row>
                          <Col lg="8" className="text-left">
                            TOTAL
                          </Col>
                          <Col lg="4">{item.taskStats["Total"]}</Col>
                        </Row>
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Card>
      </Container>
    </Fragment>
  );
};

export default TeamGetGoing;
