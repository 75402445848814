import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  FormGroup,
  Row,
  Input,
  Label,
  Form,
  Button,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import firebase from "../../data/firebase";
import Dropzone from "../common/Dropzone";
import FileDownload from "../common/FileDownload";

const TentangKami = () => {
  const [loading, setLoading] = useState(true);
  const [tentangKamiData, setTentangKamiData] = useState({});
  const [photo, setPhoto] = useState({});

  const { handleSubmit } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("content")
          .doc("tentang-kami")
          .get();
        var responseData = response.data();
        setTentangKamiData(responseData);

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const history = useHistory();

  const handlePhotoUploaded = (block, index, inputTitle, photo) => {
    var newData = tentangKamiData;
    newData[block][index][inputTitle] = photo.preview;

    setTentangKamiData(newData);
    setPhoto(photo);
  };

  const handleInputChange = (block, index, inputTitle, input) => {
    var inputValue = input.target.value;
    if (input.target.type === "textarea") {
      inputValue = inputValue.replace(/\r\n|\r|\n/g, "</br>");
    }
    var newData = tentangKamiData;
    newData[block][index][inputTitle] = inputValue;
    setTentangKamiData(newData);
  };

  const onSubmit = (data) => {
    firebase
      .firestore()
      .collection("content")
      .doc("tentang-kami")
      .set(tentangKamiData, { merge: true })
      .then(() => {
        toast.success("Berhasil disimpan", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch(function (error) {
        console.log("error " + error);
      });
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Col className="p-3">
          <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
            <Card className="mt-4 p-3">
              <div className="mt-3 mb-5">
                <h2>Banner</h2>
                <Dropzone
                  path="webImages"
                  name="bannerTentangKami"
                  handlePhotoUploaded={(photo) =>
                    handlePhotoUploaded("banner", 0, "banner", photo)
                  }
                />
                <img src={tentangKamiData.banner[0].banner} className="w-100" />
                <FileDownload url={tentangKamiData.banner[0].banner} />
              </div>
              <hr />
              <div className="mt-3 mb-5">
                <h2>Tentang Kami</h2>
                {tentangKamiData.aboutUs.map((item, index) => (
                  <Row className="my-3" key={index}>
                    <Col xs="12" md="6">
                      <Dropzone
                        path="webImages"
                        name={"aboutUs" + index}
                        handlePhotoUploaded={(photo) =>
                          handlePhotoUploaded("aboutUs", index, "photo", photo)
                        }
                      />
                      <img src={item.photo} className="w-100" />
                      <FileDownload url={item.photo} />
                    </Col>
                    <Col xs="12" md="6">
                      <Label className="col-form-label">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control my-3"
                        type="text"
                        name="title"
                        defaultValue={item.title}
                        onChange={(title) =>
                          handleInputChange("aboutUs", index, "title", title)
                        }
                      />
                      <Label className="col-form-label">
                        Subtitle <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control my-3"
                        type="text"
                        name="subtitle"
                        defaultValue={item.subtitle}
                        onChange={(subtitle) =>
                          handleInputChange(
                            "aboutUs",
                            index,
                            "subtitle",
                            subtitle
                          )
                        }
                      />
                      <Label className="col-form-label">
                        Description <span className="text-danger">*</span>
                      </Label>
                      <Input
                        className="form-control my-3"
                        type="textarea"
                        rows="15"
                        name="description"
                        defaultValue={item.description.replace(
                          /<?\/?br>/g,
                          "\n"
                        )}
                        onChange={(description) =>
                          handleInputChange(
                            "aboutUs",
                            index,
                            "description",
                            description
                          )
                        }
                      />
                    </Col>
                  </Row>
                ))}
              </div>
              <hr />
              <div className="mt-3 mb-5">
                <h2>Kenapa Kami</h2>
                <Row>
                  {tentangKamiData.whyUs.map((item, index) => (
                    <Col xs="12" sm="12" md="4" key={index}>
                      <Dropzone
                        path="webImages"
                        name={"whyUs" + index}
                        handlePhotoUploaded={(photo) =>
                          handlePhotoUploaded("whyUs", index, "image", photo)
                        }
                      />
                      <img src={item.image} className="w-100" />
                      <FileDownload url={item.image} />
                      <Input
                        className="form-control my-3"
                        type="text"
                        name="title"
                        defaultValue={item.title}
                        onChange={(title) =>
                          handleInputChange("whyUs", index, "title", title)
                        }
                      />
                      <Input
                        className="form-control my-3"
                        type="textarea"
                        rows="5"
                        name="description"
                        defaultValue={item.description}
                        onChange={(description) =>
                          handleInputChange(
                            "whyUs",
                            index,
                            "description",
                            description
                          )
                        }
                      />
                    </Col>
                  ))}
                </Row>
              </div>
              <hr />
              <div className="mt-3 mb-5">
                <h2>Statistik</h2>
                <Row>
                  <Col>
                    <Label className="col-form-label">
                      Jumlah Perjalanan <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="tripCount"
                      defaultValue={tentangKamiData.stats[0].tripCount}
                      onChange={(tripCount) =>
                        handleInputChange("stats", 0, "tripCount", tripCount)
                      }
                    />
                  </Col>
                  <Col>
                    <Label className="col-form-label">
                      Jumlah Wisatawan <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="travellerCount"
                      defaultValue={tentangKamiData.stats[0].travellerCount}
                      onChange={(travellerCount) =>
                        handleInputChange(
                          "stats",
                          0,
                          "travellerCount",
                          travellerCount
                        )
                      }
                    />
                  </Col>
                </Row>
              </div>
              <hr />
              <div className="mt-3 mb-5">
                <h2>Founders</h2>
                <Row>
                  {tentangKamiData.founders.map((item, index) => (
                    <Col key={index} sm="3" className="mb-3 mb-lg-4">
                      <Card className="border-0 hover-animate bg-transparent">
                        <Dropzone
                          path="webImages"
                          name={"founders" + index}
                          handlePhotoUploaded={(photo) =>
                            handlePhotoUploaded(
                              "founders",
                              index,
                              "photo",
                              photo
                            )
                          }
                        />
                        <CardImg
                          top
                          src={item.photo}
                          alt={item.name}
                          className="team-img"
                        />
                        <FileDownload url={item.photo} />
                        <CardBody className="team-body text-center">
                          <CardTitle tag="h6">
                            <Input
                              className="form-control"
                              type="text"
                              name={`${item.uid},name`}
                              defaultValue={item.name}
                              onChange={(name) =>
                                handleInputChange(
                                  "founders",
                                  index,
                                  "name",
                                  name
                                )
                              }
                            />
                          </CardTitle>
                          <CardSubtitle
                            tag="p"
                            className="text-muted text-xs text-uppercase"
                          >
                            <Input
                              className="form-control"
                              type="text"
                              name={`${item.uid},metric`}
                              defaultValue={item.metric}
                              onChange={(metric) =>
                                handleInputChange(
                                  "founders",
                                  index,
                                  "metric",
                                  metric
                                )
                              }
                            />
                          </CardSubtitle>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
              <FormGroup className="form-row my-4">
                <Button className="btn-success-gradien mr-2" type="submit">
                  Simpan
                </Button>
              </FormGroup>
            </Card>
          </Form>
        </Col>
      </Container>
    </Fragment>
  );
};

export default TentangKami;
