import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Plus } from "react-feather";
import firebase from "../../data/firebase";
import {
  fetchCityData,
  getCityImage,
  replaceImageUrl,
} from "../../data/helper";
import { upperCaseFirst } from "../../data/helper";
import MaterialUiTable from "../common/MaterialUiTable";

const GetStayList = () => {
  const history = useHistory();
  const [getStayList, setGetStayList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (cityData) => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("getStayList")
          .get();
        var responseData = response.data();
        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          const name = value.author ? value.author.fullName : value.authorName;
          const email = value.author && value.author.email;
          const country = upperCaseFirst(value.country);
          const city = upperCaseFirst(value.city);
          const image = getCityImage(value.city, cityData);
          const avatar = value.author
            ? value.author.avatar
            : value.authorAvatar;
          data.push({
            id: key,
            avatar: replaceImageUrl(avatar, 80, 80),
            nameEmail: name + "," + email,
            title: value.title,
            country,
            city,
            countryCity: image + "," + city + "," + country,
          });
        }
        setGetStayList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchCityData().then((cityData) => {
      fetchData(cityData);
    });
  }, []);

  const toGetStayDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/services/getstay-detail/${id}`);
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "avatar",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img className="avatar-sm" src={value} alt={value} />;
        },
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "nameEmail",
      label: "NAMA, EMAIL",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var name = value.split(",")[0];
          var email = value.split(",")[1];
          return (
            <div>
              <p className="my-0 font-weight-bold">{name}</p>
              <p className="my-0">{email}</p>
            </div>
          );
        },
        filter: false,
      },
    },
    {
      name: "title",
      label: "JUDUL GETSTAY",
      options: {
        filter: false,
      },
    },
    {
      name: "country",
      label: "NEGARA",
      options: {
        display: "excluded",
        filterType: "multiselect",
      },
    },
    {
      name: "city",
      label: "KOTA",
      options: {
        display: "excluded",
        filterType: "multiselect",
      },
    },
    {
      name: "countryCity",
      label: "NEGARA, KOTA",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var img = value.split(",")[0];
          var city = value.split(",")[1];
          var country = value.split(",")[2];
          return (
            <Row>
              <Col lg="3">
                <img
                  className="avatar-rect"
                  src={
                    img
                      ? replaceImageUrl(img, 100, 80)
                      : "https://via.placeholder.com/40/FFFFFF/000000/?text=" +
                        city
                  }
                  alt={city}
                />
              </Col>
              <Col lg="9">
                <p className="my-0 font-weight-bold">{city}</p>
                <p className="my-0">{country}</p>
              </Col>
            </Row>
          );
        },
      },
    },
  ];

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row className="ml-1">
                  <h5 className="line-height-inherit mr-3">GetStay List</h5>
                  <p className="text-muted">(total {getStayList.length})</p>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-info-gradien mr-2 px-3 button-vertical-center ml-auto mr-3"
                    onClick={() => toGetStayDetail("new")}
                  >
                    <Plus /> Tambah GetStay
                  </Button>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <MaterialUiTable
                  columns={columns}
                  data={getStayList}
                  toItemDetail={toGetStayDetail}
                  sortInitialColumn="nameEmail"
                  searchableCols="Nama, Judul, Negara, Kota"
                  defaultSearchInput={true}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GetStayList;
