import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { Plus } from "react-feather";

import TicketProviderInput from "./TicketProviderInput";

const TicketProviderComponent = (props) => {
  const { value, setValue, providerId } = props;
  const firstInput = {};
  const [state, setState] = useState(value || []);

  const addInput = () => {
    setState([...state, { ...firstInput }]);
  };

  const handleChange = (e, idx) => {
    const updatedState = [...state];
    var fieldName = "";
    var value = "";
    var id = 0;

    const className = e.target.className;
    fieldName = className.split(" form-control")[0];
    value = e.target.value;
    id = e.target.dataset.id;

    updatedState[id][fieldName] = value;
    setState(updatedState);
    setValue(providerId, updatedState);
  };

  const handleTicketAgeRangeChange = (idx, newState) => {
    const updatedState = [...state];
    if (updatedState[idx] && updatedState[idx]["ticketAgeRange"]) {
      updatedState[idx]["ticketAgeRange"] = newState;
    } else {
      if (newState) {
        updatedState[idx] = {
          ...updatedState[idx],
          ticketAgeRange: newState,
        };
      }
    }
    setState(updatedState);
    setValue(providerId, updatedState);
  };

  const deleteInput = (id) => {
    const updatedState = [...state];
    updatedState.splice(id, 1);
    setState(updatedState);
    setValue(providerId, updatedState);
  };

  return (
    <Col className="ml-auto">
      {state &&
        state.map((val, idx) => (
          <TicketProviderInput
            key={`ticketPrice-${idx}`}
            idx={idx}
            state={state}
            handleChange={handleChange}
            handleTicketAgeRangeChange={handleTicketAgeRangeChange}
            deleteInput={deleteInput}
          />
        ))}

      <Button
        type="button"
        color="primary"
        className="btn-info-gradien mr-2 px-3 button-vertical-center"
        onClick={addInput}
      >
        <Plus /> Tambah Input Tiket
      </Button>
    </Col>
  );
};

export default TicketProviderComponent;
