import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { Plus } from "react-feather";

import TicketPriceInput from "./TicketPriceInput";

const TicketPriceComponent = (props) => {
  const { value, setValue } = props;
  const firstInput = {};
  const [state, setState] = useState(value || []);

  const addInput = () => {
    setState([...state, { ...firstInput }]);
  };

  const handleChange = (e, idx) => {
    const updatedState = [...state];
    var fieldName = "";
    var value = "";
    var id = 0;

    if (e instanceof File) {
      fieldName = "image";
      value = e.preview;
      id = idx;
    } else {
      const className = e.target.className;
      fieldName = className.split(" form-control")[0];
      value = e.target.value;
      id = e.target.dataset.id;
    }

    updatedState[id][fieldName] = value;
    setState(updatedState);
    setValue(updatedState);
  };

  const handleTicketProviderChange = (idx, newState) => {
    const updatedState = [...state];
    if (updatedState[idx] && updatedState[idx]["ticketProvider"]) {
      updatedState[idx]["ticketProvider"] = newState;
    } else {
      updatedState[idx] = {
        ...updatedState[idx],
        ticketProvider: newState,
      };
    }

    setState(updatedState);
    setValue(updatedState);
  };

  const deleteInput = (id) => {
    const updatedState = [...state];
    updatedState.splice(id, 1);
    setState(updatedState);
    setValue(updatedState);
  };

  return (
    <Col className="ml-auto">
      {state &&
        state.map((val, idx) => (
          <TicketPriceInput
            key={`ticketPrice-${idx}`}
            idx={idx}
            state={state}
            handleChange={handleChange}
            handleTicketProviderChange={handleTicketProviderChange}
            deleteInput={deleteInput}
          />
        ))}

      <Button
        type="button"
        color="primary"
        className="btn-info-gradien mr-2 px-3 button-vertical-center"
        onClick={addInput}
      >
        <Plus /> Tambah Provider
      </Button>
    </Col>
  );
};

export default TicketPriceComponent;
