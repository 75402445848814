import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { Plus } from "react-feather";

import CountryCityDestinationInput from "./CountryCityDestinationInput";

const CountryCityDestinationComponent = (props) => {
  const { cityCountry, setCityCountry } = props;
  const first = {};
  const [state, setState] = useState(cityCountry || []);

  const addCityCountry = () => {
    setState([...state, { ...first }]);
  };

  const handleChange = (field, e, idx) => {
    const updatedState = [...state];
    var value = e;

    updatedState[idx][field] = value;
    setState(updatedState);
    setCityCountry(updatedState);
  };

  const deleteInput = (id) => {
    const updatedState = [...state];
    updatedState.splice(id, 1);
    setState(updatedState);
    setCityCountry(updatedState);
  };

  return (
    <Col className="ml-auto">
      {state &&
        state.map((val, idx) => (
          <CountryCityDestinationInput
            key={`countryCity-${idx}`}
            idx={idx}
            state={state}
            handleChange={handleChange}
            deleteInput={deleteInput}
          />
        ))}

      <Button
        type="button"
        color="primary"
        className="btn-info-gradien mr-2 px-3 button-vertical-center"
        onClick={addCityCountry}
      >
        <Plus /> Tambah Kota
      </Button>
    </Col>
  );
};

export default CountryCityDestinationComponent;
