import React, { useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import SelectCountryCity from "../../../common/SelectCountryCity";

const Freetime = (props) => {
  const {
    itineraryData,
    setDate,
    setTime,
    setSelectedCountry,
    setSelectedCity,
  } = props;

  const [focused, setFocused] = useState({ focused: false });

  return (
    <Col>
      <Label className="col-form-label pt-3">
        Tipe: <span>{itineraryData.type}</span>
      </Label>

      <FormGroup>
        <Label className="col-form-label">Freetime</Label>
        <SelectCountryCity
          userCountry={itineraryData.country}
          userCity={itineraryData.city}
          selectedCountry={setSelectedCountry}
          selectedCity={setSelectedCity}
        />
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label">
          Tanggal, Waktu
          <span className="text-danger">*</span>
        </Label>
        <Row>
          <Col lg="4">
            <SingleDatePicker
              date={itineraryData.date}
              onDateChange={(date) => setDate(date)}
              focused={focused.focused}
              onFocusChange={(focused) => setFocused(focused)}
              id="date"
              placeholder="Tanggal"
              numberOfMonths={1}
              orientation="horizontal"
              isOutsideRange={() => false}
              required
            />
          </Col>
          <Col lg="4">
            <Input
              type="time"
              name="time"
              id="time"
              className="startTime"
              defaultValue={itineraryData.time}
              onChange={(value) => setTime(value.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>
    </Col>
  );
};

export default Freetime;
