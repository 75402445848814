import React, { useState, useEffect, useContext, createContext } from "react";
import app from "../data/firebase";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  // Handle a new user object (updates db and sets user state)
  const handleUser = (rawUser) => {
    if (rawUser) {
      var user = formatUser(rawUser);
      setUser(user);
      return user;
    } else {
      setUser(false);
      return false;
    }
  };

  useEffect(() => {
    const unsubscribe = app.auth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          setUser(user);
        } else {
          setUser(null);
        }
      } catch (error) {
      } finally {
        setLoadingUser(false);
      }
    });

    // Unsubscribe on cleanup
    return () => unsubscribe();
  }, []);

  return {
    user,
    loadingUser,
    handleUser,
  };
}

const formatUser = (user) => {
  return {
    uid: user.uid,
    email: user.email,
    photoURL: user.photoURL,
    displayName: user.displayName,
    role: user.role,
  };
};
