import React, { useState } from "react";
import { Col, Button } from "reactstrap";
import { Plus } from "react-feather";

import ItineraryInput from "./ItineraryInput";

const ItineraryComponent = (props) => {
  const { itinerary, setItinerary } = props;
  const firstDay = {};
  const [state, setState] = useState(itinerary || []);

  const addDay = () => {
    setState([...state, { ...firstDay }]);
  };

  const handleChange = (id, e) => {
    const updatedState = [...state];
    var fieldName = "";
    if (e.target && e.target.className) {
      var className = e.target.className;
      if (className === "cities form-control") {
        fieldName = "cities";
      } else if (className === "description form-control") {
        fieldName = "description";
      }
      updatedState[e.target.dataset.id][fieldName] = e.target.value;
    } else {
      if (typeof e.freeTime !== "undefined") {
        fieldName = "freeTime";
        updatedState[id][fieldName] = e.freeTime;
      } else if (e.country) {
        fieldName = "country";
        updatedState[id][fieldName] = e.country;
      } else if (e.city) {
        fieldName = "city";
        updatedState[id][fieldName] = e.city;
      }
    }

    setState(updatedState);
    setItinerary(updatedState);
  };

  const deleteInput = (id) => {
    const updatedState = [...state];
    updatedState.splice(id, 1);
    setState(updatedState);
    setItinerary(updatedState);
  };

  return (
    <Col className="ml-auto">
      {state &&
        state.map((val, idx) => (
          <ItineraryInput
            key={`itinerary-${idx}`}
            idx={idx}
            itineraryState={state}
            handleItineraryState={handleChange}
            deleteInput={deleteInput}
          />
        ))}

      <Button
        type="button"
        color="primary"
        className="btn-info-gradien mr-2 px-3 button-vertical-center my-3"
        onClick={addDay}
      >
        <Plus /> Tambah Hari
      </Button>
    </Col>
  );
};

export default ItineraryComponent;
