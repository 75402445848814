import React, { useState, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { useDocument } from "react-firebase-hooks/firestore";
import * as firebaseApp from "firebase/app";

import { ChatContext } from "./helper/Context";
import { arrayReducer } from "./helper/arrayReducer";
import { isIterableArray } from "./helper/utils";

import { groups } from "./helper/data";
import firebase from "../../data/firebase";
import { useAuth } from "../../services/auth-context";

const ChatProvider = ({ children }) => {
  const auth = useAuth();
  const currentUserId = auth.user.uid;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [messages, messagesDispatch] = useReducer(arrayReducer, []);
  const [threads, threadsDispatch] = useReducer(arrayReducer, []);
  const [textAreaInitialHeight, setTextAreaInitialHeight] = useState(32);
  const [activeThreadId, setActiveThreadIdState] = useState(0);
  const [activeMessagesId, setActiveMessagesId] = useState(0);
  const [activeMessages, setActiveMessages] = useState([]);

  const setActiveThreadId = (threadId) => {
    setActiveThreadIdState(threadId);

    setActiveMessageWithThreadId(threads, threadId);
  };

  const updateActiveMessage = (message) => {
    const updatedMessage = {
      id: message.id,
      content: message.content,
    };

    setActiveMessages(updatedMessage);
  };

  const setMessageData = (value) => {
    const newContent = value.content;

    firebase
      .firestore()
      .collection("chats")
      .doc("threads")
      .collection("messages")
      .doc(activeMessagesId)
      .set({ content: newContent }, { merge: true });
  };

  const getUser = (thread) => {
    let user = {};

    if (thread) {
      if (isIterableArray(thread.customerUid)) {
        const { name, members } = groups.find(
          ({ id }) => id === thread.customerUid[0]
        );
        user = {
          name,
          avatar: members.map(
            (member) => users.find(({ id }) => id === member.customerUid).avatar
          ),
        };
      } else {
        user = users.find(
          ({ id }) =>
            id.toString() === thread.customerUid || id === thread.customerUid
        );
      }
    }

    return user;
  };

  const setActiveMessageWithThreadId = (threads, threadId) => {
    setLoading(true);
    const thread = threads.find(
      ({ messagesId }) =>
        messagesId.toString() === threadId || messagesId === threadId
    );

    if (thread) {
      const messageId = thread.messagesId;
      setActiveMessagesId(messageId);
      firebase
        .firestore()
        .collection("chats")
        .doc("threads")
        .collection("messages")
        .doc(messageId)
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          const message = {
            id: snapshot.id,
            content: data.content,
          };
          setActiveMessages(message);
          setLoading(false);
        });
    }
  };

  const searchOrCreateThread = (uid) => {
    var threadFound = false;
    threads.forEach((thread) => {
      if (thread.customerUid === uid) {
        setActiveThreadId(thread.messagesId);
        threadFound = true;
      }
    });

    if (!threadFound) {
      // create new thread
      setLoading(true);
      const newDocId = uid + "-CSChat";
      firebase
        .firestore()
        .collection("chats")
        .doc("threads")
        .collection("messages")
        .doc(newDocId)
        .set(
          {
            customerUid: uid,
            serviceUid: currentUserId,
            content: [],
          },
          { merge: true }
        )
        .then(() => {
          firebase
            .firestore()
            .collection("chats")
            .doc("threads")
            .set(
              {
                threads: firebaseApp.firestore.FieldValue.arrayUnion({
                  customerUid: uid,
                  messagesId: newDocId,
                  lastMessage: {},
                  serviceUid: "defaultCustomerService",
                }),
              },
              { merge: true }
            )
            .then(() => {
              setLoading(false);
              setActiveThreadId(newDocId);
              setActiveMessagesId(newDocId);
            });
        })
        .catch((e) => console.log(e));
    }
  };

  const threadsRef = firebase.firestore().collection("chats").doc("threads");
  const [threadsData, loadingThreads, errorThreads] = useDocument(
    threadsRef,
    {}
  );

  const usersRef = firebase
    .firestore()
    .collection("aggregateList")
    .doc("userList");
  const [usersData, loadingUsers, errorUsers] = useDocument(usersRef, {});

  useEffect(() => {
    if (!loadingThreads && !loadingUsers) {
      // thread data
      const rawThreadData = threadsData.data().threads;
      threadsDispatch({
        type: "INIT",
        payload: rawThreadData,
      });

      //todo: sort by lastmessage, set read

      const threadId = rawThreadData[0].messagesId;
      setActiveThreadId(threadId);
      setActiveMessageWithThreadId(rawThreadData, threadId);

      // user data
      const rawUsersData = usersData.data();
      var userList = [];
      Object.keys(rawUsersData).forEach((uid) => {
        const data = rawUsersData[uid];
        userList.push({
          id: uid,
          fullName: data.fullName,
          avatar: data.avatar,
          role: data.role,
        });
      });
      setUsers(userList);

      setLoading(false);
    }
  }, [loadingThreads, loadingUsers, threadsData]);

  const value = {
    users,
    groups,
    threads,
    getUser,
    messages,
    activeThreadId,
    setActiveThreadId,
    threadsDispatch,
    messagesDispatch,
    textAreaInitialHeight,
    setTextAreaInitialHeight,
    loading,
    activeMessages,
    setMessageData,
    activeMessagesId,
    updateActiveMessage,
    searchOrCreateThread,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

ChatProvider.propTypes = { children: PropTypes.node.isRequired };

export default ChatProvider;
