import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import * as firebaseApp from "firebase/app";
import firebase from "../../data/firebase";
import FileDownload from "../common/FileDownload";
import ItineraryComponent from "./GetFlexInput/ItineraryComponent";
import CountryCityDestinationComponent from "./GetFlexInput/CountryCityDestinationComponent";
import LinkButton from "../common/LinkButton";
import DepartureTables from "./GetFlexInput/DeparturesTable";
import USPComponent from "./GetFlexInput/USPComponent";
import Dropzone from "../common/Dropzone";
import AddOnsComponent from "./GetFlexInput/AddOnsComponent";
import EntryComponent from "../cms/tnc-faq-components/EntryComponent";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const GetFlexDetail = () => {
  let { id } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(1);
  }

  const includedList = [
    "visa",
    "healthInsuranceCovid",
    "accommodation",
    "roundTripTicket",
    "transferBus",
    "swissTravelPass",
    "PCRTest",
    "customerService",
  ];

  const mapIncludedItem = {
    visa: "Visa",
    healthInsuranceCovid: "Asuransi Kesehatan (Cover Covid)",
    accommodation: "Penginapan",
    roundTripTicket: "Tiket Pesawat Pulang Pergi",
    transferBus: "Bus untuk Transfer",
    swissTravelPass: "Swiss Travel Pass",
    PCRTest: "PCR Test",
    customerService: "Customer Service 24/7",
  };

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [getFlexData, setGetFlexData] = useState({});
  const [loading, setLoading] = useState(true);
  const [newService, setNewService] = useState(true);
  const [formData, setFormData] = useState({
    type: "",
    cities: [],
    itinerary: [],
    included: includedList.reduce(
      (map, item) => ((map[item] = false), map),
      {}
    ),
    usp: [],
    getFlexAuthor: {
      name: "",
      avatar: "",
    },
    addOns: [],
    tnc: [],
    faq: [],
    cancellationPolicy: [],
  });
  const [previousCityList, setPreviousCityList] = useState([]);

  const [mainPhoto, setMainPhoto] = useState({});
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      accept: "image/*",
      minSize: 0,
      maxSize: 5242880, //5mb
      multiple: false,
      required: true,
      onDrop: (acceptedFiles) => {
        setPhotoUploadFinished(false);
        var ref = firebase.storage().ref();
        var file = acceptedFiles[0];
        const fileName = uuidv4();
        ref
          .child("getflexImage/" + fileName)
          .put(file)
          .then(() => {
            ref
              .child("getflexImage/" + fileName)
              .getDownloadURL()
              .then((url) => {
                var photo = Object.assign(file, {
                  preview: url,
                });
                setMainPhoto(photo);
                return setPhotoUploadFinished(true);
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });

  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: true,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotos([]);
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      Promise.all(
        acceptedFiles.map((file) => {
          const fileName = uuidv4();
          ref
            .child("getflexImage/" + fileName)
            .put(file)
            .then(() => {
              ref
                .child("getflexImage/" + fileName)
                .getDownloadURL()
                .then((url) => {
                  file["preview"] = url;
                  setPhotos((photos) => [...photos, file]);
                  return setPhotoUploadFinished(true);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
    },
  });

  const getFlexTypes = [
    "semiFlexible",
    "flexible",
    "openTrip",
    "backpacker",
    "privateTrip",
  ];

  const typeMap = {
    semiFlexible: "Semi-Fleksibel",
    flexible: "Fleksibel",
    openTrip: "Open Trip",
    backpacker: "Backpacker",
    privateTrip: "Private Trip",
  };

  const [checkboxInput, setCheckboxInput] = useState({
    types: {
      basic: false,
      vip: false,
    },
  });

  const setFormDataValue = (property, value) => {
    setFormData({ ...formData, [property]: value });
  };

  const handleGetFlexAuthorAvatarChange = (e) => {
    const preview = e.preview;
    const getFlexAuthor = formData.getFlexAuthor;
    getFlexAuthor["avatar"] = preview;
    setFormData({ ...formData, getFlexAuthor });
  };

  const setIncluded = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    const includedData = formData.included;
    includedData[id] = !value;
    setFormData({ ...formData, included: includedData });
  };

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        var galleryPhotos = [];
        const response = await firebase
          .firestore()
          .collection("getFlex")
          .doc(id)
          .get();
        if (response.exists) {
          data = response.data();
          data.gallery.forEach((photo) => {
            galleryPhotos.push({ preview: photo.image, name: photo.title });
          });

          setFormData({
            ...formData,
            type: data.type,
            cities: data.cities ? data.cities : [],
            itinerary:
              data.itinerary && data.itinerary.length > 0 ? data.itinerary : [],
            included: data.included
              ? data.included
              : includedList.reduce(
                  (map, item) => ((map[item] = false), map),
                  {}
                ),
            usp: data.usp ? data.usp : [],
            getFlexAuthor: data.getFlexAuthor || {
              name: "",
              author: "",
            },
            addOns: data.addOns ? data.addOns : [],
            tnc: data.tnc ? data.tnc : [],
            faq: data.faq ? data.faq : [],
            getFlexBooklet: data.getFlexBooklet || "",
            cancellationPolicy: data.cancellationPolicy || [],
          });

          setCheckboxInput({
            categories: data["type"]
              ? data["type"]
              : {
                  basic: false,
                  vip: false,
                },
          });

          setPreviousCityList(data.cities);
        }
        setGetFlexData(data);
        setPhotos(galleryPhotos);
        setMainPhoto({ preview: data.image });
      } catch (err) {
        console.error(err);
      }
    };
    if (id !== "new") {
      setNewService(false);
      fetchData().then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  const onTypeChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, type: value });
  };

  const deleteFromList = async (id) => {
    await firebase
      .firestore()
      .collection("aggregateList")
      .doc("getFlexList")
      .set(
        {
          [id]: firebaseApp.firestore.FieldValue.delete(),
        },
        { merge: true }
      );
  };

  const deleteFromCities = (cities, id) => {
    if (cities && cities.length > 0) {
      cities.forEach((item) => {
        const country = item.country;
        const city = item.city;
        firebase
          .firestore()
          .collection("countries")
          .doc(country)
          .collection("cities")
          .doc(city)
          .collection("getFlex")
          .doc(id)
          .delete();
      });
    }
  };

  const deleteService = () => {
    firebase
      .firestore()
      .collection("getFlex")
      .doc(id)
      .delete()
      .then(() => {
        deleteFromList(id).then(() => {
          deleteFromCities(formData["cities"], id);
          toast.success("Berhasil dihapus", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.push(`${process.env.PUBLIC_URL}/services/getflex-list`);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const getCountryCount = (cityArr) => {
    var groupCityByCountry = cityArr.reduce(function (r, a) {
      r[a.country] = r[a.country] || [];
      r[a.country].push(a);
      return r;
    }, Object.create(null));

    if (groupCityByCountry) return Object.keys(groupCityByCountry).length;
    return 0;
  };

  const saveToList = async (docId, data) => {
    await firebase
      .firestore()
      .collection("aggregateList")
      .doc("getFlexList")
      .set(
        {
          [docId]: data,
        },
        { merge: true }
      );
  };

  const cityIsInList = (city, newList) => {
    for (let i = 0; i < newList.length; i++) {
      if (newList[i].city === city) {
        return true;
      }
    }
    return false;
  };

  const saveToCity = (docId, countryCityList, data) => {
    // remove from removed cities
    if (previousCityList && previousCityList.length > 0) {
      previousCityList.forEach((item) => {
        const country = item.country;
        const city = item.city;

        if (!cityIsInList(city, countryCityList)) {
          firebase
            .firestore()
            .collection("countries")
            .doc(country)
            .collection("cities")
            .doc(city)
            .collection("getFlex")
            .doc(docId)
            .delete()
            .catch((err) => console.log(err));
        }
      });
    }

    countryCityList.forEach((item) => {
      const country = item.country;
      const city = item.city;

      firebase
        .firestore()
        .collection("countries")
        .doc(country)
        .collection("cities")
        .doc(city)
        .collection("getFlex")
        .doc(docId)
        .set(data, { merge: true });
    });
  };

  const updateWishtripGetFlex = (wishtripUserList, docId, docData) => {
    wishtripUserList &&
      wishtripUserList.forEach((uid) => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("wishtrip")
          .doc(docId)
          .set(docData, { merge: true });
      });
  };

  const onSubmit = (data) => {
    var docId = "";
    var gallery = [];

    if (data !== "") {
      var slug = "getflex-" + data["title"].replace(/[^0-9|a-z|A-Z]/g, "");
      var image = mainPhoto.preview ? mainPhoto.preview : "";

      if (newService) {
        docId = uuidv4();
      } else {
        docId = id;
      }

      photos.forEach((photo) => {
        gallery.push({
          image: photo["preview"],
          title: photo["name"],
        });
      });

      const itemData = {
        tag: data["tag"] || "",
        title: data["title"] || "",
        type: formData["type"] || "",
        gallery: gallery || [],
        image: image || "",
        durationDay: data["durationDay"] || "",
        durationNight: data["durationNight"] || "",
        // pricePerPerson: data["pricePerPerson"] || "",
        description: data["description"] || "",
        included: formData["included"],
        additionalIncluded: data["additionalIncluded"] || "",
        notIncluded: data["notIncluded"] || "",
        cities: formData["cities"] || "",
        itinerary: formData["itinerary"] || "",
        usp: formData["usp"] || "",
        getFlexAuthor: {
          name: data["getFlexAuthorName"] || "",
          avatar: formData["getFlexAuthor"]["avatar"] || "",
        },
        addOns: formData["addOns"] || [],
        tnc: formData["tnc"] || [],
        faq: formData["faq"] || [],
        cancellationPolicy: formData["cancellationPolicy"] || [],
        getFlexBooklet: formData["getFlexBooklet"] || "",
        slug: slug || "",
      };

      const countriesSet = new Set();
      if (itemData.cities && itemData.cities.length > 0) {
        itemData.cities.forEach((countryCity) => {
          countriesSet.add(countryCity.country);
        });
      }

      const listData = {
        tag: itemData["tag"],
        title: itemData["title"],
        type: itemData["type"],
        image: itemData["image"],
        durationDay: itemData["durationDay"],
        durationNight: itemData["durationNight"],
        countryCount: getCountryCount(itemData["cities"]),
        countriesList: Array.from(countriesSet),
        cityCount: itemData["cities"].length,
        // pricePerPerson: itemData["pricePerPerson"],
        dates: getFlexData.dates || [],
      };

      firebase
        .firestore()
        .collection("getFlex")
        .doc(docId)
        .set(itemData, { merge: true })
        .then(() => {
          saveToList(docId, listData)
            .then(function () {
              saveToCity(docId, itemData.cities, listData);

              // update getFlexData in wishtrip
              const wishtripUserList = getFlexData.wishtripUser;
              var wishtripData = {
                countryCount: getCountryCount(itemData["cities"]),
                cityCount: itemData["cities"].length,
                durationDay: data["durationDay"],
                durationNight: data["durationNight"],
                image: itemData["image"],
                // pricePerPerson: itemData["pricePerPerson"],
                title: itemData["title"],
              };
              updateWishtripGetFlex(wishtripUserList, docId, wishtripData);

              toast.success("Berhasil disimpan", {
                position: toast.POSITION.TOP_CENTER,
              });
              history.push(`${process.env.PUBLIC_URL}/services/getflex-list`);
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus GetFlex?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteService}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2 className="mb-5">GetFlex</h2>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  {!newService && (
                    <Row className="mb-3">
                      <LinkButton
                        title="Lihat Halaman Service"
                        url={`https://getgoing.co.id/getFlex/${getFlexData.slug}?id=${id}`}
                      />
                    </Row>
                  )}
                  <Label for="mainImage" className="d-block form-label">
                    Foto Utama <span className="text-danger">*</span>
                  </Label>

                  <div
                    {...getRootProps2({ className: "dropzone dz-clickable" })}
                  >
                    <input {...getInputProps2()} />
                    <div className="dz-message text-muted">
                      <p>Klik untuk ubah foto baru (max 1)</p>
                      <img
                        src={mainPhoto["preview"]}
                        alt={mainPhoto["preview"]}
                        className="avatar avatar-large mb-2"
                      />
                      {!photoUploadFinished && (
                        <div className="loader-box">
                          <div className="loader-3"></div>
                        </div>
                      )}
                    </div>
                  </div>
                  <Label className="col-form-label">
                    Tipe<span className="text-danger">*</span>
                  </Label>

                  {getFlexTypes.map((item) => (
                    <FormGroup key={item} style={{ marginLeft: 20 }}>
                      <Label>
                        <Input
                          type="radio"
                          id={item}
                          key={item}
                          name={item}
                          value={item}
                          checked={formData.type === item}
                          onChange={(e) => onTypeChange(e)}
                        />
                        {typeMap[item]}
                      </Label>
                    </FormGroup>
                  ))}

                  <FormGroup>
                    <Label className="col-form-label">Tag</Label>
                    <span> (1 baris per tag. Cth: Eropa, Asia, ...)</span>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="tag"
                      innerRef={register({ required: false })}
                      defaultValue={getFlexData.tag}
                    />
                    <span style={{ color: "red" }}>
                      {errors.tag && "Harus diisi"}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Judul <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="title"
                      innerRef={register({ required: true })}
                      defaultValue={getFlexData.title}
                    />
                    <span style={{ color: "red" }}>
                      {errors.title && "Harus diisi"}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Deskripsi <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="description"
                      innerRef={register({ required: true })}
                      defaultValue={getFlexData.description}
                    />
                    <span style={{ color: "red" }}>
                      {errors.description && "Harus diisi"}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Durasi<span className="text-danger">*</span>
                    </Label>
                    <Row>
                      <FormGroup>
                        <Label className="col-form-label">
                          Hari<span className="text-danger">*</span>
                        </Label>
                        <Input
                          className="form-control"
                          type="number"
                          name="durationDay"
                          innerRef={register({ required: true })}
                          defaultValue={getFlexData.durationDay}
                        />
                        <span style={{ color: "red" }}>
                          {errors.durationDay && "Harus diisi"}
                        </span>
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">
                          Malam<span className="text-danger">*</span>
                        </Label>
                        <Input
                          className="form-control"
                          type="number"
                          name="durationNight"
                          innerRef={register({ required: true })}
                          defaultValue={getFlexData.durationNight}
                        />
                        <span style={{ color: "red" }}>
                          {errors.durationNight && "Harus diisi"}
                        </span>
                      </FormGroup>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Termasuk</Label>

                    {includedList.map((keyName, i) => (
                      <FormGroup check key={i}>
                        <Label check>
                          <Input
                            type="checkbox"
                            id={keyName}
                            key={keyName}
                            name={keyName}
                            value={formData.included[keyName] || ""}
                            defaultChecked={
                              formData.included
                                ? formData.included[keyName]
                                : false
                            }
                            onChange={setIncluded}
                          />{" "}
                          {mapIncludedItem[keyName]}
                        </Label>
                      </FormGroup>
                    ))}

                    <Label className="col-form-label">
                      Termasuk Tambahan (1 item per baris)
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="additionalIncluded"
                      innerRef={register({ required: false })}
                      defaultValue={
                        getFlexData.additionalIncluded &&
                        getFlexData.additionalIncluded.replace(",", "\n")
                      }
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Tidak Termasuk (1 item per baris)
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="notIncluded"
                      innerRef={register({ required: false })}
                      defaultValue={
                        getFlexData.notIncluded &&
                        getFlexData.notIncluded.replace(",", "\n")
                      }
                    />
                  </FormGroup>

                  <FormGroup className="mt-5">
                    <h4>Unique Selling Point</h4>
                    <USPComponent
                      value={formData.usp}
                      setValue={(value) => setFormDataValue("usp", value)}
                    />
                  </FormGroup>

                  {/* todo: country code for flags  */}
                  <FormGroup className="mt-5">
                    <h4>Destinasi</h4>
                    <CountryCityDestinationComponent
                      cityCountry={formData.cities}
                      setCityCountry={(value) =>
                        setFormDataValue("cities", value)
                      }
                    />
                  </FormGroup>

                  <FormGroup className="mt-5">
                    <h4>Itinerary</h4>
                    <ItineraryComponent
                      itinerary={formData.itinerary}
                      setItinerary={(value) =>
                        setFormDataValue("itinerary", value)
                      }
                    />
                  </FormGroup>

                  <FormGroup className="mt-5">
                    <h4>Add-Ons</h4>
                    <AddOnsComponent
                      value={formData.addOns}
                      setValue={(value) => setFormDataValue("addOns", value)}
                    />
                  </FormGroup>

                  <FormGroup className="mt-5">
                    <h4>TNC Spesifik</h4>
                    <EntryComponent
                      value={formData.tnc}
                      setValue={(value) => setFormDataValue("tnc", value)}
                    />
                  </FormGroup>

                  <FormGroup className="mt-5">
                    <h4>FAQ Spesifik</h4>
                    <EntryComponent
                      value={formData.faq}
                      setValue={(value) => setFormDataValue("faq", value)}
                    />
                  </FormGroup>

                  <FormGroup className="mt-5">
                    <h4>Kebijakan Pembatalan Spesifik</h4>
                    <EntryComponent
                      value={formData.cancellationPolicy}
                      setValue={(value) =>
                        setFormDataValue("cancellationPolicy", value)
                      }
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Booklet</Label>
                    <Row>
                      <Col lg="4">
                        <Dropzone
                          path="itineraryFiles"
                          name={"Booklet"}
                          handlePhotoUploaded={(e) =>
                            setFormDataValue("getFlexBooklet", e.preview)
                          }
                          itineraryFile
                        />
                      </Col>
                      <Col lg="8">
                        <Document
                          file={formData.getFlexBooklet}
                          onLoadSuccess={onDocumentLoadSuccess}
                          className="pdf-preview"
                        >
                          <Page
                            pageNumber={pageNumber}
                            renderTextLayer={false}
                          />
                        </Document>

                        {formData.getFlexBooklet && (
                          <FileDownload url={formData.getFlexBooklet} />
                        )}
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label pt-0">Foto Galeri</Label>
                    <Row className="ml-n1 mr-n1">
                      {photos &&
                        photos.map((file, index) => (
                          <Col key={index} lg="4" xs="6" className="px-1 mb-2">
                            <img
                              src={file.preview}
                              alt={file.preview}
                              className="img-fluid"
                            />

                            <FileDownload url={file.preview} />
                          </Col>
                        ))}
                    </Row>
                    <div
                      {...getRootProps({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps()} />

                      <div className="dz-message text-muted">
                        <p>Upload file-file baru (minimal 2)</p>
                        {!newService && (
                          <p>Foto-foto lama akan diganti dengan foto baru</p>
                        )}
                        {!photoUploadFinished && (
                          <div className="loader-box">
                            <div className="loader-3"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </FormGroup>

                  {!newService && <DepartureTables getFlexId={id} />}
                  <h5>Disusun oleh</h5>
                  <FormGroup className="mb-3">
                    <Label className="form-label">Nama</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="getFlexAuthorName"
                      innerRef={register({ required: false })}
                      defaultValue={
                        getFlexData.getFlexAuthor &&
                        getFlexData.getFlexAuthor.name
                      }
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label className="form-label">Foto</Label>
                    <Row>
                      <Col>
                        <Dropzone
                          path="webImages"
                          name={"getFlexAuthor"}
                          handlePhotoUploaded={(e) =>
                            handleGetFlexAuthorAvatarChange(e)
                          }
                        />
                      </Col>
                      <Col>
                        <span className="media user-header">
                          <img
                            className="mr-2 img-35"
                            src={formData.getFlexAuthor.avatar}
                            alt={"getFlexAuthorAvatar"}
                            style={{ maxHeight: 100, maxWidth: 100 }}
                          />
                        </span>
                        {formData.getFlexAuthor.avatar && (
                          <FileDownload url={formData.getFlexAuthor.avatar} />
                        )}
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="form-row mt-5 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      disabled={!photoUploadFinished}
                    >
                      Simpan
                    </Button>
                    {!newService && (
                      <Button
                        type="button"
                        onClick={toggle}
                        color="mr-2"
                        className="btn-secondary-gradien mr-2"
                      >
                        Hapus Layanan
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default GetFlexDetail;
