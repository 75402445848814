import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import * as firebaseApp from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../data/firebase";
import SelectCountryCity from "../common/SelectCountryCity";
import FileDownload from "../common/FileDownload";
import TicketPriceComponent from "../services/PublicTransportation/TicketPriceComponent";
import TicketShopComponent from "../services/PublicTransportation/TicketShopComponent";
import { upperCaseFirst } from "../../data/helper";
import LinkButton from "../common/LinkButton";
import FreeActivitiesList from "./free-activities/FreeActivitiesList";
import RecommendedItineraryList from "./recommended-itinerary/RecommendedItineraryList";
import CovidLinksComponent from "../services/CovidLinks/CovidLinksComponent";
import Dropzone from "../common/Dropzone";
import FilePreview from "../common/FilePreview";

const CountryCityDetail = () => {
  let { country, city } = useParams();

  const [loading, setLoading] = useState(true);
  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [newCity, setNewCity] = useState(true);
  const [cityData, setCityData] = useState({});

  const vaccinesList = [
    "Pfizer (BioNTech)",
    "Moderna",
    "AstraZeneca",
    "Vaxzevria",
    "Covishield",
    "Johnson & Johnson",
    "CoronaVac (Sinovac)",
    "Sinopharm",
    "Novavax",
  ];

  const mapPublicPlaceOpeningHours = {
    restaurantCafe: "Restoran & Café",
    supermarktMall: "Supermarket & Mall",
    theater: "Bioskop & Tempat Hiburan Malam",
    openRestricted: "Buka hingga jam: ",
    openNormal: "Buka normal (tidak ada aturan jam tutup karena covid)",
    closed: "Tutup (sama sekali tidak buka akibat covid)",
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [heroPhoto, setHeroPhoto] = useState({});
  const [isPhotoUpdated, setPhotoUpdated] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880, //5mb
    multiple: false,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotoUploadFinished(false);

      const file = acceptedFiles[0];
      uploadHeroPhoto(file).then((url) => {
        file["preview"] = url;
        setPhotoUploadFinished(true);
        setHeroPhoto(file);
        setPhotoUpdated(true);
      });
    },
  });

  const uploadHeroPhoto = (file) => {
    return new Promise((resolve, reject) => {
      const fileName = uuidv4();
      const ref = firebase.storage().ref(`cityImages/${fileName}`);
      ref.put(file).then(() => {
        ref
          .getDownloadURL()
          .then((url) => {
            resolve(url);
          })
          .catch((e) => reject(e));
      });
    }).catch((err) => console.log(err));
  };

  const [photos, setPhotos] = useState([]);
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      accept: "image/*",
      minSize: 0,
      maxSize: 5242880, //5mb
      multiple: true,
      required: true,
      onDrop: (acceptedFiles) => {
        setPhotos([]);
        setPhotoUploadFinished(false);
        var ref = firebase.storage().ref();
        Promise.all(
          acceptedFiles.map((file) => {
            const fileName = uuidv4();
            ref
              .child("cityImages/" + fileName)
              .put(file)
              .then(() => {
                ref
                  .child("cityImages/" + fileName)
                  .getDownloadURL()
                  .then((url) => {
                    file["preview"] = url;
                    setPhotoUploadFinished(true);
                    return setPhotos((photos) => [...photos, file]);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
          })
        );
      },
    });

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("countries")
          .doc(country)
          .collection("cities")
          .doc(city)
          .get();
        if (response.exists) {
          data = response.data();

          if (!data.ticketPrice) {
            data.ticketPrice = [];
          }

          if (!data.ticketShop) {
            data.ticketShop = [];
          }
        }
        setHeroPhoto({ preview: data.hero });
        const currentPhotos = data.lightboxImages;
        var photos = [];
        if (currentPhotos) {
          currentPhotos.forEach((image) => {
            photos.push({ preview: image });
          });
        }

        setPhotos(photos);
        setCityData({
          ...data,
          country: country,
          city: city,
          covidRegulations:
            data.covidRegulations &&
            Object.keys(data.covidRegulations).length > 0
              ? data.covidRegulations
              : {
                  indonesianAllowed: "",
                  vaccines: vaccinesList.reduce(
                    (map, vaccine) => ((map[vaccine] = false), map),
                    {}
                  ),
                  publicPlaceOpeningHours: {
                    restaurantCafe: "",
                    supermarktMall: "",
                    theater: "",
                  },
                  applications: [],
                  importantLinks: [],
                },
        });
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    if (country !== "new" && city !== "new") {
      fetchData();
      setNewCity(false);
    } else {
      setCityData({
        covidRegulations: {
          indonesianAllowed: "",
          vaccines: vaccinesList.reduce(
            (map, vaccine) => ((map[vaccine] = false), map),
            {}
          ),
          publicPlaceOpeningHours: {
            restaurantCafe: "",
            supermarktMall: "",
            theater: "",
          },
          applications: [],
          importantLinks: [],
        },
      });
      setLoading(false);
    }
  }, []);

  const setSelectedCountry = (country) => {
    setCityData({ ...cityData, country: country });
  };

  const setSelectedCity = (city) => {
    setCityData({ ...cityData, city: city });
  };

  const setTicketPrice = (value) => {
    setCityData({ ...cityData, ticketPrice: value });
  };

  const setTicketShop = (value) => {
    setCityData({ ...cityData, ticketShop: value });
  };

  const setCovidRegulationsApplications = (value) => {
    const covidRegulations = cityData.covidRegulations;
    covidRegulations["applications"] = value;
    setCityData({ ...cityData, covidRegulations });
  };

  const setCovidRegulationsImportantLinks = (value) => {
    const covidRegulations = cityData.covidRegulations;
    covidRegulations["importantLinks"] = value;
    setCityData({ ...cityData, covidRegulations });
  };

  const setCovidRegulations = (field, e) => {
    const value = e.target.value;

    const covidRegulations = cityData.covidRegulations;

    if (field === "vaccines") {
      const id = e.target.id;
      covidRegulations[field][id] = !covidRegulations[field][id];
    } else if (field.includes("publicPlaceOpeningHours")) {
      const id = field.split("::")[1];
      covidRegulations.publicPlaceOpeningHours[id] = value;
    } else {
      covidRegulations[field] = value;
    }
    setCityData({ ...cityData, covidRegulations: covidRegulations });
  };

  const handleFileUploaded = (field, e) => {
    const value = e.preview;
    setCityData({ ...cityData, [field]: value });
  };

  const deleteCity = () => {
    firebase
      .firestore()
      .collection("countries")
      .doc(country)
      .collection("cities")
      .doc(city)
      .delete()
      .then(() => {
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(`${process.env.PUBLIC_URL}/country/city-list`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const updateList = (cityData) => {
    const countryName = cityData.countryName;
    const cityName = cityData.cityName;
    const cityImg = cityData.hero;

    if (newCity) {
      firebase
        .firestore()
        .collection("aggregateList")
        .doc("countryCityList")
        .update({
          [countryName]: firebaseApp.firestore.FieldValue.arrayUnion(
            cityName + "," + cityImg
          ),
        })
        .then(function () {
          toast.success("Berhasil disimpan", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.push(`${process.env.PUBLIC_URL}/country/city-list`);
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    } else if (isPhotoUpdated) {
      firebase
        .firestore()
        .collection("aggregateList")
        .doc("countryCityList")
        .get()
        .then((response) => {
          const countryCityData = response.data();
          const cityList = countryCityData[countryName];
          if (cityList && cityList.length > 0) {
            const cityIdx = cityList.findIndex(
              (city) => city.split(",")[0] === cityName
            );
            if (cityIdx > -1) {
              cityList[cityIdx] = cityName + "," + cityImg;
              firebase
                .firestore()
                .collection("aggregateList")
                .doc("countryCityList")
                .set(
                  {
                    [countryName]: cityList,
                  },
                  { merge: true }
                )
                .then(() => {
                  toast.success("Berhasil disimpan", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  history.push(`${process.env.PUBLIC_URL}/country/city-list`);
                });
            }
          }
        });
    } else {
      toast.success("Berhasil disimpan", {
        position: toast.POSITION.TOP_CENTER,
      });
      history.push(`${process.env.PUBLIC_URL}/country/city-list`);
    }
  };

  const onSubmit = (data) => {
    var country = cityData.country;
    var city = cityData.city.trim();
    if (country && city) {
      var lightboxImages = [];
      photos.forEach((photo) => {
        lightboxImages.push(photo.preview);
      });

      const updateCityData = {
        cityName: city,
        countryName: country,
        about: data["about"] || "",
        videoId: data["videoId"] || "",
        hero: heroPhoto.preview || "",
        getGuidePrice: data["getGuidePrice"] || "",
        minGetGuidePrice: data["minGetGuidePrice"] || "",
        maxGetGuidePrice: data["maxGetGuidePrice"] || "",
        getGuidePriceExtra: data["getGuidePriceExtra"] || "",
        minGetGuidePriceExtra: data["minGetGuidePriceExtra"] || "",
        maxGetGuidePriceExtra: data["maxGetGuidePriceExtra"] || "",
        maxAdultCount: data["maxAdultCount"] || "",
        maxChildCount: data["maxChildCount"] || "",
        currency: data["currency"] || "",
        lightboxImages: lightboxImages || [],
        recommendedItinerary: cityData.recommendedItinerary || [],
        ticketPrice: cityData.ticketPrice || [],
        ticketShop: cityData.ticketShop || [],
        ticketVideo: data.ticketVideo || "",
        transportationMap: cityData.transportationMap || "",
        transportationZone: cityData.transportationZone || "",
        additionalInfoTransportation: data.additionalInfoTransportation || "",
        covidRegulations: {
          ...cityData.covidRegulations,
          otherVaccines: data["covidRegulationsOtherVaccines"] || "",
          certificateInfo: data["covidRegulationsCertificateInfo"] || "",
          quarantine: data["covidRegulationsQuarantine"] || "",
          hygeneGuidance: data["covidRegulationsHygeneGuidance"] || "",
          additionalInfo: data["covidRegulationsAdditionalInfo"] || "",
        },
      };
      firebase
        .firestore()
        .collection("countries")
        .doc(country)
        .collection("cities")
        .doc(city)
        .set(updateCityData, { merge: true })
        .then(() => {
          updateList(updateCityData);
        })
        .catch(function (error) {
          console.log("error " + error);
        });
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>
        <ModalFooter>
          <ModalBody>Hapus Kota?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteCity}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2>Detail Kota</h2>

                {!newCity && (
                  <LinkButton
                    title="Lihat Kota"
                    url={`https://getgoing.co.id/hasil-pencarian/${cityData.country}/${cityData.city}`}
                  />
                )}

                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <Label className="col-form-label my-3">Foto Utama</Label>{" "}
                    {"  "}
                    {!newCity && cityData.hero && (
                      <FileDownload url={cityData.hero} />
                    )}
                    <div
                      {...getRootProps({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps()} />
                      <div>
                        <div className="dz-message text-muted">
                          <p>Klik untuk upload foto baru (max 1)</p>
                          <img
                            src={heroPhoto.preview}
                            alt={city}
                            className="avatar avatar-large mb-2"
                          />
                          {!photoUploadFinished && (
                            <div className="loader-box">
                              <div className="loader-3"></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </FormGroup>

                  {newCity ? (
                    <div className="my-3">
                      <SelectCountryCity
                        userCountry={cityData.country}
                        userCity={cityData.city}
                        selectedCountry={setSelectedCountry}
                        selectedCity={setSelectedCity}
                        newCity
                      />
                    </div>
                  ) : (
                    <div>
                      <Label className="col-form-label">Negara</Label>
                      <Input
                        className="form-control"
                        type="text"
                        defaultValue={upperCaseFirst(cityData.country)}
                        disabled
                      />
                      <Label className="col-form-label">Kota</Label>
                      <Input
                        className="form-control"
                        type="text"
                        defaultValue={upperCaseFirst(cityData.city)}
                        disabled
                      />
                    </div>
                  )}

                  <FormGroup>
                    <Label className="col-form-label">Deskripsi</Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="about"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.about}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Video ID Youtube</Label>{" "}
                    {"  "}
                    <span>
                      (Contoh:
                      <span style={{ textDecoration: "line-through" }}>
                        https://youtu.be/
                      </span>
                      <strong>cwenLWoeItQ</strong>)
                    </span>
                    <Input
                      className="form-control"
                      type="text"
                      name="videoId"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.videoId}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Mata Uang</Label>
                    <span> (Simbol &amp; nama, cth: € Euro)</span>
                    <Input
                      className="form-control"
                      type="text"
                      name="currency"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.currency}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Default Harga GetGuide per Grup per 5 Jam (dalam RUPIAH)
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="getGuidePrice"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.getGuidePrice}
                    />
                  </FormGroup>

                  <Row>
                    <Col>
                      <Label className="col-form-label">
                        Minimal Harga GetGuide per Grup per 5 Jam (dalam RUPIAH)
                      </Label>
                      <Input
                        className="form-control"
                        type="number"
                        step="1"
                        name="minGetGuidePrice"
                        innerRef={register({ required: false })}
                        defaultValue={cityData.minGetGuidePrice}
                      />
                    </Col>
                    <Col>
                      <Label className="col-form-label">
                        Maksimal Harga GetGuide per Grup per 5 Jam (dalam
                        RUPIAH)
                      </Label>
                      <Input
                        className="form-control"
                        type="number"
                        step="1"
                        name="maxGetGuidePrice"
                        innerRef={register({ required: false })}
                        defaultValue={cityData.maxGetGuidePrice}
                      />
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label className="col-form-label">
                      Default Harga GetGuide per Ekstra Jam (dalam RUPIAH)
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="getGuidePriceExtra"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.getGuidePriceExtra}
                    />
                  </FormGroup>

                  <Row>
                    <Col>
                      <Label className="col-form-label">
                        Minimal Harga GetGuide per Ekstra Jam (dalam RUPIAH)
                      </Label>
                      <Input
                        className="form-control"
                        type="number"
                        step="1"
                        name="minGetGuidePriceExtra"
                        innerRef={register({ required: false })}
                        defaultValue={cityData.minGetGuidePriceExtra}
                      />
                    </Col>
                    <Col>
                      <Label className="col-form-label">
                        Maksimal Harga GetGuide per Ekstra Jam (dalam RUPIAH)
                      </Label>
                      <Input
                        className="form-control"
                        type="number"
                        step="1"
                        name="maxGetGuidePriceExtra"
                        innerRef={register({ required: false })}
                        defaultValue={cityData.maxGetGuidePriceExtra}
                      />
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label className="col-form-label">
                      GetGuide: Jumlah dewasa maksimal
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="maxAdultCount"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.maxAdultCount}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      GetGuide: Jumlah anak maksimal
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step="1"
                      name="maxChildCount"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.maxChildCount}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label pt-0">Foto Galeri</Label>
                    {photos &&
                      photos.map((file, index) => (
                        <div key={index}>
                          <div>
                            <img
                              src={file.preview}
                              alt={index}
                              className="img-fluid rounded shadow mb-4 avatar-large"
                            />
                          </div>
                          <Input
                            type="text"
                            name="photo"
                            defaultValue={file.preview}
                            disabled
                          />
                        </div>
                      ))}
                    <div
                      {...getRootProps2({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps2()} />

                      <div className="dz-message text-muted">
                        <p>Ubah foto dan Upload foto-foto baru (minimal 2)</p>
                        {!photoUploadFinished && (
                          <div className="loader-box">
                            <div className="loader-3"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </FormGroup>

                  <h2 className="mt-5">Transportasi Umum</h2>

                  <h4 className="mt-5">Tiket</h4>
                  <TicketPriceComponent
                    value={cityData.ticketPrice}
                    setValue={setTicketPrice}
                  />
                  <div key="ticketshop">
                    <h4 className="mt-5">Website / Tempat Beli Tiket</h4>
                    <TicketShopComponent
                      value={cityData.ticketShop}
                      setValue={setTicketShop}
                    />
                  </div>
                  <FormGroup>
                    <h4 className="mt-5">
                      Video Cara Membeli Tiket (Video ID Youtube)
                    </h4>{" "}
                    {"  "}
                    <span>
                      (Contoh:
                      <span style={{ textDecoration: "line-through" }}>
                        https://youtu.be/
                      </span>
                      <strong>cwenLWoeItQ</strong>)
                    </span>
                    <Input
                      className="form-control"
                      type="text"
                      name="ticketVideo"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.ticketVideo}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Zona (pdf)</Label>
                    <Col>
                      <Dropzone
                        path="webImages"
                        name="zona"
                        handlePhotoUploaded={(e) =>
                          handleFileUploaded("transportationZone", e)
                        }
                        itineraryFile
                      />
                    </Col>
                    <Col>
                      <FilePreview file={cityData.transportationZone} />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Peta Transportasi (pdf)
                    </Label>
                    <Col>
                      <Dropzone
                        path="webImages"
                        name="peta Transportasi"
                        handlePhotoUploaded={(e) =>
                          handleFileUploaded("transportationMap", e)
                        }
                        itineraryFile
                      />
                    </Col>
                    <Col>
                      <FilePreview file={cityData.transportationMap} />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <h4 className="mt-5">Informasi Tambahan</h4> {"  "}
                    <span>
                      (Informasi tambahan yang mungkin harus dilakukan turis
                      sebelum bisa membeli atau menaiki alat Transpotasi)
                    </span>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="additionalInfoTransportation"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.additionalInfoTransportation}
                    />
                  </FormGroup>

                  <h2 className="mt-5">Regulasi Covid</h2>

                  <FormGroup>
                    <h5>
                      Apakah turis dari Indonesia diizinkan untuk berkunjung ?
                    </h5>
                    <Row>
                      <Col lg="2">
                        {["Ya", "Tidak"].map((item) => (
                          <FormGroup key={item} style={{ marginLeft: 20 }}>
                            <Label>
                              <Input
                                type="radio"
                                id={item}
                                key={item}
                                name={item}
                                value={item}
                                checked={
                                  cityData.covidRegulations
                                    ?.indonesianAllowed === item
                                }
                                onChange={(e) =>
                                  setCovidRegulations("indonesianAllowed", e)
                                }
                              />
                              {item}
                            </Label>
                          </FormGroup>
                        ))}
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <h5 className="mt-5">Vaksin yang diterima</h5>
                    {vaccinesList.map((keyName, i) => (
                      <FormGroup check key={i}>
                        <Label check>
                          <Input
                            type="checkbox"
                            id={keyName}
                            key={keyName}
                            name={keyName}
                            value={
                              cityData.covidRegulations?.vaccines
                                ? cityData.covidRegulations?.vaccines[keyName]
                                : ""
                            }
                            defaultChecked={
                              cityData.covidRegulations?.vaccines
                                ? cityData.covidRegulations?.vaccines[keyName]
                                : false
                            }
                            onChange={(e) => setCovidRegulations("vaccines", e)}
                          />{" "}
                          {keyName}
                        </Label>
                      </FormGroup>
                    ))}
                    <Label className="col-form-label pt-0">
                      (Lainnya: Pisahkan nama vaksin dengan enter)
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="covidRegulationsOtherVaccines"
                      innerRef={register({ required: false })}
                      defaultValue={
                        cityData.covidRegulations?.otherVaccines &&
                        cityData.covidRegulations?.otherVaccines.replace(
                          ",",
                          "\n"
                        )
                      }
                    />
                  </FormGroup>

                  <FormGroup>
                    <h5 className="mt-5">Informasi Sertifikat Vaksin</h5>
                    <Label className="col-form-label pt-0">
                      (info penting tentang sertifikat vaksin, seperti
                      contohnya: cara merubah kartu vaksin ke format yang
                      berlaku di tujuan)
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="covidRegulationsCertificateInfo"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.covidRegulations?.certificateInfo}
                    />
                  </FormGroup>

                  <FormGroup>
                    <h5 className="mt-5">Durasi Karantina</h5>
                    <Label className="col-form-label pt-0">
                      (Masukan jumlah hari atau "0" apabila tidak diberlakukan
                      karantina)
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      name="covidRegulationsQuarantine"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.covidRegulations?.quarantine}
                    />
                  </FormGroup>

                  <FormGroup>
                    <h5 className="mt-5">Jam Tutup</h5>

                    {["restaurantCafe", "supermarktMall", "theater"].map(
                      (item) => (
                        <div className="mt-3" key={item}>
                          <h6 className=" pt-0">
                            {mapPublicPlaceOpeningHours[item]}
                          </h6>

                          <FormGroup>
                            <Row>
                              <Col lg="2">
                                <Label>Buka sampai jam:</Label>
                              </Col>
                              <Col lg="3">
                                <Input
                                  className={`${item}Time`}
                                  type="time"
                                  input={`${item}Time`}
                                  name={`${item}Time`}
                                  id={`${item}Time`}
                                  value={
                                    cityData.covidRegulations
                                      ?.publicPlaceOpeningHours?.[item]
                                  }
                                  onChange={(e) =>
                                    setCovidRegulations(
                                      "publicPlaceOpeningHours::" + item,
                                      e
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          {["openNormal", "closed"].map((isOpen) => (
                            <FormGroup
                              key={isOpen + item}
                              style={{ marginLeft: 20 }}
                            >
                              <Label>
                                <Input
                                  type="radio"
                                  id={isOpen + item}
                                  key={isOpen + item}
                                  name={isOpen + item}
                                  value={isOpen}
                                  checked={
                                    cityData.covidRegulations
                                      ?.publicPlaceOpeningHours?.[item] ===
                                    isOpen
                                  }
                                  onChange={(e) =>
                                    setCovidRegulations(
                                      "publicPlaceOpeningHours::" + item,
                                      e
                                    )
                                  }
                                />
                                {mapPublicPlaceOpeningHours[isOpen]}
                              </Label>
                            </FormGroup>
                          ))}
                        </div>
                      )
                    )}
                  </FormGroup>

                  <FormGroup>
                    <h5 className="mt-5">Protokol Kesehatan</h5>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="covidRegulationsHygeneGuidance"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.covidRegulations?.hygeneGuidance}
                    />
                  </FormGroup>

                  <FormGroup>
                    <h5 className="mt-5">Informasi Tambahan</h5>
                    <Label className="col-form-label pt-0">
                      (Masukan informasi tambahan, seperti, harus mengisi
                      formulir tertentu sebelum melakukan perjalan ke destinasi,
                      dan informasi tambahan lainnya yang belum disebutkan
                      diatas)
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="covidRegulationsAdditionalInfo"
                      innerRef={register({ required: false })}
                      defaultValue={cityData.covidRegulations?.additionalInfo}
                    />
                  </FormGroup>

                  <div key="covidRegulationsApplication">
                    <h5 className="mt-5">Aplikasi yang Harus Didownload</h5>
                    <TicketShopComponent
                      value={cityData.covidRegulations?.applications}
                      setValue={setCovidRegulationsApplications}
                    />
                  </div>

                  <h5 className="mt-5">Link Penting</h5>
                  <CovidLinksComponent
                    value={cityData.covidRegulations?.importantLinks}
                    setValue={setCovidRegulationsImportantLinks}
                  />

                  {!newCity && (
                    <RecommendedItineraryList
                      country={country}
                      city={city}
                      recommendedItineraryList={cityData.recommendedItinerary}
                    />
                  )}

                  {!newCity && (
                    <FreeActivitiesList
                      country={country}
                      city={city}
                      freeActivityList={cityData.freeActivity}
                    />
                  )}

                  <FormGroup className="form-row mt-5 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      disabled={!photoUploadFinished}
                    >
                      Simpan Kota
                    </Button>
                    {!newCity && (
                      <Button
                        className="btn-secondary-gradien mr-2"
                        type="button"
                        onClick={toggle}
                      >
                        Hapus Kota
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default CountryCityDetail;
