import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Row, Badge } from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import firebase from "../../data/firebase";
import { formatDateTime } from "../common/TableUtils";
import { replaceImageUrl, upperCaseFirst } from "../../data/helper";
import MaterialUiTable from "../common/MaterialUiTable";

const BergabungList = () => {
  const history = useHistory();
  const [bergabungList, setBergabungList] = useState([]);
  const [loading, setLoading] = useState(true);

  const statusMap = {
    new: "0_baru",
    verified: "4_terverifikasi",
    unverified: "3_tidak terverifikasi",
    contacted: "1_dikontak",
    photoCheck: "2_cek foto",
  };

  const statusLabel = {
    "0_baru": "Baru",
    "4_terverifikasi": "Terverifikasi",
    "3_tidak terverifikasi": "Tidak Terverifikasi",
    "1_dikontak": "Dikontak",
    "2_cek foto": "Cek Foto",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("bergabungFormList")
          .get();
        var responseData = response.data();
        var data = [];
        for (const key in responseData) {
          const value = responseData[key];

          var city = value.city ? upperCaseFirst(value.city) : "";
          var country = value.country ? upperCaseFirst(value.country) : "";
          var photo = replaceImageUrl(value.photo, 80, 80);

          data.push({
            id: key,
            photo,
            status: statusMap[value.status],
            nameEmail: value.name + "," + (value.email ? value.email : " "),
            Kota: city,
            Negara: country,
            cityCountry: city + "," + country,
            phone: value.phone,
            createdAt: value.createdAt ? value.createdAt : "",
          });
        }
        setBergabungList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const getStatusColor = (value) => {
    switch (value) {
      case "0_baru":
        return "#449AEF";
      case "4_terverifikasi":
        return "#00C950";
      case "3_tidak terverifikasi":
        return "#FF3300";
      case "1_dikontak":
        return "#F2C94C";
      case "2_cek foto":
        return "#979797";
      default:
        return "#000";
    }
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "photo",
      label: " ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img className="avatar-sm" src={value} alt={value} />;
        },
      },
    },
    {
      name: "nameEmail",
      label: "NAMA",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var name = value.split(",")[0];
          var email = value.split(",")[1];
          return (
            <div>
              <p className="my-0 font-weight-bold">{name}</p>
              <p className="my-0">{email}</p>
            </div>
          );
        },
      },
    },
    {
      name: "phone",
      label: "NO. TELEPON",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Negara",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Kota",
      options: {
        display: "excluded",
      },
    },
    {
      name: "cityCountry",
      label: "DOMISILI",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var city = value.split(",")[0];
          var country = value.split(",")[1];
          return (
            <div>
              <p className="my-0 font-weight-bold">{city}</p>
              <p className="my-0">{country}</p>
            </div>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "WAKTU MENDAFTAR",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Badge color="light" pill style={{ color: "green" }}>
                <i className="fa fa-circle"></i>{" "}
                {moment().diff(value.toDate(), "days")} hari yang lalu
              </Badge>
              <p>({formatDateTime(value)})</p>
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ color: getStatusColor(value) }}>
              <i className="fa fa-circle"></i> {statusLabel[value]}
            </div>
          );
        },
        filterOptions: {
          renderValue: (v) => statusLabel[v],
        },
        customFilterListOptions: {
          render: (v) => statusLabel[v],
        },
      },
    },
  ];

  const toFormDetail = (id) => {
    history.push(`${process.env.PUBLIC_URL}/forms/bergabung-detail/${id}`);
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">Formulir Bergabung</h5>
            <p className="text-muted">(total {bergabungList.length})</p>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={bergabungList}
            toItemDetail={toFormDetail}
            sortInitialColumn="status"
            initialColDirection="asc"
            searchableCols="Nama, Email, No. Telepon, Negara, Kota, Status"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default BergabungList;
