import React, { useState } from "react";
import { useAuth } from "../../services/auth-context";
import { Route, Redirect } from "react-router-dom";
import app from "../../data/firebase";
// import Loader from '../../layout/Loader';

export default function ProtectedRoute(props) {
  const auth = useAuth();

  const [isAdmin, setAdmin] = useState(false);
  const [loadingToken, setLoadingToken] = useState(true);

  if (!auth.loadingUser) {
    if (auth.user == null) {
      return <Redirect to={props.redirectTo}></Redirect>;
    } else {
      app
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult.claims.admin || idTokenResult.claims.superadmin) {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
          setLoadingToken(false);
        })
        .catch((error) => {
          console.log(error);
        });

      return (
        !loadingToken &&
        (isAdmin ? (
          <Route exact path={props.path} key={props.path}>
            {" "}
            {props.children}{" "}
          </Route>
        ) : (
          <Redirect to={props.notAuthorized}></Redirect>
        ))
      );
    }
  } else {
    return null;
  }
}
