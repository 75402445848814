import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import logoMerah from "../assets/images/Logo Dashboard.png";
import { MENUITEMS } from "./sidebar/menu";
import configDB from "../data/customizer/config";
import { SwitchToggle } from "../redux/common/actions";
import useWindowSize from "../util/useWindowSize";

const Sidebar = () => {
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const dispatch = useDispatch();
  const width = useWindowSize();
  const switchToggle = useSelector((state) => state.Common.switchToggle);
  const sidebar_background_color =
    configDB.data.settings.sidebar_background_setting;

  useEffect(() => {
    const currentUrl = window.location.pathname;
    mainmenu.filter((items) => {
      if (items.path === currentUrl) setNavActive(items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) setNavActive(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) {
            setNavActive(subSubItems);
            return true;
          } else {
            return false;
          }
        });
        return subItems;
      });
      return items;
    });

    // eslint-disable-next-line
  }, []);

  const setNavActive = (item) => {
    MENUITEMS.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
            return true;
          } else {
            return false;
          }
        });
      }
      return menuItem;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    if (!item.active) {
      MENUITEMS.forEach((a) => {
        if (MENUITEMS.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  return (
    <Fragment>
      <div
        className={`page-sidebar ${
          switchToggle && width <= 991 ? "open" : sidebar_background_color
        }`}
      >
        <div className="main-header-left-2 d-none d-lg-block">
          <div className="logo-wrapper compactLogo">
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <img
                className="blur-up lazyloaded compactlogo logo-dashboard-sidebar"
                src={logoMerah}
                alt=""
              />
            </Link>
          </div>
        </div>
        <div className="sidebar custom-scrollbar">
          <ul className="sidebar-menu">
            {MENUITEMS.map((menuItem, i) => (
              <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                {menuItem.sidebartitle ? (
                  <div className="sidebar-title">{menuItem.sidebartitle}</div>
                ) : (
                  ""
                )}
                {menuItem.type === "sub" ? (
                  <a
                    className="sidebar-header"
                    href="#javascript"
                    onClick={() => {
                      toggletNavActive(menuItem);
                    }}
                  >
                    <menuItem.icon />
                    <span>{menuItem.title}</span>
                    <i className="fa fa-angle-right pull-right"></i>
                  </a>
                ) : (
                  ""
                )}
                {menuItem.type === "link" ? (
                  <Link
                    className={`sidebar-header ${
                      menuItem.active ? "active" : ""
                    }`}
                    onClick={() => {
                      toggletNavActive(menuItem);
                      dispatch(SwitchToggle(true));
                    }}
                    to={menuItem.path}
                  >
                    <menuItem.icon />
                    <span className="menuItemTitle">{menuItem.title}</span>
                    {menuItem.children ? (
                      <i className="fa fa-angle-right pull-right"></i>
                    ) : (
                      ""
                    )}
                  </Link>
                ) : (
                  ""
                )}
                {menuItem.type === "blockTitle" ? (
                  <div>
                    <h6 className="mt-5 ml-3 text-white">{menuItem.title}</h6>
                  </div>
                ) : (
                  ""
                )}
                {menuItem.children ? (
                  <ul
                    className={`sidebar-submenu ${
                      menuItem.active ? "menu-open" : ""
                    }`}
                    style={
                      menuItem.active
                        ? { opacity: 1, transition: "opacity 500ms ease-in" }
                        : {}
                    }
                  >
                    {menuItem.children.map((childrenItem, index) => (
                      <li
                        key={index}
                        className={
                          childrenItem.children
                            ? childrenItem.active
                              ? "active"
                              : ""
                            : ""
                        }
                      >
                        {childrenItem.type === "sub" ? (
                          <a
                            href={childrenItem.path}
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            <i className="fa fa-circle"></i>
                            {childrenItem.title}{" "}
                            <i className="fa fa-angle-down pull-right"></i>
                          </a>
                        ) : (
                          ""
                        )}

                        {childrenItem.type === "link" ? (
                          <Link
                            className={`d-block pl-3 py-0 ${
                              childrenItem.active ? "active" : ""
                            }`}
                            onClick={() => {
                              toggletNavActive(childrenItem);
                              dispatch(SwitchToggle(true));
                            }}
                            to={childrenItem.path}
                          >
                            <span className="childItemTitle">
                              {childrenItem.title}
                            </span>
                          </Link>
                        ) : (
                          ""
                        )}
                        {childrenItem.type === "external_link" ? (
                          <a
                            href={childrenItem.path}
                            className={childrenItem.active ? "active" : ""}
                          >
                            {childrenItem.title}
                          </a>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </li>
            ))}
          </ul>
        </div>

        <Col className="footer-copyright py-0">
          <hr className="line-footer" />
          <p className="mb-0">
            © 2018-2021, PT. Gue Eksplorasi Terus. All rights reserved.
          </p>
        </Col>
      </div>
    </Fragment>
  );
};

export default Sidebar;
