import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "../../data/firebase";
import { upperCaseFirst } from "../../data/helper";

const PopularCities = () => {
  const [loading, setLoading] = useState(true);
  const [popularCitiesList, setPopularCitiesList] = useState([]);
  const [allCitiesList, setAllCitiesList] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("popularCities")
          .get();
        var responseData = response.data();
        var popularCitiesOptions = [];
        var selectedCities = [];
        responseData["popularCities"].forEach((countryCityStr) => {
          var cityCountry = countryCityStr.split(",");
          var city = cityCountry[0];
          var country = cityCountry[1];
          popularCitiesOptions.push({
            value: countryCityStr,
            label: upperCaseFirst(city) + ", " + upperCaseFirst(country),
          });
          selectedCities.push(countryCityStr);
        });
        try {
          var allCitiesOption = [];
          const response2 = await firebase
            .firestore()
            .collection("aggregateList")
            .doc("countryCityList")
            .get();
          var responseData2 = response2.data();
          for (const key in responseData2) {
            const value = responseData2[key];
            value.forEach((cityImgStr) => {
              const country = key;
              const city = cityImgStr.split(",")[0];
              const image = cityImgStr.split(",")[1];
              allCitiesOption.push({
                value: city + "," + country + "," + image,
                label: upperCaseFirst(city) + ", " + upperCaseFirst(country),
              });
            });
          }
          setAllCitiesList(allCitiesOption);
          setPopularCitiesList(popularCitiesOptions);
          setSelectedCities(selectedCities);
          setLoading(false);
        } catch (err) {
          console.error(err);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const setSelected = (e, i) => {
    var selected = selectedCities;
    selected[i] = e.value;
    setSelectedCities(selected);
  };

  const history = useHistory();
  const { handleSubmit } = useForm();

  const onSubmit = (data) => {
    firebase
      .firestore()
      .collection("aggregateList")
      .doc("popularCities")
      .set(
        {
          popularCities: selectedCities,
        },
        { merge: true }
      )
      .then(() => {
        toast.success("Berhasil disimpan", {
          position: toast.POSITION.TOP_CENTER,
        });
        // history.push(`${process.env.PUBLIC_URL}/country/city-list`);
      })
      .catch(function (error) {
        console.log("error " + error);
      });
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2>Kota Populer</h2>

                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                    <Row key={i} className="my-3">
                      <Col lg="1">
                        <h4>{i + 1}:</h4>
                      </Col>
                      <Col lg="4">
                        <Select
                          id={`reactselect-${i}`}
                          instanceId={i}
                          className="search-form-banner-text border-0 shadow-0"
                          isSearchable={true}
                          name="location"
                          options={allCitiesList}
                          menuPlacement="bottom"
                          onChange={(e) => setSelected(e, i)}
                          defaultValue={popularCitiesList[i]}
                        />
                      </Col>
                    </Row>
                  ))}

                  <FormGroup className="form-row mt-5 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                    >
                      Simpan
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default PopularCities;
