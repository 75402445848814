import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import Dropzone from "../../common/Dropzone";
import FileDownload from "../../common/FileDownload";
import TicketProviderComponent from "./TicketProviderComponent";

const TicketPriceInput = (props) => {
  const {
    idx,
    state,
    handleChange,
    handleTicketProviderChange,
    disableDelete,
    deleteInput,
  } = props;

  const ticketTypeId = `ticketType-${idx}`;
  const imageId = `image-${idx}`;
  const descriptionId = `description-${idx}`;
  return (
    <div key={`activity-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <Col className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <h5>{idx + 1}</h5>
            <Col md="11">
              <FormGroup className="mb-3">
                <Label className="form-label" for={ticketTypeId}>
                  Nama Provider Tiket
                </Label>
                <Input
                  type="text"
                  input={ticketTypeId}
                  name={ticketTypeId}
                  id={ticketTypeId}
                  data-id={idx}
                  className="ticketType"
                  value={state[idx].ticketType || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label" for={imageId}>
                  Foto / Logo
                </Label>
                <Row>
                  <Col>
                    <Dropzone
                      path="webImages"
                      name={state[idx].ticketType}
                      handlePhotoUploaded={(e) => handleChange(e, idx)}
                    />
                  </Col>
                  <Col>
                    <span className="media user-header">
                      <img
                        className="mr-2 img-35"
                        src={state[idx].image || ""}
                        alt={state[idx].ticketType}
                        style={{ maxHeight: 100, maxWidth: 100 }}
                      />
                    </span>
                    {state[idx].image && (
                      <FileDownload url={state[idx].image} />
                    )}
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label">Deskripsi</Label>
                <Input
                  type="textarea"
                  input={descriptionId}
                  name={descriptionId}
                  id={descriptionId}
                  data-id={idx}
                  rows="2"
                  className="description"
                  value={state[idx].description || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <TicketProviderComponent
                value={state[idx].ticketProvider}
                setValue={handleTicketProviderChange}
                providerId={idx}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default TicketPriceInput;
