import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import FileDownload from "./FileDownload";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FilePreview = (props) => {
  const { file } = props;
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(1);
  }
  return (
    <div>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        className="pdf-preview"
      >
        <Page pageNumber={pageNumber} renderTextLayer={false} />
      </Document>

      {file && <FileDownload url={file} />}
    </div>
  );
};

export default FilePreview;
