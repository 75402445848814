import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import Dropzone from "../../common/Dropzone";
import DropzoneMultiple from "../../common/DropzoneMultiple";
import FileDownload from "../../common/FileDownload";

const AddOnsInput = (props) => {
  const {
    idx,
    state,
    handleChange,
    disableDelete,
    deleteInput,
    resetAllPhotos,
  } = props;

  const titleId = `titleId-${idx}`;
  const subtitleId = `subtitleId-${idx}`;
  const galleryId = `galleryId-${idx}`;
  const descriptionId = `descriptionId-${idx}`;
  const descriptionPointsId = `descriptionPointsId-${idx}`;
  const priceId = `priceId-${idx}`;
  const priceUnitId = `priceUnitId-${idx}`;
  const maxItemCountId = `maxItemCountId-${idx}`;

  return (
    <div key={`activity-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <Col className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <h5>{idx + 1}</h5>
            <Col md="11">
              <FormGroup className="mb-3">
                <Label className="form-label" for={titleId}>
                  Judul
                </Label>
                <Input
                  type="text"
                  input={titleId}
                  name={titleId}
                  id={titleId}
                  data-id={idx}
                  className="title"
                  value={state[idx].title || ""}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label" for={subtitleId}>
                  Subtitle
                </Label>
                <Input
                  type="textarea"
                  input={subtitleId}
                  name={subtitleId}
                  id={subtitleId}
                  data-id={idx}
                  className="subtitle"
                  value={state[idx].subtitle || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label" for={descriptionId}>
                  Deskripsi
                </Label>
                <Input
                  type="textarea"
                  input={descriptionId}
                  name={descriptionId}
                  id={descriptionId}
                  data-id={idx}
                  className="description"
                  value={state[idx].description || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label" for={descriptionPointsId}>
                  Poin-Poin Tambahan (1 baris per point)
                </Label>
                <Input
                  type="textarea"
                  input={descriptionPointsId}
                  name={descriptionPointsId}
                  id={descriptionPointsId}
                  data-id={idx}
                  className="descriptionPoints"
                  value={state[idx].descriptionPoints || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label" for={priceId}>
                  Harga (dalam RUPIAH)
                </Label>
                <Input
                  type="number"
                  step="1"
                  input={priceId}
                  name={priceId}
                  id={priceId}
                  data-id={idx}
                  className="price"
                  value={state[idx].price || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label" for={priceUnitId}>
                  Jenis Harga
                  <span> (Cth: pax, orang. Default: orang)</span>
                </Label>
                <Input
                  type="text"
                  input={priceUnitId}
                  name={priceUnitId}
                  id={priceUnitId}
                  data-id={idx}
                  className="priceUnit"
                  value={state[idx].priceUnit || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label" for={maxItemCountId}>
                  Jumlah Maksimal
                </Label>
                <Input
                  type="number"
                  step="1"
                  input={maxItemCountId}
                  name={maxItemCountId}
                  id={maxItemCountId}
                  data-id={idx}
                  className="maxItemCount"
                  value={state[idx].maxItemCount || ""}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="form-label" for={galleryId}>
                  Galeri
                </Label>
                <Row>
                  <Col>
                    <DropzoneMultiple
                      path="webImages"
                      name={state[idx].title}
                      handlePhotoUploaded={(e) => handleChange(e, idx)}
                      resetAllPhotos={() => resetAllPhotos(idx)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {state[idx].gallery &&
                      state[idx].gallery.length > 0 &&
                      state[idx].gallery.map((file, index) => (
                        <div key={index}>
                          <div>
                            <img
                              src={file}
                              alt={index}
                              className="img-fluid rounded shadow mb-4 avatar-large"
                            />
                          </div>
                          <FileDownload url={file} />
                        </div>
                      ))}
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AddOnsInput;
