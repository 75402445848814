import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Input,
  Row,
  CardHeader,
  FormGroup,
  Label,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../data/firebase";
import BookingStatus from "../common/BookingStatus";
import { formatDateTime } from "../common/TableUtils";
import LinkButton from "../common/LinkButton";
import { getPhoneNumber } from "../../data/helper";

const formatDate = (value) => {
  const date =
    typeof value === "string" || value instanceof Date
      ? moment(value).format("DD MMM YYYY")
      : moment(value.toDate()).format("DD MMM YYYY");
  return date;
};

const BookingFormDetail = () => {
  let { id } = useParams();

  const [bookingData, setBookingData] = useState({});
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit, errors } = useForm();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const dismissToast = () => {
    setShowToast(false);
  };

  const [bookingStatusState, setBookingStatusState] = useState([]);
  const [selectedNextStep, setSelectedNextStep] = useState("");

  const statusArray = [
    // "contacting",
    // "consulting1",
    // "offering1",
    // "consulting2",
    // "offering2",
    // "consulting3",
    // "offering3",
    // "deal",
    // "invoice",
    // "generating_itinerary",
    // "sending_itinerary",

    // getflex basic status
    "pending_dp_payment",
    "dp_paid",
    "pending_full_payment",
    "paid_off",
    "passport_application",
    "passport_done",
    "passport_sent",
    "visa_application",
    "visa_done",
    "visa_sent",
    "itinerary_available",
    "booklet_sent",
    "preparation_meeting",
    "pcr_test",
    "departure",
    // failed status
    "payment_failed",
    "booking_failed",
  ];

  const stepOptions = statusArray.map((status) => {
    return { value: status, label: status };
  });

  const onSelectStepChange = (e) => {
    const value = e.value;
    setSelectedNextStep(value);
  };

  const toState = () => {
    var currentBookingState = bookingStatusState[bookingStatusState.length - 1];
    var currentStatus = currentBookingState["status"];
    var newCurrentStatus = {
      status: currentStatus,
      comment: currentBookingState["comment"]
        ? currentBookingState["comment"]
        : "",
      date: new Date(),
      file: currentBookingState["file"] ? currentBookingState["file"] : "",
      completed: true,
    };
    var updatedState = bookingStatusState;
    updatedState[updatedState.length - 1] = newCurrentStatus;
    var nextStatusObj = {};
    if (!selectedNextStep) {
      nextStatusObj = {
        status: getNextStateName(),
      };
    } else {
      nextStatusObj = {
        status: selectedNextStep,
      };
    }
    var allStatus = [...updatedState, { ...nextStatusObj }];
    setBookingStatusState(allStatus);
  };

  const addState = () => {
    var currentBookingState = bookingStatusState[bookingStatusState.length - 1];
    var currentStatus = currentBookingState["status"];

    var newCurrentStatus = {
      status: currentStatus,
      comment: currentBookingState["comment"]
        ? currentBookingState["comment"]
        : "",
      date: new Date(),
      file: currentBookingState["file"] ? currentBookingState["file"] : "",
      completed: true,
    };

    var updatedState = bookingStatusState;
    updatedState[updatedState.length - 1] = newCurrentStatus;

    var nextStatusObj = getNextStatusObj(currentBookingState);
    var allStatus = [...updatedState, { ...nextStatusObj }];

    setBookingStatusState(allStatus);
  };

  const getNextStatusObj = (statusObj) => {
    var statusName = statusObj?.status;

    var nextStatusName = "";
    var index = statusArray.indexOf(statusName);
    if (index >= 0 && index < statusArray.length - 1) {
      nextStatusName = statusArray[index + 1];
    }
    if (nextStatusName) {
      var nextStatusObj = {
        status: nextStatusName,
      };
      return nextStatusObj;
    }
    return null;
  };

  const getNextStateName = () => {
    return getNextStatusObj(bookingStatusState[bookingStatusState.length - 1])
      ?.status;
  };

  const endStep = () => {
    var activeStatusName =
      bookingStatusState[bookingStatusState.length - 1]?.status;
    if (
      ["departure", "payment_failed", "booking_failed"].includes(
        activeStatusName
      )
    ) {
      return true;
    }
    return false;
  };

  const handleBookingChange = (e) => {
    const updatedStatus = [...bookingStatusState];
    var fieldName = "";
    var className = e.target.className;
    var id = e.target.dataset.id;
    if (className === "comment form-control") {
      fieldName = "comment";
      updatedStatus[id][fieldName] = e.target.value;
      setBookingStatusState(updatedStatus);
    } else if (className === "file form-control-file") {
      fieldName = "file";
      var file = e.target.files[0];
      const fileName = uuidv4();

      //upload file to storage
      firebase
        .storage()
        .ref()
        .child("bookingFiles/" + bookingData.clientDetail.name + "/" + fileName)
        .put(file)
        .then(() => {
          return firebase
            .storage()
            .ref()
            .child(
              "bookingFiles/" + bookingData.clientDetail.name + "/" + fileName
            )
            .getDownloadURL()
            .then((url) => {
              updatedStatus[id][fieldName] = url;
              setBookingStatusState(updatedStatus);
            });
        })
        .catch((error) => {
          console.log("error:", file, error.message);
        });
    }
  };

  const updateUserBooking = (data) => {
    const currentStatus = data.status[data.status.length - 1];
    if (bookingData.productType === "getflex-basic") {
      // update booking
      firebase
        .firestore()
        .collection("users")
        .doc(bookingData.clientDetail.uid)
        .collection("bookings")
        .doc("bookings")
        .set(
          {
            [bookingData.id]: {
              status: data.status,
              customerService: data.customerService,
            },
          },
          { merge: true }
        );
    }
  };

  const onSubmit = (e) => {
    var currentBookingState = bookingStatusState[bookingStatusState.length - 1];
    var currentStatus = currentBookingState["status"];

    var newCurrentStatus = {
      status: currentStatus,
      comment: currentBookingState["comment"]
        ? currentBookingState["comment"]
        : "",
      date: new Date(),
      file: currentBookingState["file"] ? currentBookingState["file"] : "",
      completed: false,
    };

    var updatedState = bookingStatusState;
    updatedState[updatedState.length - 1] = newCurrentStatus;

    const data = {
      customerService: e.customerService,
      notes: e.notes,
      status: updatedState,
    };

    firebase
      .firestore()
      .collection("bookingForms")
      .doc(id)
      .set(data, { merge: true })
      .then(() => {
        //updateUserBooking(data);
        setToastMessage("Data berhasil disimpan");
        setShowToast(true);
      })
      .catch((e) => {
        console.log(e);
      });

    setBookingStatusState(updatedState);
  };

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("bookingForms")
          .doc(id)
          .get();
        if (response.exists) {
          data = response.data();
          var allStatusDetail = data.status ? data.status : [];
          var currentStatusObj = data.status[data.status.length - 1];
          if (currentStatusObj.completed) {
            var activeState = getNextStatusObj(currentStatusObj);
            allStatusDetail.push(activeState);
            setBookingStatusState(allStatusDetail);
          } else {
            setBookingStatusState(allStatusDetail);
          }
        }

        setBookingData(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="toast-top-right">
        <Toast isOpen={showToast} className="sign-out-toast">
          <ToastHeader toggle={dismissToast}>Simpan berhasil</ToastHeader>
          <ToastBody>{toastMessage}</ToastBody>
        </Toast>
      </div>
      <Container fluid={true}>
        <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
          <h2>{bookingData.name}</h2>
          <Row>
            <Col xl="4 xl-50 box-col-6">
              <Card className="p-3">
                <FormGroup>
                  <Label className="col-form-label">Customer Service</Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="customerService"
                    innerRef={register({ required: false })}
                    defaultValue={bookingData.customerService}
                  />
                  <span style={{ color: "red" }}>
                    {errors.customerService && "Harus diisi"}
                  </span>
                </FormGroup>
                <div className="my-3">
                  <h4>Data Pemesan </h4>
                  {bookingData.clientDetail.uid && (
                    <div>
                      <Row>
                        <LinkButton
                          title="Lihat User"
                          url={`${process.env.PUBLIC_URL}/users/user-detail/${bookingData.clientDetail.uid}`}
                        />
                      </Row>
                      <Row>
                        <Col lg="4">
                          <p className="font-weight-bold">Id:</p>
                        </Col>
                        <Col lg="8">
                          <p>{bookingData.clientDetail.uid}</p>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Row>
                    <Col lg="4">
                      <p className="font-weight-bold">Nama:</p>
                    </Col>
                    <Col lg="8">
                      <p>{bookingData.clientDetail.name}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <p className="font-weight-bold">Email:</p>
                    </Col>
                    <Col lg="8">
                      <p>{bookingData.clientDetail.email}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <p className="font-weight-bold">No. Telp:</p>
                    </Col>
                    <Col lg="8">
                      <p>
                        {getPhoneNumber(bookingData.clientDetail.phoneNumber)}
                      </p>
                    </Col>
                  </Row>
                </div>

                <div className="my-3">
                  <h4>Data Service </h4>
                  <Row>
                    <Col lg="4">
                      <p className="font-weight-bold">Tipe Service:</p>
                    </Col>
                    <Col lg="8">
                      <p>{bookingData.productType}</p>
                    </Col>
                  </Row>
                  {bookingData.productType === "getguide" && (
                    <div>
                      <Row>
                        <Col lg="3">
                          <p className="font-weight-bold">ID Service:</p>
                        </Col>
                        <Col lg="5">
                          <p>{bookingData.productId}</p>
                        </Col>
                        <Col>
                          <LinkButton
                            title="Lihat Service"
                            url={`${process.env.PUBLIC_URL}/services/getguide-detail/${bookingData.productId}`}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <p className="font-weight-bold">ID Partner:</p>
                        </Col>
                        <Col lg="5">
                          <p>{bookingData.productAuthor.uid}</p>
                        </Col>
                        <Col>
                          <LinkButton
                            title="Lihat Partner"
                            url={`https://getgoing.co.id/user-profile/${bookingData.productAuthor.uid}`}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <p className="font-weight-bold">Nama Partner:</p>
                        </Col>
                        <Col lg="8">
                          <p>{bookingData.productAuthor.fullName}</p>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {bookingData.productType === "getflex-basic" && (
                    <div>
                      <Row>
                        <Col lg="3">
                          <p className="font-weight-bold">ID Service:</p>
                        </Col>
                        <Col lg="5">
                          <p>{bookingData.productId}</p>
                        </Col>
                        <Col>
                          <LinkButton
                            title="Lihat Service"
                            url={`${process.env.PUBLIC_URL}/services/getflex-detail/${bookingData.productId}`}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <p className="font-weight-bold">
                            ID Periode Keberangkatan:
                          </p>
                        </Col>
                        <Col lg="5">
                          <p>{bookingData.bookingDetail.departureId}</p>
                        </Col>
                        <Col>
                          <LinkButton
                            title="Lihat Detail"
                            url={`${process.env.PUBLIC_URL}/getflex/departure/${bookingData.productId}/${bookingData.bookingDetail.departureId}`}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <p className="font-weight-bold">
                            ID Itinerary Group:
                          </p>
                        </Col>
                        <Col lg="5">
                          <p>{bookingData.itineraryGroupId}</p>
                        </Col>
                        <Col>
                          <LinkButton
                            title="Lihat Detail"
                            url={`${process.env.PUBLIC_URL}/itinerary/itinerary-group/${bookingData.itineraryGroupId}`}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>

                <div className="my-3">
                  <h4>Detail Booking </h4>

                  <Row>
                    <Col lg="4">
                      <p className="font-weight-bold">Tanggal Pesan:</p>
                    </Col>
                    <Col lg="8">
                      <p>{formatDateTime(bookingData.createdAt)}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <p className="font-weight-bold">Untuk Tanggal:</p>
                    </Col>
                    <Col lg="8">
                      <p>
                        {formatDate(
                          bookingData.bookingDetail.date ||
                            bookingData.bookingDetail.startDate
                        )}
                      </p>
                    </Col>
                  </Row>

                  {bookingData.productType === "getguide" && (
                    <div>
                      <Row>
                        <Col lg="4">
                          <p className="font-weight-bold">Mulai Jam:</p>
                        </Col>
                        <Col lg="8">
                          <p>{bookingData.bookingDetail.startTime}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <p className="font-weight-bold">Durasi:</p>
                        </Col>
                        <Col lg="8">
                          <p>{bookingData.bookingDetail.duration} Jam</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <p className="font-weight-bold">Kota, Negara:</p>
                        </Col>
                        <Col lg="8">
                          <p>
                            {bookingData.city}, {bookingData.country}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Row>
                    <Col lg="4">
                      <p className="font-weight-bold">Dewasa:</p>
                    </Col>
                    <Col lg="8">
                      <p>{bookingData.bookingDetail.adultCount}</p>
                    </Col>
                    <Col lg="4">
                      <p className="font-weight-bold">Anak:</p>
                    </Col>
                    <Col lg="8">
                      <p>{bookingData.bookingDetail.childCount}</p>
                    </Col>
                  </Row>

                  {bookingData.productType === "getflex-basic" && (
                    <div>
                      {bookingData.bookingDetail.travellerList &&
                        bookingData.bookingDetail.travellerList.map(
                          (traveller, idx) => (
                            <div key={idx} className="my-3">
                              <h6>
                                Traveller {idx + 1}{" "}
                                {traveller.isAdult ? "(Dewasa)" : "(Anak)"}
                              </h6>
                              <Row>
                                <Col lg="4">
                                  <p className="font-weight-bold">
                                    Nama Lengkap:
                                  </p>
                                </Col>
                                <Col lg="8">
                                  <p>{traveller.name}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <p className="font-weight-bold">Email:</p>
                                </Col>
                                <Col lg="8">
                                  <p>{traveller.email}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <p className="font-weight-bold">Alamat:</p>
                                </Col>
                                <Col lg="8">
                                  <p>{traveller.address}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <p className="font-weight-bold">
                                    No Telepon:
                                  </p>
                                </Col>
                                <Col lg="8">
                                  <p>{getPhoneNumber(traveller.phone)}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <p className="font-weight-bold">
                                    Info tambahan:
                                  </p>
                                </Col>
                                <Col lg="8">
                                  <p>{traveller.additionalInfo}</p>
                                </Col>
                              </Row>
                            </div>
                          )
                        )}
                    </div>
                  )}

                  <Row>
                    <Col lg="4">
                      <p className="font-weight-bold">Metode Pembayaran:</p>
                    </Col>
                    <Col lg="8">
                      <p>
                        {JSON.stringify(
                          bookingData.bookingDetail.paymentMethod
                        )}
                      </p>
                    </Col>
                  </Row>
                </div>

                <FormGroup>
                  <Label className="col-form-label">Notes</Label>
                  <Input
                    className="form-control"
                    type="textarea"
                    name="notes"
                    innerRef={register({ required: false })}
                  />
                </FormGroup>
                {/* <Row className="mt-3">
                                    <Button type="button" color="danger" onClick={handleDeleteBooking}>Hapus Booking</Button>
                                </Row> */}
              </Card>
            </Col>
            <Col xl="4 xl-50 box-col-6">
              <Card className="p-3">
                <CardHeader>
                  <h5>Status</h5>
                </CardHeader>
                <CardBody>
                  <div className="timeline-small">
                    {bookingStatusState &&
                      bookingStatusState.map((val, idx) => (
                        <BookingStatus
                          key={`booking-${idx}`}
                          idx={idx}
                          bookingState={bookingStatusState}
                          handleBookingChange={handleBookingChange}
                        />
                      ))}
                  </div>

                  <Row className="my-3">
                    {!endStep() && (
                      <Button
                        type="button"
                        color="primary"
                        className="px-3 button-vertical-center"
                        onClick={addState}
                      >
                        Ke Step Selanjutnya ({getNextStateName()})
                      </Button>
                    )}
                  </Row>

                  <Row className="my-3">
                    {!endStep() && (
                      <Col>
                        <Select
                          id="step"
                          instanceId="step"
                          name="step"
                          options={stepOptions}
                          defaultValue={{
                            value:
                              bookingStatusState[bookingStatusState.length - 1]
                                ?.status,
                            label:
                              bookingStatusState[bookingStatusState.length - 1]
                                ?.status,
                          }}
                          isSearchable
                          onChange={onSelectStepChange}
                          input
                        />
                        <Button
                          type="button"
                          color="primary"
                          className="px-3 button-vertical-center"
                          onClick={toState}
                        >
                          Pilih Step Selanjutnya
                        </Button>
                      </Col>
                    )}
                  </Row>

                  <Row className="my-3">
                    <Button
                      type="submit"
                      color="primary"
                      className="px-3 button-vertical-center"
                    >
                      Simpan
                    </Button>
                  </Row>

                  {/* <Row className="my-3">
                    {showDealButton() && (
                      <Button
                        type="button"
                        color="primary"
                        className="px-3 button-vertical-center"
                        onClick={toDealStep}
                      >
                        Batal, Lanjut Ke Deal
                      </Button>
                    )}
                  </Row> */}

                  {/* <Row className="my-3">
                    {!noDealStep() && (
                      <Button
                        type="button"
                        color="danger"
                        onClick={() => toNoDealState()}
                      >
                        No Deal
                      </Button>
                    )}
                  </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default BookingFormDetail;
