import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";

const CovidLinksInput = (props) => {
  const { idx, state, handleChange, disableDelete, deleteInput } = props;

  const nameId = `nameId-${idx}`;
  const linkId = `link-${idx}`;
  return (
    <div key={`activity-${idx}`}>
      {state[idx] && (
        <div>
          <Row className="mb-5">
            {!disableDelete && (
              <Col className="px-0">
                <Button
                  type="button"
                  color="danger"
                  data-id={idx}
                  onClick={() => deleteInput(idx)}
                >
                  x
                </Button>
              </Col>
            )}
            <h5>{idx + 1}</h5>
            <Col md="11">
              <FormGroup className="mb-3">
                <Label className="form-label" for={nameId}>
                  Deskripsi
                </Label>
                <Input
                  type="text"
                  input={nameId}
                  name={nameId}
                  id={nameId}
                  data-id={idx}
                  className="name"
                  value={state[idx].name || ""}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label" for={linkId}>
                  Link ke Website
                </Label>
                <Input
                  type="text"
                  input={linkId}
                  name={linkId}
                  id={linkId}
                  data-id={idx}
                  className="link"
                  value={state[idx].link || ""}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default CovidLinksInput;
