import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import idLocale from "moment/locale/id";
import * as firebaseApp from "firebase/app";
import firebase from "../../../data/firebase";
import FileDownload from "../../common/FileDownload";
import OpeningHoursInput from "../../common/OpeningHoursInput";
import EntryComponent from "../../cms/tnc-faq-components/EntryComponent";

const FreeActivitiesDetail = () => {
  let { id, country, city } = useParams();

  const freeActivityRef = firebase
    .firestore()
    .collection("countries")
    .doc(country)
    .collection("cities")
    .doc(city)
    .collection("freeActivity")
    .doc(id);

  const [photoUploadFinished, setPhotoUploadFinished] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggle = () => setDeleteModal(!deleteModal);

  const [dateFocused, setDateFocused] = useState(null);
  moment.locale("id", idLocale);
  const [range, setRange] = useState({
    startDate: moment(),
    endDate: moment(),
  });

  const [freeActivityData, setFreeActivityData] = useState({});
  const [loading, setLoading] = useState(true);
  const [newActivity, setNewActivity] = useState(true);

  const [mainPhoto, setMainPhoto] = useState({});
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      accept: "image/*",
      minSize: 0,
      maxSize: 12582912, //21mb
      multiple: false,
      required: true,
      onDrop: (acceptedFiles) => {
        setPhotoUploadFinished(false);
        var ref = firebase.storage().ref();
        var file = acceptedFiles[0];
        const fileName = uuidv4();
        ref
          .child("getinfoImage/" + fileName)
          .put(file)
          .then(() => {
            ref
              .child("getinfoImage/" + fileName)
              .getDownloadURL()
              .then((url) => {
                var photo = Object.assign(file, {
                  preview: url,
                });
                setMainPhoto(photo);
                return setPhotoUploadFinished(true);
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });

  const [photos, setPhotos] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 12582912, //12mb
    multiple: true,
    required: true,
    onDrop: (acceptedFiles) => {
      setPhotos([]);
      setPhotoUploadFinished(false);
      var ref = firebase.storage().ref();
      Promise.all(
        acceptedFiles.map((file) => {
          const fileName = uuidv4();
          ref
            .child("getinfoImage/" + fileName)
            .put(file)
            .then(() => {
              ref
                .child("getinfoImage/" + fileName)
                .getDownloadURL()
                .then((url) => {
                  file["preview"] = url;
                  setPhotos((photos) => [...photos, file]);
                  return setPhotoUploadFinished(true);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
      );
    },
  });

  const activityCategories = [
    "museum",
    "park",
    "festival",
    "religion",
    "monument",
  ];

  const categoriesMap = {
    museum: "Museum",
    park: "Taman",
    festival: "Festival",
    religion: "Religi",
    monument: "Monument",
  };

  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const daysMap = {
    monday: "Senin",
    tuesday: "Selasa",
    wednesday: "Rabu",
    thursday: "Kamis",
    friday: "Jumat",
    saturday: "Sabtu",
    sunday: "Minggu",
  };

  const [checkboxInput, setCheckboxInput] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const [durationCheckbox, setDurationCheckbox] = useState({
    wholeYear: false,
  });

  const onCheckboxFreeDayChange = (e, day) => {
    const value = e.target.value;
    checkboxInput[day] = !value;
    setCheckboxInput({ ...checkboxInput });
  };

  const onCheckboxWholeYearChange = (e) => {
    const value = e.target.value;
    setDurationCheckbox({ wholeYear: !value });
  };

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        var galleryPhotos = [];
        const response = await freeActivityRef.get();
        if (response.exists) {
          data = response.data();
          data.gallery.forEach((photo) => {
            galleryPhotos.push({ preview: photo.img, name: photo.title });
          });

          setCheckboxInput(data.days ? data.days : checkboxInput);
          setDurationCheckbox({ wholeYear: data.wholeYear || false });

          if (
            data.dateRange &&
            data.dateRange.startDate &&
            data.dateRange.startDate
          ) {
            setRange({
              startDate: moment(data.dateRange?.startDate.toDate()),
              endDate: moment(data.dateRange?.endDate.toDate()),
            });
          }
        }
        setFreeActivityData(data);
        setPhotos(galleryPhotos);
        setMainPhoto({ preview: data.img });
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    if (id !== "new") {
      setNewActivity(false);
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  const getCategoryOptions = () => {
    const options = [];
    activityCategories.map((cat) => {
      options.push({
        value: cat,
        label: categoriesMap[cat],
      });
    });
    return options;
  };

  const handleCategoryOptionChange = (e) => {
    setFreeActivityData({ ...freeActivityData, category: e.value });
  };

  const onOpeningHoursChange = (e, id) => {
    const openingHoursTemp = freeActivityData.openingHours
      ? freeActivityData.openingHours
      : ["", "", "", "", "", "", ""];
    openingHoursTemp[id] = e;
    setFreeActivityData({
      ...freeActivityData,
      openingHours: openingHoursTemp,
    });
  };

  const setFormDataValue = (property, value) => {
    setFreeActivityData({ ...freeActivityData, [property]: value });
  };

  const deleteFromCity = () => {
    firebase
      .firestore()
      .collection("countries")
      .doc(country)
      .collection("cities")
      .doc(city)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        var freeActivityObj = data["freeActivity"];

        if (freeActivityObj) {
          var newObj = {};
          Object.keys(freeActivityObj).forEach((key) => {
            if (key !== id) {
              newObj[key] = freeActivityObj[key];
            }
          });

          firebase
            .firestore()
            .collection("countries")
            .doc(country)
            .collection("cities")
            .doc(city)
            .set(
              {
                freeActivity: newObj,
              },
              { merge: true }
            )
            .catch((err) => console.log(err));
        }
      });
  };

  const deleteFromWishtrip = () => {
    var wishtripUserList = freeActivityData.wishtripUser;
    wishtripUserList &&
      wishtripUserList.forEach((uid) => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("wishtrip")
          .doc(id)
          .delete()
          .catch((err) => console.log(err));
      });
  };

  const deleteActivity = () => {
    freeActivityRef
      .delete()
      .then(() => {
        deleteFromWishtrip();
        deleteFromCity();
        toast.success("Berhasil dihapus", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push(
          `${process.env.PUBLIC_URL}/country/city-detail/${country}/${city}`
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const updateCity = async (docId, docData) => {
    const freeActivityCityData = {
      name: docData.name,
      category: docData.category,
      coordinates: docData.coordinates,
      img: docData.img,
      frequency: docData.frequency,
      wholeYear: docData.wholeYear,
      duration: docData.duration,
      city: docData.city,
      country: docData.country,
      days: docData.days,
    };
    await firebase
      .firestore()
      .collection("countries")
      .doc(docData.country)
      .collection("cities")
      .doc(docData.city)
      .set(
        {
          freeActivity: {
            [docId]: freeActivityCityData,
          },
        },
        { merge: true }
      )
      .catch((err) => console.log(err));
  };

  const updateWishtripGetInfo = (docId, docData) => {
    const freeActivityWishtripData = {
      name: docData.name,
      category: docData.category,
      img: docData.img,
      frequency: docData.frequency,
      wholeYear: docData.wholeYear,
      duration: docData.duration,
      city: docData.city,
      country: docData.country,
    };

    var wishtripUserList = freeActivityData.wishtripUser;
    wishtripUserList &&
      wishtripUserList.forEach((uid) => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("wishtrip")
          .doc(docId)
          .set(freeActivityWishtripData, { merge: true });
      });
  };

  const onSubmit = (data) => {
    var docId = "";
    var swiper = [];
    var coordinates = { latitude: "", longitude: "" };

    if (data !== "") {
      coordinates.latitude = data["coordinatesLat"];
      coordinates.longitude = data["coordinatesLong"];

      var contact = {
        phone: data["phone"] || "",
        email: data["email"] || "",
        website: data["website"] || "",
      };

      if (newActivity) {
        docId = uuidv4();
      } else {
        docId = id;
      }

      photos.forEach((photo) => {
        swiper.push({
          img: photo["preview"],
          title: photo["name"],
        });
      });

      const wholeYear = durationCheckbox.wholeYear;
      const durationRange = {
        startDate: range.startDate.toDate(),
        endDate: range.endDate.toDate(),
      };

      const docData = {
        name: data["name"],
        address: data["address"],
        days: checkboxInput || {},
        frequency: data["frequency"],
        wholeYear,
        duration: !wholeYear ? durationRange : "",
        zip: data["zip"],
        city,
        country,
        coordinates,
        description: data["description"],
        category: freeActivityData["category"],
        contact,
        openingHours: freeActivityData["openingHours"] || [],
        gallery: swiper,
        img: mainPhoto.preview || "",
        tnc: freeActivityData.tnc || [],
        faq: freeActivityData.faq || [],
      };

      firebase
        .firestore()
        .collection("countries")
        .doc(country)
        .collection("cities")
        .doc(city)
        .collection("freeActivity")
        .doc(docId)
        .set(docData, { merge: true })
        .then(() => {
          updateWishtripGetInfo(docId, docData);
          updateCity(docId, docData).then(() => {
            history.push(
              `${process.env.PUBLIC_URL}/country/city-detail/${country}/${city}`
            );
          });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    }
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Modal isOpen={deleteModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Konfirmasi</ModalHeader>

        <ModalFooter>
          <ModalBody>Hapus Kegiatan?</ModalBody>
          <Button className="btn-success-gradien mr-2" onClick={deleteActivity}>
            Ya
          </Button>{" "}
          <Button className="btn-secondary-gradien mr-2" onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Col style={{ margin: "0 auto" }}>
          <div className="authentication-box contact-profile-form">
            <Card className="mt-4">
              <CardBody>
                <h2 className="mb-5">Kegiatan Gratis</h2>
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <Label for="mainImage" className="d-block form-label">
                    Foto Utama <span className="text-danger">*</span>
                  </Label>

                  <div
                    {...getRootProps2({ className: "dropzone dz-clickable" })}
                  >
                    <input {...getInputProps2()} />
                    <div className="dz-message text-muted">
                      <p>Klik untuk ubah foto baru (max 1)</p>
                      <img
                        src={mainPhoto["preview"]}
                        alt={mainPhoto["preview"]}
                        className="avatar avatar-large mb-2"
                      />
                      {!photoUploadFinished && (
                        <div className="loader-box">
                          <div className="loader-3"></div>
                        </div>
                      )}
                    </div>
                  </div>

                  <FormGroup>
                    <Label className="col-form-label">
                      Nama <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="name"
                      innerRef={register({ required: true })}
                      defaultValue={freeActivityData.name}
                    />
                    <span style={{ color: "red" }}>
                      {errors.name && "Harus diisi"}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Kategori</Label>
                    <Select
                      defaultValue={{
                        value: freeActivityData.category,
                        label: freeActivityData.category
                          ? categoriesMap[freeActivityData.category]
                          : "",
                      }}
                      name="category"
                      options={getCategoryOptions()}
                      onChange={handleCategoryOptionChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Deskripsi <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="description"
                      innerRef={register({ required: true })}
                      defaultValue={freeActivityData.description}
                    />
                    <span style={{ color: "red" }}>
                      {errors.description && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Alamat<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="address"
                      innerRef={register({ required: true })}
                      defaultValue={freeActivityData.address}
                    />
                    <span style={{ color: "red" }}>
                      {errors.address && "Harus diisi"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      ZIP<span className="text-danger">*</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="zip"
                      innerRef={register({ required: true })}
                      defaultValue={freeActivityData.zip}
                    />
                    <span style={{ color: "red" }}>
                      {errors.zip && "Harus diisi"}
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Kota, Negara</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="city"
                      innerRef={register({ required: false })}
                      defaultValue={`${city}, ${country}`}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      Koordinat<span className="text-danger">*</span>
                      {"  "}
                      <span>
                        <a
                          href="https://www.latlong.net/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Cari Koordinat
                        </a>
                      </span>
                    </Label>
                    <Row>
                      <Col lg="3">
                        <Input
                          className="form-control"
                          type="text"
                          name="coordinatesLat"
                          innerRef={register({ required: true })}
                          defaultValue={
                            freeActivityData.coordinates &&
                            freeActivityData.coordinates.latitude
                          }
                        />
                        <span style={{ color: "red" }}>
                          {errors.coordinatesLat && "Harus diisi"}
                        </span>
                      </Col>
                      <Col lg="3">
                        <Input
                          className="form-control"
                          type="text"
                          name="coordinatesLong"
                          innerRef={register({ required: true })}
                          defaultValue={
                            freeActivityData.coordinates &&
                            freeActivityData.coordinates.longitude
                          }
                        />
                        <span style={{ color: "red" }}>
                          {errors.coordinatesLong && "Harus diisi"}
                        </span>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label pt-2">
                      Durasi <span className="text-danger">*</span>
                    </Label>
                    <div>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="wholeYear"
                          key="wholeYear"
                          name="wholeYear"
                          value={durationCheckbox.wholeYear || ""}
                          defaultChecked={
                            durationCheckbox
                              ? durationCheckbox.wholeYear
                              : false
                          }
                          onChange={(e) => onCheckboxWholeYearChange(e)}
                        />{" "}
                        Sepanjang Tahun
                      </Label>
                    </div>

                    {!durationCheckbox.wholeYear && (
                      <div>
                        <p>Tanggal mulai - Tanggal selesai</p>
                        <DateRangePicker
                          startDate={range.startDate}
                          startDateId="startDate"
                          startDatePlaceholderText="Dari"
                          endDate={range.endDate}
                          endDateId="endDate"
                          endDatePlaceholderText="Sampai"
                          block={true}
                          onDatesChange={({ startDate, endDate }) =>
                            setRange({ startDate, endDate })
                          }
                          focusedInput={dateFocused}
                          onFocusChange={(dateFocused) =>
                            setDateFocused(dateFocused)
                          }
                          orientation={"horizontal"}
                          required
                          isOutsideRange={() => false}
                        />
                      </div>
                    )}
                  </FormGroup>

                  <Label className="col-form-label">
                    Hari Gratis <span className="text-danger">*</span>
                  </Label>

                  {days.map((keyName, i) => (
                    <FormGroup check key={i}>
                      <Label check>
                        <Input
                          type="checkbox"
                          id={keyName}
                          key={keyName}
                          name={keyName}
                          value={
                            checkboxInput && (checkboxInput[keyName] || "")
                          }
                          defaultChecked={
                            checkboxInput ? checkboxInput[keyName] : false
                          }
                          onChange={(e) => onCheckboxFreeDayChange(e, keyName)}
                        />{" "}
                        {daysMap[keyName]}
                      </Label>
                    </FormGroup>
                  ))}

                  <FormGroup>
                    <Label className="col-form-label">
                      Keterangan <span className="text-danger">*</span>
                    </Label>
                    <span> (Senin pertama setiap bulan, ...)</span>
                    <Input
                      className="form-control"
                      type="text"
                      name="frequency"
                      innerRef={register({ required: true })}
                      defaultValue={freeActivityData.frequency}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">Kontak</Label>
                    <Row>
                      <Col lg="2">
                        <p>Phone</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="phone"
                          name="phone"
                          innerRef={register({ required: false })}
                          defaultValue={
                            freeActivityData.contact &&
                            freeActivityData.contact.phone
                          }
                        />
                      </Col>
                      <Col lg="2">
                        <p>Email</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="email"
                          name="email"
                          innerRef={register({ required: false })}
                          defaultValue={
                            freeActivityData.contact &&
                            freeActivityData.contact.email
                          }
                        />
                      </Col>
                      <Col lg="2">
                        <p>Website</p>
                      </Col>
                      <Col lg="10">
                        <Input
                          className="form-control"
                          type="text"
                          name="website"
                          innerRef={register({ required: false })}
                          defaultValue={
                            freeActivityData.contact &&
                            freeActivityData.contact.website
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Jam Buka</Label>

                    {days.map((el, id) => (
                      <Row key={id} className="mb-5">
                        <Col lg="2">
                          <p>{daysMap[el]}</p>
                        </Col>
                        <Col lg="10">
                          <OpeningHoursInput
                            value={
                              freeActivityData.openingHours &&
                              freeActivityData.openingHours[id]
                            }
                            setValue={(e) => onOpeningHoursChange(e, id)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </FormGroup>

                  <FormGroup className="mt-5">
                    <h4>TNC Spesifik</h4>
                    <EntryComponent
                      value={freeActivityData.tnc}
                      setValue={(value) => setFormDataValue("tnc", value)}
                    />
                  </FormGroup>

                  <FormGroup className="mt-5">
                    <h4>FAQ Spesifik</h4>
                    <EntryComponent
                      value={freeActivityData.faq}
                      setValue={(value) => setFormDataValue("faq", value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label pt-0">Foto Galeri</Label>
                    <Row className="ml-n1 mr-n1">
                      {photos &&
                        photos.map((file, index) => (
                          <Col key={index} lg="4" xs="6" className="px-1 mb-2">
                            <img
                              src={file.preview}
                              alt={file.preview}
                              className="img-fluid"
                            />

                            <FileDownload url={file.preview} />
                          </Col>
                        ))}
                    </Row>
                    <div
                      {...getRootProps({ className: "dropzone dz-clickable" })}
                    >
                      <input {...getInputProps()} />

                      <div className="dz-message text-muted">
                        <p>Upload file-file baru (minimal 2)</p>
                        {!newActivity && (
                          <p>Foto-foto lama akan diganti dengan foto baru</p>
                        )}
                        {!photoUploadFinished && (
                          <div className="loader-box">
                            <div className="loader-3"></div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* {uploadError &&
                                        <p className="text-danger">Harap upload min. 2, maks. 10 fotomu.</p>
                                    } */}
                  </FormGroup>

                  <FormGroup className="form-row mt-3 mb-0">
                    <Button
                      color="mr-2"
                      type="submit"
                      className="btn-success-gradien mr-2"
                      disabled={!photoUploadFinished}
                    >
                      Simpan
                    </Button>
                    {!newActivity && (
                      <Button
                        type="button"
                        onClick={toggle}
                        color="mr-2"
                        className="btn-secondary-gradien mr-2"
                      >
                        Hapus Kegiatan
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Container>
    </Fragment>
  );
};

export default FreeActivitiesDetail;
