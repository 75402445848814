import React, { Fragment, useEffect, useState } from "react";
import { Card, CardHeader, Row, Col, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import firebase from "../../data/firebase";
import {
  fetchCityData,
  getCityImage,
  replaceImageUrl,
  upperCaseFirst,
} from "../../data/helper";
import MaterialUiTable from "../common/MaterialUiTable";

const UserUpdateRequestList = () => {
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (cityData) => {
      try {
        const response = await firebase
          .firestore()
          .collection("aggregateList")
          .doc("userList")
          .get();
        var responseData = response.data();
        var data = [];
        for (const key in responseData) {
          const value = responseData[key];
          if (value.status === "pending_update_confirmation") {
            const image = getCityImage(value.city, cityData);

            const avatar = replaceImageUrl(value.avatar, 80, 80);

            data.push({
              id: key,
              avatar,
              nameEmail: value.fullName + "," + value.email,
              country: value.country,
              city: value.city,
              cityCountry:
                image +
                "," +
                upperCaseFirst(value.city) +
                "," +
                upperCaseFirst(value.country),
              status: value.status,
            });
          }
        }
        setUserList(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchCityData().then((cityData) => {
      fetchData(cityData);
    });
  }, []);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "avatar",
      label: " ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img className="avatar-sm" src={value} alt={value} />;
        },
      },
    },
    {
      name: "nameEmail",
      label: "NAMA",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var name = value.split(",")[0];
          var email = value.split(",")[1];
          return (
            <div>
              <p className="my-0 font-weight-bold">{name}</p>
              <p className="my-0">{email}</p>
            </div>
          );
        },
      },
    },
    {
      name: "country",
      options: {
        display: "excluded",
      },
    },
    {
      name: "city",
      options: {
        display: "excluded",
      },
    },
    {
      name: "cityCountry",
      label: "DOMISILI",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var img = value.split(",")[0];
          var city = value.split(",")[1];
          var country = value.split(",")[2];
          return (
            <Row>
              <Col lg="4">
                <img
                  className="avatar-rect w-100"
                  src={
                    img
                      ? img
                      : "https://via.placeholder.com/40/FFFFFF/000000/?text=" +
                        city
                  }
                  alt={city}
                />
              </Col>
              <Col lg="8">
                <p className="my-0 font-weight-bold">{city}</p>
                <p className="my-0">{country}</p>
              </Col>
            </Row>
          );
        },
      },
    },
    // {
    //   name: "createdAt",
    //   label: "WAKTU KIRIM FORM",
    //   options: {
    //     filter: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <div>
    //           <Badge color="light" pill style={{ color: "green" }}>
    //             <i className="fa fa-circle"></i>{" "}
    //             {moment().diff(value.toDate(), "days")} hari yang lalu
    //           </Badge>
    //           <p>({formatDateTime(value)})</p>
    //         </div>
    //       );
    //     },
    //   },
    // },
    {
      name: "status",
      label: "STATUS",
    },
  ];

  const toFormDetail = (id) => {
    history.push(
      `${process.env.PUBLIC_URL}/community/user-update-request-detail/${id}`
    );
  };

  if (loading) {
    return (
      <div className="loader-box">
        <div className="loader-3"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Row className="ml-1">
            <h5 className="line-height-inherit mr-3">User Update List</h5>
            <p className="text-muted">(total {userList.length})</p>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <MaterialUiTable
            columns={columns}
            data={userList}
            toItemDetail={toFormDetail}
            sortInitialColumn="status"
            initialColDirection="asc"
            searchableCols="Nama, Email, Negara, Kota, Status"
            defaultSearchInput={true}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default UserUpdateRequestList;
